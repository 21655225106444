/***************************************************************************************
 * FileName      : AddProductModal.js
 * Description   : 소곤핏 관리자 > 브랜드 > 전체 상품 목록 > 디자인 라벨 추가 모달 컴포넌트
 * Company       : (주)엘리그
 * Author        : 최현민
 * Created Date  : 2024.06.10
 * Modifide Date : 2024.06.11 : CHM : 사이즈라벨링, 디자인라벨링 모달 추가
 *               :
 * Reference     :
 ***************************************************************************************/

import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import {
	getProductDesign,
	putProductDesign,
} from "../../../../api/admin/brandApi";
import { AddDesignLabelModal } from "./AddDesignLabelModal";

// 디자인 라벨 모달(생성/수정 구분)
export const DesignLabelModal = ({ id, show, onHide, type }) => {
	if (type === "create") {
		return <AddDesignLabelModal id={id} show={show} onHide={onHide} />;
	} else if (type === "update") {
		return <EditDesignLabelModal id={id} show={show} onHide={onHide} />;
	}
};

// 디자인 라벨 질문 및 답변 항목
export const radioButtonGroups = [
	{
		label: "패드 파우치 유무",
		name: "padPouch",
		options: [
			{ value: "y", label: "있음" },
			{ value: "n", label: "없음" },
		],
	},
	{
		label: "어깨끈 탈부착 가능 유무",
		name: "detachableStrap",
		options: [
			{ value: "y", label: "가능" },
			{ value: "n", label: "불가능" },
		],
	},
	{
		label: "봉제라인 유무",
		name: "wingLine",
		options: [
			{ value: "y", label: "있음" },
			{ value: "n", label: "없음" },
		],
	},
	{
		label: "후크 위치",
		name: "hookPosition",
		options: [
			{ value: "front", label: "앞" },
			{ value: "back", label: "뒤" },
			{ value: "nothing", label: "뒤없음" },
		],
	},
	{
		label: "컵 디자인",
		name: "cupDesign",
		options: [
			{ value: "alllace", label: "전체레이스" },
			{ value: "partiallace", label: "부분레이스" },
			{ value: "ribbondecoration", label: "리본장식" },
			{ value: "pleatsdecoration", label: "주름장식" },
			{ value: "multiplestrap", label: "다중스트랩" },
			{ value: "plain", label: "무지" },
			{ value: "pattern", label: "패턴" },
			{ value: "spangle", label: "스팽글" },
			{ value: "leather", label: "레더" },
			{ value: "velvet", label: "벨벳" },
			{ value: "cubic", label: "큐빅" },
		],
	},
	{
		label: "둘레 디자인",
		name: "wingDesign",
		options: [
			{ value: "alllace", label: "전체레이스" },
			{ value: "partiallace", label: "부분레이스" },
			{ value: "multiplestrap", label: "다중스트랩" },
			{ value: "nudi", label: "누디" },
			{ value: "spandex", label: "스판" },
			{ value: "mesh", label: "망사" },
			{ value: "cotton", label: "면" },
			{ value: "modal", label: "모달" },
			{ value: "cool", label: "쿨소재(여름원단)" },
			{ value: "warm", label: "웜소재(겨울원단" },
			{ value: "seamless", label: "심리스" },
			{ value: "band", label: "밴드" },
		],
	},
	{
		label: "어깨끈 디자인",
		name: "strapDesign",
		options: [
			{ value: "lace", label: "레이스" },
			{ value: "lettering", label: "레터링" },
			{ value: "multiplestrap", label: "다중스트랩" },
			{ value: "wideband", label: "넓은밴드" },
			{ value: "regularband", label: "기본밴드" },
			{ value: "halterneck", label: "홀터넥" },
			{ value: "strapless", label: "어깨끈없음" },
		],
	},
	{
		label: "색상",
		name: "color",
		options: [
			{ value: "warmcolor", label: "웜톤" },
			{ value: "coolcolor", label: "쿨톤" },
			{ value: "achromaticcolor", label: "무채색 컬러" },
			{ value: "beigecolor", label: "베이지 계열" },
			{ value: "softcolor", label: "소프트 컬러" },
		],
	},
];

// 라디오버튼 그룹 컴포넌트
export const RadioButtonGroup = ({
	label,
	options,
	name,
	handleChange,
	selectedValue,
}) => (
	<Form.Group controlId={name} className="mb-3">
		<Form.Label>{label}</Form.Label>
		<div className="custom-checkbox-group">
			{options.map((option, index) => (
				<Form.Check
					key={index}
					type="radio"
					name={name}
					id={`${name}_${option?.value}`}
					label={option?.label}
					value={option?.value}
					className="custom-checkbox custom-checkbox-long"
					custom
					onChange={handleChange}
					checked={selectedValue && selectedValue === option.value}
				/>
			))}
		</div>
	</Form.Group>
);

// 디자인 라벨 수정용 모달
const EditDesignLabelModal = ({ id, show, onHide }) => {
	const [selectedValues, setSelectedValues] = useState({
		productId: "",
		padPouch: "",
		detachableStrap: "",
		wingLine: "",
		hookPosition: "",
		cupDesign: "",
		wingDesign: "",
		strapDesign: "",
		color: "",
	});

	useEffect(() => {
		getProductDesign(id).then((res) =>
			setSelectedValues({
				productId: res?.product,
				padPouch: res?.padpouch,
				detachableStrap: res?.detachablestrap,
				wingLine: res?.wingline,
				hookPosition: res?.hookposition,
				cupDesign: res?.cupdesign,
				wingDesign: res?.wingdesign,
				strapDesign: res?.strapdesign,
				color: res?.color,
			})
		);
	}, [id, show]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setSelectedValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	// 모든 값이 선택되었는지 확인
	const isFormValid = () => {
		return Object.values(selectedValues).every((value) => value !== "");
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>라벨(디자인) 수정</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						if (isFormValid()) {
							putProductDesign({
								labelId: id,
								data: selectedValues,
							})
								.then((res) => onHide())
								.catch((error) =>
									alert("라벨 수정 중 에러 발생")
								);
						}
					}}
				>
					{radioButtonGroups.map((group, index) => (
						<RadioButtonGroup
							key={index}
							label={group?.label}
							name={group?.name}
							options={group?.options}
							handleChange={handleInputChange}
							selectedValue={selectedValues[group.name]}
						/>
					))}
					<Button
						className="w-100"
						variant={isFormValid() ? "primary" : "secondary"}
						type="submit"
						disabled={!isFormValid()}
					>
						저장
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};
