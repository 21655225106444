/***************************************************************************************
* FileName      : SampleBlock.js
* Description   : 소곤핏 관리자 > 메인 > 매거진영역관리 페이지 > 우측 샘플블록
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.04.11
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
function SampleMagazineBlock({ data}) {
    console.log("data", data);
    const imgSrc = {
        main: "/img/template_maincontents.png",
        block: "/img/template_block.png",
        list: "/img/template_list.png"
    }
    return (
        <>
            <div className="mb-3 d-flex justify-content-between">
                <div />
                <h5 className="text-center">Sample</h5>
                <div />
            </div>
            <div>
                {
                    data?.map((item) => (
                        item?.hidden == false && (
                            <>
                                <p className='fs-5 mt-5'>{item?.title}</p>
                                {item?.main_content_exist && <img src="/img/template_maincontents.png" alt="매거진영역" className="img-fluid mt-1" />}
                                {Array.from(Array(item?.y_layout).keys()).map((i) => <img src={imgSrc[item?.x_layout == 1 ? 'list': 'block']} alt="매거진영역" className="img-fluid mt-2" />)}
                            </>
                        )
                    ))
                }
            </div>
        </>
    )
}
export default SampleMagazineBlock;