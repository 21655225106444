/***************************************************************************************
* FileName      : BrandProductList.js
* Description   : 소곤핏 중간 관리자 > Product > 상품 관리 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Link } from 'react-router-dom';
import { useGetBrands } from '../../../api/admin/brandApi.js';
import { useBrandProduct } from '../../../api/admin/product.js';
import { Modal , Button } from 'react-bootstrap';

import React, { useState, useEffect } from 'react';
import ProductRow from './BrandProductList/ProductRow.js';
import AddProductModal from './BrandProductList/AddProductModal.js';
import Pagination from '../common/Pagination.js'
import { useParams } from 'react-router-dom';

function BrandProductList()
{
    // 브랜드명 추출
    const { BrandName } = useParams();
    
    const { data, error, isFetching:brandisFetching} = useGetBrands();
  
    const { data:ProductData, error:ProductError, isFetching, page, setPage } = useBrandProduct(BrandName);
  

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

 
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };


    console.log(data);
    console.log(ProductData);


    if (error) return <div>Error loading Brands</div>;
    if (!data) return <div>Loading Brands...</div>;

    return (
        <>
         <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <ul className="side-menu">

                        {/* {
                            data?.data?.length > 1? ( */}
                            <Link to='/admin/bmproduct/all'>
                                <li className={`side-item ${BrandName === "all" ? 'active' : ''}`}>
                                    전체
                                </li>
                            </Link> 
                            {/* ) : null
                        } */}
                            
                        {
                            data && data.data && data.data.length > 0 ? (
                                <>
                                {data.data.map((brand, index) => (
                                    <Link to={`/admin/bmproduct/${brand.name}`} key={index}>
                                        <li className={`side-item ${BrandName === brand.name ? 'active' : ''}`}>
                                            {brand.name}
                                        </li>
                                    </Link>
                                ))}
                                </>
                            ) : null
                        }
                        </ul>
           
                    </div>

                    <div className='col-md-12'>
                        <div className="box">
                            <div className='tit'>
                                <span className='group-title'>전체({ProductData && ProductData.totalCnt})</span>
                                <button onClick={handleShowModal}>상품 추가</button>
                            </div>
                            <table className="admin_table">
                                <thead>
                                    <th>ID</th>
                                    <th>상품명</th>
                                    <th>상품이미지</th>
                                    <th>구매 링크</th>
                                    <th>상품가격</th>
                                    <th>판매가격</th>
                                    <th>브랜드</th>
                                    <th>조회수</th>
                                    <th>BBTI</th>
                                    <th>라벨링</th>
                                    <th>공개/비공개</th>
                                    <th>수정/삭제</th>
                                </thead>
                                <tbody>
                                    {ProductData && ProductData.data && ProductData.data.length > 0 ? 
                                        ProductData.data.map((product) => (
                                        <ProductRow 
                                            key={product?.id}
                                            product={product}
                                        />
                                        )) :
                                        <tr>
                                            <td colSpan="10">데이터가 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            {/* 페이지내비게이션 */}
                            <div className='pagination-wrap'>   
                                <Pagination
                                    count={data?.totalCnt || 0}
                                    page={page}
                                    setPage={setPage}
                                    handlePrevPage={handlePrevPage}
                                    handleNextPage={handleNextPage}
                                    />
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </section>

       {/* 새로운 상품 등록 모달 */}
       <AddProductModal
            show={showModal}
            onHide={handleCloseModal}
            
        />

        </>
    )
}

export default BrandProductList 