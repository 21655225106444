/***************************************************************************************
 * FileName      : Signup.js
 * Description   : 소곤핏 고객 회원가입
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.09
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { useState, useEffect } from "react";
import MobileHeader from "./Component/MobileHeader";
import { useForm } from "react-hook-form";
import { getCheckedUsername } from "../../api/admin/memberApi.js";
import { registerUser } from "../../api/services/memberApi.js";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { LoadingSpinner } from "../admin/Component/LoadingSpinner.js";
import { getCheckedNickName } from "../../api/services/memberApi.js";
import { Helmet } from "react-helmet-async";
import {
	verifyphoneNumber,
	verifyPhoneNumberCheck,
} from "../../api/services/memberApi.js";
import { formatTime } from "./Common/FormatTime.js";
import Swal from "sweetalert2";
import { showAlert } from './Common/alertUtil.js';


function Signup() {
	const queryClient = useQueryClient();

	// 로딩 상태
	const [isLoading, setIsLoading] = useState(false);

	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [authRequestsCount, setAuthRequestsCount] = useState(0); // 인증 요청 횟수 상태
	const maxAuthRequests = 5; // 최대 인증 요청 횟수
	const [verificationCompleted, setVerificationCompleted] = useState(false); // 인증 완료 여부 상태

	// ID 중복확인 , 형식확인 했는지 상태
	const [idCheckState, setIdCheckState] = useState(false);

	// 닉네임 중복확인 상태
	const [isNicknameChecked, setIsNicknameChecked] = useState(false);

	const [allAgreed, setAllAgreed] = useState(false);
	const [agreements, setAgreements] = useState({
		serviceAgreed: false,
		personalInfoAgreed: false,
		marketingAgreed: false,
	});

	const [phoneNumber, setPhoneNumber] = useState("");

	const [authCode, setAuthCode] = useState("");
	const [showMessage, setShowMessage] = useState(false);
	const [timer, setTimer] = useState(180);
	const [interval, setIntervalId] = useState(null);

	const [phoneHash, setPhoneHash] = useState("");

	const isPhoneNumberValid = phoneNumber.length === 11;

	const username = watch("username");
	const nickname = watch("nickname");

	// 약관동의
	const handleAgreementChange = (event) => {
		const { name, checked } = event.target;

		setAgreements((prevAgreements) => ({
			...prevAgreements,
			[name]: checked,
		}));
		const allChecked = Object.values({
			...agreements,
			[name]: checked,
		}).every((value) => value === true);
		setAllAgreed(allChecked);
	};

	// 전체 약관 동의
	const handleAllAgreementChange = (event) => {
		const { checked } = event.target;
		setAgreements((prevAgreements) =>
			Object.keys(prevAgreements).reduce(
				(newAgreements, agreementKey) => ({
					...newAgreements,
					[agreementKey]: checked,
				}),
				{}
			)
		);
		setAllAgreed(checked);
	};

	// 유효성 검사
	const validateForm = () => {
		let isValid = true;

		// id 중복확인
		if (!idCheckState) {
			showAlert("ID 중복 확인은 필수입니다."); 
			return;
		}

		// 닉네임 중복확인
		if (!isNicknameChecked) {
			showAlert("닉네임 중복 확인은 필수입니다."); 
			return;
		}

		// 휴대폰 번호 확인
		if (phoneNumber.length !== 11) {
			showAlert( "휴대폰 번호를 확인해주세요."); 
			return;
		}
		// 약관동의
		if (!agreements.serviceAgreed) {
			showAlert("서비스 이용약관에 동의해주세요."); 
			return;
		}

		if (!agreements.personalInfoAgreed) {
			showAlert("개인정보 취급방침에 동의해주세요."); 
			return;
		}

		if (!phoneHash) {
			showAlert("휴대폰 인증은 필수입니다."); 
			return;
		}
		return isValid;
	};

	// id 확인
	const handleIdCheck = (event) => {
		event.preventDefault();

		if(username){
			getCheckedUsername(username)
				.then((data) => {
					showAlert(data?.data); 
					setIdCheckState(true);
				})
				.catch((error) => {
					showAlert(error.response.data.error); 
				});
			}
		else{
			showAlert("아이디를 입력해주세요."); 
		}
	};

	// 휴대폰 입력
	const handlePhoneNumberChange = (e) => {
		const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
		setPhoneNumber(onlyNumbers);
	};

	// 휴대폰 인증번호 요청
	const handlePhoneAuth = (event) => {
		event.preventDefault();

		verifyphoneNumber(phoneNumber)
			.then((res) => {
				showAlert(res?.data?.data); 
			
				setShowMessage(true);
				setTimer(180);
			})
			.catch((error) => {
				showAlert(error?.response?.data?.error ||
					"휴대폰 번호를 확인해주세요.",); 
			
			});
	};

	// 휴대폰 인증번호 확인
	const handlePhoneAuthCheck = (event) => {
		event.preventDefault();

		if (!phoneNumber) {
			showAlert("휴대폰 번호를 입력하여 인증번호를 요청해주세요."); 
			return;
		}
		if (!authCode) {
			showAlert("인증번호를 입력해주세요."); 
			
			return;
		}

		verifyPhoneNumberCheck(phoneNumber, authCode)
			.then((res) => {
				showAlert(res?.data?.data); 
				
				setPhoneHash(res?.data?.phone_hash);
				setVerificationCompleted(true); // 인증 완료 상태로 설정
				clearInterval(interval);
			})
			.catch((error) => {
				showAlert(error?.response?.data?.error ||
					"휴대폰 번호를 확인해주세요."); 
			});
	};

	// 닉네임 중복확인
	const handleNickNameCheck = (event) => {
		event.preventDefault();
		if(nickname){
			getCheckedNickName(nickname)
			.then((data) => {
				showAlert(data.data); 
				
				setIsNicknameChecked(true);
			})
			.catch((error) => {
				showAlert(error.response.data.error); 
				
			});
		}else{
			showAlert("닉네임을 입력해주세요."); 
		
		}
		
	};

	const mutation = useMutation(registerUser, {
		onMutate: () => {},
		onSuccess: (data) => {
			Swal.fire({
				text: "회원가입이 완료되었습니다. 로그인 페이지로 이동합니다.",
				showCancelButton: false, 
				confirmButtonText: "확인",				
			
			}).then((result) => {
				if (result.isConfirmed) {
					setIsLoading(false);
					reset();
					// 회원가입 완료 이벤트 추가
					window.hackleClient.track({ key: "complete_signup" });
					window.location.href = "/login";
				}
			});

			
		},
		onError: (error) => {
			const errors = error.response.data.error;
			const firstErrorMessage = Array.isArray(errors)
            ? Object.entries(errors[0]).map(([key, value]) => `${key}: ${value}`).join('\n')
            : errors;
        	
			showAlert(firstErrorMessage); 
		
			setIsLoading(false);
		},
	});

	const onSubmit = (data) => {
		if (validateForm()) {
			setIsLoading(true);

			data.marketing_agree = agreements.marketingAgreed;
			data.phone_number = phoneNumber;
			data.phone_hash = phoneHash;
			mutation.mutate(data);
		}
	};

	useEffect(() => {
		setIdCheckState(false);
	}, [username]);

	useEffect(() => {
		setIsNicknameChecked(false);
	}, [nickname]);

	// 휴대폰 인증 시간 제한
	useEffect(() => {
		let id;
		if (showMessage && timer > 0) {
			id = setInterval(() => {
				setTimer((prev) => prev - 1);
			}, 1000);
			setIntervalId(id); // interval id 저장
		}
		if (timer === 0) {
			setShowMessage(false);
		}
		return () => clearInterval(id);
	}, [showMessage, timer]);

	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 회원가입</title>
			</Helmet>
			<MobileHeader back={true} title={"회원가입"} />
			<div className="signup mt-5">
				<div className="inner">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="join-form">
							<div className="require">
								<p>필수 입력 정보</p>
							</div>

							<div className="input_box">
								<div className="icheck_box">
									<div>
										<p>아이디</p>
										<input
											type="text"
											name="username"
											placeholder="영문+숫자 6자 이상"
											className={
												errors.username
													? "error-input2"
													: ""
											}
											{...register("username", {
												required:
													"사용자 이름을 입력하세요",
											})}
										/>
									</div>
									<button
										className="pink_btn"
										onClick={handleIdCheck}
									>
										ID중복확인
									</button>
								</div>

								<div className="join-field">
									<p>비밀번호</p>
									<input
										type="password"
										name="password"
										placeholder="영문,숫자,특수문자 조합 8자 이상"
										className={
											errors.password
												? "error-input2"
												: ""
										}
										{...register("password", {
											required: "비밀번호를 입력하세요",
										})}
									/>

									{errors.password && (
										<span className="error-message">
											{errors.password.message}
										</span>
									)}
								</div>

								<div className="join-field">
									<p>비밀번호 재확인</p>
									<input
										type="password"
										name="password2"
										placeholder='비밀번호 재입력'
										className={
											errors.password2
												? "error-input2"
												: ""
										}
										{...register("password2", {
											required: "비밀번호를 입력하세요",
										})}
									/>
								</div>

								<div className="join-field">
									<p>이름</p>
									<input
										type="name"
										name="name"
										className={
											errors.name ? "error-input2" : ""
										}
										{...register("name", {
											required: "이름을 입력하세요",
										})}
									/>
								</div>

								<div className="input_box">
									<div className="icheck_box">
										<div>
											<p>닉네임</p>
											<input
												type="text"
												name="nickname"
												className={
													errors.nickname
														? "error-input2"
														: ""
												}
												{...register("nickname", {
													required:
														"닉네임을 입력하세요",
												})}
											/>
										</div>
										<button
											className="pink_btn"
											onClick={handleNickNameCheck}
										>
											중복확인
										</button>
									</div>
								</div>
							
								<div className="join-field">
									<p>이메일</p>
									<input
										type="email"
										name="email"
										placeholder='계정 분실 시 본인인증 정보로 활용됩니다.'
										className={
											errors.email ? "error-input2" : ""
										}
										{...register("email", {
											required: "이메일를 입력하세요",
										})}
									/>
								</div>

								<div className="input_box">
									<div className="icheck_box">
										<div>
											<p>휴대폰 번호</p>
											<input
												type="text"
												name="phoneNumber"
												placeholder="-없이 휴대폰 번호를 입력해주세요."
												onChange={
													handlePhoneNumberChange
												}
												disabled={verificationCompleted}
											/>
										</div>
										<button
											className="pink_btn"
											onClick={handlePhoneAuth}
											disabled={!isPhoneNumberValid}
										>
											인증요청
										</button>
									</div>
								</div>

								{showMessage && (
									<>
										<div className="fm-wrap mb-4">
											<p>
												인증번호를 전송하였습니다.
												(재전송 가능 남은시간:{" "}
												{formatTime(timer)})
											</p>
										</div>

										<div className="input_box">
											<div className="icheck_box">
												<div>
													<p>인증번호</p>
													<input
														type="text"
														name="code"
														onChange={(e) =>
															setAuthCode(
																e.target.value
															)
														}
														disabled={
															verificationCompleted
														}
													/>
												</div>
												<button
													className="pink_btn"
													onClick={
														handlePhoneAuthCheck
													}
												>
													인증하기
												</button>
											</div>
										</div>
									</>
								)}
							</div>

							<div className="require">
								<p>약관동의</p>
							</div>
							<div className="agree-term">
								<span className="check mb-3">
									<input
										type="checkbox"
										id="chkAll"
										className="checkboxA"
										checked={allAgreed}
										onChange={handleAllAgreementChange}
									/>
									<label for="chkAll">전체 약관 동의</label>
								</span>

								<ul className="checkTermArea">
									<li className="mb-2">
										<span className="check">
											<input
												type="checkbox"
												id="term_s"
												className="checkboxA s"
												name="serviceAgreed"
												checked={
													agreements.serviceAgreed
												}
												onChange={handleAgreementChange}
											/>
											<label for="term_s">
												서비스 이용약관 동의(필수)
											</label>
										</span>
										<a
											onClick={() =>
												window.open(
													"https://suave-cloudberry-17e.notion.site/36de9191557a416f93b016dcc84e3b37"
												)
											}
										>
											상세보기
										</a>
									</li>
									<li className="mb-2">
										<span className="check">
											<input
												type="checkbox"
												id="term_p"
												className="checkboxA s"
												name="personalInfoAgreed"
												checked={
													agreements.personalInfoAgreed
												}
												onChange={handleAgreementChange}
											/>
											<label for="term_p">
												개인정보 취급방침(필수)
											</label>
										</span>
										<a
											onClick={() =>
												window.open(
													"https://suave-cloudberry-17e.notion.site/9fb9bbd74fc04e56b33981394226f4ce"
												)
											}
										>
											상세보기
										</a>
									</li>
									<li className="mb-2">
										<span className="check">
											<input
												type="checkbox"
												id="term_m"
												className="checkboxA s"
												name="marketingAgreed"
												checked={
													agreements.marketingAgreed
												}
												onChange={handleAgreementChange}
											/>
											<label for="term_m">
												마케팅 정보 수신(선택)
											</label>
										</span>
										<a href="#">상세보기</a>
									</li>
								</ul>
							</div>
						</div>
						<button
							className="btn-primary btn-login mb-5"
							isLoding={mutation.isLoading}
							type="submit"
						>
							{isLoading ? <LoadingSpinner /> : "회원가입"}
						</button>
					</form>
				</div>
			</div>
		</>
	);
}

export default Signup;
