/***************************************************************************************
* FileName      : MagazineLogBoard.js
* Description   : 소곤핏 관리자,중간관리자 > 매거진 > log 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import MagazineMenu from "./MagazineMenu.js"
import { Link , useParams } from 'react-router-dom';
import { useGetCategory  , deleteMagazineCategory , useMagazineLog } from "../../../api/admin/magazine.js";

import Pagination from "../common/Pagination.js";
import MagazineLogRow from './MagazineLogBoard/MagazineLogRow.js';
import { useEffect, useState } from "react";
// import { DropdownButton, Dropdown  } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import { getMe } from "../../../api/common/memberApi.js";

function MagazineLogBoard()
{
    // id 추출
    const { magazineCategoryId } = useParams();

   
    const { data, error, isFetching, page, setPage, toggle, setToggle } = useMagazineLog(magazineCategoryId);

    // 카테고리 조회
    const { isLoading , data:categoryData } = useGetCategory();

    const [role, setRole] = useState(null);


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    useEffect(()=>{
        getMe()
        .then(data => {
            setRole(data.role);
        })
        .catch(error => {
          console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
        });

    }, [])

    return (
        <>
         <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    
                    <aside className="col-md-12  menu-2">
                        <MagazineMenu categoryData={categoryData} role={role}/>
                    </aside>
                    
                       
                    <div className="col-md-12">
                        <div className="box">
                            <div className="tit">
                                <span className="group-title">
                                    전체({data?.totalCnt ? data?.totalCnt : 0 })
                                </span>
                               
                            </div>
                            
                            <table className="admin_table">
                                <colgroup>
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                </colgroup>
                                <thead>
                                    <th>ID</th>
                                    <th>MAGAZINE</th>
                                    <th>대표이미지</th>
                                    <th>제목</th>
                                    <th>URL</th>
                                    <th>콘텐츠</th>
                                    <th>작성일</th>
                                    <th>작성자</th>
                                    <th>공개/비공개</th>
                                    <th>수정/등록</th>
                                    <th>승인상태</th>
                                    <th>
                                    {role === null ? "승인/거절" : "재요청"}
                                    </th>
                                    
                                </thead>
                                <tbody>
                                    {data && data.data && data.data.length > 0 ? 
                                        data.data.map((magazine) => (
                                            <MagazineLogRow 
                                                key={magazine?.id}
                                                magazine={magazine}
                                                role={role}
                                            />
                                        )) :
                                        <tr>
                                            <td colSpan="컬럼수">데이터가 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            {/* 페이지내비게이션 */}
                            <div className='pagination-wrap'>   
                                <Pagination
                                    count={data?.totalCnt || 0}
                                    page={page}
                                    setPage={setPage}
                                    handlePrevPage={handlePrevPage}
                                    handleNextPage={handleNextPage}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        </>
    )
}



export default MagazineLogBoard 