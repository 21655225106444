/***************************************************************************************
 * FileName      : ContentModal.js
 * Description   : 소곤핏 관리자 > 소곤톡 > 상세 내용 모달창
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.06.26
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/

import React, { useEffect, useState } from "react";
import { formatContent } from "../../common/formatContent";
import { Modal } from "react-bootstrap";

const ContentModal = ({ show, onHide, content }) => {
	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>상세내용</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{formatContent(content) || "내용이 없습니다."}
			</Modal.Body>
		</Modal>
	);
};

export default ContentModal;
