import { queryClient, useMutation, useQuery, prefetchQuery } from "react-query";
import { instance } from "../axiosConfig.js";

export const getMainContents = () => instance.get(`layout/main/contents`);

// export const getProductContents = () => instance.get(`product/customer`);
export const getProductContents = () => instance.get(`layout/product/contents`);

// 메인페이지 소곤톡 조회
export const getPostContents = async (order) => {

	return order
		? instance.get(`post/?order=${order}&page_size=5`)
		: instance.get(`/post/?order=new&page_size=5`);
};

// 고객용 상품 조회수 기록
export const getProductViews = (id) => instance.get(`product/customer/${id}`);
