import React, { useState } from 'react';

function Step3({answers, setAnswers}) {
    
    const answerList = [
        "어깨끈 흘러내림",
        "어깨끈이 조이고 아픔",
        "6개월 이상 수유",
        "다이어트로 체중 7kg 이상 감량",
        "가슴 성형 수술",
        "가슴 절개 수술",
        "해당사항 없음"
    ];

    const handleClick = (selectedAnswer) => {
        // "해당사항 없음"을 선택한 경우의 특별 처리
        if (selectedAnswer === "해당사항 없음") {
            if (!answers.includes("해당사항 없음")) {
                setAnswers(["해당사항 없음"]); // 다른 모든 선택 해제
            } else {
                setAnswers([]); // 해당사항 없음 선택 해제
            }
            return;
        }

        // "해당사항 없음"이 이미 선택되어 있는 경우, 그것을 제거
        if (answers.includes("해당사항 없음")) {
            setAnswers([selectedAnswer]);
            return;
        }

        // 일반적인 다중 선택 처리
        if (answers.includes(selectedAnswer)) {
            setAnswers(answers.filter(item => item !== selectedAnswer));
        } else {
            setAnswers([...answers, selectedAnswer]);
        }
    };

    return (
        <>
            {answerList?.map((data, idx) => (
                <div 
                    key={idx}
                    className={answers.includes(data) ? "answer-btn active" : "answer-btn"}
                    onClick={() => handleClick(data)}
                >
                    {data}
                </div>
            ))}
        </>
    );
}

export default Step3;