import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { registerMagazineCategory } from '../../../api/admin/magazine';

const AddCategoryModal = ({ show, onHide }) => {

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();


    const createCategoryMutaion = useMutation(registerMagazineCategory , {
        onSuccess : (data) => {
            alert("카테고리 등록이 완료되었습니다")
            reset();
            onHide();
        },
        onError : (data) => {
            alert(data.request.responseText);
        }
    });


    const onSubmit = (data) => {
        const Data = {
            name: data.name,
            description: data.description,
        };
        console.log(Data);
        createCategoryMutaion.mutate(Data);
    };


    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>매거진 카테고리 추가</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>     
                <div className='signIn-form'>
                    <div className='input_box'>
                        <p>카테고리 이름</p>
                        <input 
                            type='text' 
                            name='name' 
                            className={errors.name ? 'error-input' : ''}
                            {...register('name', { required: '카테고리 명 입력은 필수입니다.' })} />
                            
                        <p>상세설명</p>
                        <input 
                            type='text' 
                            name='description' 
                            className={errors.description ? 'error-input' : ''}
                            {...register('description')} />
                       
                    </div>
                    <button className='btn_black_1' type="submit">
                       카테고리 추가
                    </button>

                </div>
            </form>
        </Modal.Body>

    </Modal>
  );
};

export default AddCategoryModal;
