/***************************************************************************************
 * FileName      : PostSkeleton.js
 * Description   : 소곤핏 소곤톡 PostSkeleton 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.09.06
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MobileHeader from "../Component/MobileHeader"

function PostSkeleton() {
	return (
		<>
		<MobileHeader back={true} title={"소곤톡"} />
		<div className="post">
			<section className="prodcut-content my-5">
				<div className="inner">
					<div className="sort-box">
						<p><Skeleton width={150} height={24} /></p>
					</div>
					<div className="post-wrap">
						{[...Array(5)].map((_, index) => (
						<div className="post-content" key={index}>
							<div className="post-tags">
								<Skeleton width={70} height={24} />
							</div>
				
							<div className="post-title-wrap">
							<p className="post-tit">
								<Skeleton width={150} height={24} />
							</p>
							<Skeleton width={20} height={20} />
							</div>
				
							<span className="post-desc">
								<Skeleton width={300} height={24} />
							</span>
							<div className="post-info">
								<div>
									<Skeleton width={150} height={24} />
								</div>
								<div className="d-flex">
									<span className="heart">
										<Skeleton width={30} height={24} />
									</span>
									<span className="comment">
										<Skeleton width={30} height={24} />
									</span>
								</div>
							</div>
						</div>
						))}
					</div>
				</div>
		</section>
		</div>

		</>

	);
}

export default PostSkeleton;
