/***************************************************************************************
* FileName      : AddProductModal.js
* Description   : 소곤핏 관리자 > 브랜드 > 전체 상품 목록 > 라벨 추가 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.06.10
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { getProduct, updateProductSizes } from '../../../../api/admin/brandApi';

export const SizeLabelModal = ({ id, show, onHide, type }) => {
    const isEdit = type === 'update';
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        if (show) {
            if (isEdit) {
                getProduct(id).then((response) => {
                    setSelectedValues(response?.sizes || []);
                    const types = new Set(response?.sizes.map(size => size.slice(-1)));
                    setSelectedTypes([...types]);
                });
            } else {
                setSelectedTypes([]);
                setSelectedValues([]);
            }
        }
    }, [show, isEdit, id]);

    const handleCheckboxChange = (size) => {
        setSelectedTypes((prevSelectedTypes) => {
            if (prevSelectedTypes.includes(size)) {
                setSelectedValues((prevSelectedValues) => prevSelectedValues.filter((value) => !value.endsWith(size)));
                return prevSelectedTypes.filter((item) => item !== size);
            } else {
                return [...prevSelectedTypes, size];
            }
        });
    };

    const handleRoundCheckboxChange = (size, round) => {
        const value = `${round}${size}`;
        setSelectedValues((prevSelectedValues) => {
            if (prevSelectedValues.includes(value)) {
                return prevSelectedValues.filter((item) => item !== value);
            } else {
                return [...prevSelectedValues, value];
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submit Values:', selectedValues);
        updateProductSizes(id, { sizes: selectedValues })
            .then(() => {
                alert('상품 라벨(사이즈) 등록이 완료되었습니다.');
                onHide();
            })
            .catch((error) => {
                console.error('상품 라벨(사이즈) 등록 중 에러 발생:', error);
            });
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? '상품 라벨(사이즈) 수정' : '상품 라벨(사이즈) 등록'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="cupSize" className='mb-3'>
                        <Form.Label>컵사이즈</Form.Label>
                        <div className='custom-checkbox-group'>
                            {['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'].map((size) => (
                                <Form.Check
                                    key={size}
                                    type="checkbox"
                                    id={`bbti_checkbox_${size}`}
                                    label={size}
                                    className="custom-checkbox custom-checkbox-circle"
                                    custom
                                    onChange={() => handleCheckboxChange(size)}
                                    checked={selectedTypes.includes(size)}
                                />
                            ))}
                        </div>
                    </Form.Group>
                    {selectedTypes.map((size) => (
                        <Form.Group controlId={`cupSize${size}`} key={size} className='mb-3'>
                            <Form.Label>{size}컵 상품의 사이즈를 모두 선택해주세요.</Form.Label>
                            <div className='custom-checkbox-group'>
                                {['65', '70', '75', '80', '85', '90', '95'].map((round) => (
                                    <Form.Check
                                        key={`${round}_${size}`}
                                        type="checkbox"
                                        id={`${round}_${size}`}
                                        label={`${round}${size}`}
                                        className="mb-2 custom-checkbox"
                                        custom
                                        onChange={() => handleRoundCheckboxChange(size, round)}
                                        checked={selectedValues.includes(`${round}${size}`)}
                                    />
                                ))}
                            </div>
                        </Form.Group>
                    ))}
                    <Button variant="primary" type="submit">
                        {isEdit ? '수정' : '등록'}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};