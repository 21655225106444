/***************************************************************************************
* FileName      : FindPassword.js
* Description   : 소곤핏 고객 > 비밀번호 찾기 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.06.12
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Helmet } from 'react-helmet-async';
import MobileHeader from "./Component/MobileHeader";
import { findPassword } from '../../api/services/memberApi';
import { useState } from 'react';
import { LoadingSpinner } from "./../admin/Component/LoadingSpinner";
import FindPassword_Phone from './FindPassword_Phone';

import { showAlert } from './Common/alertUtil.js';

function FindPassword() {

    const [selectedOption, setSelectedOption] = useState('find_email');


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = () => {
      if (!isLoading) {


        if(name === ''){
            showAlert("이름을 입력해주세요."); 
            return ;
        }
        if(email === ''){
            showAlert("이메일을 입력해주세요."); 
            return ;
        }
        if(username === ''){
          showAlert("아이디를 입력해주세요."); 
          return ;
        }
        
        setIsLoading(true);
        findPassword(name, email, username)
            .then(res =>  showAlert(res?.data?.data))
            .catch(error => {
                error?.response?.data?.error ? (
                    showAlert(error?.response?.data?.error)
                ) : (
                    console.log(error)
                )
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
    }

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.id);
    };


    return (
    <>
    <Helmet>
      <title>소곤핏 Sogonfit - 비밀번호찾기</title>
    </Helmet>
      <MobileHeader back={true} title={"비밀번호찾기"}/>
      <div className="findId mt-5">
        <div className="inner">
            <div className="find-titleArea mt-4 mb-4">
                <p>        
                일반회원 비밀번호찾기로, 네이버, 카카오로 
                <br/>
                회원가입한 회원은 비밀번호 검색이 되지 않습니다
                </p>
            </div>

            <div className='auth-method mt-4 mb-4'>
              <div className='findId-radio'>
                  <input 
                    type='radio' 
                    name='findId' 
                    id='find_email'
                    checked={selectedOption === 'find_email'}
                    onChange={handleOptionChange}
                    />
                    <label htmlFor='find_email' className='radio-button'>이메일</label>

                  <input 
                    type='radio' 
                    name='findId' 
                    id='find_phone' 
                    checked={selectedOption === 'find_phone'}
                    onChange={handleOptionChange}
                    />
                <label htmlFor='find_phone' className='radio-button'>휴대폰 번호</label>
              </div>
            </div>

          {/* 이메일로 비밀번호 찾기 */}
          <div className='findPassword-email' style={{display: selectedOption === 'find_email' ? 'block' : 'none'}}>
            <p>아이디</p>
            <input 
              type="text" 
              onChange={(e) => setUsername(e.target.value)}
              />

            <p>이름</p>
            <input 
              type="text" 
              onChange={(e) => setName(e.target.value)}
            />

            <p>이메일</p>
            <input 
              type="text"
              onChange={(e) => setEmail(e.target.value)}
              />
  
            <button className="btn-primary findBtn mt-4" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? <LoadingSpinner /> : '확인'} 
            </button>

          </div>


          {/* 휴대폰번호로 비밀번호 찾기 */}
          <FindPassword_Phone selectedOption={selectedOption}/>
        </div>
    </div>
    </>        
  );
}

export default FindPassword;