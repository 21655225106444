import React, { useEffect, useState } from 'react';

// 줄바꿈 문자를 <br /> 태그로 변환하는 함수
export const formatContent = (text) => {
    return (text || '').split('\n').map((line, index) => (
        <React.Fragment key={index}>
        {line}
        <br />
        </React.Fragment>
    ));
};