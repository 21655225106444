/***************************************************************************************
 * FileName      : MagazineBoard.js
 * Description   : 소곤핏 관리자 > 매거진 > 메인페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date : 2024.07.17 : CHM : BBTI 라벨링 임시 추가
 *               :
 * Reference     :
 ***************************************************************************************/
import MagazineMenu from "./MagazineMenu";
import { Link, useParams } from "react-router-dom";
import {
	useGetCategory,
	useMagazineContent,
	deleteMagazineCategory,
	useMagazineLog,
} from "../../../api/admin/magazine";

import Pagination from "../common/Pagination";
import MagazineRow from "./MagazineBoard/MagazineRow.js";
import { useEffect, useState } from "react";
// import { DropdownButton, Dropdown  } from "react-bootstrap";
import { EditCategoryModal } from "./Board/EditCategoryModal";
import { BbtiLabelModal } from "./Board/BbtiLabelModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import { getMe } from "../../../api/common/memberApi.js";

function MagazineBoard() {
	const [showModal, setShowModal] = useState(false); // 카테고리 수정 모달
	const [showBbtiModal, setShowBbtiModal] = useState(false); // BBTI 라벨링 모달
	const [showBbtiUpdateModal, setShowBbtiUpdateModal] = useState(false); // BBTI 라벨링 수정 모달
	const [currentMagazine, setCurrentMagazine] = useState(null); // 모달 open 대상 매거진

	const handleShowModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	// BBTI 라벨링 등록 모달 열기
	const handleShowBbtiModal = (currentId) => {
		setCurrentMagazine(currentId);
		setShowBbtiModal(true);
	};

	// BBTI 라벨링 수정 모달 열기
	const handleShowBbtiUpdateModal = (currentId) => {
		setCurrentMagazine(currentId);
		setShowBbtiUpdateModal(true);
	};

	// id 추출
	const { magazineCategoryId } = useParams();

	const { data, error, isFetching, page, setPage, toggle, setToggle } =
		useMagazineContent(magazineCategoryId);

	// const { data, error, isFetching, page, setPage, toggle, setToggle } = useMagazineLog(magazineCategoryId);

	// 카테고리 조회
	const { isLoading, data: categoryData } = useGetCategory();

	const [role, setRole] = useState(null);

	const handlePrevPage = () => {
		setPage((prevPage) => prevPage - 1);
	};

	const handleNextPage = () => {
		setPage((prevPage) => prevPage + 1);
	};

	useEffect(() => {
		getMe()
			.then((data) => {
				setRole(data.role);
			})
			.catch((error) => {
				console.error(
					"데이터를 가져오는 중 오류가 발생했습니다:",
					error
				);
			});
	}, []);

	const handleDeleteClick = (magazineCategoryId) => {
		const confirmDelete = window.confirm(
			"해당 카테고리 게시물도 모두 삭제 됩니다 . 정말로 삭제 하시겠습니까?"
		);
		if (confirmDelete) {
			deleteMagazineCategory(magazineCategoryId)
				.then((response) => {
					alert("성공적으로 삭제되었습니다.");
					window.location.href = "/admin/magazine/all";
				})
				.catch((error) => {
					console.error("상품 삭제 중 에러 발생:", error);
				});
		}
	};

	return (
		<>
			<section className="admin-content">
				<div className="container-fluid">
					<div className="row">
						<aside className="col-md-12  menu-2">
							<MagazineMenu
								categoryData={categoryData}
								role={role}
							/>
						</aside>

						<div className="col-md-12">
							<div className="box">
								{role != "CP" &&
									magazineCategoryId !== "all" && (
										<SettingBtnGroup
											handleShowModal={handleShowModal}
											handleDeleteClick={
												handleDeleteClick
											}
											magazineCategoryId={
												magazineCategoryId
											}
										/>
									)}

								<div className="tit">
									<span className="group-title">
										전체(
										{data?.totalCnt ? data?.totalCnt : 0})
									</span>
									<Link
										to={`/admin/magazine/writing/${magazineCategoryId}`}
									>
										<p className="text-white">글쓰기</p>
									</Link>
								</div>

								<table className="admin_table">
									<colgroup>
										<col style={{ width: "5%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "20%" }} />
										<col style={{ width: "15%" }} />
										<col style={{ width: "5%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "5%" }} />
										<col style={{ width: "5%" }} />
										<col style={{ width: "5%" }} />
										<col style={{ width: "5%" }} />
										<col style={{ width: "5%" }} />
									</colgroup>
									<thead>
										<th>ID</th>
										<th>MAGAZINE</th>
										<th>대표이미지</th>
										<th>제목</th>
										<th>URL</th>
										<th>콘텐츠</th>
										<th>작성일</th>
										<th>작성자</th>
										<th>BBTI</th>
										<th>공개/비공개</th>
										<th>수정</th>
										<th>삭제</th>
									</thead>
									<tbody>
										{data &&
										data.data &&
										data.data.length > 0 ? (
											data.data.map((magazine) => (
												<MagazineRow
													key={magazine?.id}
													magazine={magazine}
													role={role}
													onBbtiClick={
														handleShowBbtiModal
													}
													onBbtiUpdateClick={
														handleShowBbtiUpdateModal
													}
												/>
											))
										) : (
											<tr>
												<td colSpan="컬럼수">
													데이터가 없습니다.
												</td>
											</tr>
										)}
									</tbody>
								</table>

								{/* 페이지내비게이션 */}
								<div className="pagination-wrap">
									<Pagination
										count={data?.totalCnt || 0}
										page={page}
										setPage={setPage}
										handlePrevPage={handlePrevPage}
										handleNextPage={handleNextPage}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* 카테고리 수정 모달 */}
			<EditCategoryModal
				id={magazineCategoryId}
				show={showModal}
				onHide={handleCloseModal}
			/>

			{/* 매거진 BBTI 등록 모달 */}
			{currentMagazine && showBbtiModal && (
				<BbtiLabelModal
					id={currentMagazine}
					show={showBbtiModal}
					onHide={() => {
						setShowBbtiModal(false);
						setCurrentMagazine(null);
					}}
				/>
			)}

			{/* 매거진 BBTI 수정 모달 */}
			{currentMagazine && showBbtiUpdateModal && (
				<BbtiLabelModal
					id={currentMagazine}
					show={showBbtiUpdateModal}
					onHide={() => {
						setShowBbtiUpdateModal(false);
						setCurrentMagazine(null);
					}}
					type="update"
				/>
			)}
		</>
	);
}

function SettingBtnGroup({
	handleShowModal,
	handleDeleteClick,
	magazineCategoryId,
}) {
	return (
		<DropdownButton
			as={ButtonGroup}
			id={`dropdown-button-drop`}
			size="sm"
			variant="secondary"
			title={
				<>
					<span>설정 </span>
					<FontAwesomeIcon icon={faGear} />
				</>
			}
		>
			<Dropdown.Item eventKey="1" onClick={handleShowModal}>
				수정
			</Dropdown.Item>
			<Dropdown.Item
				eventKey="2"
				onClick={() => handleDeleteClick(magazineCategoryId)}
			>
				삭제
			</Dropdown.Item>
		</DropdownButton>
	);
}

export default MagazineBoard;
