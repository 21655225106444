/***************************************************************************************
* FileName      : alertUtil.js
* Description   : 소곤핏 고객 > alert 커스텀
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.07.21
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import Swal from 'sweetalert2';

export const showAlert = (message, showCancelButton = false, confirmButtonText = '확인') => {
  Swal.fire({
    text: message,
    showCancelButton,
    confirmButtonText,
  });
};
