/***************************************************************************************
 * FileName      : SimpleTest.js
 * Description   : 소곤핏고객 분석 페이지
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  :
 * Modifide Date : 2024.06.10 : PKH : bbti 추천 상품리스트 : 무한스크롤 , 더보기 버튼 추가
 *               : 2024.06.10 : CHM : 테스트 유형 블록 추가
 *               : 2024.06.12 : CHM : 체형분석 테스트(BBTI)임시 제거
 * Reference     :
 ***************************************************************************************/
import { useEffect, useState, useRef } from "react";
import { Col, Row, Tabs, Tab, Button } from "react-bootstrap";
import MobileHeader from "../Component/MobileHeader";
import Gnb from "../../../Gnb";
import ProductItem from "../Main/ProductItem";
import { getProductCustomer } from "../../../api/admin/magazine";
import { getMe } from "../../../api/common/memberApi";
import { bbtiList } from "../../../api/services/bodytypeApi";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

import ProductList from "./SimpleTest/ProductList";
import { LoadingSpinner } from "../../admin/Component/LoadingSpinner";

import { ReactComponent as BodyTestIcon } from "../../../assets/icon/body_test.svg";
import { ReactComponent as PreferenceTestIcon } from "../../../assets/icon/preference_test.svg";
import { ReactComponent as SizeTestIcon } from "../../../assets/icon/size_test.svg";

function SimpleTest({}) {
	const [userData, setUserData] = useState(); // 사용자 데이터
	const [bbtis, setBbtis] = useState(); // 체형 리스트
	useEffect(() => {
		getMe()
			.then((res) => {
				res?.data === "guest" ? setUserData("guest") : setUserData(res);
			})
			.catch((err) => {
				setUserData("guest");
			});
	}, []);
	useEffect(() => {
		bbtiList().then((res) => {
			setBbtis(res?.data?.data);
		});
	}, [userData]);
	return userData === "guest" ? (
		// bbti 정보가 없는 회원
		<SimpleTestUndefinded key={userData} />
	) : (
		// bbti 정보가 있는 회원
		<SimpleTestUser key={userData} userData={userData} bbtis={bbtis} />
	);
}

// bbti 정보가 없는 회원
function SimpleTestUndefinded({}) {
	const navigate = useNavigate();
	return (
		<>
			<Helmet>
				<title>소곤핏 sogonfit - 분석(등록된 정보 없음)</title>
			</Helmet>
			<MobileHeader back={true} title={"분석"} />
			<div className="bbtitest" style={{ margin: "48px auto" }}>
				<div className="inner">
					<Row style={{ alignItems: "center" }}>
						<Col xs={12}>
							<p className="py-2">
								유형 분석 테스트를 모두 완료하면
								<br/>
								나에게 더 잘 맞는 속옷을 추천해드려요!</p>
						</Col>
						<Col xs={12}>
							<p className="fc-light" style={{"fontSize": "12px"}}>로그인 후 이용이 가능해요.</p>
						</Col>
						<Col xs={12} className="research-progress">
							<div className="research-progress-bar">
								<div className="research-step"></div>
								<div className="research-step"></div>
								<div className="research-step"></div>
							</div>
							<p style={{
								"fontSize": "14px",
								"color": "#AAAAAA"
							}}>
								<span className="fc-main">0</span>/3
							</p>
						</Col>

						<Col
							xs={12}
							className="none-scroll"
							// style={{ overflowX: "scroll" }}
						>
							{/* <div style={{ display: "inline-flex", gap: "12px" }}> */}
							<div className="w-100">
								<TestCard
									testName={'체형분석'}
									title={"내 체형 분석하기"}
									imgSrc={"img/brassiere.png"}
									state={undefined}
									event={() => {
										window.hackleClient.track({
											key: "clicked_research",
											properties: {
												타입: "체형분석", // 체형분석, 취향분석, 사이즈분석
											},
										});
										ReactGA.event({
											action: "체형분석 테스트",
											category:
												"체형분석 테스트(다시) 시작",
										});
										// 2024.06.12 : CHM : 체형분석 테스트(BBTI)임시 제거 -> 다시오픈
										navigate("/bbtiIntro");
									}}
								/>
								<TestCard
									testName={'취향분석'}
									title={"내 취향 분석하기"}
									state={"준비중"}
									event={() => {
										window.hackleClient.track({
											key: "clicked_research",
											properties: {
												타입: "취향분석", // 체형분석, 취향분석, 사이즈분석
											},
										});
										alert("서비스 준비중입니다.");
									}}
								/>
								<TestCard
									testName={'사이즈분석'}
									title={"내 사이즈 분석하기"}
									state={"준비중"}
									event={() => {
										window.hackleClient.track({
											key: "clicked_research",
											properties: {
												타입: "사이즈분석", // 체형분석, 취향분석, 사이즈분석
											},
										});
										alert("서비스 준비중입니다.");
									}}
								/>
							</div>
						</Col>
					</Row>

					{/* 비회원에게 분석 유도 */}
					<div class="research-card">
						{/* data-direction으로 텍스트 방향 설정 */}
						<p className="pt-5 pb-3">
							나에게 딱 맞는 브라를 찾아보세요.
							<br/>
							<span
								className="fc-main"
								style={{"fontSize":"12px"}}>
									분석 테스트를 모두 진행하면 정확도를 높일 수 있어요!
							</span>
						</p>
					</div>
					{/* 비회원에게 분석 유도 끝 */}
					<ProductList />
				</div>
				<Gnb />
			</div>
		</>
	);
}

function SimpleTestUser({ userData, bbtis }) {
	const [userBodyType, setUserBodyType] = useState(""); // 사용자 체형(bbti)
	const [typeLoading, setTypeLoading] = useState(true); // Loading state

	useEffect(() => {
		if (userData?.bbti && bbtis) {
			setTypeLoading(true);

			const matchedBbti = bbtis?.find(
				(bbti) => bbti?.id === userData?.bbti
			);
			if (matchedBbti) {
				setUserBodyType(matchedBbti?.type);
			}
			setTypeLoading(false);
		}
	}, [userData, bbtis]);

	const navigate = useNavigate();

	return (
		<>
			<Helmet>
				<title>소곤핏 sogonfit - 분석</title>
			</Helmet>
			<MobileHeader back={true} title={"분석"} />
			<div className="bbtitest" style={{ margin: "48px 0" }}>
				<div className="inner">
					<Row style={{ alignItems: "center" }}>
						<Col xs={12}>
							<p className="py-2">
								유형 분석 테스트를 모두 완료하면
								<br/>
								나에게 더 잘 맞는 속옷을 추천해드려요!</p>
						</Col>
						
						<Col xs={12}>
							<p className="fc-light" style={{"fontSize": "12px"}}>테스트를 더 진행하면 추천이 더욱 정확해져요.</p>
						</Col>
						<Col xs={12} className="research-progress">
							<div className="research-progress-bar">
								<div className={userBodyType ? "research-step success" : "research-step"}></div>
								<div className="research-step"></div>
								<div className="research-step"></div>
							</div>
							<p style={{
								"fontSize": "14px",
								"color": "#AAAAAA"
							}}>
								<span className="fc-main">{userBodyType ? "1" : "0"}</span>/3
							</p>
						</Col>

						<Col
							xs={12}
							className="none-scroll"
						>
							<div className="w-100">
								<TestCard
									testName={'체형분석'}
									title={"내 체형 분석하기"}
									imgSrc={"img/brassiere.png"}
									state={
										userBodyType
											? userBodyType + "형"
											: undefined
									}
									event={() => {
										window.hackleClient.track({
											key: "clicked_research",
											properties: {
												타입: "체형분석", // 체형분석, 취향분석, 사이즈분석
											},
										});
										ReactGA.event({
											action: "체형분석 테스트",
											category:
												"체형분석 테스트(다시) 시작",
										});
										// 2024.06.12 : CHM : 체형분석 테스트(BBTI)임시 제거 -> 다시오픈
										navigate("/bbtiIntro");
									}}
								/>
								<TestCard
									testName={'취향분석'}
									title={"내 취향 분석하기"}
									state={"준비중"}
									event={() => {
										window.hackleClient.track({
											key: "clicked_research",
											properties: {
												타입: "취향분석", // 체형분석, 취향분석, 사이즈분석
											},
										});
										alert("서비스 준비중입니다.");
									}}
								/>
								<TestCard
									testName={'사이즈분석'}
									title={"내 사이즈 분석하기"}
									state={"준비중"}
									event={() => {
										window.hackleClient.track({
											key: "clicked_research",
											properties: {
												타입: "사이즈분석", // 체형분석, 취향분석, 사이즈분석
											},
										});
										alert("서비스 준비중입니다.");
									}}
								/>
							</div>
						</Col>
					</Row>

					<p className="pt-5 pb-3">
						나에게 딱 맞는 브라를 찾아보세요.
						<br/>
						<span
							className="fc-main"
							style={{"fontSize":"12px"}}>
								분석 테스트를 모두 진행하면 정확도를 높일 수 있어요!
						</span>
					</p>

					<ProductList userBodyType={userBodyType} />

				</div>
				<Gnb />
			</div>
		</>
	);
}

// 테스트유형 블록
const TestCard = ({ title, state, event, testName }) => {
	const [nowState, setNowState] = useState(false);
	useEffect(() => {
		state ? setNowState(true) : setNowState(false);
		state === "준비중" && setNowState(false);
	}, [state]);
	return (
		<div
			className={`test-card ${nowState ? "active" : ""}`}
			onClick={event}
		>
			<div className="img-block">
				{
					testName === "체형분석" ? (
						<BodyTestIcon
							className="test-card-img"
							style={nowState ? {"fill": "#e85f43"} :{"fill": "#AAAAAA"}}
						/>
					) : testName === "취향분석" ? (
						<PreferenceTestIcon 
							className="test-card-img"
							style={nowState ? {"fill": "#e85f43"} :{"fill": "#AAAAAA"}}
						/>
					) : testName === "사이즈분석" && (
						<SizeTestIcon 
							className="test-card-img"
							style={nowState ? {"fill": "#e85f43"} :{"fill": "#AAAAAA"}}
						/>
					)
				}
			</div>
			<div class="text-block">
				<div className="test-card-heading">{title}</div>
				<div className={nowState ? "test-card-state" : "test-card-state active"}>{state ?? "테스트 전"}</div>
			</div>
		</div>
	);
};
export default SimpleTest;
