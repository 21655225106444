/***************************************************************************************
* FileName      : PostRow.js
* Description   : 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.06.07
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { extractYearMonthDay2 } from '../../common/extractDateUtils';
import { Link } from 'react-router-dom';
import { updateHiddenPost } from '../../../../api/admin/post';
import ContentModal from './ContentModal';
;
function PostRow({ post }){
    
    // 모달 관련 기능
    const [showModal, setShowModal] = useState(false); // 상품 등록 모달
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    const handleUpdateStatusClick = (postId , status) => {
  
      const confirmationMessage = status
      ? '정말로 해당 게시물을 비공개 처리 하시겠습니까?'
      : '정말로 해당 게시물을 공개 처리 하시겠습니까?';

      const confirm = window.confirm(confirmationMessage);
    
      if (confirm) {
        updateHiddenPost(postId, status)
        .then(data => {
           console.log(data);

        })
        .catch(error => {
            // 에러 처리
        });
    }
  };
  return (
        <React.Fragment key={post?.id}>
            <tr>
              <td class="admin-tooltip-container">
                  <span class="admin-tooltip">
                      <span class="admin-tooltip-item">{post?.id}</span>
                  </span>
                  <span className="text">
                    {post?.id && post?.id.length > 10
                        ? `${post?.id.slice(0, 10)}...`
                        : post?.id
                    }
                  </span>
              </td>
              <td>{post?.user?.username}({post?.user?.nickname})</td>
              <td>{post?.title}</td>
              <td>
                <button onClick={handleShowModal}>상세내용 확인</button>
              </td>
    
              <td>
                {post?.images.map((image, index) => (
                  <img key={index} src={image} alt={`image-${index}`} style={{ maxWidth: '100px', marginRight: '10px' }} />
                ))}
              </td>
              <td>{post?.posted_at}</td>
        
              <td>{post?.like_count}</td>
              <td>{post?.view_count}</td>
              <td>
              {post?.admin_hidden || post?.user_hidden 
              ? `${post?.admin_hidden ? '관리자가 비공개' : ''} 
                ${post?.user_hidden ? '유저가 비공개' : ''}`.trim()
              : '공개'}
        
              </td>
              <td>
                  {post?.admin_hidden ? (
                    <a 
                      className='pink_btn cursor-pointer' 
                      onClick={() => handleUpdateStatusClick(post?.id,false)}>
                    공개
                    </a>
                  ):
                  <a 
                    className='pink_btn cursor-pointer' 
                    onClick={() => handleUpdateStatusClick(post?.id,true)}>
                    비공개
                  </a>
                }
                
                </td>
                <td>
                  <Link to={`/post/${post?.id}`}>게시글로 이동</Link>
                </td>

            </tr>

          {/* 상세내용 모달 */}
          <ContentModal
            show={showModal}
            content={post?.content}
            onHide={handleCloseModal}
        />

        </React.Fragment>

        
    )
}

export default PostRow