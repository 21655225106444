// 관리자 > 이미지 업로드
import axios from 'axios';
import { instance } from '../axiosConfig.js';

export const getUploadURL = () => {
  return(
    instance.post(`aws/upload`)
    .then((response) => response.data)
  )
}
export const uploadImage = ({ file , uploadURL, imageURL }) => {
  return axios.put(uploadURL, file[0]).then(()=>imageURL)
}