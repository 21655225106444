/***************************************************************************************
* FileName      : MyPostComments.js
* Description   : 소곤핏 마이페이지 > 나의 활동 알림 (내 글의 댓글과 좋아요)
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.09
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import MobileHeader from "./Component/MobileHeader";
import Gnb from '../../Gnb';
import { MdKeyboardArrowRight } from "react-icons/md";
import { useGetBrands } from '../../api/services/product';

import { fetchPostData } from '../../api/services/post';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight , faHeart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { useInfiniteQuery } from 'react-query';

import Postitem from './Mypage/MyPostComments/Postitem';

import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../admin/Component/LoadingSpinner';

import { fetchRecordData } from '../../api/services/mypageApi';
function MyPostComments()
{
    const [page, setPage] = useState(0);
    const [tag, setTag] = useState('all');

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
      } = useInfiniteQuery(  ['recordData', tag, page], ({ pageParam = 1 }) => fetchRecordData(tag,pageParam), {
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.nextPage; 
        },
    });

    return (
        
        <>
        <MobileHeader back={true} title={"댓글/좋아요"}/>
        <div className="mypage">
            <section className='mypage-menu my-5'>
                <div className="inner pb-5"> 
          
                    <div className="post-wrap">
                        {data?.pages.length === 0 || data?.pages.reduce((total, page) => total + page.data.length, 0) === 0 ? (
                            <div className='text-center mt-3'>
                                나의 글에 댓글과 좋아요가 아직 없습니다
                            </div>
                        ) : (
                        <InfiniteScroll
                            style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
                            dataLength={data ? data.pages.reduce((total, page) => total + page.data.length, 0) : 0}
                            next={() => fetchNextPage()}
                            hasMore={hasNextPage}
                            loader={<div>Loading...</div>}
                            endMessage={<div></div>}
                        >
                            {data?.pages.map((page, pageIndex) => (
                                <React.Fragment key={pageIndex}>
                                    {page.data.map(item => (
                                        <Postitem item={item} key={item.id} />
                                    ))}
                                </React.Fragment>
                            ))}
                        </InfiniteScroll>
                    )}
                </div>
            </div>
            </section>

            <Gnb/>
        </div>
        </>
    )
}

export default MyPostComments;