/***************************************************************************************
* FileName      : AdminLoding.js
* Description   : 관리자 대시보드 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.21
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import { useEffect, useState } from "react";

import { setCookie } from '../../api/cookieUtils.js';
import React from 'react';
import { getMe } from '../../api/common/memberApi';
import { LoadingSpinner } from "./Component/LoadingSpinner";
function AdminLoding()
{

    useEffect(()=>{

        getMe()
        .then(data => {
            setCookie('role', data?.role); 

            if(data.role === 'CP') window.location.href="/admin/magazine/all";
            else if(data.role === 'BM') window.location.href="/admin/bmproduct/all";
            else window.location.href="/admin";
        })
        .catch(error => {
          console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
        });

    }, [])

    return (
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                        <LoadingSpinner />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AdminLoding 

