import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';

function Step1({ height, setHeight, weight, setWeight }) {

    // 소수점과 음수 기호 입력 방지
    const preventDecimalAndNegative = (e) => {
        if (e.key === '.' || e.key === ',' || e.key === '-' || e.key === '+') {
            e.preventDefault();
        }
    };

    // 입력값 검증 및 변환
    const handleChange = (setValue) => (e) => {
        const value = e.target.value;
        // 빈 문자열이면 그대로 저장
        if (value === '') {
            setValue('');
            return;
        }

        // 숫자로 변환하고 정수로 변환
        const numValue = Math.floor(Math.abs(Number(value)));
        
        // 유효한 양의 정수인 경우에만 저장
        if (!isNaN(numValue)) {
            setValue(numValue);
        }
    };

    return (
        <>
            <Row className='my-3'>
                <Col xs={1} />
                <Col xs={2}><p className="w-100 pt-2 text-start">키</p></Col>
                <Col xs={6}>
                    <input 
                        className="w-100 p-2" 
                        type="number"
                        value={height}
                        onChange={handleChange(setHeight)}
                        onKeyDown={preventDecimalAndNegative}
                        placeholder="키"
                        min="0" 
                    />
                </Col>
                <Col xs={2}><p className="w-100 pt-2 text-start">cm</p></Col>
                <Col xs={1} />
            </Row>
            <Row className='my-3'>
                <Col xs={1} />
                <Col xs={2}><p className="w-100 pt-2 text-start">몸무게</p></Col>
                <Col xs={6}>
                    <input 
                        className="w-100 p-2" 
                        type="number"
                        value={weight}
                        onChange={handleChange(setWeight)}
                        onKeyDown={preventDecimalAndNegative}
                        placeholder="몸무게"
                        min="0"
                    />
                </Col>
                <Col xs={2}><p className="w-100 pt-2 text-start">kg</p></Col>
                <Col xs={1} />
            </Row>
        </>
    );
}

export default Step1;