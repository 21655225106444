import Header from './Header.js'
import Footer from './Footer.js'

import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <>
    <div className="root" >
        <Header />
        <Outlet />
        {/* <Footer /> */}
    </div>
    </>
  );
};

export default Layout;