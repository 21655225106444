/***************************************************************************************
* FileName      : AddBrandModal.js
* Description   : 소곤핏 관리자 > 브랜드 > 전체 브랜드 목록 > 브랙드 추가 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { registerBrand } from '../../../../api/admin/brandApi';
import { getUploadURL, uploadImage } from '../../../../api/common/uploadImageApi';
import { LoadingSpinner } from '../../Component/LoadingSpinner';

const AddBrandModal = ({ show, onHide }) => {

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();

    // 공개 비공개
    const [isPrivate, setIsPrivate] = useState(true);
    
    const [selectedFileName, setSelectedFileName] = useState('');


    const createBrandMutaion = useMutation(registerBrand , {
        onSuccess : (data) => {
            alert("브랜드 등록이 완료되었습니다")
            reset();
            setSelectedFileName('');
            onHide();
        },
        onError : (data) => {
            alert(data.request.responseText);
        }
    });

    // const uploadImageMutation = useMutation(uploadImage,{
    const uploadImageMutation = useMutation(uploadImage,{
        onSuccess : (result) => {
            console.log(result);
            const brandData = {
                name: watch("name"),
                // logo: result.variants[0],
                logo: result,
                website: watch("website"),
                owner: watch("owner"),
                phone_number : watch("phone_number"),
                hidden:isPrivate 
            };
            createBrandMutaion.mutate(brandData);
        }
    });
    
    {/* hidden:false => 공개 ,hidden:true => 비공개 */}

    // const uploadURLMutaion = useMutation(getUploadURL , {
    //     onSuccess : (data) => {
    //         console.log(data);
    //         uploadImageMutation.mutate({
    //             uploadURL : data.uploadURL,
    //             file : watch("logo"),
    //         })
    //     }
    // });

    const uploadURLMutaion = useMutation(getUploadURL , {
        onSuccess : (data) => {
            console.log(data);
            console.log(data.imageURL);
            uploadImageMutation.mutate({
                uploadURL : data.uploadURL,
                imageURL : data.imageURL,
                file : watch("logo"),
            })
        }
    });


    const onSubmit = (data) => {
    //   console.log("data:", watch("name"))
      uploadURLMutaion.mutate();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFileName(file.name);
        } else {
            setSelectedFileName('');
        }
    };
    
    const handleCheckboxChange = () => {
        setIsPrivate(!isPrivate); 
    };

      
    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>브랜드 등록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>     
                <div className='input-form'>
                    <div className='input_box'>
                        <p>브랜드 명</p>
                        <input 
                            type='text' 
                            name='name' 
                            className={errors.name ? 'error-input' : ''}
                            {...register('name', { required: '브랜드 명 입력은 필수입니다.' })} />
                                                
                        <p>로고</p>
                        <div class="filebox">
                            <input  className={errors.logo ? 'upload-name error-input' : 'upload-name'} 
                                    value={selectedFileName} 
                                    placeholder="로고 이미지파일" />
                                    
                            <label htmlFor="logo_file">파일찾기</label> 

                            <input
                                type='file'
                                id='logo_file'
                                name='logo'
                                accept="image/jpg, image/png, image/jpeg"
                        
                                {...register('logo', { required: '로고 이미지는 필수입니다.' } )}
                                onChange={handleFileChange}
                            />
                        </div>

                        <p>사이트링크</p>
                        <input 
                            type='text' 
                            name='website' 
                            className={errors.website ? 'error-input' : ''}
                            placeholder='반드시 http:// 또는 https:// 를 입력해주세요'
                            {...register('website', { required: '사이트링크 입력은 필수입니다.' })} />

                
                        <p>대표자 이름</p>
                        <input 
                            type='text' 
                            name='owner' 
                            className={errors.owner ? 'error-input' : ''}
                            {...register('owner', { required: '대표자 입력은 필수입니다.' })} />

                        <p>연락처</p>
                        <input 
                            type='text' 
                            name='phone_number' 
                            className={errors.phone_number ? 'error-input' : ''}
                            {...register('phone_number')} />

                        <div class="form-check">
                            <input class="form-check-input custom-checkbox" type="checkbox"  id="hiddenCheck" checked={isPrivate} onChange={handleCheckboxChange} />
                            <label class="form-check-label" for="hiddenCheck">
                            비공개
                            </label>
                        </div>
                       
                       
                    </div>
                    <button className='btn_black_1' type="submit">
                        {createBrandMutaion.isLoading || uploadImageMutation.isLoading || uploadURLMutaion.isLoading
                        ?  <LoadingSpinner /> : '브랜드 등록'} 
                    </button>

                </div>
            </form>
        </Modal.Body>

    </Modal>
  );
};

export default AddBrandModal;
