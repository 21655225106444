import React, { useEffect } from "react";
import { userLogout } from "../../api/common/memberApi";
import Cookies from "js-cookie";
import { LoadingSpinner } from "../admin/Component/LoadingSpinner";

function kakaoLogout() {
	Cookies.remove("token");
	userLogout()
		.then((res) => {
			window.location.href = "/";
			window.hackleClient.resetUser(); // 핵클 로그아웃
		})
		.catch((error) => {
			console.log(error?.response?.data);
			alert("카카오 로그아웃 처리 도중 에러가 발생되었습니다.");
		});
	return (
		<>
			<div className="spinner-container">
				<LoadingSpinner />
			</div>
		</>
	);
}

export default kakaoLogout;
