/***************************************************************************************
* FileName      : BrandOrdering.js
* Description   : 소곤핏 관리자 > 브랜드관리 > 브랜드 순서
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.07.31
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useGetBrands, updateBrandOrdering } from '../../../api/admin/brandApi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function BrandOrdering(){
    const queryClient = useQueryClient();

    const [brands, setBrands] = useState([]);
    // 매거진 카테고리 리스트 조회
    const { data: BrandData, isLoading: isBrandDataLoading, isError: isBrandDataError } = useGetBrands();
    
    // 카테고리 리스트 조회 성공 시 화면에 뿌려주기
    useEffect(()=>{
        setBrands(BrandData?.data);
        console.log(BrandData?.data)
    }, [BrandData])

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(brands);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        console.log("순서 변경 : ",(items?.map(x => x?.id)).join(","));

        updateBrandOrdering((items?.map(x => x?.id)).join(","))
            .then((res) => {
                // 쿼리를 다시 불러와서 새로고침
                queryClient?.invalidateQueries('brands');
            })
            .catch((error) => {
                alert("순서변경에 실패했습니다.");
            });
    };

    
    return (
        <>
        <section className="admin-content">
            <div className="container-fluid box">
                <div className='tit'>
                    <span className='group-title'>전체({brands?.length})</span>
                    {
                        brands?.length == 0 ? (
                            <p style={{fontSize: '12px'}}>등록된 브랜드가 없습니다.</p>
                        ) : (
                            <p style={{fontSize: '12px'}}>드래그앤 드롭으로 브랜드 순서 변경</p>
                        )
                    }
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="magazine-items">
                        {(provided) => (
                            <div className="col-md-4 mt-2"
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                    {
                                        brands?.map((item, index) => (
                                            <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <BrandDndBlock
                                                            key={item?.id}
                                                            id={item?.id}
                                                            name={item.name}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))
                                    }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </section>
        </>
    )
}

// 드래그 앤 드롭 블록
function BrandDndBlock({id, name}){
    return(
        <div className={'box mb-2'}>
            <div className="d-flex justify-content-between">
                <p className={`fw-bold ${"text-secondary"}`}>
                    {name}
                </p>
            </div>
        </div>
    )
}



export default BrandOrdering;