/***************************************************************************************
* FileName      : UserRow.js
* Description   : 소곤핏 user 관리 UserRow 컴포넌트 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { extractYearMonthDayHourMinute } from '../common/extractDateUtils';
import { UpdateAcceptedAuth , DeleteAuth } from '../../../api/admin/memberApi';
import EditUserModal from './EditUserModal';

function UserRow({ user }){
    
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

  

    const handleUpdateAccepted = () => {
        const confirmationMessage = "승인 하시겠습니까?";
        
        if (window.confirm(confirmationMessage)) {
            UpdateAcceptedAuth(user?.id)
            .then(data => {
                alert('승인 처리 되었습니다.');
            })
            .catch(error => {
                console.error(' 오류가 발생했습니다:', error);
            });
        }
    };

    const handleDeleteAuth= () => {
        const confirmationMessage = "정말로 거절 하시겠습니까 ?  해당 유저는 삭제 처리 됩니다.";
        
        if (window.confirm(confirmationMessage)) {
            DeleteAuth(user?.id)
            .then(data => {
                alert('거절 처리 되었습니다. ');
            })
            .catch(error => {
                console.error('오류가 발생했습니다:', error);
            });
        }
    };

    return (
        <React.Fragment key={user?.id}>
            <tr>
                <td>{user?.id}</td>       
                <td>{user?.username}</td>
                <td>{user?.type_display === "중간관리자" ? user?.role : user?.type_display }</td>
                <td>
                    {user?.brand.length > 0 && (
                      <>
                        {user.brand.map((brand) => (
                            <span className="mr-1" key={brand.id}>{brand.name}</span>
                        ))}
                    </>
                    )}
                    
                    {user?.category.length > 0 && (
                        <ul>
                            {user.category.map((category) => (
                                <li key={category.id}>{category.name}</li>
                            ))}
                        </ul>
                    )}

                </td>
                <td></td>
                <td>{user?.phone_number}</td>
     
             
                <td>{user?.content_count}</td>
                <td>
                    {user?.is_accepted ? "승인완료" :
                        <>
                        <button className="pink_btn mr-1" onClick={()=>handleUpdateAccepted()}>승인</button>
                        <button className="pink_btn" onClick={()=>handleDeleteAuth()}>거절</button>
                        </>
                    }
                </td>
                <td>{extractYearMonthDayHourMinute(user?.date_joined)}</td>
                <td>{user?.last_login ? extractYearMonthDayHourMinute(user?.last_login) : "-"}</td>
                <td>
                    <button className="pink_btn" onClick={handleShowModal}>수정</button>  
                </td>
            </tr>

            {/* 수정 모달 */}
            <EditUserModal
                show={showModal}
                onHide={handleCloseModal}
                id={user?.id}
            />
        </React.Fragment>
    )
}

export default UserRow