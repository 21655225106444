/***************************************************************************************
* FileName      : VerifyEmail.js
* Description   : 소곤핏 고객 > 아이디 찾기 > 결과 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.06.12
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Helmet } from 'react-helmet-async';
import MobileHeader from "./../Component/MobileHeader";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { findIdVerifyEmail } from '../../../api/services/memberApi';

function VerifyEmail()
{
    const navigate = useNavigate();

    // 현재 페이지의 URL을 가져오기
    const url = window.location.href;

    // URL을 파싱하여 검색 매개변수를 추출
    const searchParams = new URL(url).searchParams;
    const email = searchParams.get("email");
    const code = searchParams.get("verification_code");


    const [userId, setUserId] = useState('');

    useEffect(()=>{
        findIdVerifyEmail(email, code)
            .then(res => setUserId(res?.data?.username))
            .catch(error => alert(error?.response?.data?.error))
    },[])

    // useEffect(()=>{
    //     const data = {
    //         email : email || "",  
    //         code : code || ""
    //     };

    //     console.log(data)



    // })
    
    return(
        <>
        <Helmet>
            <title>소곤핏 Sogonfit - 아이디찾기</title>
        </Helmet>
        <MobileHeader back={true} title={"아이디찾기"}/>
        <div className="findId mt-5">
            <div className="inner">
                <div className='find_wrap'>
                    <div className="find_contents">
                        <p>        
                            {email}로 가입된 아이디는
                            <br/>
                            <span className='text-hw fw-bold'>{userId}</span> 입니다.
                        </p>
                    </div>
                    <div className='fbutton-wrap mt-4'> 
                        <button 
                            className='btn-primary findBtn'
                            onClick={()=>{navigate('../login')}}>로그인하기</button>
                        <button 
                            className='btn-primary findBtn'
                            onClick={()=>{navigate('../findPassword')}}>비밀번호 찾기</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default VerifyEmail