/***************************************************************************************
 * FileName      : SimpleTestIntro.js
 * Description   : 소곤핏 체형분석 소개 페이지
 * Company       : (주)엘리그
 * Author        : 최현민
 * Created Date  :
 * Modifide Date : 2024.08.26 : CHM : 내 체형분석하기 소개화면
 *               : 
 *               : 
 * Reference     :
 ***************************************************************************************/
import { useEffect, useState } from "react";
import MobileHeader from "../Component/MobileHeader";
import { getMe } from "../../../api/common/memberApi";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Svg1 } from "../../../assets/bg/line_1.svg";
import { ReactComponent as Svg2 } from "../../../assets/bg/line_2.svg";
import { ReactComponent as Svg3 } from "../../../assets/bg/line_3.svg";
import Gnb from "../../../Gnb";

function SimpleTestIntro() {
    const [userData, setUserData] = useState("guest"); // 사용자 데이터
    useEffect(() => {
		getMe()
			.then((res) => {
				res?.data === "guest" ? setUserData("guest") : setUserData(res);
			})
			.catch((err) => {
				setUserData("guest");
			});
	}, []);
    const navigate = useNavigate();
    useEffect(() => {
        const svgs = document.querySelectorAll(".floating-svg");
        svgs.forEach((svg) => {
            const randomDuration = Math.random() * 4 + 10;
            svg.style.animationDuration = `${randomDuration}s`;
            svg.style.top = `${Math.random() * 90}%`;
            svg.style.left = `${Math.random() * 90}%`;
        });
    }, []);

	return(
        <>
            <Helmet>
				<title>소곤핏 sogonfit - 내 체형 분석하기</title>
			</Helmet>
            <MobileHeader back={true} title={"내 체형 분석하기"} />
            <div style={{margin: "48px auto 0 auto"}}>
                <div className="intro-thumb-wrap">
                    <div className="floating-svg-wrap">
                        <Svg1 alt="svg1" className="floating-svg" style={{"fill" : "#d1c6df"}}/>
                        <Svg2 alt="svg2" className="floating-svg" style={{"fill" : "#d1c6df"}}/>
                        <Svg2 alt="svg2" className="floating-svg" style={{"fill" : "#c7ddd9"}}/>
                        <Svg3 alt="svg3" className="floating-svg" style={{"fill" : "#d1c6df"}}/>
                        <Svg3 alt="svg3" className="floating-svg" style={{"fill" : "#c7ddd9"}}/>
                    </div>
                    <div className="text-area">
                        <h3>내 가슴은 어떤 특징이 있을까?</h3>
                        <h5 className="fc-main">내 체형 알아보기</h5>
                    </div>
                </div>
                <div className="inner">
                    <div className="p-2">
                        <p className="fc-main fw-bold">
                            우리의 얼굴이 모두 다르듯, 가슴의 모양도 특징도 모두 달라요.
                        </p>
                        <p className="fc-gray mt-3">
                            같은 사이즈의 브라인데 어떤 건 편하고, 어떤 건 불편한 경험. 혹시 있지 않으세요? 가슴의 형태에 따라 편안한 브라도 달라져요. 간단한 질문으로 내 가슴의 특징을 알아보세요. 내 체형에 잘 맞는 브라도 소곤핏이 추천해드릴게요!
                        </p>
                    </div>
                    {
                        userData === "guest"? 
                        <button className="mt-3 type-test-btn" onClick={()=>navigate("/login")}>로그인 하고 분석 시작하기</button> :
                        <button className="mt-3 type-test-btn active" onClick={()=>navigate("/bbtiQuestion")}>분석 시작하기</button>
                    }
                </div>
            </div>
            <div className="bbtitest">
                <Gnb />
            </div>
            
        </>
    )
}

export default SimpleTestIntro;
