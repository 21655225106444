// 관리자 > 브랜드 관리
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState } from 'react';
import { instance } from '../axiosConfig.js';

const PAGE_SIZE = 10;

// 브랜드 등록 
export const registerBrand = (data) =>
  instance.post(`brand/`, data, {
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => response.data);

// 브랜드 삭제 
export const deleteBrand = (brandId) => {
  return instance.delete(`brand/${brandId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.data)
  .catch(error => {
    console.error('Error deleting brand:', error);
    throw error; 
  });
};


// 브랜드 조회
const fetchBrands = (page , pageSize) => {
    return instance.get(`/brand/?page=${page}&page_size=${pageSize}`)
      .then(res => res.data);
};
  
export const useBrandsQuery = () => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [toggle, setToggle] = useState(false);
  
    const { data, error, isFetching } = useQuery(['brands', page], () => fetchBrands(page), {
      onSuccess: (data) => {
        setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
     
        if (page < totalPages) {
          prefetchQuery(['brands', page + 1], () => fetchBrands(page + 1));
        }
      }
    });
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage,
      totalPages,
      toggle,
      setToggle,
    };
};

// 브랜드 검색 
export const handleBrandSearch = async ({
  searchAuth, 
  setSearchUrl,
  setSearchResults,
  }) => {
  try {
    let url = `/brand/`;

    if(searchAuth) url += `?search=${searchAuth}` 

    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
}
};

//선택한 브랜드 정보 조회
export const getBrand = async (id) => {
  try {
    const response = await instance.get(`brand/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// 선택한 브랜드 수정.
export const updateBrand = (id, newData) =>
  instance.patch(`brand/${id}`, newData, {
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => response.data);




// 전체상품 //

// 전체상품 조회
const fetchProducts = (page , pageSize) => {
  return instance.get(`/product/?page=${page}&page_size=${pageSize}`)
    .then(res => res.data);
};

export const useProductsQuery = () => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [toggle, setToggle] = useState(false);

  const { data, error, isFetching } = useQuery(['products', page], () => fetchProducts(page), {
    onSuccess: (data) => {
      setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
   
      if (page < totalPages) {
        prefetchQuery(['brands', page + 1], () => fetchProducts(page + 1));
      }
    }
  });

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    totalPages,
    toggle,
    setToggle,
  };
};

// 상품 검색 
export const handleProductSearch = async ({
  searchAuth, 
  setSearchUrl,
  setSearchResults,
  }) => {
  try {
    let url = `/product/`;

    if(searchAuth) url += `?search=${searchAuth}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
// 상품 등록 
export const registerProduct = (data) =>
  instance.post(`product/`, data).then(response => response.data);

// 상품 삭제 
export const deleteProduct = (productId) => {
  return instance.delete(`product/${productId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.data)
  .catch(error => {
    console.error('Error deleting brand:', error);
    throw error; 
  });
};

// 상품 디자인 라벨 등록
export const postProductDesign = ({id, data}) =>
  instance.post(`label/design`, {
    product: id,
    padpouch: data?.padPouch,
    detachablestrap: data?.detachableStrap,
    wingline: data?.wingLine,
    hookposition: data?.hookPosition,
    cupdesign: data?.cupDesign,
    wingdesign: data?.wingDesign,
    strapdesign: data?.strapDesign,
    color: data?.color,
  })
  .then(res => res?.data);

// 상품 디자인 라벨링 상세조회
export const getProductDesign = (id) => {
  return instance.get(`label/design/${id}`)
  .then(res => res?.data);
}

// 상품 디자인 라벨 수정
export const putProductDesign = ({labelId, data}) =>
instance.put(`label/design/${labelId}`, {
  product: data?.productId,
  padpouch: data?.padPouch,
  detachablestrap: data?.detachableStrap,
  wingline: data?.wingLine,
  hookposition: data?.hookPosition,
  cupdesign: data?.cupDesign,
  wingdesign: data?.wingDesign,
  strapdesign: data?.strapDesign,
  color: data?.color,
})
.then(res => res?.data);

// 관리자가 상품 공개,비공개 변경 
export const updateProductHidden = (pk, hidden) => {
  return instance.patch(`product/${pk}`,
  {
    hidden: hidden
  })
}

// 상품 등록 > 브랜드 조회 
export const useGetBrands = () => {
  return useQuery('brands', async () => {
    const response = await instance.get(`brand/?page_size=all`);
    return response.data;
  });
};

// 브랜드 카테고리 순서 변경
export const updateBrandOrdering = (data) => {
  return instance.post(`brand/priority`, {
    priority: data
  })
}

//선택한 상품 정보 조회
export const getProduct = async (id) => {
  try {
    const response = await instance.get(`product/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// 선택한 상품 수정.
export const updateProduct = (id, newData) =>
  instance.patch(`product/${id}`, newData, {
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => response.data);

// 선택한 상품 사이즈 등록/수정.
export const updateProductSizes = (id, newData) =>
  instance.patch(`product/${id}`, newData)
  .then(response => response.data);

// bbti 리스트 조회
export const useGetBbti = () => {
  return useQuery('bbtiList', async () => {
    const response = await instance.get(`bbti/?page_size=all`);
    return response.data;
  });
};


// 관리자가 브랜드 공개,비공개 변경 
export const updateBrandHidden = (pk, hidden) => {
  return instance.patch(`/brand/${pk}`,
  {
    hidden: hidden
  })
}


