/***************************************************************************************
* FileName      : AddProductModal.js
* Description   : 중간 관리자 > 상품 > 전체 상품 목록 > 상품 추가 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useGetBrands , useGetBbti } from '../../../../api/admin/brandApi';
import { registerBMProduct } from '../../../../api/admin/product';
import { getUploadURL , uploadImage } from '../../../../api/common/uploadImageApi';
import { LoadingSpinner } from '../../Component/LoadingSpinner';
import { Row, Col, DropdownButton, Dropdown, Badge } from 'react-bootstrap';

const AddProductModal = ({ show, onHide }) => {

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();

    // 공개 비공개
    const [isPrivate, setIsPrivate] = useState(true);

    const handleCheckboxChange = () => {
        setIsPrivate(!isPrivate); 
    };

    const [selectedFileName, setSelectedFileName] = useState('');
    
    // 브랜드 조회
    const { data: brandsData, isLoading: isBrandsDataLoading, isError: isBrandsDataError } = useGetBrands();

    // bbti 조회 
    const { data: BbtiData, isLoading: isBbtiDataLoading, isError: isBbtiDataError } = useGetBbti();

    // bbti 선택
    const [selectedTypes, setSelectedTypes] = useState([]);

    const createProductMutaion = useMutation(registerBMProduct , {
        onSuccess : (data) => {
            reset();
            onHide();
            setSelectedTypes([]);
            alert("상품 등록 요청이 완료되었습니다. 관리자 승인 후 게시됩니다.");
        },
        onError : (data) => {
            alert(data.request.responseText);
            // console.log("error", data)
        }
    });

    const uploadImageMutation = useMutation(uploadImage,{
        onSuccess : (result) => {
            const productData = {
                name: watch("name"),
                brand: watch("brand"),
                image: result,
                original_price: watch("original_price"),
                sale_price : watch("sale_price"),
                link: watch("link"),
                bbti : selectedTypes,
                hidden : isPrivate 
            };
            console.log("productData",productData)

            const BMData = {
                after : productData
            }

            createProductMutaion.mutate(BMData);
        }
    });

    const uploadURLMutaion = useMutation(getUploadURL , {
        onSuccess : (data) => {
            console.log(data);
            uploadImageMutation.mutate({
                uploadURL : data.uploadURL,
                imageURL : data.imageURL,
                file : watch("productImage"),
            })
        },
        onError : (data) => {
            console.log("error", data)
        }
    });

    useEffect(()=>{
        console.log("selectedTypes:",selectedTypes)
    },[selectedTypes])

 

    const onSubmit = (data) => {
        //   console.log("data:", watch("name"))
        uploadURLMutaion.mutate();
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFileName(file.name);
        } else {
            setSelectedFileName('');
        }
    };
    

    const handleBBTiCheckboxChange = (type) => {
      if (selectedTypes.includes(type)) {
        setSelectedTypes(selectedTypes.filter(t => t !== type));
      } else {
        setSelectedTypes([...selectedTypes, type]);
      }
    };

    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>상품 등록 요청</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>     
                <div className='input-form'>
                    <div className='input_box'>
                        <p>브랜드</p>
                        
                        {isBrandsDataLoading ? (
                                <p>Loading...</p>
                            ) : isBrandsDataError ? (
                                <p>Error loading brands data</p>
                            ) : (
                                <select {...register('brand')} defaultValue="">
                                    <option value="" disabled hidden>
                                        브랜드를 선택하세요
                                    </option>
                                    {brandsData?.data.map((brand) => (
                                        <option key={brand?.id} value={brand?.id}>
                                            {brand?.name}
                                        </option>
                                    ))}
                                </select>
                            )}

                        <p>상품명</p>
                        <input 
                            type='text' 
                            name='name' 
                            className={errors.name ? 'error-input' : ''}
                            {...register('name', { required: '상품명 입력은 필수입니다.' })} />
                            
                        <p>사이트링크</p>
                        <input 
                            type='text' 
                            name='link' 
                            className={errors.link ? 'error-input' : ''}
                            {...register('link', { required: '사이트링크 입력은 필수입니다.' })} />
                        
                        <p>상품이미지</p>
                        <div class="filebox">
                            <input  className={errors.productImage ? 'upload-name error-input' : 'upload-name'} 
                                    value={selectedFileName} 
                                    placeholder="상품 이미지파일" />
                                    
                            <label htmlFor="productImage_file">파일찾기</label> 

                            <input
                                type='file'
                                id='productImage_file'
                                name='productImage'
                                accept="image/jpg, image/png, image/jpeg"
                        
                                {...register('productImage', { required: '상품 이미지는 필수입니다.' } )}
                                onChange={handleFileChange}
                            />
                        </div>
                     
                        <p>상품가격</p>
                        <input 
                            type='text' 
                            name='original_price' 
                            className={errors.original_price ? 'error-input' : ''}
                            {...register('original_price', { required: '상품가격 입력은 필수입니다.' })} />

                        <p>판매가격</p>
                        <input 
                            type='text' 
                            name='sale_price' 
                            className={errors.sale_price ? 'error-input' : ''}
                            {...register('sale_price', { required: '판매가격 입력은 필수입니다.' })} />

                        <p>BBTI</p>
                        <div className='bbtiList'>
                            { isBbtiDataLoading ? (
                                <p>Loding...</p>
                            ) : isBbtiDataError ? (
                                <p>Error bbti data</p>
                            ) : (
                                BbtiData?.data.map(item => (
                                    <div key={item?.id}>

                                        <input 
                                            type="checkbox"
                                            className='bbti_checkbox'  
                                            checked={selectedTypes.includes(item?.type)}
                                            onChange={() => handleBBTiCheckboxChange(item?.type)}
                                            id={`bbti_checkbox_${item.id}`} />

                                        <label 
                                            className="bbti_label" 
                                            htmlFor={`bbti_checkbox_${item.id}`}>
                                            {item?.type}
                                        </label>

                                    </div>
                                ))
                            )}
                        </div>
                       
                        <div className="form-check">

                            <input className="form-check-input custom-checkbox" type="checkbox"  id="hiddenCheck" checked={isPrivate} onChange={handleCheckboxChange} />
                            <label className="form-check-label" for="hiddenCheck">
                            비공개
                            </label>
                        </div>
                       
                       
                           
                    </div>
                    <button className='btn_black_1' type="submit">
                        {createProductMutaion.isLoading || uploadImageMutation.isLoading || uploadURLMutaion.isLoading
                        ?  <LoadingSpinner /> : '상품 등록 요청'} 
                    </button>

                </div>
            </form>
        </Modal.Body>
    </Modal>
  );
};

export default AddProductModal;
