
/***************************************************************************************
 * FileName      : SogontalkItem.js
 * Description   : 소곤핏 메인페이지 > 소곤톡 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.08.23
 * Modifide Date : 
 *               : 
 * Reference     :
 ***************************************************************************************/
import { ReactComponent as HeartIcon } from "../../../assets/icon/heart.svg";
import { ReactComponent as CommentIcon } from "../../../assets/icon/comment.svg";
import { useNavigate } from "react-router-dom";

function Songontalk({item}){
	const navigate = useNavigate();
	
    // console.log(item);
    return (
        <>
        <div 
			className="post-item"
			onClick={() => {
				// 매거진 클릭이벤트 추가
				window.hackleClient.track({
					key: "clicked_sogontalk",
					properties: {
						클릭위치: "메인페이지",
						타이틀: item?.title,
						작성자: item?.user?.username, // 익명, 작성자
					},
				});
				navigate(`/post/${item?.id}`);
			}}		
			>
			<div className="post-tit">
				<p>{item?.title}</p>
				<div className="post-stats">
					<p>{item?.tags[0].name}</p>
					<p>{item?.posted_at}</p>
				</div>
			</div>
			<div className="post-meta">
				<div className="">
					<HeartIcon className="main-meta-icon"/>
					<span>{item?.like_count}</span>
				</div>
				<div className="">
					<CommentIcon className="main-meta-icon"/>
					<span>{item?.reply_count}</span>
				</div>
			</div>
		</div>
        </>
    )
}

export default Songontalk