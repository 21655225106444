/***************************************************************************************
* FileName      : ProductOrdering.js
* Description   : 고객 > 상품 리스트 > 정렬 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.07.23
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

			
function ProductOrdering({ show, onHide ,ordering, setOrdering }) {
  
    const applySettings = (orderType) => {
      setOrdering(orderType);  
      onHide(); 
    };

    const getStyle = (orderType) => {
      return orderType === ordering ? { color: '#ff843d' } : {};
    };

    return (
    <>
      <Offcanvas show={show} onHide={onHide}  placement="bottom" >
          <Offcanvas.Body>
            <ul className='orderingList'>
              <li style={getStyle('random')} onClick={() => applySettings('random')}>
              추천
              </li>
              <li style={getStyle('-view')} onClick={() => applySettings('-view')}>
              인기순
              </li>
              <li style={getStyle('-created_at')} onClick={() => applySettings('-created_at')}>
              최신순 
              </li>
              <li style={getStyle('sale_price')} onClick={() => applySettings('sale_price')}>
              낮은 가격순
              </li>
              <li style={getStyle('-sale_price')} onClick={() => applySettings('-sale_price')}>
              높은 가격순
              </li>
              <li style={getStyle('discount_ratio')} onClick={() => applySettings('discount_ratio')}>
              할인율 높은순
              </li>
            </ul>
           
          </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}



export default ProductOrdering;