/***************************************************************************************
 * FileName      : MagazineRow.js
 * Description   :
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  : 2024.03.21
 * Modifide Date : 2024.07.17 :CHM : BBTI 라벨링 추가
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import { deleteMagazine } from "../../../../api/admin/magazine";
import { extractYearMonthDayHourMinute } from "../../common/extractDateUtils";
import ToggleSwitch from "../../Component/ToggleSwitch";
import MLContentModal from "../MagazineLogBoard/MLContentModal";
function MagazineRow({ key, magazine, role, onBbtiClick, onBbtiUpdateClick }) {
	// console.log("magazine:",magazine)

	const [showModal, setShowModal] = useState(false);

	const handleShowModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	const handleEditClick = (id) => {
		const editUrl = `/admin/magazine/edit/${id}`;
		window.location.href = editUrl;
	};

	const [isDeleting, setIsDeleting] = useState(false);
	const handleDeleteClick = (magazineId) => {
		const confirmDelete = window.confirm("정말로 삭제 하시겠습니까?");
		if (confirmDelete) {
			setIsDeleting(true);

			deleteMagazine(magazineId)
				.then((response) => {
					alert("성공적으로 삭제되었습니다.");
				})
				.catch((error) => {
					console.error("상품 삭제 중 에러 발생:", error);
				})
				.finally(() => {
					setIsDeleting(false);
				});
		}
	};

	console.log(magazine);

	return (
		<React.Fragment key={magazine?.id}>
			<tr>
				<td>{magazine?.id}</td>
				<td>{magazine?.category?.name}</td>
				<td>
					<img src={magazine?.main_image} />
				</td>
				<td>{magazine?.title}</td>
				<td>
					{magazine?.link && (
						<a
							href={magazine.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							{magazine.link}
						</a>
					)}
				</td>
				<td>
					<a className="pink_btn" onClick={handleShowModal}>
						확인
					</a>
				</td>
				<td>{extractYearMonthDayHourMinute(magazine?.created_at)}</td>
				<td>{magazine?.user?.username}</td>
				<td>
					<p className="mb-3">
						{magazine?.bbti?.length !== 0 ? "O" : "X"}
					</p>
					<a
						className="pink_btn"
						onClick={() => {
							magazine?.bbti?.length === 0
								? onBbtiClick(magazine?.id)
								: onBbtiUpdateClick(magazine?.id);
						}}
					>
						{magazine?.bbti?.length === 0 ? "등록" : "수정"}
					</a>
				</td>
				<td>
					<ToggleSwitch
						pk={magazine?.id}
						checked={magazine?.hidden}
						toggleType="magazine"
					/>
				</td>
				<td>
					<a
						className="pink_btn"
						onClick={() => handleEditClick(magazine?.id)}
					>
						수정
					</a>
				</td>
				<td>
					{role != "CP" ? (
						<a
							className="pink_btn"
							onClick={() => handleDeleteClick(magazine?.id)}
							disabled={isDeleting}
						>
							{isDeleting ? "삭제 중..." : "삭제"}
						</a>
					) : (
						"-"
					)}
				</td>
			</tr>

			{/* 콘텐츠 확인 모달 */}
			<MLContentModal
				targetData={magazine?.contents}
				show={showModal}
				onHide={handleCloseModal}
			/>
		</React.Fragment>
	);
}

export default MagazineRow;
