/***************************************************************************************
* FileName      : Edit.js
* Description   : 소곤핏 관리자,cp > 매거진 관리 > 매거진 수정(요청) 페이지
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.12.14
* Modifide Date : 2023.12.14 : CHM : 에디터 simpleUpload 플러그인 헤더 수정(access 토큰 변경사항 반영)
*               : 2024.03.19 : CHM : 수정 기능 추가
*               : 2024.07.08 : PKH : 카테고리 선택 안했을 시 에러 수정
* Reference     : 미정
***************************************************************************************/
// 승인된 을 CP가 수정요청 하는 페이지 (cp로 로그인하여 Magazine 페이지에서 )
// 관리자가 승인된 을 수정하는 페이지

import React, { useEffect , useState } from 'react';
import Cookies from 'js-cookie';
import { useMutation , useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGetCategory , updateCPMagazine , getMagazine , updateMagazine } from "../../../api/admin/magazine";

import { getUploadURL , uploadImage } from  '../../../api/common/uploadImageApi';
import { LoadingSpinner } from '../Component/LoadingSpinner';

import MagazineContentBox from './Writing/MagazineContentBox';

function Edit()
{
    
    // 쿠키 가져오기
    const role = Cookies.get('role');

    const { register, handleSubmit, formState: { errors } , reset , watch , setValue} = useForm();

    // 원래 타이틀
    const [previousTitle, setPreviousTitle] = useState("");


    const { magazineId } = useParams();

    // 데이터 조회
    const { data ,isLoading } = useQuery(['magazine',magazineId], () => getMagazine(magazineId));


    //  카테고리 조회
    const { data: magazineCategoryData, isLoading: isCategoryDataLoading, isError: isCategoryDataError } = useGetCategory();
    
    // 선택한  타입 
    const [selectedMagazine, setSelectedMagazine] = useState();

    // 대표이미지 
    const [selectedFileName, setSelectedFileName] = useState('');

    // 콘텐츠 내용
    const [magazineContent, setMagazineContent] = useState('');
    

    // 대표이미지 미리보기  
    const [previewUrl , setPreviewUrl] = useState();

    // 기존에 작성된 제목
    const [prevTitle, setPrevTitle] = useState();
    // 기존에 작성된 매거진 콘텐츠 내용
    const [prevMagazine, setPrevMagazine] = useState();

       
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // 파일 미리보기를 위한 URL을 설정
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file); 
            setSelectedFileName(file.name);
        } else {
            setPreviewUrl(null);
            setSelectedFileName('');
        }

    };
    
    // 선택한  타입 저장
    const handleSelectChange = (event) => {
        const { value } = event.target;
        setSelectedMagazine(value);
    };

    useEffect(() => {
        if (!isLoading && data) {
            setValue('title', data.title);
            setPreviousTitle(data.title); 
            setValue('link', data.link);
            setSelectedMagazine(data.category.id);
            setValue('isPrivate', data.hidden);
            setPreviewUrl(data.main_image);
            setMagazineContent(data.contents);
            setPrevMagazine(data?.contents);
        }
        // console.log("data:",data);
    }, [data]);


    // 관리자가  수정
    const updateAdminMagazineMutaion = useMutation((newData) => updateMagazine(magazineId, newData), {
        onSuccess : (data) => {
            reset();
            alert("수정이 완료 되었습니다");
            window.location.href = '/admin/magazine/all';
           
        },
        onError : (data) => {
            alert(data.request.responseText);
        }
    });

    // CP가  수정요청
    const updateCPMagazineMutaion = useMutation((newData) => updateCPMagazine(magazineId, newData), {
        onSuccess : (data) => {
            reset();
            alert(" 수정 요청이 완료 되었습니다. 관리자 승인 후 게시됩니다.");
            window.location.href = '/admin/magazinelog/all';
        },
        onError : (data) => {
            alert(data.request.responseText);
        }
    });


    const uploadImageMutation = useMutation(uploadImage,{
        onSuccess : (result) => {
    
            const Data = {
                link: watch("link"),
                main_image: result,
                contents: sendContents,
                category: selectedMagazine?.id ?? selectedMagazine,
                hidden: watch("isPrivate") 
            };

    
            if (watch("title") !== previousTitle) {
                // 타이틀이 수정되었을 때
                Data.title = watch("title");
            }

            // updateMagazineMutaion.mutate(Data);
            confirmRole(Data , 0);
        }
    });

    const uploadURLMutaion = useMutation(getUploadURL , {
        onSuccess : (data) => {
            uploadImageMutation.mutate({
                uploadURL : data?.uploadURL,
                imageURL : data?.imageURL,
                file : watch("MImage"),
            })
        },
        onError : (data) => {
            console.log("error", data)
        }
    });

    // 에디터 open상태 확인 (true면 등록버튼 비활성)
    const [onEditor, setOnEditor] = useState(true);

    // role 확인하여  수정
    const confirmRole = (Data , img) => {
        if(role === "CP"){
            if(img === 1) Data.main_image = data.main_image; 
            const CPData = {
                after : Data,
                magazine : magazineId
            }
            updateCPMagazineMutaion.mutate(CPData);
        }else{
            updateAdminMagazineMutaion.mutate(Data);
        }
 
    }

    const onSubmit = (data) => {
        if( !(watch("MImage").length === 1)) {
            // 이미지 수정 없을경우
            const newData = {
                link: watch("link"),
                contents: sendContents,
                category: selectedMagazine?.id ?? selectedMagazine,
                hidden: watch("isPrivate") 
            };

            
            if (watch("title") !== previousTitle) {
                // 타이틀이 수정되었을 때
                newData.title = watch("title");
            }
            
            confirmRole(newData , 1);

          }else{
            uploadURLMutaion.mutate();
          }

    };
    

    // 매거진 콘텐츠 형식에 따라 String 맨 앞에 형식 표시
    const CONTENT_TYPES = {
        content: 'C',
        product: 'P',
        new: 'N',
        best: 'B',
        random: 'R'
    };
    const [sendContents, setSendContents] = useState([]);

    // magazineContent의 변화에 따라 sendContents를 업데이트
    useEffect(() => {
        if(magazineContent != ''){
            const contents = magazineContent?.filter(data => data?.data || data?.layout)
            .map(data => {
                // 기본적으로 data 또는 layout 중 존재하는 값을 사용
                const contentData = data?.data || data?.layout;
                const prefix = CONTENT_TYPES[data.type] || '';
                return prefix + contentData;
            }) || [];

            setSendContents(contents);
        }
    }, [magazineContent]);

    return (
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            <div className='tit_area line_thick'>
                                <h2 className='tit_lv2'> 
                                    {role === "CP" ? "수정 요청" : "수정" } 
                                </h2>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>     
                                <div className='form_table no_line'>
                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>카테고리</p>
                                        </div>
                                        <div className='td'>
                                            {isCategoryDataLoading ? (
                                                <p>Loading...</p>
                                            ) : isCategoryDataError ? (
                                                <p>Error loading Category data</p>
                                            ) : (
                                                <select value={selectedMagazine || ""}  onChange={handleSelectChange}>
                                                    {magazineCategoryData.data.map((category) => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>

                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>제목</p>
                                        </div>
                                        <div className='td'>
                                            <input 
                                                type='text' 
                                                name='title' 
                                                className={errors.title ? 'error-input' : ''}
                                                {...register('title', { required: '제목 입력은 필수입니다.' })} />
                                            
                                        </div>
                                    </div>

                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>링크</p>
                                        </div>
                                        <div className='td'>
                                            <input 
                                                type='text' 
                                                name='link' 
                                                className={errors.link ? 'error-input' : ''}
                                                {...register('link', { required: '링크 입력은 필수입니다.' })} />
                                            
                                        </div>
                                    </div>
                                    
                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>대표 이미지</p>
                                        </div>
                                        <div className='td'>
                                            <div className='imgPreview'>
                                                { previewUrl ? (
                                                <>
                                                    <div className='mpreimg'>
                                                        <span>기존 이미지</span>
                                                        <img className='editImg' src={previewUrl}/>
                                                    </div>
                                                </>
                                                ) : (
                                                <></>
                                                )}
                                            </div>

                                            <div class="filebox">
                                                <input  className={errors.MImage ? 'upload-name error-input' : 'upload-name'} 
                                                        value={selectedFileName} 
                                                        placeholder="대표 이미지파일" />
                                                        
                                                <label htmlFor="productImage_file">파일찾기</label> 

                                                <input
                                                    type='file'
                                                    id='productImage_file'
                                                    name='productImage'
                                                    accept="image/jpg, image/png, image/jpeg"
                                            
                                                    {...register('MImage')}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                     

                                            {/* <ProductImageUploader  /> */}
                                        </div>
                                    </div>
                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>내용</p>
                                        </div>
                                        <div className='td'>
                                            <MagazineContentBox
                                                className='my-5 w-100'
                                                magazineContent={magazineContent}
                                                setMagazineContent={setMagazineContent}
                                                prevMagazine={prevMagazine}
                                                onEditor={onEditor}
                                                setOnEditor={setOnEditor}
                                            />

                                        </div>
                                    </div>
                                    <div className='tr'>
                                        <div className='th'>
                                            
                                        </div>
                                        <div className='td'>
                                        <div className="form-check">
                                            <input className="form-check-input custom-checkbox" 
                                                    type="checkbox"  
                                                    id="hiddenCheck" 
                                                    {...register('isPrivate')}
                                            />

                                            <label className="form-check-label" for="hiddenCheck">
                                            비공개
                                            </label>
                                        </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='btn-wrap'>
                                    <button className='btn_white_2'
                                        type="button"
                                        onClick={()=>alert('취소ㄱ?')}>
                                        취소
                                    </button>
                                    {
                                        onEditor ? (
                                            <button className='btn_disable_2'
                                                type="button"
                                                onClick={()=>alert('에디터가 열려있으면 매거진 등록이 불가능합니다.')}>
                                                매거진 수정 요청
                                            </button>
                                        ):(
                                            <button className='btn_black_2'
                                                type="submit">
                                                {updateAdminMagazineMutaion.isLoading || uploadImageMutation.isLoading || uploadURLMutaion.isLoading
                                                ? <LoadingSpinner /> : '수정'}
                                            </button>
                                        )
                                    }
                                    {/* <p onClick={()=>console.log("매거진 콘텐츠 : ", preview())}>미리보기</p> */}
                                </div>
                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}


export default Edit 