/***************************************************************************************
 * FileName      : Post.js
 * Description   : 소곤핏 소곤톡 리스트 페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.02
 * Modifide Date : 2024.08.23 : 디자인 수정사항 반영
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import MobileHeader from "./Component/MobileHeader";
import Gnb from "../../Gnb";
import Dropdown from "react-bootstrap/Dropdown";
import { BiSortAlt2 } from "react-icons/bi";
import { fetchPostData } from "../../api/services/post";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHeart,
	faCommentDots,
	faPen,
	faBars
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useInfiniteQuery } from "react-query";

import Postitem from "./Post/Postitem";

import InfiniteScroll from "react-infinite-scroll-component";
// import { LoadingSpinner } from "../admin/Component/LoadingSpinner";

import { getMe } from "../../api/common/memberApi";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PostSkeleton from "./Post/PostSkeleton";

function Post() {
	const [page, setPage] = useState(0);
	const [tag, setTag] = useState("all");

	// 회원 정보
	const [user, setUser] = useState({});

	// 정렬
	const [order, setOrder] = useState("");

	const [tagList , setTagList] = useState();

	useEffect(() => {
		getMe().then((data) => {
			const userData =
				data?.data === "guest"
					? { type: "guest", username: "" }
					: { type: data?.type, username: data?.username };

			setUser(userData);
		});
	}, []);

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		isLoading,
	} = useInfiniteQuery(
		["postdata", tag, page, order],
		({ pageParam = 1 }) => fetchPostData(tag, pageParam, order),
		{
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.nextPage;
			},
		}
	);


	const [activeIndex, setActiveIndex] = useState(null);

	useEffect(() => {
		setActiveIndex("-1");
	}, []);

	const handleSelect = (eventKey) => {
		setOrder(eventKey);
	};

	const handleTagClick = (id, index) =>{

	}
	if (isLoading) {
		return (
			<PostSkeleton/>
		);
	}
	  
	  

	// console.log(data?.pages[0]?.totalCnt);
	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 소곤톡</title>
			</Helmet>
			<MobileHeader back={true} title={"소곤톡"} />
			<div className="post">
				<section className="prodcut-content my-5">
					{/* <div className='tags-slider'>
                    <span className=''>#다이어트</span>
                </div> */}

					<div className="inner">
						{/* <nav className="tagsNav">
                        <span 
                            className={`tag-box pointer mb-2'  ${activeIndex === '-1' ? 'active '  : ''}`}
                            onClick={()=>{handleTagClick('all' , '-1' );}}>
							<FontAwesomeIcon className="bars" icon={faBars}  />
						주제
                        </span>
                        
                        {tagList?.data.map((brand, index) => (
                            <>
                         
                            <div className={`tag-box pointer mb-2 ${index === activeIndex ? 'active' : ''}`}
                                key={index}  
                                onClick={()=>{handleTagClick(brand.id , index);}}>
                            
                                {brand?.name}
                
                            </div>
                            </>
                        ))}
                    </nav> */}

					{/* <div>
						<p>총<span>111</span>건</p>
					</div> */}
					<div className="sort-box">
						<p>
							총 <span>{data?.pages[0]?.totalCnt}</span>건
						</p>
						{/* <p onClick={handleOrderingModalShow}>정렬</p> */}
						<Dropdown onSelect={handleSelect}>
							<Dropdown.Toggle id="sort-dropdown">
								<BiSortAlt2 />
								정렬
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item eventKey="popular">
									인기순
								</Dropdown.Item>
								<Dropdown.Item eventKey="reply">
									댓글순
								</Dropdown.Item>
								<Dropdown.Item eventKey="new">
									최신순
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="post-wrap mb-5">
						<InfiniteScroll
							style={{
								display: "flex",
								flexWrap: "wrap",
								gap: "10px",
								paddingBottom: "50px",
							}}
							dataLength={
								data?.pages
								  ? data.pages.reduce(
									  (total, page) => total + page.data.length,
									  0
									)
								  : 0
							  }
							next={() => fetchNextPage()}
							hasMore={hasNextPage}
							loader={<div></div>}
							endMessage={<div></div>}
						>
						{data?.pages?.map((page, pageIndex) => (
							<React.Fragment key={pageIndex}>
								{page.data.map((item) => (
									<Postitem
										isLoading={isFetchingNextPage}
										item={item}
										key={item.id}
									/>
								))}
							</React.Fragment>
						))}
					</InfiniteScroll>
					
					</div>

					{user?.type !== "guest" && (
						<Link
							to="/post/write"
							className="posting-btn"
							onClick={() => {
								ReactGA.event({
									action: "소곤톡 포스팅",
									category: "소곤톡 글쓰기",
								});
							}}
						>
							<FontAwesomeIcon className="pen" icon={faPen} />
						</Link>
					)}
					</div>
				</section>

				<Gnb />
			</div>
		</>
	);
}

export default Post;
