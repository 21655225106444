/***************************************************************************************
* FileName      : PostReportRow.js
* Description   : 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.07
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { deletePost } from '../../../../api/admin/Report'
import { extractYearMonthDay2 } from '../../common/extractDateUtils';
import { Link } from 'react-router-dom';
import { updateReportPost } from '../../../../api/admin/Report';

function PostReportRow({ report }){
   
    const handleUpdateStatusClick = (reportId , status) => {

      const confirmationMessage =
        status === "D" ? '정말로 해당 게시물을 삭제 하시겠습니까?' :
        status === "H" ? '정말로 해당 게시물을 비공개 하시겠습니까?' :
        status === "P" ? '정말로 해당 게시물을 보류 하시겠습니까?' : '';
    
      const confirm = window.confirm(confirmationMessage);
      
      if (confirm) {
        updateReportPost(reportId, { status: status })
        .then(data => {
            let successMessage = "";
            switch (status) {
                case "D":
                    successMessage = "성공적으로 삭제 되었습니다.";
                    break;
                case "H":
                  successMessage = "성공적으로 비공개 되었습니다.";
                  break;
                case "P":
                    successMessage = "성공적으로 보류 되었습니다.";
                    break;
                default:
                    successMessage = "작업이 성공적으로 완료되었습니다.";
                    break;
            }
            alert(successMessage);
        })
        .catch(error => {
            // 에러 처리
        });
    }
  };

    return (
        <React.Fragment key={report?.id}>
            <tr>
                <td>{report?.id}</td>
                <td>{report?.target?.username}({report?.target?.nickname})</td>
                <td>{report?.user?.username}({report?.user?.nickname})</td>
                <td>
                  <Link to={`/post/${report?.post}`}>게시글로 이동</Link>
                  {/* {report?.post} */}
                </td>

                <td>{report?.reason}</td>
              
                <td>{extractYearMonthDay2(report?.created_at)}</td>
              
                <td>
                  {report?.status === 'R' ? (
                    <>
                      <a className='pink_btn mr-1' onClick={() => handleUpdateStatusClick(report?.id , "D")}>
                      삭제
                      </a>
                      <a className='pink_btn mr-1' onClick={() => handleUpdateStatusClick(report?.id , "H")}>
                      비공개
                      </a>
                      <a className='pink_btn mr-1' onClick={() => handleUpdateStatusClick(report?.id , "P")}>
                      보류
                      </a>
                    </>
                  ) : report?.status === 'P' ? (
                      <span>보류됨</span>
                  ) : report?.status === 'D' ? (
                      <span>삭제완료</span>
                  ) : report?.status === 'H' ? (
                      <span>비공개</span>
                  ) : null
                
                  
                  }

                </td>

            </tr>

        </React.Fragment>
    )
}

export default PostReportRow