import { Link } from "react-router-dom";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useLocation,
} from "react-router-dom";
import { getMe, userLogout } from "../../api/common/memberApi";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
function Mheader() {
	const [type, setType] = useState(null);
	const [role, setRole] = useState(null);
	const [username, setUsername] = useState(null);

	useEffect(() => {
		console.log(role);
	}, [role]);
	useEffect(() => {
		getMe()
			.then((data) => {
				console.log(data);
				setRole(data.role);
				setType(data.type);
				setUsername(data.username);
			})
			.catch((error) => {
				console.error(
					"데이터를 가져오는 중 오류가 발생했습니다:",
					error
				);
			});
	}, []);

	const location = useLocation();

	const pathData = {
		"/admin": {
			title: "대시보드",
			contentText: "소곤핏 서비스 이용 통계",
		},
		"/admin/loading": {
			title: "로딩",
			contentText: "로딩중입니다.",
		},
		"/admin/users": {
			title: "회원관리",
			contentText: "회원정보 조회 및 상세관리",
		},
		"/admin/mainManagement": {
			title: "메인관리",
			contentText: "메인페이지 관리",
		},
		"/admin/magazine": {
			title: "매거진관리",
			contentText: "매거진 발행 및 관리",
		},
		"/admin/magazinelog": {
			title: "매거진관리",
			contentText: "중간관리자 매거진 승인 거절 관리",
		},
		"/admin/category": {
			title: "매거진관리",
			contentText: "매거진 카테고리 순서 변경",
		},
		"/admin/brand": {
			title: "브랜드관리",
			contentText: "소곤핏 입점 브랜드 관리",
		},
		"/admin/brandOrder": {
			title: "브랜드관리",
			contentText: "브랜드 순서 변경",
		},
		"/admin/test": {
			title: "테스트통계",
			contentText: "사용자 테스트 이용 및 결과 통계",
		},
		"/admin/bmproduct": {
			title: "상품 관리",
			contentText: "중간 관리자 승인된 상품 관리",
		},
		"/admin/productlog": {
			title: "상품 관리",
			contentText: "중간 관리자 상품 승인 거절 관리",
		},
		"/admin/post": {
			title: "소곤톡",
			contentText: "소곤톡 게시판 관리",
		},
		"/admin/report": {
			title: "소곤톡",
			contentText: "소곤톡 신고 관리",
		},
	};

	// 현재 경로에 해당하는 레이블 가져오기
	const currentPathData =
		pathData[
			location.pathname
				.split("/")
				.slice(0, 3)
				.join("/")
				.replace(/\/$/, "")
		];

	return (
		<>
			<header className="m-header">
				<div className="admin-header-wrap">
					<div className="admin-logo-box">
						<div className="logo">
							<Link to="/admin" className="pointer">
								<img
									src={
										process.env.PUBLIC_URL +
										"/img/slogo.png"
									}
									alt=""
									width="120px"
								/>
							</Link>
						</div>
					</div>
					<div className="admin-tit-box">
						<div className="status-bar ">
							<div className="tit-wrap">
								<span>{currentPathData?.title}</span>
								<span>{currentPathData?.contentText}</span>
							</div>
						</div>
					</div>

					<div className="admin-logout-box">
						<span>{username && username}님</span>
						<p
							onClick={() => {
								userLogout()
									.then((res) => {
										Cookies.remove("access");
										window.hackleClient.resetUser(); // 핵클 로그아웃
										window.location.href = "/";
									})
									.catch((error) =>
										alert(
											"로그아웃 에러",
											error.response.data
										)
									);
							}}
						>
							로그아웃
						</p>
					</div>
				</div>
			</header>
		</>
	);
}
export default Mheader;
