/***************************************************************************************
* FileName      : VerifyEmailPw.js
* Description   : 소곤핏 고객 > 비밀번호 찾기 > 결과 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.06.12
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Helmet } from 'react-helmet-async';
import MobileHeader from "../Component/MobileHeader";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../../api/services/memberApi';
import { LoadingSpinner } from '../../admin/Component/LoadingSpinner';

function VerifyEmailPw()
{
  
    // 현재 페이지의 URL을 가져오기
    const url = window.location.href;

    // URL을 파싱하여 검색 매개변수를 추출
    const searchParams = new URL(url).searchParams;
    const email = searchParams.get("email");
    const userid = searchParams.get("username");
    const verification_code = searchParams.get("verification_code");

    const navigate = useNavigate();

    const [inputPw, setInputPw] = useState('');
    const [inputPw2, setInputPw2] = useState('');

    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = () => {
        if (!isLoading) {
            setIsLoading(true);

            changePassword(email, userid, verification_code, inputPw, inputPw2)
                .then(res => {
                    alert(res?.data?.data);
                    navigate('/login');
                })
                .catch(error => {
                    error?.response?.data?.error ? (
                        alert(error?.response?.data?.error)
                    ) : (
                        alert("비밀번호 변경에 실패했습니다.")
                    )
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }

    
    return(
        <>
        <Helmet>
            <title>소곤핏 Sogonfit - 비밀번호 재설정</title>
        </Helmet>
        <MobileHeader back={true} title={"비밀번호 재설정"}/>
        <div className="findId mt-5">
            <div className="inner">
                <p>새로운 비밀번호</p>
                <input 
                    name="pw"
                    type="password"
                    onChange={(e) => setInputPw(e.target.value)}
                    />
                <p>새로운 비밀번호 확인</p>
                <input 
                    name="pw"
                    type="password"
                    placeholder='비밀번호를 한번 더 입력해주세요'
                    onChange={(e) => setInputPw2(e.target.value)}
                    />
            </div>

            <button className="btn-primary findBtn mt-4" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? <LoadingSpinner /> : '비밀번호 재설정 완료'} 
            </button>

        </div>
 
       
        </>
    )
}

export default VerifyEmailPw