/***************************************************************************************
 * FileName      : Magazine.js
 * Description   : 소곤핏 매거진 메인페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { useState, useEffect } from "react";
import MagazineMain from "./Main/MagazineMain";
import MobileHeader from "./Component/MobileHeader";
import MagazineContentList from "./Magazine/MagazineContentList";

import { useGetCategory } from "../../api/services/magazine";
import Gnb from "../../Gnb";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

function Magazine() {
	const location = useLocation();
	const [pageFilter, setPageFilter] = useState(null);

	useEffect(() => {
		// 지정된 URL 매개변수를 가 있으면 해당 값을 가져옴
		// 메인페이지 more 클릭한 경우에 사용
		const queryParams = new URLSearchParams(location.search);
		setPageFilter(queryParams.get("type"));
	}, [location]);

	const [magazineCategoryId, setMagazineCategoryId] = useState("all");
	const [activeIndex, setActiveIndex] = useState(null);

	const [categorys, setCategorys] = useState([]);

	// 카테고리 조회
	const { data: categoryData } = useGetCategory();

	useEffect(() => {
		const processedData = categoryData?.data.map((item) => ({
			title: item.name,
			id: item.id,
		}));

		setCategorys(processedData);

		if (pageFilter !== null) {
			// 메인페이지 more 클릭한 경우에 필터 적용
			processedData?.forEach((item, index) => {
				item?.title === pageFilter &&
					handleCategoryClick(item?.id, index);
			});
		}
	}, [categoryData, pageFilter]);

	useEffect(() => {
		pageFilter === null && setActiveIndex("-1");
	}, []);

	const handleCategoryClick = (id, index) => {
		setMagazineCategoryId(id);
		setActiveIndex(index);
	};

	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 매거진 리스트</title>
				<meta property="og:site_name" content="소곤핏 매거진" />
				<meta
					property="og:title"
					content="소곤핏 매거진 - 속옷 전문 여성 매거진"
				/>
				<meta
					property="og:image"
					content="/img/sogonfit_thumbnail.jpeg"
				/>
			</Helmet>
			<MobileHeader back={true} title={"소곤로그"} />
			<div className="magazine">
				<section className="magazine-content my-5">
					<div className="inner pb-5">
						<nav className="magazineNav">
							<span
								className={`category-box pointer mb-2'  ${activeIndex === "-1" ? "active " : ""}`}
								onClick={() => {
									handleCategoryClick("all", "-1");
								}}
							>
								전체
							</span>
							{categorys?.map((item, index) => (
								<span
									className={`category-box pointer mb-2 ${index === activeIndex ? "active" : ""}`}
									key={index}
									onClick={() => {
										handleCategoryClick(item?.id, index);
									}}
								>
									{item?.title}
								</span>
							))}
						</nav>
						<MagazineContentList
							magazineCategoryId={magazineCategoryId}
						/>
					</div>
				</section>
				<Gnb />
			</div>
		</>
	);
}

export default Magazine;
