/***************************************************************************************
* FileName      : Likeitem.js
* Description   : 마이페이지 > 매거진 좋아요 목록 > Likeitem 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.13
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart , faCommentDots , faPen } from '@fortawesome/free-solid-svg-icons';

import { extractYearMonthDay2 } from '../../../admin/common/extractDateUtils';

function Likeitem({ item })
{
    // const handleClick = () => {
    //     window.location.href = `/post/${item.post}`;
    // };

    // function truncateText(text, maxLength) {
    //     if (text.length > maxLength) {
    //       return text.slice(0, maxLength) + '...';
    //     }
    //     return text;
    // }
    
    console.log(item);
    return(
        <>
        <Link className='my-magazine-content' to={`/magazine/${item.id}`}>
            <div className='my-m-img'>
                <img src={item?.main_image} alt={item?.title} />
            </div>

            <div className='magazine-info'>
                <div className='post-tags'>    
                    <span key={item.category.id} className='post-tag'>{item.category.name}</span>
                </div>
                <div className='post-tit'>
                    <p>{item?.title}</p>
                    <div className='m-mheart'>
                        <FontAwesomeIcon icon={faHeart} style={{ marginRight: '3px' }}/>
                        {item?.like_count}
                    </div>
                </div>
            </div>

        </Link>
        </>
    )
}

export default Likeitem