/***************************************************************************************
* FileName      : FindPassword.js
* Description   : 소곤핏 고객 > 비밀번호 찾기 페이지 > 휴대폰번호로 비밀번호 찾기
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.06.12
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

import { verifyPasswordPhone , verifyPasswordPhoneCheck , verifyPasswordPhoneChange } from '../../api/services/memberApi';
import { useState , useEffect } from 'react';
import { formatTime } from './Common/FormatTime';
import { showAlert } from './Common/alertUtil';

function FindPassword_Phone({selectedOption}) 
{
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');

    const isPhoneNumberValid = phoneNumber.length === 11; 

    const [showMessage, setShowMessage] = useState(false);
    const [timer, setTimer] = useState(180);
    const [interval, setIntervalId] = useState(null);

    const [phoneHash , setPhoneHash] = useState('');

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState(''); 


    // 휴대폰 인증 완료 여부 상태
    const [verificationCompleted, setVerificationCompleted] = useState(false); 
      
    // 휴대폰 입력
    const handlePhoneNumberChange = (e) => {
      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
      setPhoneNumber(onlyNumbers);
    };
    
    // 휴대폰 인증번호 요청
    const handlePhoneAuth = (event) => {
      event.preventDefault();

      verifyPasswordPhone(name , phoneNumber , username)
      .then(res =>{
        showAlert(res?.data?.data)
        setShowMessage(true);
        setTimer(180); 
      })
      .catch(error => {
        const errors = error.response.data.error;
        const firstErrorMessage = Array.isArray(errors)
              ? Object.entries(errors[0]).map(([key, value]) => `${key}: ${value}`).join('\n')
              : errors;
        	
			  showAlert(firstErrorMessage); 
      });
    };


    // 휴대폰 인증번호 확인
    const verifyPhoneCheck = () => {
      verifyPasswordPhoneCheck(username , phoneNumber , code)
      .then(res =>{
        showAlert(res?.data?.data);
        setVerificationCompleted(true); // 인증 완료 상태로 설정
        setPhoneHash(res?.data?.phone_hash);
        clearInterval(interval); 
      })
      .catch(error => {
        const errors = error.response.data.error;
        const firstErrorMessage = Array.isArray(errors)
              ? Object.entries(errors[0]).map(([key, value]) => `${key}: ${value}`).join('\n')
              : errors;
        	
			  showAlert(firstErrorMessage); 

      });
    };

    // 비밀번호 변경
    const verifyPasswordChange = () => {
      verifyPasswordPhoneChange(phoneNumber , username , phoneHash, password , password2)
      .then(res =>{
        showAlert(res?.data?.data+" 로그인 페이지로 이동합니다.");
        window.location.href = '/login';
      })
      .catch(error => {
        showAlert(error?.response?.data?.error || "확인해주세요.");
    
      });
    };

    useEffect(() => {
      let id;
      if (showMessage && timer > 0) {
        id = setInterval(() => {
          setTimer(prev => prev - 1);
        }, 1000);
        setIntervalId(id); // interval id 저장
      }
      if (timer === 0) {
        setShowMessage(false); 
      }
      return () => clearInterval(id);
    }, [showMessage, timer]);


    return (
    <>
     <div className='findPassword-phone' style={{display: selectedOption === 'find_phone' ? 'block' : 'none'}}>
       { !verificationCompleted && ( 
        <>
        <p>이름</p>
        <input 
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          />

        <p>아이디</p>
        <input 
          type="text"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          />
                  
          
        <p>휴대폰 번호 인증</p>
        
        <div className='fpd'>
          <input 
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            />
          <button 
            className="getCodeBtn pointer"    
            onClick={handlePhoneAuth}        
            disabled={!isPhoneNumberValid}>
            인증번호 요청
          </button>
        </div>


        {showMessage && (
          <>
          <div className='fm-wrap mt-1 mb-4'>
            <p>인증번호를 전송하였습니다. (재전송 가능 남은시간: {formatTime(timer)})</p>
          </div>
   
          <p>인증번호</p>

          <input 
            type="text"
            name="authNumber"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            disabled={verificationCompleted}
            placeholder='인증번호를 입력해주세요.'
          />

        </>
        )}
 

        <button className="btn-primary findBtn mt-4" onClick={verifyPhoneCheck}>
        인증하기
        </button>
        </>
      )}

      {verificationCompleted && (
      <>
        <p>비밀번호</p>
        <input 
          type='password' 
          name='password' 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p>비밀번호 재확인</p>
        <input 
          type='password' 
          name='password2' 
          // placeholder='비밀번호 재확인'
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />


        <button className="btn-primary findBtn mt-4" onClick={verifyPasswordChange}>
        비밀번호 변경
        </button>
      </>
      )}

      </div>
    </>        
  );
}

export default FindPassword_Phone;