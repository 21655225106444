// 관리자 > 소곤톡
import { queryClient, useMutation, useQuery, prefetchQuery } from "react-query";
import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { instance } from "../axiosConfig.js";

const PAGE_SIZE = 10;

// 관리자 > 소곤톡 > 글 조회
const fetchAdminpostList = (page, totalPages, privateChecked) => {
	if (page > totalPages) {
		return Promise.resolve({ data: [], totalCount: 0 });
	}

	const url = `/post/admin?page=${page}${privateChecked ? "&admin_hidden=true" : ""}`;

	return instance.get(url).then((res) => res.data);
};

export const useAdminpostList = (privateChecked) => {
	const queryClient = useQueryClient();

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		window.scrollTo(0, 0);
		const nextPage = page + 1;
		if (nextPage <= totalPages) {
			queryClient.prefetchQuery([`AdminPostList`, nextPage], () =>
				fetchAdminpostList(nextPage, totalPages)
			);
		}
	}, [page, totalPages, queryClient]);

	useEffect(() => {
		setPage(1);
	}, [privateChecked]);

	const { data, error, isFetching } = useQuery(
		[`AdminPostList`, page, privateChecked],
		() => fetchAdminpostList(page, totalPages, privateChecked),
		{
			onSuccess: (data) => {
				setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));

				if (page < totalPages) {
					prefetchQuery(["AdminPostList", page + 1], () =>
						fetchAdminpostList(page + 1, totalPages, privateChecked)
					);
				}
			},
		}
	);

	return {
		data,
		error,
		isFetching,
		page,
		setPage,
		totalPages,
	};
};

// 관리자 > 소곤톡 > 게시글 비공개 -> 공개 처리
export function updateHiddenPost(id, status) {
	return instance.patch(`post/admin/hidden/${id}`, {
		admin_hidden: status,
	});
}
