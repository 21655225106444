/***************************************************************************************
* FileName      : Postitem.js
* Description   : 마이페이지 > 댓글 > Postitem 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.17
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { extractYearMonthDay2 } from '../../../admin/common/extractDateUtils';
import { getRecordCheck } from '../../../../api/services/mypageApi';

function Postitem({ item })
{
    const handleClick = async () => {
        try {
            const res = await getRecordCheck(item.id);
            window.location.href = `/post/${item.post}`;
        } catch (error) {
            console.log(error?.response);
            alert('로그아웃 처리중 에러가 발생하였습니다.');
        }
    }

    return(
        <>
        <div className='mpr-item'>
            <div>
                <p>내가 작성한 
                    <span className='tit'>{item.post_title}</span> 
                    글에 새로운 댓글이 달렸습니다. 
                </p>
                {!item?.is_checked &&
                    <button onClick={handleClick}>확인</button>
                }

            </div>
            <span>{extractYearMonthDay2(item?.created_at)}</span>
        </div> 
        </>
    )
}

export default Postitem