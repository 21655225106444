// cookieUtils.js

// Access 토큰을 쿠키에서 가져오는 함수
export const getAccessFromCookie = () => {
    const cookies = document.cookie.split('; ');
      for (let cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'access') {
          return value;
        }
      }
      return null;
  }
   
  
  export const getValueFromCookie = (key) => {
    const cookies = document.cookie.split('; ');
      for (let cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === key) {
          return value;
        }
      }
      return null;
  }
  
  export const setTokenToCookie = (token) => {
    document.cookie = `token=${token}; path=/;`;
  };
  
  export const setCookie = (name, data) => {
    return document.cookie = `${name}=${data}; path=/;`
  }