/***************************************************************************************
 * FileName      : test.js
 * Description   : 관리자페이지 테스트 통계
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  : 2023.12.15
 * Modifide Date : 2023.12.15 : CHM : api 수정사항 적용 non_user -> guest로 api 변수 변경사항 적용
 *               : 2024.07.09 : CHM : BBTI 체형 테스트 통계 조회 수정
 * Reference     : 미정
 ***************************************************************************************/
import React from "react";
import { useEffect, useState } from "react";
import {
	useGetbbtiStatistic,
	useGetResearchStatistic,
	useGetparticipant,
	useGetsizeStatistic,
} from "../../../api/admin/bbti";

function Test() {
	// bbti target 조회
	const [bbtiTarget, setBbtiTarget] = useState("전체");

	// 사이즈 테스트 target 조회
	const [sizeTarget, setSizeTarget] = useState("전체");

	// bbti 테스트 결과 통계
	// const { data: statisticData } = useGetbbtiStatistic(bbtiTarget);
	const { data: researchStatistic } = useGetResearchStatistic();

	// 사이즈 테스트
	// const { data: sizeData } = useGetsizeStatistic(sizeTarget);

	// console.log("사이즈",sizeData);

	// bbti 체형 target 변경
	const [bbti_buttonText, setBbtiButtonText] = useState("전체");

	const handleItemClick = (text) => {
		setBbtiTarget(text);
		setBbtiButtonText(text);
	};

	console.log("researchStatistic", researchStatistic);

	return (
		<>
			<section className="admin-content">
				<div className="container-fluid">
					<p className="group-title mb-4">BBTI 체형 테스트</p>
					{/* <div className="row mb-5">
						<div className="col-md-3">
							<div className="box text-center">
								<p className="">참여자 수 (회원/전체)</p>
								<p className="f2">
									{statisticData &&
										statisticData.user_participant_count}{" "}
									/ {totalParticipants}
								</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="box text-center">
								<p className="">완료자 수 (회원/전체)</p>
								<p className="f2">
									{statisticData &&
										statisticData.user_completion_count}{" "}
									/ {totalCompletions}
								</p>
							</div>
						</div>
					</div> */}

					{/* BBTI 체형 테스트 */}
					<div className="row">
						<div className="col-md-12">
							<div className="box">
								<div className="tit">
									<div class="btn-group">
										<button
											class="selectBtn btn-secondary btn-sm dropdown-toggle"
											type="button"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											{bbti_buttonText}
										</button>
										<ul class="selectDropdown dropdown-menu">
											<li>
												<a
													className="dropdown-item"
													onClick={() =>
														handleItemClick("전체")
													}
												>
													전체
												</a>
											</li>
											<li>
												<a
													className="dropdown-item"
													onClick={() =>
														handleItemClick("회원")
													}
												>
													회원
												</a>
											</li>
										</ul>
									</div>
								</div>

								{/* <p className="sub-tit">BBTI 테스트 결과</p> */}

								<table className="admin_table">
									<colgroup>
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "50%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
									</colgroup>
									<thead>
										<th>#</th>
										<th>결과</th>
										<th width="30%">비율(%)</th>
										<th width="20%" className="tcenter">
											비율(%)
										</th>
										<th className="tcenter">전체 이용자</th>
									</thead>

									<tbody>
										{researchStatistic?.map(
											(item, index) => (
												<tr
													key={`${item?.type} ${index}`}
												>
													<td>{index}</td>
													<td>{item?.type}</td>
													<td>
														<div className="bar_ratio">
															<div
																style={{
																	width: `${item?.ratio}%`,
																}}
															></div>
														</div>
													</td>
													<td className="tcenter">
														{item?.ratio}%
													</td>
													<td className="tcenter">
														{item?.count}
													</td>
												</tr>
											)
										)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Test;
