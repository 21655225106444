/***************************************************************************************
* FileName      : axiosConfig.js
* Description   : axios instance
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.12.14
* Modifide Date : 2020.12.14 : CHM : import 정리
*               : 
* Reference     : 미정
***************************************************************************************/
import axios from 'axios';
import { BASE_URL } from './apiConfig';
import { getAccessFromCookie, setCookie, getValueFromCookie , getRefreshFromCookie } from './cookieUtils';

// 공통 axios 헤더 인증 토큰 설정
const token = getAccessFromCookie();

let headers = {};
const userHeaders = {
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/json',
};
const guestHeaders = {
  'Content-Type': 'application/json',
}
token ? headers = userHeaders : headers=guestHeaders

//공통 axios instance
export const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: headers
});

// 공통 Axios 헤더 인증 토큰 설정
const updateAxiosHeaders = (token) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } 
};

// 공통 axios instance 초기화
export const initializeAxiosInstance = () => {
  const token = getAccessFromCookie();
  updateAxiosHeaders(token);
};

initializeAxiosInstance();

// [jdu] 응답 인터셉터
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// [pkh] 요청 인터셉터
instance.interceptors.request.use(
  (config) => {
    if(!config.headers) return config;
    const accesstoken = getAccessFromCookie();

    if(accesstoken && config.headers) {
      config.headers["Authorization"] = `Bearer ${accesstoken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// [jdu] 응답 인터셉터
instance.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject});
        }).then(token => {
          updateAxiosHeaders(token);
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return instance(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function(resolve, reject) {
        instance.post('/auth/refresh')
          .then(res => {
            if (res.status === 200) {
              // if (process.env.REACT_APP_ENV_NAME === 'dev') {
                setCookie('access', res.data.access);
                setCookie('refresh', res.data.refresh);
              // }

              const newToken = getAccessFromCookie();

              updateAxiosHeaders(newToken);

              originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
              processQueue(null, newToken);
              resolve(instance(originalRequest));
            }
          })
          .catch(err => {
            processQueue(err, null);
            window.location.replace('/login');
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);




// [hm] 새로운 axios instance 설정 - 이미지 업로드용
export const imageUploadInstance = axios.create({
	baseURL: `${BASE_URL}/`, // 이미지 업로드 API의 URL
	headers: {
		'Authorization': `Bearer ${token}`,
		'Content-Type': 'multipart/form-data', // 이미지 업로드 시에는 multipart/form-data로 설정
	},
});

// [hm] 새로운 axios instance 설정 - 에디터 이미지 업로드용
export const simpleUploadInstance = axios.create({
  uploadUrl: `${BASE_URL}/aws/directupload`, // 이미지 업로드 URL
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`
  },
});

export const simpleUploadHeader = {
  uploadUrl: `${BASE_URL}/aws/directupload`, // 이미지 업로드 URL
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${token}`
  },
};