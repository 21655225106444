/***************************************************************************************
* FileName      : SideMenu.js
* Description   : 소곤핏 관리자 > 메인 > 배너관리
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const SideMenuItem = ({ to, children }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
  

    return (
        <Link to={to} >
            <li className={`side-item ${isActive ? 'active' : ''}`}>
                {children}
            </li>
        </Link>
    );
};

const SideMenu = () => {
  return (
    <ul className="side-menu">
      <SideMenuItem to="/admin/mainManagement/banner">배너관리</SideMenuItem>
      <SideMenuItem to="/admin/mainManagement/magazine">매거진영역관리</SideMenuItem>
      <SideMenuItem to="/admin/mainManagement/product">상품영역관리</SideMenuItem>
    </ul>
  );
};

export default SideMenu;