import { useEffect, useState } from 'react';
import { bbtiStart, bbtiSave, bbtiSubmit } from '../../../api/services/bodytypeApi.js';
import MobileHeader from '../Component/MobileHeader';
import { useNavigate } from "react-router-dom/dist/index.js";

import Step1 from './BodyTypeAnswer/Step1.js';
import Step2 from './BodyTypeAnswer/Step2.js';
import Step3 from './BodyTypeAnswer/Step3.js';
import Step4 from './BodyTypeAnswer/Step4.js';
import Step5 from './BodyTypeAnswer/Step5.js';
import Step6 from './BodyTypeAnswer/Step6.js';
import Step7 from './BodyTypeAnswer/Step7.js';

function BbtiTest(){
	const [uuid, setUUID] = useState('');
	useEffect(() => {
		bbtiStart()
			.then(res => {
				res?.data?.key && setUUID(res?.data?.key);
				console.log(res?.data);
				console.log(res?.data?.key);
			})
			.catch(error => console.log(error?.response?.data));
	}, [])
  	return (
		<>
			<MobileHeader back={true} title={"체형분석"} />
			<QuestionStep uuid={uuid}/>
		</>
  	)
}

// 2024.11.22 : CHM : 질문 및 답변 데이터셋	추가
// 질문 및 답변 데이터셋
function QuestionStep({uuid}){
	const navigate = useNavigate();
	// 질문 번호
	const [step, setStep] = useState(1);

	// Step1 변수 : 키와 몸무게
	const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
	// Step2 변수 : 컵 사이즈
	const [cupSize, setCupSize] = useState('');
	// Step3 변수 : 경험/불편함
	const [answers3, setAnswers3] = useState([]);
	// Step4 변수 : 보완/커버
	const [answers4, setAnswers4] = useState([]);
	// Step5 변수 : 보완/커버
	const [answers5, setAnswers5] = useState([]);
	// Step6 변수 : 보완/커버
	const [answers6, setAnswers6] = useState([]);
	// Step7 변수 : 보완/커버
	const [answer, setAnswer] = useState('');

	// 질문 내용
	const questionData = {
		1:{
			progress: "14%",
			title: <p className="title">키와 몸무게를 입력해주세요.</p>,
			selectType: "소수점 없이 입력해 주세요(예 : 160, 50)",
		},
		2:{
			progress: "28%",
			title: <p className="title">컵 사이즈를 선택해주세요.</p>,
			selectType: "하나만 선택해 주세요.",
		},
		3:{
			progress: "42%",
			title: <p className="title">해당되는 경험이나 지속적인 불편함을 <br /> 모두 선택해주세요!</p>,
			selectType: "모두 선택해 주세요.",
		},
		4:{
			progress: "56%",
			title: <p className="title">보완되거나 커버되었으면 하는 <br /> 체형 고민을 선택해주세요!</p>,
			selectType: "모두 선택해 주세요.",
		},
		5: {
			progress: "70%",
			title: <p className="title">들뜸이 있는 곳을 모두 선택해주세요</p>,
			selectType: "모두 선택해 주세요.",
		},
		6: {
			progress: "82%",
			title: <p className="title">눌리는 곳을 모두 선택해주세요</p>,
			selectType: "모두 선택해 주세요.",
		},
		7: {
			progress: "95%",
			title: <p className="title">내 가슴과 해당되는 유두방향을 선택해주세요</p>,
			selectType: "잠시 거울을 보고 체형을 점검해보면 어떨까요?",
		}
	}

	// 이전 질문으로 이동
	const prevStep = () => {
		step === 1 ? alert("첫번째 질문입니다.") : setStep(step-1);
	}

	const nextStep = (now) => {
		bbtiSave(uuid, now, 
			now === 1 ? [height, weight] : 
			now === 2 ? cupSize : 
			now === 3 ? answers3 : 
			now === 4 ? answers4 : 
			now === 5 ? answers5 : 
			now === 6 ? answers6 : 
			now === 7 ? answer : null
		);
		if (now === 7) {
			submit();
		}else{
			setStep(now+1);
		}
	}

	const submit = () => {
		let bbtiScore = {
			U: 0, M: 0, T: 0,
			O: 0, R: 0, I: 0,
			W: 0, A: 0, C: 0,
		};
	
		// Q1: 몸무게 기준
		if (weight <= 46) bbtiScore.I += 6;
		if (weight >= 66) bbtiScore.U += 4;
	
		// Q2: 컵 사이즈
		if (['AA', 'A'].includes(cupSize)) bbtiScore.I += 14;
		if (['C', 'D', 'E', 'F', 'G', 'H', 'I'].includes(cupSize)) bbtiScore.O += 10;
	
		// Q3: 경험/불편함
		if (answers3.includes('어깨끈 흘러내림')) {
			bbtiScore.U += 4;
			bbtiScore.W += 4;
			bbtiScore.M += 2;
			bbtiScore.I += 2;
			bbtiScore.C += 3;
		}
		if (answers3.includes('어깨끈이 조이고 아픔')) {
			bbtiScore.O += 4;
			bbtiScore.T += 2;
		}
		if (answers3.includes('6개월 이상 수유')) {
			bbtiScore.U += 8;
			bbtiScore.A += 4;
			bbtiScore.C += 6;
		}
		if (answers3.includes('다이어트로 체중 7kg 이상 감량')) {
			bbtiScore.U += 7;
			bbtiScore.C += 7;
		}
		if (answers3.includes('가슴 성형 수술')) {
			bbtiScore.O += 10;
			bbtiScore.T += 10;
		}
		if (answers3.includes('가슴 절개 수술')) bbtiScore.A += 10;
		if (answers3.includes('해당사항 없음')) {
			bbtiScore.M += 6;
			bbtiScore.R += 6;
		}
	
		// Q4: 체형 고민
		if (answers4.includes('부유방')) bbtiScore.R += 4;
		if (answers4.includes('짝가슴')) bbtiScore.A += 10;
		if (answers4.includes('새가슴')) bbtiScore.W += 10;
		if (answers4.includes('등살')) bbtiScore.R += 4;
		if (answers4.includes('가슴을 안정적으로 덮길 원해요')) {
			bbtiScore.O += 4;
			bbtiScore.T += 4;
		}
		if (answers4.includes('해당사항 없음')) bbtiScore.M += 6;
	
		// Q5: 들뜸 위치 점수
		if (answers5.includes('A. 컵의 위쪽')) bbtiScore.U += 8;
		if (answers5.includes('B. 컵의 앞쪽')) {
			bbtiScore.W += 8;
			bbtiScore.A += 4;
		}
		if (answers5.includes('C. 컵의 가운데')) {
			bbtiScore.M += 5;
			bbtiScore.I += 7;
		}
		if (answers5.includes('D. 컵의 아래쪽')) bbtiScore.O += 3;
		if (answers5.includes('E. 컵의 옆쪽')) bbtiScore.T += 3;
		if (answers5.includes('F. 없음')) bbtiScore.R += 10;
	
		// Q6: 눌림 위치 점수
		if (answers6.includes('A. 컵의 위쪽')) bbtiScore.T += 10;
		if (answers6.includes('B. 컵의 앞쪽')) bbtiScore.C += 6;
		if (answers6.includes('C. 컵의 옆쪽')) bbtiScore.W += 7;
		if (answers6.includes('D. 컵의 아래쪽')) bbtiScore.I += 2;
		if (answers6.includes('E. 없음')) {
			bbtiScore.M += 4;
			bbtiScore.R += 4;
		}
	
		// Q7: 유두 방향 점수
		if (answer === '바깥쪽') bbtiScore.W += 13;
		if (answer === '정면') bbtiScore.A += 14;
		if (answer === '아래쪽') bbtiScore.C += 20;


		// 가슴유형 계산
		const umt = Object.entries({
			U: bbtiScore.U,
			M: bbtiScore.M,
			T: bbtiScore.T
		}).reduce((a, b) => a[1] > b[1] ? a : b);
	
		const ori = Object.entries({
			O: bbtiScore.O,
			R: bbtiScore.R,
			I: bbtiScore.I
		}).reduce((a, b) => a[1] > b[1] ? a : b);
	
		const wac = Object.entries({
			W: bbtiScore.W,
			A: bbtiScore.A,
			C: bbtiScore.C
		}).reduce((a, b) => a[1] > b[1] ? a : b);
	
		const bbtiType = umt[0] + ori[0] + wac[0];
	
		// 콘솔 로그
		console.log(bbtiScore);
		console.log('UMT 중 가장 높은 값:', umt[0], '점수:', umt[1]);
		console.log('ORI 중 가장 높은 값:', ori[0], '점수:', ori[1]);
		console.log('WAC 중 가장 높은 값:', wac[0], '점수:', wac[1]);
		console.log('나의 가슴유형:', bbtiType);

		// 쿠키 만료 시간(10분)설정
		const expirationTime = new Date();
    	expirationTime.setTime(expirationTime.getTime() + (10 * 60 * 1000));
		// 쿠키저장
		document.cookie = `bbtiUuid=${uuid};expires=${expirationTime.toUTCString()};path=/`;
	
		// 상태 업데이트를 한번에 처리
		bbtiSubmit(uuid, bbtiType);
		navigate('/BodyTypeResult/'+bbtiType);
	};
	return(
		<>
			<div className="stq-wrap">
				<div className="progress-box">
					<div className="progress-bar" style={{"width": questionData[step].progress}}></div>
				</div>

				<div className="stq-question">
					<p className="title">{questionData[step].title}</p>
					<div className="answer-area">
						<p className="select-type">{questionData[step].selectType}</p>
						{step === 1 && <Step1
										height={height}
										setHeight={setHeight}
										weight={weight}
										setWeight={setWeight}
										/>}
						{step === 2 && <Step2
										cupSize={cupSize}
										setCupSize={setCupSize}
										/>}
						{step === 3 && <Step3 
										answers={answers3}
										setAnswers={setAnswers3}
										/>}
						{step === 4 && <Step4 
										answers={answers4}
										setAnswers={setAnswers4}
										/>}
						{step === 5 && <Step5 
										answers={answers5}
										setAnswers={setAnswers5}
										/>}
						{step === 6 && <Step6 
										answers={answers6}
										setAnswers={setAnswers6}
										/>}
						{step === 7 && <Step7 
										answer={answer}
										setAnswer={setAnswer}
										/>}
					</div>
				</div>
			</div>
			<div className="stq-bottom">
				<button className={step === 1 ? "btn disable" : "btn"} onClick={()=>prevStep()}>뒤로가기</button>
				{
					step === 1 ? ((height === '' || weight === '') ? <button className="btn disable" onClick={()=>alert("키와 몸무게를 입력해주세요.")}>다음</button> : <button className="btn active" onClick={()=>nextStep(1)}>다음</button>):
					step === 2 ? ((cupSize === '') ? <button className="btn disable" onClick={()=>alert("컵 사이즈를 선택해주세요.")}>다음</button> : <button className="btn active" onClick={()=>nextStep(2)}>다음</button>):
					step === 3 ? ((answers3 == []) ? <button className="btn disable" onClick={()=>alert("경험이나 불편함을 선택해주세요.")}>다음</button> : <button className="btn active" onClick={()=>nextStep(3)}>다음</button>):
					step === 4 ? ((answers4 == []) ? <button className="btn disable" onClick={()=>alert("고민을 선택해주세요.")}>다음</button> : <button className="btn active" onClick={()=>nextStep(4)}>다음</button>):
					step === 5 ? ((answers5 == []) ? <button className="btn disable" onClick={()=>alert("들뜸이 있는 곳을 선택해주세요.")}>다음</button> : <button className="btn active" onClick={()=>nextStep(5)}>다음</button>):
					step === 6 ? ((answers6 == []) ? <button className="btn disable" onClick={()=>alert("눌리는 곳을 선택해주세요.")}>다음</button> : <button className="btn active" onClick={()=>nextStep(6)}>다음</button>):
					step === 7 && (answer === '') ? <button className="btn disable" onClick={()=>alert("유두의 방향을 선택해주세요.")}>완료</button> : <button className="btn active" onClick={()=>nextStep(7)}>완료</button>
				}
			</div>
		</>
	)
}
export default BbtiTest;