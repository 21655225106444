/***************************************************************************************
* FileName      : MagazineManagement.js
* Description   : 소곤핏 관리자 > 메인 > 상품영역관리 페이지 
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import SideMenuItem from './SideMenu';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getProductLayout, newProductLayoutPriority } from '../../../api/admin/layout';
import ProductGenerator from './ProductBlock/GeneratorBlock';
import UpdateProductBlock from './ProductBlock/UpdateBlock';
import SampleProductBlock from './ProductBlock/SampleBlock';
import { useLocation } from 'react-router-dom';


function ProductManagement(){
    const queryClient = useQueryClient();
    const location = useLocation();

    const { data, isLoading, isError } = useQuery('layout', getProductLayout);

    const [products, setProducts] = useState([]);
    const [managementType, setManagementType] = useState('none');
    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (data) {
            console.log(data);
            setProducts(data);
        }
    }, [data, location.pathname]);

    // 레이아웃 순서 변경 드래그앤드롭
    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(products);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        console.log("요렇게 바꿈 ",(items?.map(x => x?.id)).join(","));

        newProductLayoutPriority((items?.map(x => x?.id)).join(","))
            .then((res) => {
                // 쿼리를 다시 불러와서 새로고침
                queryClient.invalidateQueries('layout');
            })
            .catch((error) => {
                alert("순서변경에 실패했습니다.");
            });
    };

    isLoading && <div>Loading...</div>;

    // 상품 레이아웃 영역 설정 버튼 클릭
    function productSetting(productBlockData){
        setUpdateData(productBlockData);
        setManagementType('setting');
    }

    return (       
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <SideMenuItem to="/admin/mainManagement/magazine">매거진영역관리</SideMenuItem>
                    </div>

                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="magazine-items">
                            {(provided) => (
                                <div ref={provided.innerRef}{...provided.droppableProps} 
                                    className="col-md-4">
                                    {products?.map((item, index) => (
                                        <Draggable 
                                            key={item.id}
                                            draggableId={String(item.id)}
                                            index={index}
                                        >
                                            {provided => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <ProductDndBlock
                                                        key={item?.id}
                                                        id={item?.id}
                                                        title={item?.title}
                                                        type={item?.type}
                                                        x={item?.x_layout}
                                                        y={item?.y_layout}
                                                        hidden={item?.hidden}
                                                        productSetting={productSetting}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    <div className="box text-center pointer"
                                        onClick={() => setManagementType('generate')}
                                    >+ 상품 영역 추가</div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    {/* 상품생성, 수정/삭제, 미리보기 블록 */}
                    {managementType === 'generate' ? (
                        <div className="col-md-5">
                            <div className="box">
                                <ProductGenerator setManagementType={setManagementType}/>
                            </div>
                        </div>
                    )  : (
                    managementType === 'setting' ? (
                        <div className="col-md-5">
                            <div className="box">
                                <UpdateProductBlock
                                    data={updateData}
                                    setManagementType={setManagementType}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-3">
                            <div className="box">
                                <SampleProductBlock data={data}/>
                            </div>
                        </div>

                    )
                    )}
                    
                </div>
            </div>
        </section>
        </>
    )
}

// 드래그 앤 드롭 블록
function ProductDndBlock({id, title, type, x, y, hidden, productSetting}){
    return(
        <div className={`box mb-2 ${hidden && "bg-light"}`}>
            <div className="d-flex justify-content-between">
                <p className={`fw-bold ${"text-secondary"}`}>
                    {title}
                </p>
                <p className="pointer"
                    onClick={() => productSetting({id, title, type, x, y, hidden})}
                >설정</p>
            </div>
        </div>
    )
}



export default ProductManagement;