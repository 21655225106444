import { useState , useEffect} from 'react';
import { queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { instance } from '../axiosConfig.js';

const PAGE_SIZE = 10;

// 일반회원 회원가입
export const registerUser = (data) =>{
  return instance.post(`auth/user-signup`, data)
  .then(response => response.data)

}



// 닉네임 중복 확인
export function getCheckedNickName(nickName) {  
  return instance.post('/auth/check-nickname', {
      nickname: nickName,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

// 개인정보 수정.
export const updateAuthMe = (newData) =>
  instance.patch(`auth/me`, newData,).then(response => response.data);



// 유저 이름과 이메일로 아이디 찾기 인증 코드 요청
export function findId(name, email) {
  return instance.post('/verify/username-email', {
    name: name,
    email: email,
  })
}

// 이메일 인증 ID 확인
export function findIdVerifyEmail(email, code) {
  return instance.post('/verify/username-email-check', {
    email: email,
    code: code,
  })
}


// 비밀번호 찾기 인증 코드 요청
export function findPassword(name, email , username) {
  return instance.post('/verify/password-email', {
    name: name,
    email: email,
    username:username
  })
}

// 이메일 인증 PW 변경
export function changePassword(email, id, verification_code, password, password2) {
  return instance.post('/verify/password-email-check', {
    email: email,
    username: id,
    code: verification_code,
    password: password,
    password2: password2,
  })
}

// 휴대폰 번호로 인증코드 요청
export function verifyphoneNumber(phone_number) {
  return instance.post('/verify/phonenumber', {
    phone_number: phone_number,
  })
}

// 휴대폰 인증 코드 확인
export function verifyPhoneNumberCheck(phone_number, code) {
  return instance.post('/verify/phonenumber-check', {
    phone_number: phone_number,
    code: code,
  })
}


// 유저 이름과 휴대폰 번호로 아이디 찾기 인증 코드 요청
export function verifyUsernamephone(name , phone_number) {
  return instance.post('/verify/username-phone', {
    name : name ,
    phone_number: phone_number,
  })
}

// 유저 이름과 휴대폰 번호로 아이디 찾기 인증 코드 요청
export function verifyUsernamephoneCheck(code , phone_number) {
  return instance.post('/verify/username-phone-check', {
    code : code ,
    phone_number: phone_number,
  })
}

// 유저 이름, 휴대폰 번호, 아이디로 비밀번호 찾기 인증 코드 요청
export function verifyPasswordPhone(name , phone_number , username) {
  return instance.post('/verify/password-phone', {
    name: name,
    phone_number: phone_number,
    username: username
  })
}

// 비밀번호 찾기 휴대폰 인증번호 확인
export function verifyPasswordPhoneCheck(username , phoneNumber , code) {
  return instance.post('/verify/password-phone-check', {
    username: username,
    phone_number: phoneNumber,
    code: code
  })
}

// 비밀번호 찾기 > 비빌번호 변경
export function verifyPasswordPhoneChange(phone_number , username , phoneHash, password , password2) {
  return instance.post('/verify/password-phone-change', {
    phone_number: phone_number,
    username: username,
    phone_hash: phoneHash,
    password : password,
    password2 : password2
  })
}

