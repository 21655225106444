import Mheader from './Mheader.js'
import MNav from './MNav.js'


import { Outlet } from 'react-router-dom';

const ManagerLayout = () => {
  return (
    <>
      <Mheader />
      <MNav/>
      <Outlet />
    </>
  );
};

export default ManagerLayout;