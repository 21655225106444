/***************************************************************************************
 * FileName      : Postitem.js
 * Description   : 소곤핏 소곤톡 글 Postitem 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.17
 * Modifide Date : 2024.09.06 : PKH : Skeleton 추가 
 *               :
 * Reference     :
 ***************************************************************************************/
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHeart,
	faCommentDots,
	faPen,
} from "@fortawesome/free-solid-svg-icons";

import ReactGA from "react-ga4";
import { ReactComponent as HeartIcon } from "../../../assets/icon/heart.svg";
import { ReactComponent as CommentIcon } from "../../../assets/icon/comment.svg";
import { ReactComponent as ImageIcon } from "../../../assets/icon/image.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function truncateText(text, maxLength) {
	if (text.length > maxLength) {
		return text.slice(0, maxLength) + "...";
	}
	return text;
}

function Postitem({ isLoading , item }) {
	return (
		<>
			<Link
				className="post-content"
				to={`/post/${item?.id}`}
				onClick={() => {
					window.hackleClient.track({
						key: "clicked_sogontalk",
						properties: {
							클릭위치: "소곤톡 리스트",
							타이틀: item?.title,
							작성자: item?.user?.username, // 익명, 작성자
						},
					});
				}}
			>
				<div className="post-tags">
					{!isLoading && item?.tags?.map((tag) => (
						<span
							key={tag?.id}
							className="post-tag"
							onClick={() => {
								ReactGA.event({
									action: "태그 클릭",
									category: `[소곤톡] 리스트 태그 클릭 : ${tag?.name}`,
								});
							}}
						>
							# {tag?.name}
						</span>
					))}
					{item?.is_hot && (
						<span
							className="post-tag hot"
							onClick={() => {
								ReactGA.event({
									action: "태그 클릭",
									category: `[소곤톡] 리스트 태그 클릭 : Hot`,
								});
							}}
						>
							HOT
						</span>
					)}
					{item?.is_new && (
						<span
							className="post-tag new"
							onClick={() => {
								ReactGA.event({
									action: "태그 클릭",
									category: `[소곤톡] 리스트 태그 클릭 : New`,
								});
							}}
						>
							NEW
						</span>
					)}
				</div>
				<div className="post-title-wrap">
					<p className="post-tit">
						{isLoading ? (
							<Skeleton width={150} height={24} />
						) : (
							item?.title
						)}
					</p>

					{isLoading ? (
						<Skeleton width={20} height={20} />
					) : (
						item?.images.length > 0 ? (
						<ImageIcon
							style={{
							fill: '#B9B9B9',
							width: '15px',
							height: '15px'
							}}
						/>
						) : ""
					)}
				
				</div>
			
				<span className="post-desc">
					{isLoading ? (
							<Skeleton width={300} height={24} />
						) : (
							truncateText(item?.content, 50) )
					}
				</span>
				<div className="post-info">
					<div>
						<span className="date">
							{isLoading ? (
								<Skeleton width={100} height={15} />
							) : (
								item?.is_edited
								? `${item?.posted_at} (수정됨)`
								: item?.posted_at
							)}
						</span>

						<span>
							{isLoading ? (
								<Skeleton width={50} height={15} /> 
							) : (
								`조회 ${item?.view_count}`
							)}
						</span>
					</div>
					<div>
						{!isLoading && (
							<>
							<span className="heart">
								<HeartIcon className="posticon"/>
								{item?.like_count}
							</span>
							<span className="comment">
								<CommentIcon className="posticon"/>
								{item?.reply_count}
							</span>
							</>
						)}
					</div>
				</div>
			</Link>
		</>
	);
}

export default Postitem;
