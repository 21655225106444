/***************************************************************************************
 * FileName      : MNav.js
 * Description   : 소곤핏 관리자 페이지 네비게이션
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.04.19
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
	faChevronDown,
	faChevronUp,
	faHome,
	faUsers,
	faBook,
	faTags,
	faPencil,
	faImages,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useLocation,
} from "react-router-dom";

import { getMe } from "../../api/common/memberApi";
import { useState, useEffect } from "react";
import MenuItems from "./MenuItems";
function MNav() {
	const location = useLocation();
	const currentPathData = location.pathname
		.split("/")
		.slice(0, 3)
		.join("/")
		.replace(/\/$/, "");

	const [type, setType] = useState(null);
	const [role, setRole] = useState(null);

	useEffect(() => {
		getMe()
			.then((data) => {
				// console.log(data);
				setRole(data.role);
				setType(data.type);
			})
			.catch((error) => {
				console.error(
					"데이터를 가져오는 중 오류가 발생했습니다:",
					error
				);
			});
	}, []);

	const menuItems = [
		{
			title: "대시보드",
			link: "/admin",
			link2: "/admin",
		},
		{
			title: "메인관리",
			link: "/admin/mainManagement/banner",
			link2: "/admin/mainManagement",
		},
		{
			title: "매거진",
			contents: [
				{
					title: "매거진 관리",
					link: "/admin/magazine/all",
					link2: "/admin/magazine",
				},
				{
					title: "매거진 승인/거절",
					link: "/admin/magazinelog/all",
					link2: "/admin/magazinelog",
				},
				{
					title: "카테고리 순서",
					link: "/admin/category/ordering",
					link2: "/admin/category",
				},
			],
		},
		{
			title: "브랜드",
			contents: [
				{
					title: "브랜드/상품 관리",
					link: "/admin/brand",
					link2: "/admin/brand",
				},
				{
					title: "상품 승인/거절",
					link: "/admin/productlog/all",
					link2: "/admin/productlog",
				},
				{
					title: "브랜드 순서",
					link: "/admin/brandOrder/ordering",
					link2: "/admin/brandOrder",
				},
			],
		},
		{
			title: "테스트 통계",
			link: "/admin/test",
			link2: "/admin/test",
		},
		{
			title: "회원관리",
			link: "/admin/users",
			link2: "/admin/users",
		},
		{
			title: "소곤톡",
			contents: [
				{ title: "전체", link: "/admin/post", link2: "/admin/post" },
				{
					title: "신고 관리",
					link: "/admin/report/post",
					link2: "/admin/report",
				},
				// {title: '태그 관리', link: '/admin/report/post', link2:'/admin/report/reply'},
			],
		},
	];

	const CPmenuItems = [
		{
			title: "매거진",
			contents: [
				{
					title: "매거진 관리",
					link: "/admin/magazine/all",
					link2: "/admin/magazine",
				},
				{
					title: "매거진 재요청",
					link: "/admin/magazinelog/all",
					link2: "/admin/magazinelog",
				},
			],
		},
	];

	const BMmenuItems = [
		{
			title: "상품",
			contents: [
				{
					title: "상품 관리",
					link: "/admin/bmproduct/all",
					link2: "/admin/bmproduct",
				},
				{
					title: "상품 승인관리",
					link: "/admin/productlog/all",
					link2: "/admin/productlog",
				},
			],
		},
	];

	return (
		<>
			<nav className="Mnav">
				{role === "CP" && <MenuItems menuItems={CPmenuItems} />}
				{role === "BM" && <MenuItems menuItems={BMmenuItems} />}
				{type === "A" && <MenuItems menuItems={menuItems} />}
			</nav>
		</>
	);
}

export default MNav;
