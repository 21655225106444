/***************************************************************************************
* FileName      : FindId.js
* Description   : 소곤핏 고객 > 아이디 찾기 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.06.12
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Helmet } from 'react-helmet-async';
import MobileHeader from "./Component/MobileHeader";
import { LoadingSpinner } from "./../admin/Component/LoadingSpinner";
import { useEffect, useState } from 'react';
import { findId , verifyUsernamephone , verifyUsernamephoneCheck } from '../../api/services/memberApi';
import { useNavigate } from 'react-router-dom';
import { formatTime } from './Common/FormatTime.js';
import { showAlert } from './Common/alertUtil.js';

function FindId() {

    const navigate = useNavigate();
    
    const [selectedOption, setSelectedOption] = useState('findId_email');


    const [username, setUsername] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');

    const isPhoneNumberValid = phoneNumber.length === 11; 

    const [showMessage, setShowMessage] = useState(false);
    const [timer, setTimer] = useState(180);
    const [interval, setIntervalId] = useState(null);

    // 휴대폰 인증 완료 여부 상태
    const [verificationCompleted, setVerificationCompleted] = useState(false); 


    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);

    // 이메일로 아이디 찾기
    const handleSubmit = () => {
      if (!isLoading) {
        setIsLoading(true);

        if (name === '') {
          showAlert("이름을 입력해주세요."); 
          setIsLoading(false);
          return;
        }
        if (email === '') {
            showAlert("이메일을 입력해주세요."); 
            return;
        }

        findId(name, email)
            .then(res =>  showAlert(res?.data?.data))
            .catch(error => {
                error?.response?.data?.error ? (
                  showAlert(error?.response?.data?.error)
                ) : (
                  showAlert("이름 또는 이메일을 확인해주세요.")
                )
            })
            .finally(() => {
              setIsLoading(false);
            });
      }
    }

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.id);
    };

    // 휴대폰 입력
    const handlePhoneNumberChange = (e) => {
      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
      setPhoneNumber(onlyNumbers);
    };

    // 휴대폰 인증번호 요청
    const requestVerificationCode = () => {
      verifyUsernamephone(name , phoneNumber)
        .then(res =>{
          showAlert(res?.data?.data); 

          setShowMessage(true);
          setTimer(180); 
        })
        .catch(error => {
          showAlert(error?.response?.data?.error || "휴대폰 번호를 확인해주세요."); 

        });
    };
  
    // 휴대폰 인증번호 확인
    const verifyPhoneCheck = () => {
      verifyUsernamephoneCheck(code , phoneNumber)
      .then(res =>{
        showAlert("휴대폰 번호가 성공적으로 인증되었습니다."); 

        setUsername(res?.data?.username);
        setVerificationCompleted(true); // 인증 완료 상태로 설정
        clearInterval(interval); 
      })
      .catch(error => {
        showAlert(error?.response?.data?.error || "휴대폰 번호를 확인해주세요."); 
    
      });
    };

    useEffect(() => {
      let id;
      if (showMessage && timer > 0) {
        id = setInterval(() => {
          setTimer(prev => prev - 1);
        }, 1000);
        setIntervalId(id); // interval id 저장
      }
      if (timer === 0) {
        setShowMessage(false); 
      }
      return () => clearInterval(id);
    }, [showMessage, timer]);


    useEffect(()=>{

      setName('');
      setEmail('');
      setPhoneNumber('');
      setCode('');

    },[selectedOption])

    return (
    <>
    <Helmet>
      <title>소곤핏 Sogonfit - 아이디찾기</title>
    </Helmet>
      <MobileHeader back={true} title={"아이디찾기"}/>
      <div className="findId mt-5">
        <div className="inner">
           
            <div className="find-titleArea mt-4 mb-4">
                <p>        
                일반회원 아이디찾기로, 네이버, 카카오로 
                <br/>
                회원가입한 회원은 아이디 검색이 되지 않습니다
                </p>
            </div>

            <div className='auth-method mt-4 mb-4'>
              <div className='findId-radio'>
                  <input 
                    type='radio' 
                    name='findId' 
                    id='findId_email'
                    checked={selectedOption === 'findId_email'}
                    onChange={handleOptionChange}
                    />
                    <label htmlFor='findId_email' className='radio-button'>이메일</label>

                  <input 
                    type='radio' 
                    name='findId' 
                    id='findId_phone' 
                    checked={selectedOption === 'findId_phone'}
                    onChange={handleOptionChange}
                    />
                <label htmlFor='findId_phone' className='radio-button'>휴대폰 번호</label>
              </div>
            </div>
            

            {/* 이메일로 아이디 찾기 */}
            <div className='findId-email' style={{display: selectedOption === 'findId_email' ? 'block' : 'none'}}>
              <p>이름</p>
              <input 
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                />

              <p>이메일</p>
              <input 
                type="text" 
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />

              <button className="btn-primary findBtn mt-4" onClick={handleSubmit} disabled={isLoading}>
                  {isLoading ? <LoadingSpinner /> : '확인'} 
              </button>
            </div>


            {/* 휴대폰번호로 아이디 찾기 */}
            <div className='findId-phone' style={{display: selectedOption === 'findId_phone' ? 'block' : 'none'}}>
              
              {!username && (<>
                <p>이름</p>
                <input 
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  />

                <p>휴대폰 번호 인증</p>
                <div className={`fpd ${showMessage ? '' : 'mb-4'}`}>
                  <input 
                    type="text"
                    name="phoneNumber"
                    value={phoneNumber}
                    placeholder='-없이 휴대폰 번호를 입력해주세요.'
                    onChange={handlePhoneNumberChange}
                  />

              <button 
                  className="getCodeBtn pointer" 
                  onClick={requestVerificationCode} 
                  disabled={!isPhoneNumberValid}
                >
                인증번호 요청
                </button>
      
              </div>

            
              {showMessage && (
                <div className='fm-wrap mt-1 mb-4'>
                  <p>인증번호를 전송하였습니다. (재전송 가능 남은시간: {formatTime(timer)})</p>
                </div>
              )}

              <p className='mt-4'>인증번호</p>
             
              <input 
                type="text"
                name="authNumber"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled={verificationCompleted}
                placeholder='인증번호를 입력해주세요.'
              />

              <button className="btn-primary findBtn mt-4" onClick={verifyPhoneCheck} disabled={isLoading}>
              인증하기
              </button>

              </>
            )}
           
              {username && (
                <div className='find_wrap'>
                  <div className="find_contents">
                      <p>        
                          {phoneNumber}로 가입된 아이디는
                          <br/>
                          <span className='text-hw fw-bold'>{username}</span> 입니다.
                      </p>
                  </div>
                  <div className='fbutton-wrap mt-4'> 
                      <button 
                          className='btn-primary findBtn'
                          onClick={()=>{navigate('../login')}}>로그인하기</button>
                      <button 
                          className='btn-primary findBtn'
                          onClick={()=>{navigate('../findPassword')}}>비밀번호 찾기</button>
                  </div>
                </div>
              )}
            </div>
        </div>
    </div>
    </>        
  );
}

export default FindId;