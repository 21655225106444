/***************************************************************************************
 * FileName      : LatestItem.js
 * Description   : 소곤핏 메인페이지 > 최근에 올라온 글 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date : 2024.05.05 : CHM : 매거진 상세페이지로 이동하는 기능 추가
 *               :
 * Reference     :
 ***************************************************************************************/

// x축이 1일때 사용하는 컴포넌트
import { extractYearMonthDay2 } from "../../admin/common/extractDateUtils";
import SkeletonComponent from "../../../components/service/SkeletonComponent";
import { useNavigate } from "react-router-dom";

function LatestItem({ content, isLoading, nowPage }) {
	const navigate = useNavigate();

	const handleClick = () => {
		// 소곤로그 클릭이벤트
		window.hackleClient.track({
			key: "clicked_sogonlog",
			properties: {
				클릭위치: nowPage ? nowPage : "",
				타이틀: content?.title,
				글작성자: content?.user?.username,
				카테고리: content?.category?.name,
				레이아웃: "리스트형",
			},
		});
		navigate(`/magazine/${content?.id}`);
	};

	const title = content?.title;
	const maxLength = 50;
	const shortenedTitle =
		title && title.length > maxLength
			? `${title.substring(0, maxLength)}...`
			: title;

	if (isLoading) {
		return (
			<div>
				<SkeletonComponent width="100%" height="calc(34.51vw)" />
			</div>
		);
	}

	return (
		<>
			<div className="latest-item" onClick={() => handleClick()}>
				<div className="thumbnail">
					{/* <img src={process.env.PUBLIC_URL + 'img/sample.png'} alt="" /> */}
					<img src={content?.main_image} alt="" />
				</div>

				<div className="cardText">
					<div className="info">
						<span className="tagBox">
							{content?.category?.name}
						</span>
						<p>{shortenedTitle}</p>
					</div>
					<div className="cardFooter">
						<span>{extractYearMonthDay2(content?.created_at)}</span>
						<span>{content?.user?.username}</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default LatestItem;
