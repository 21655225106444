/***************************************************************************************
* FileName      : ReportModal.js
* Description   : 소곤핏 고객 > 게시글 신고 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.08
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { registerPostReport , registerReplyReport } from '../../../api/services/post';

import { useMutation } from 'react-query';
const ReportModal = ({ show, onHide , postId , reportType }) => {

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();


    const createPostReportMutaion = useMutation(
        (reportType === "post") ? 
        registerPostReport : registerReplyReport, {

        onSuccess : (data) => {
            reset();
            onHide();
            alert('신고가 완료되었습니다.');
        },
        onError: (data) => {
            data.response.data.error.map(errorMessage => {
                alert(errorMessage);
            });
        }
    });


    const onSubmit = async (formData) => {
        if(reportType === "post"){
            formData.post = postId;
        }else if(reportType === "reply"){
            formData.reply = postId;
        }
        
        await createPostReportMutaion.mutateAsync(formData);
    };



    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>신고하기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>     
                <div className=''>
                    <div className='postWrite-field content'>
                
                        <p className='reportTit'>신고사유</p>
                        <textarea 
                            name='reason' 
                            className={errors.reason ? 'error-input' : ''}
                            {...register('reason', { required: '신고 사유는 필수입니다.' })} />
                            
                           
                        <button className='reportBtn' type="submit">신고하기</button>
                           
                    </div>
             

                </div>
            </form>
        </Modal.Body>
    </Modal>
  );
};

export default ReportModal;
