import React, { useState , useEffect } from 'react';
import { deleteBrand } from  '../../../../api/admin/brandApi';
import EditBrandModal from './EditBrandModal';
import ToggleSwitch from '../../Component/ToggleSwitch';

function BrandRow({ brand }){

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

  
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteClick = (brandId) => {
        const confirmDelete = window.confirm('정말로 삭제 하시겠습니까?');
        if (confirmDelete) {
          setIsDeleting(true);
    
          deleteBrand(brandId)
            .then(response => {
              alert("브랜드가 성공적으로 삭제되었습니다.")
            })
            .catch(error => {
              console.error('브랜드 삭제 중 에러 발생:', error);
            })
            .finally(() => {
              setIsDeleting(false);
            });
        }
    };

    return (
        <React.Fragment key={brand?.id}>
            <tr>
                <td>{brand?.id}</td>
                <td>{brand?.name}</td>
                <td><img src={brand?.logo}/></td>
                <td>
                  <a className='siteLink' target='_blank' href={brand?.website}>{brand?.website}</a>
                </td>
                <td>{brand?.owner}</td>
                <td>{brand?.phone_number}</td>
                <td>{brand?.products}</td>
                <td>{brand?.views}</td>
                <td>
                  {/* {brand?.hidden ? 
                    <span className='fs-8 text-grey'>비공개</span> : 
                    <span className='fs-8 text-success'>공개</span>
                  } */}
           
                  <ToggleSwitch pk={brand?.id} checked={brand?.hidden}  toggleType="brand" />

                </td>
                <td>
                  <a className='pink_btn' onClick={handleShowModal}>수정</a>         
                </td>
                <td>
                    <a className='pink_btn' onClick={() => handleDeleteClick(brand?.id)} disabled={isDeleting}>
                        {isDeleting ? '삭제 중...' : '삭제'}
                    </a>
                </td>
                <td>{brand?.code}</td>

            </tr>

            {/* 브랜드 수정 모달 */}
            <EditBrandModal
                show={showModal}
                onHide={handleCloseModal}
                id={brand?.id}
             
            />
        </React.Fragment>
    )
}

export default BrandRow