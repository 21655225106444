/***************************************************************************************
 * FileName      : PostPrivateList.js
 * Description   : 소곤핏 관리자 > 소곤톡 > 글 관리
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.06.24
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { Link } from "react-router-dom";

import React, { useState, useEffect } from "react";
// import BrandRow from './BrandList/BrandRow.js';

import Pagination from "../common/Pagination.js";
import { useAdminpostList } from "../../../api/admin/post.js";
import PostRow from "./PostList/PostRow.js";

function PostList() {
	const [privateChecked, setPrivateChecked] = useState(false);

	const {
		data: PostData,
		error: PostError,
		isFetching,
		page,
		setPage,
	} = useAdminpostList(privateChecked);

	const handlePrevPage = () => {
		setPage((prevPage) => prevPage - 1);
	};

	const handleNextPage = () => {
		setPage((prevPage) => prevPage + 1);
	};

	const handleChange = () => {
		setPrivateChecked(!privateChecked);
	};

	// 검색
	const [searchAuth, setSearchAuth] = useState("");
	const [searchOption, setSearchOption] = useState("all");
	const [searchResults, setSearchResults] = useState([]);
	const [searchConfirm, setSearchConfirm] = useState(false);

	// 검색버튼 클릭 시
	const onSearch = () => {
		// handleSearch({
		//     searchAuth,
		//     setSearchUrl,
		//     setSearchResults
		// });

		setSearchConfirm("true");
	};

	console.log(PostData);
	return (
		<>
			<section className="admin-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12 menu-2">
							<ul className="side-menu">
								<Link to="/admin/post/private">
									<li className="side-item active">
										전체 글
									</li>
								</Link>
							</ul>
						</div>

						<div className="aphidden-check-box">
							<input
								type="checkbox"
								id="privacyCheckbox"
								checked={privateChecked}
								onChange={handleChange}
								className=""
							/>
							<label htmlFor="privacyCheckbox">
								관리자가 비공개
							</label>
						</div>

						{/* 검색 */}
						{/* <div className="col-md-12">
                        <div className="box search-wrap mb-4 " >
                            <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                            </select>
                         
                            <div className="search-field">
                                <i className='xi-search'></i>
                            
                                <input type="text" 
                                        value={searchAuth}
                                        onChange={(e) => setSearchAuth(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                onSearch();
                                            }
                                        }}
                                        placeholder="Search"
                                />
                            </div>
                        </div>
                    </div> */}

						<div className="col-md-12">
							<div className="box">
								<div className="tit">
									<span className="group-title">
										전체({PostData && PostData.totalCnt})
									</span>
								</div>
								<table className="admin_table">
									<colgroup>
										<col style={{ width: "5%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "15%" }} />
										<col style={{ width: "5%" }} />
										<col style={{ width: "5%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
										<col style={{ width: "10%" }} />
									</colgroup>
									<thead>
										<th>ID</th>
										<th>작성자</th>
										<th>제목</th>
										<th>상세내용</th>
										<th>이미지</th>
										<th>작성일시</th>
										<th>좋아요수</th>
										<th>조회수</th>
										<th>상태</th>
										<th>처리</th>
										<th>이동</th>
									</thead>
									<tbody>
										{PostData &&
										PostData.data &&
										PostData.data.length > 0 ? (
											PostData.data.map((post) => (
												<PostRow
													key={post?.id}
													post={post}
												/>
											))
										) : (
											<tr>
												<td colSpan="17">
													결과가 없습니다.
												</td>
											</tr>
										)}
									</tbody>
								</table>

								{/* 페이지내비게이션 */}
								<div className="pagination-wrap">
									<Pagination
										count={PostData?.totalCnt || 0}
										page={page}
										setPage={setPage}
										handlePrevPage={handlePrevPage}
										handleNextPage={handleNextPage}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default PostList;
