/***************************************************************************************
* FileName      : EditProductModal.js
* Description   : 소곤핏 관리자 > 브랜드 > 전체 상품 목록 > 상품 수정 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date : 2024.05.30 : CHM : 브랜드 미선택 시 에러메세지 추가, 상품명이 바뀐 경우만 name 변경
*               : 2024.07.08 : PKH : 상품 가격 수정 오류 수정 , 리팩토링
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation , useQuery } from 'react-query';
import { getUploadURL , uploadImage } from '../../../../api/common/uploadImageApi';
import { LoadingSpinner } from '../../Component/LoadingSpinner';

import { getProduct , updateProduct , useGetBrands , useGetBbti } from '../../../../api/admin/brandApi';


const EditProductModal = ({ id, show, onHide }) => {

    const { data ,isLoading } = useQuery(['product',id], () => getProduct(id));

    // 공개 비공개
    const [isPrivate, setIsPrivate] = useState(true);
    // bbti 선택
    const [selectedTypes, setSelectedTypes] = useState([]);

    // 이미지 미리보기  
    const [previewUrl , setPreviewUrl] = useState();

    // 기존 상품 명
    const [prevName, setPrevName] = useState('');

    // 타입 선택
    const [selectedCategory, setSelectedCategory] = useState();
  
    // 카테고리 목록
    const categories = [
        { id: 1, name: 'BRA', label: '브라' },
        { id: 2, name: 'PANTY', label: '팬티' },
        { id: 3, name: 'SET', label: '세트' },
        { id: 4, name: 'ETC', label: '기타' }
    ];

    useEffect(() => {
        if (show && !isLoading && data) {
            setValue('name', data?.name);
            setValue('brand', data?.brand?.id);
            setValue('original_price', data?.original_price);
            setValue('sale_price', data?.sale_price);
            setValue('link', data?.link);
            setPreviewUrl(data?.image);
            setIsPrivate(data?.hidden);
            setSelectedTypes(data?.bbti)
            setSelectedCategory(data?.type)
        }
        setPrevName(data?.name);
    }, [data, show]);

    const { register, handleSubmit, formState: { errors } , setValue , reset , watch } = useForm();


    const handleCheckboxChange = () => {
        setIsPrivate(!isPrivate); 
    };

    const [selectedFileName, setSelectedFileName] = useState('');
    
    // 브랜드 조회
    const { data: brandsData, isLoading: isBrandsDataLoading, isError: isBrandsDataError } = useGetBrands();

    // bbti 조회 
    const { data: BbtiData, isLoading: isBbtiDataLoading, isError: isBbtiDataError } = useGetBbti();
 


    // 카테고리 값 변경 핸들러
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const updateProductMutation = useMutation((newData) => updateProduct(id, newData), {
        onSuccess : (data) => {
            alert("수정되었습니다");
            reset();
            onHide();
    
        },
        onError : (data) => {
            alert(data?.request?.responseText);
        }
    });

    const uploadImageMutation = useMutation(uploadImage, {
        onSuccess: (result) => {
            const productData = createProductData(result);
            if (productData) {
                updateProductMutation.mutate(productData);
            }
        }
    });
    

    const uploadURLMutation = useMutation(getUploadURL, {
        onSuccess: (data) => {
            uploadImageMutation.mutate({
                uploadURL: data.uploadURL,
                imageURL: data.imageURL,
                file: watch("productImage"),
            });
        },
        onError: (data) => {
            console.log("error", data);
        }
    });
    
    const createProductData = (image = null) => {
        const brand = watch("brand");
        if (brand === '') {
            alert('브랜드를 선택해주세요');
            return null;
        }
    
        const typesArray = selectedTypes.map(item => item.type).filter(Boolean);
        const productData = {
            brand: brand,
            type: selectedCategory,  
            original_price: Number(watch("original_price")),
            sale_price: Number(watch("sale_price")),
            link: watch("link"),
            bbti: typesArray,
            hidden: isPrivate
        };
    
        if (image) {
            productData.image = image;
        }
    
        if (prevName !== watch("name")) {
            productData.name = watch("name");
        }
    
        // console.log(productData);
        
        return productData;
    };

    const onSubmit = (data) => {
        if (!(watch("productImage").length === 1)) {
            const productData = createProductData();
            if (productData) {
                updateProductMutation.mutate(productData);
            }
        } else {
            uploadURLMutation.mutate();
        }
    };

    

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file); 
            setSelectedFileName(file.name);
        } else {
            setPreviewUrl(null);
            setSelectedFileName('');
        }

    };

    const handleBBTiCheckboxChange = (type) => {
        const isSelected = selectedTypes.some(selectedType => selectedType.type === type);
    
        if (isSelected) {
            setSelectedTypes(selectedTypes.filter(selectedType => selectedType.type !== type));
        } else {
            setSelectedTypes([...selectedTypes, { type }]);
        }
    };

    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>상품 수정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>     
                <div className='input-form'>
                    <div className='input_box'>
                        <p>브랜드</p>
                        
                        {isBrandsDataLoading ? (
                                <p>Loading...</p>
                            ) : isBrandsDataError ? (
                                <p>Error loading brands data</p>
                            ) : (
                                <select {...register('brand')} defaultValue="">
                                    <option value="" disabled>
                                        브랜드를 선택하세요
                                    </option>
                                    {brandsData.data.map((brand) => (
                                        <option key={brand?.id} value={brand?.id}>
                                            {brand?.name}
                                        </option>
                                    ))}
                                </select>
                            )}

                        <p>상품명</p>
                        <input 
                            type='text' 
                            name='name' 
                            className={errors.name ? 'error-input' : ''}
                            {...register('name', { required: '상품명 입력은 필수입니다.' })} />
                        
                        <p>상품 타입</p>
                        <div className='category-s-wrap'>
                        {categories.map(item => (
                                <div key={item.id}>
                                    <input
                                        type="radio"
                                        className='bbti_checkbox'
                                        checked={selectedCategory === item.name}
                                        onChange={() => handleCategoryChange(item.name)}
                                        id={item.name}  
                                        name="bbti_radio_group"
                                    />
                                    <label
                                        className="bbti_label"
                                        htmlFor={item.name}
                                    >
                                        {item.label}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <p>사이트링크</p>
                        <input 
                            type='text' 
                            name='link' 
                            className={errors.link ? 'error-input' : ''}
                            {...register('link', { required: '사이트링크 입력은 필수입니다.' })} />
                        
                        <p>상품이미지</p>
                        <div className='imgPreview'>
                            { previewUrl ? (
                            <>
                                <div className='mpreimg'>
                                    <img className='editImg' src={previewUrl}/>
                                </div>
                            </>
                            ) : (
                            <>
                            </>
                            )}
                        </div>

                        <div class="filebox">
                            <input  
                                className={errors.productImage ? 'upload-name error-input' : 'upload-name'} 
                                value={selectedFileName} 
                                placeholder="상품 이미지파일" />
                                    
                            <label htmlFor="productImage_file">파일찾기</label> 

                            <input
                                type='file'
                                id='productImage_file'
                                name='productImage'
                                accept="image/jpg, image/png, image/jpeg"
                        
                                {...register('productImage' )}
                                onChange={handleFileChange}
                            />
                        </div>

                        <p>상품가격</p>
                        <input 
                            type='text' 
                            name='original_price' 
                            className={errors.original_price ? 'error-input' : ''}
                            {...register('original_price', { required: '상품가격 입력은 필수입니다.' })} />

                        <p>판매가격</p>
                        <input 
                            type='text' 
                            name='sale_price' 
                            className={errors.sale_price ? 'error-input' : ''}
                            {...register('sale_price', { required: '판매가격 입력은 필수입니다.' })} />

                       
                        <p>BBTI</p>
                        <div className='bbtiList'>
                            { isBbtiDataLoading ? (
                                <p>Loding...</p>
                            ) : isBbtiDataError ? (
                                <p>Error bbti data</p>
                            ) : (
                                BbtiData?.data.map(item => (
                                    <div key={item?.id}>
                                        <input 
                                            type="checkbox"
                                            className='bbti_checkbox'  
                                            checked={selectedTypes && selectedTypes.map(selectedType => selectedType.type).includes(item?.type)}
                                            // checked={selectedTypes.includes(item?.type)}
                                            onChange={() => handleBBTiCheckboxChange(item?.type)}
                                            id={`bbti_checkbox_${item.id}`} />

                                        <label 
                                            className="bbti_label" 
                                            htmlFor={`bbti_checkbox_${item.id}`}>
                                            {item?.type}
                                        </label>
                                    </div>
                                ))
                            )}
                        </div>
                       
                        <div class="form-check">
                            <input class="form-check-input custom-checkbox" type="checkbox"  id="hiddenCheck" checked={isPrivate} onChange={handleCheckboxChange} />
                            <label class="form-check-label" for="hiddenCheck">
                            비공개
                            </label>
                        </div>
                       
                       
                           
                    </div>
                    <button className='btn_black_1' type="submit">
                        {updateProductMutation.isLoading || uploadImageMutation.isLoading || uploadURLMutation.isLoading
                        ?  <LoadingSpinner /> : '상품 수정'} 
                    </button>

                </div>
            </form>
        </Modal.Body>
    </Modal>
  );
};

export default EditProductModal;
