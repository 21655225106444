/***************************************************************************************
* FileName      : UpdateBlock.js
* Description   : 소곤핏 관리자 > 메인 > 매거진영역관리 페이지 > 우측 매거진 영역 수정 블록
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.04.11
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { getLayoutTypes, updateLayout, deleteLayout } from '../../../../api/admin/layout';

// 매거진 블록 수정
function UpdateMagazineBlock({ data, setManagementType }) {
    const queryClient = useQueryClient();
    const [layoutTypes, setLayoutTypes] = useState([]);

    const [formData, setFormData] = useState({
        title: data?.title || '',
        type: data?.type || '',
        layout: data?.x && data?.y ? `${data.x}x${data.y}` : '',
        mainContentExist: data?.main || false,
        visible: !data?.hidden || false
    });

    useEffect(() => {
        getLayoutTypes().then((res) => {
            setLayoutTypes(res?.data.data);
        });
    }, []);

    useEffect(() => {
        setFormData({
            title: data?.title || '',
            type: data?.type || '',
            layout: data?.x && data?.y ? `${data.x}x${data.y}` : '',
            mainContentExist: data?.main || false,
            visible: !data?.hidden || false
        });
    }, [data]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const onSubmit = () => {
        const { title, type, layout, mainContentExist, visible } = formData;
        if (!title || !type || !layout) {
            alert('필수 입력값을 입력해주세요');
            return;
        } else {
            const [x, y] = layout.split('x');
            console.log(data?.id, formData);
            updateLayout(data?.id, title, type, ~~x, ~~y, mainContentExist, !visible)
                .then((res) => {
                    console.log(res.data);
                    setManagementType('none');
                    queryClient.invalidateQueries('layout');
                })
                .catch((error) => {
                    alert('수정에 실패했습니다.');
                });
        }
    };

    const onDelete = (id) => {
        deleteLayout(id)
            .then(res => {
                alert('성공적으로 삭제되었습니다.');
            }).catch(error => {
                console.error('삭제에 실패했습니다 :', error);
            })
    };
    return (
        <>
            <div className="mb-3 d-flex justify-content-between">
                <div />
                <h5 className="fw-bold text-center">매거진 영역 수정</h5>
                <p className="fw-bold pointer"
                    onClick={() => setManagementType('none')}
                >X</p>
            </div>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">타이틀</label>
                <input type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    placeholder="타이틀을 입력하세요"
                    value={formData.title}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="category" className="form-label">구분</label>
                <select className="form-select"
                    id="category"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                >
                    <option value="" disabled>구분을 선택하세요</option>
                    {layoutTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                    ))}
                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="layout" className="form-label">레이아웃</label>
                <select className="form-select"
                    id="layout"
                    name="layout"
                    value={formData.layout}
                    onChange={handleChange}
                >
                    <option value="">레이아웃을 선택하세요</option>
                    <option value="2x1">2x1</option>
                    <option value="2x2">2x2</option>
                    <option value="2x3">2x3</option>
                    <option value="1x2">1x2</option>
                    <option value="1x4">1x4</option>
                    <option value="1x6">1x6</option>
                </select>
            </div>
            <div className="mb-3 form-check ps-0">
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">대표글 사용</label>
                <div className="form-check form-switch">
                    <input className="form-check-input pointer"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={formData.mainContentExist}
                        onChange={(e) => setFormData(prevState => ({ ...prevState, mainContentExist: e.target.checked }))}
                    />
                </div>
            </div>
            <div className="mb-3 form-check ps-0">
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">공개</label>
                <div className="form-check form-switch">
                    <input className="form-check-input pointer"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={formData.visible}
                        onChange={(e) => setFormData(prevState => ({ ...prevState, visible: e.target.checked }))}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <button type="submit"
                        className="btn btn-primary w-100"
                        onClick={onSubmit}
                    >저장</button>
                </div>
                <div className="col-6">
                    <button type="button"
                        className="btn btn-danger w-100"
                        onClick={() => onDelete(data?.id)}
                    >삭제</button>
                </div>
            </div>
        </>
    )
}

export default UpdateMagazineBlock;