/***************************************************************************************
 * FileName      : EditProfile.js
 * Description   : 소곤핏 마이페이지 > 프로필 수정 페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.09
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import MobileHeader from "./Component/MobileHeader";
import Gnb from "../../Gnb";
import { useForm } from "react-hook-form";
import { LoadingSpinner } from "../admin/Component/LoadingSpinner";

import { getCheckedNickName } from "../../api/services/memberApi.js";

import { updateAuthMe } from "../../api/services/memberApi.js";

import { getUploadURL, uploadImage } from "../../api/common/uploadImageApi.js";

import { useMutation, useQuery } from "react-query";
import { getMe } from "../../api/common/memberApi";
import { FiPlus } from "react-icons/fi";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { showAlert } from "./Common/alertUtil";
function EditProfile() {
	const navigate = useNavigate();

	const {
		register,
		watch,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm();

	// 닉네임 중복확인 , 형식확인 했는지 상태
	const [nCheckState, setnCheckState] = useState(false);

	// 이미지 미리보기
	const [previewUrl, setPreviewUrl] = useState();

	// 이미지 변경 여부 확인
	const [isChangeImage, setIsChangeImage] = useState(false);

	const [previousNickname, setPreviousNickname] = useState("");

	const [fileData, setFileData] = useState(null);
	// 유효성 검사
	const validateForm = () => {
		let isValid = true;

		// id 중복확인

		if (watch("nickname") !== previousNickname && !nCheckState) {
			showAlert("닉네임 중복 확인은 필수입니다."); 
			return;
		}

		return isValid;
	};

	useEffect(() => {
		getMe()
			.then((data) => {
				console.log(data);
				setValue("nickname", data?.nickname);
				setPreviousNickname(data?.nickname);
				setValue("profile", data?.profile);
				setPreviewUrl(data?.profile);
			})
			.catch((error) => {});
	}, []);

	// 닉네임 중복 확인
	const handleNickNameCheck = (event) => {
		event.preventDefault();

		getCheckedNickName(watch("nickname"))
			.then((data) => {
				showAlert(data.data); 
				setnCheckState(true);
			})
			.catch((error) => {
				showAlert(error.response.data.error); 
			});
	};

	const updateProfileMutaion = useMutation(
		(newData) => updateAuthMe(newData),
		{
			onSuccess: (data) => {
				reset();
				navigate("/mypage", { replace: true });
			},
			onError: (data) => {
				console.log("error", data);
			},
		}
	);

	const uploadImageMutation = useMutation(uploadImage, {
		onSuccess: (result) => {
			const newData = {
				profile: result,
			};

			if (watch("nickname") != previousNickname) {
				// 닉네임이 수정되었을 때
				newData.nickname = watch("nickname");
			}

			updateProfileMutaion.mutate(newData);
		},
	});

	const uploadURLMutaion = useMutation(getUploadURL, {
		onSuccess: (data) => {
			uploadImageMutation.mutate({
				uploadURL: data.uploadURL,
				imageURL: data.imageURL,
				file: fileData,
			});
		},
	});

	const onSubmit = (data) => {
		if (validateForm()) {
			if (isChangeImage) {
				// 이미지 변경이 있을 때
				uploadURLMutaion.mutate();
			} else {
				if (data.nickname != previousNickname) {
					// 닉네임 변경이 있을 때
					const newData = {
						nickname: data.nickname,
					};
					updateProfileMutaion.mutate(newData);
				} else {
					showAlert("수정 된 내용이 없습니다."); 
					navigate("/mypage", { replace: true });
				}
			}
		}
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const previewUrl = URL.createObjectURL(file);
			setPreviewUrl(previewUrl);
			setIsChangeImage(true);

			setFileData({ 0: file, length: 1 });
		} else {
			setPreviewUrl(null);
			setIsChangeImage(false);

			setFileData({ 0: null, length: 0 });
		}
	};

	useEffect(() => {
		setnCheckState(false);
	}, [watch("nickname")]);

	// 모바일 앱인지 확인하는 함수
	const isMobileApp = () => {
		return /flutter-app/i.test(navigator.userAgent);
	};

	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 프로필 수정</title>
			</Helmet>
			<MobileHeader back={true} title={"프로필 수정"} />
			<div className="mypage">
				<section className="mypage-menu mt-5">
					<div className="inner">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="profile-edit mt-3">
								<p className="mb-2 text-center">프로필 사진</p>
								{isMobileApp() ? (
									<MobileProfile
										previewUrl={previewUrl}
										handleFileChange={handleFileChange}
										register={register}
									/>
								) : (
									<PcProfile
										previewUrl={previewUrl}
										handleFileChange={handleFileChange}
										register={register}
									/>
								)}
							</div>

							<div className="profile-edit">
								<p>닉네임*</p>
								<div className="w80nick">
									<input
										type="text"
										name="nickname"
										className={
											errors.nickname ? "error-input" : ""
										}
										{...register("nickname", {
											required: "닉네임을 입력하세요",
										})}
									/>

									<button
										className="pink_btn"
										onClick={handleNickNameCheck}
									>
										중복확인
									</button>
								</div>
							</div>

							<button className="btn_black_1" type="submit">
								{updateProfileMutaion.isLoading ||
								uploadImageMutation.isLoading ||
								uploadURLMutaion.isLoading ? (
									<LoadingSpinner />
								) : (
									"수정"
								)}
							</button>
						</form>
					</div>
				</section>
				<Gnb />
			</div>
		</>
	);
}

function PcProfile({ previewUrl, handleFileChange, register }) {
	return (
		<>
			<div className="profileImg">
				<div className="editProfile_img-wrap">
					{previewUrl ? (
						<img
							className="editImg"
							src={previewUrl}
							width="100%"
						/>
					) : (
						<img
							src={process.env.PUBLIC_URL + "/favicon-96x96.png"}
							alt=""
							width="100%"
						/>
					)}
					<label className="selectFileBtn" htmlFor="profile_file">
						<FiPlus />
					</label>
				</div>
			</div>

			<input
				type="file"
				id="profile_file"
				name="profile"
				accept="image/*"
				className="hidden-file-input"
				{...register("profile")}
				onChange={handleFileChange}
			/>
		</>
	);
}

function MobileProfile({ previewUrl, handleFileChange, register }) {
	const handleClick = () => {
		if (window.fileChooser) {
			window.fileChooser.postMessage("open");
		} else {
			alert("갤러리 접근이 불가능합니다. 갤러리 접근을 허용해주세요.");
		}
	};

	useEffect(() => {
		window.uploadAppImg = (base64) => {
			const blob = base64ToBlob(base64, "image/png");
			const file = new File([blob], "uploaded_image.png", {
				type: "image/png",
			});

			const event = { target: { files: [file] } };
			handleFileChange(event);
		};

		// 컴포넌트 언마운트 시 전역 함수 해제
		return () => {
			delete window.uploadAppImg;
		};
	}, [handleFileChange]);

	// base64 파일로 변환
	function base64ToBlob(base64, contentType) {
		const byteCharacters = atob(base64);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	return (
		<>
			<div className="profileImg">
				<div className="editProfile_img-wrap">
					{previewUrl ? (
						<img
							className="editImg"
							src={previewUrl}
							width="100%"
						/>
					) : (
						<img
							src={process.env.PUBLIC_URL + "/favicon-96x96.png"}
							alt=""
							width="100%"
						/>
					)}
					<label className="selectFileBtn" onClick={handleClick}>
						<FiPlus />
					</label>
				</div>
			</div>
			<input
				type="file"
				id="profile_file"
				name="profile"
				accept="image/*"
				className="hidden-file-input"
				{...register("profile")}
				onChange={handleFileChange}
			/>
		</>
	);
}
export default EditProfile;
