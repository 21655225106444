import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from 'ckeditor5-custom-hw';
import { Button } from 'react-bootstrap';
import { simpleUploadInstance, simpleUploadHeader } from '../../../../api/axiosConfig';

// 에디터 옵션
const editorOptions = { 
    fontFamily: [
        'default', '나눔스퀘어 네오, Arial, sans-serif', 'noto-sans'
    ],
    fontSize: [8, 10, 12, 14, 16, 18, 20, 22, 24],
    toolbar: [
        'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'italic', 'bold',
        '|', 'alignment:left', 'alignment:center', 'alignment:right',
        '|', 'insertTable', 'mediaEmbed', 'link',
        '|', 'imageUpload',
        '|', 'imageStyle:block', 'imageStyle:alignLeft', 'imageStyle:alignRight',
        '|', 'horizontalLine', 'blockQuote',
        '|', 'undo', 'redo',
    ],
    imgStyle : [
        {name: 'block', title: '블록이미지(중앙 자리차지)'},
        {name: 'alignLeft', title: '어울림 이미지(글 왼쪽)'},
        {name: 'alignRight', title: '어울림 이미지(글 오른쪽)'},
    ],
    imgToolbar : ['imageStyle:block', 'imageStyle:alignLeft', 'imageStyle:alignRight',]
}

// 공통 에디터 컴포넌트
// id: 콘텐츠 수정 시 필요한 id
// onConfirm: 콘텐츠 등록 또는 수정 시 필요한 함수
// setOnEditor: 에디터 추가 시 필요한 함수
// defaultContent: 콘텐츠 수정 시 기존 콘텐츠 데이터
const MagazineEditor = ({ id, onConfirm, setOnEditor, defaultContent }) => {
	const [editorData, setEditorData] = useState(defaultContent ? defaultContent :'');
	const [showEditor, setShowEditor] = useState(true);

	const handleChange = (event, editor) => {
		const newData = editor.getData();
		setEditorData(newData);
	};

	// 콘텐츠 신규 등록 시 아래 함수 사용
	const handleConfirm = () => {
		onConfirm({
			type: 'content',
			data: editorData
		});
		setShowEditor(false);
		setOnEditor(false);
	};

	// 콘텐츠 수정 시 아래 함수 사용
	const handleUpdate = () => {
		console.log("data : ", editorData);
		onConfirm(id, {
			type: 'content',
			data: editorData
		});
		setShowEditor(false);
		setOnEditor(false);
	}



	return (
		<div>
			{showEditor && (
				<div>
					<CKEditor
						editor={CustomEditor}
						data={editorData}
						onChange={handleChange}
						onReady={()=>{
							console.log('에디터 사용 준비!')
						}}
						config={{
							simpleUpload: simpleUploadHeader,
							fontFamily: {options: editorOptions?.fontFamily},
							fontSize: {options: editorOptions?.fontSize},
							toolbar: editorOptions?.toolbar,
							image: {
								styles: {options: editorOptions?.imgStyle},
								toolbar: editorOptions?.imgToolbar
							}
						}}
					/>
					{
						id != undefined ? (
							<Button
								onClick={handleUpdate}
								className='mt-2 w-100'
							>
								수정
							</Button>
						) : (
						<Button 
							onClick={handleConfirm}
							className='mt-2 w-100'
						>확인</Button>
						)
					}
				</div>
			)}
		</div>
	);
};

export default MagazineEditor;