/***************************************************************************************
 * FileName      : PostWrite.js
 * Description   : 소곤핏 소곤톡 글쓰기 작성 페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.02
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import MobileHeader from "./Component/MobileHeader";
import Gnb from "../../Gnb";
import { LoadingSpinner } from "../admin/Component/LoadingSpinner";
import { registerPost } from "../../api/services/post";

import { useNavigate } from "react-router-dom";

import ReactGA from "react-ga4";
import { Helmet } from "react-helmet-async";
import { FaCamera } from "react-icons/fa";

import {
	getUploadURL,
	uploadImage,
	recommendTags,
} from "../../api/services/post";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { showAlert } from "./Common/alertUtil";

function PostWrite() {
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm();

	const [activeIndex, setActiveIndex] = useState(null);

	const [aitags, setAitags] = useState([]);

	const inputRef = useRef(null);
	const firstFocus = useRef(true);

	// 로딩 상태
	const [isLoading, setIsLoading] = useState(false);

	const [tags, setTags] = useState([]);
	const [inputValue, setInputValue] = useState("");

	// ai태그 추천 로딩상태
	const [aitagLoading, setAitagLoading] = useState(false);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	// 이미지
	const [images, setImages] = useState([]);

	const handleInputKeyDown = (e) => {
		if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
			e.preventDefault();
			const trimmedValue = inputValue.trim(); // 입력값에서 공백을 제거

			if (tags.includes(trimmedValue)) {
				showAlert("이미 사용된 태그입니다"); 

				setInputValue("");
				return;
			}
			// 입력값이 비어있거나 태그 갯수가 이미 3개인 경우 아무 동작도 하지 않습니다.
			if (trimmedValue === "" || tags.length === 3) return;

			// 입력값에서 특수 문자 및 이모지 제거
			const sanitizedValue = trimmedValue
			.replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '') // 이모지 제거
			.replace(/[^\p{L}\p{N}\p{Z}\p{P}\p{M}\p{S}]/gu, '');
			// .replace(/[^\w\s가-힣]/g, "") // 특수 문자 제거
			// .replace(/[\u{1F600}-\u{1F64F}]/gu, ""); // 이모지 제거
		  
			// 입력값의 길이가 10자 이상이면 경고창 띄우고 함수 종료
			if (sanitizedValue.length > 10) {
				showAlert("태그는 10자 이상 입력할 수 없습니다."); 
				return;
			}
			console.log(trimmedValue);	
			console.log("Input Value:", inputValue); //
			if (sanitizedValue !== "") {
				setTags([...tags, sanitizedValue]);
				setInputValue("");
			}
			
		
		}
	};

	// 태그 삭제
	const removeTag = (indexToRemove) => {
		setTags(tags.filter((_, index) => index !== indexToRemove));
	};

	// 소곤톡 등록
	const createPostMutaion = useMutation(registerPost, {
		onSuccess: (data) => {
			reset();
			showAlert("등록이 완료 되었습니다."); 
			ReactGA.event({
				action: "소곤톡 포스팅",
				category: "소곤톡 작성 완료",
			});
			setIsLoading(false);
			navigate(`/post/${data.id}`, { replace: true });
		},
		onError: (data) => {
			if (Array.isArray(data.response.data.error)) {
				data.response.data.error.forEach((errorMessage) => {
					showAlert(errorMessage);
				});
			} else {
				showAlert(data.response.data.error);
			}
			setIsLoading(false);
		},
	});

	useEffect(() => {
		setActiveIndex("-1");
	}, []);

	// 이미지 업로드
	const uploadImages = async () => {
		try {
			const uploadedUrls = await Promise.all(
				images?.map(async (imageFile) => {
					const uploadUrl = await getUploadURL(imageFile);
					// alert(`urls ${uploadUrl.imageURL} ; uploadURL ${uploadUrl.uploadURL}`);
					if (uploadUrl) {
						let uploadedUrl = null;
						uploadedUrl = await uploadImage(uploadUrl);
						return uploadedUrl;
					}
					return null;
				})
			);

			const updatedImages = uploadedUrls.filter((url) => url !== null);
			// alert(updatedImages);
			return updatedImages;
		} catch (error) {
			console.error("이미지 업로드 에러:", error);
			throw error;
		}
	};

	const onSubmit = async (data) => {
		setIsLoading(true);
		const Data = {
			title: watch("title"),
			content: watch("content"),
			tags: tags.length === 0 ? ["고민"] : tags,
			is_anonymous: watch("is_anonymous"),
		};

		try {
			if (images.length > 0) {
				const uploadedImages = await uploadImages();
				Data.images = uploadedImages;
			}
			createPostMutaion.mutate(Data);
		} catch (error) {
			console.error("이미지 업로드 중 에러 발생:", error);
			showAlert("이미지 업로드 중 에러가 발생했습니다.");
			setIsLoading(false); // 에러 발생 시 로딩 상태 해제
		}
	};

	// 이미지 파일 선택
	const handleFileChange = (event) => {
		const files = event.target.files;
		// if (files && files[0] && images.length < 5) {
		// 	setImages((prevImages) => [...prevImages, files[0]]);
		// }
		if (files && files[0] && images.length < 5) {
			const fileArray = Array.from(files);
			const newImages = fileArray.slice(0, 5 - images.length);// 최대 5개의 이미지 제한
			setImages((prevImages) => [...prevImages, ...newImages]);
			event.target.value = null; // 파일 입력 필드 초기화
		}
	};

	// 이미지 삭제
	const handleDelete = (index) => {
		setImages((prevImages) => prevImages.filter((_, i) => i !== index));
	};

	// ai 추천 태그
	const tagAi = async () => {
		try {
			if(watch("content").length>10){
				setAitagLoading(true);
				const response = await recommendTags({ value: watch("content") });
				const tagsWithoutComma = response.result[0]
				.split(",")
				.map((word) => word.trim())
				.filter((word) => word.length <= 10);
				setAitags(tagsWithoutComma);

				setAitagLoading(false);
			}
		} catch (error) {
			console.error("Error fetching tag recommendations:", error);
			setAitagLoading(false);
		}
	};

	const handleFocus = () => {
		if (firstFocus.current) {
			firstFocus.current = false;
			tagAi();
		}
	};

	// 태그 입력 처음 포커스
	useEffect(() => {
		const inputElement = inputRef.current;
		if (inputElement) {
			inputElement.addEventListener("focus", handleFocus);
		}
		return () => {
			if (inputElement) {
				inputElement.removeEventListener("focus", handleFocus);
			}
		};
	}, []);

	// ai 태그 클릭시
	const handleTagClick = (tag) => {
		if (!tags.includes(tag) && tags.length < 3) {
			setTags([...tags, tag]);
		} else if (tags.includes(tag)) {
			showAlert("이미 사용된 태그입니다"); 
		} else {
			showAlert("태그는 최대 3개까지 입력할 수 있습니다."); 
		}
	};

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			AI가 태그를 추천해드려요!
		</Tooltip>
	);

	// 모바일 앱인지 확인하는 함수
	const isMobileApp = () => {
		return /flutter-app/i.test(navigator.userAgent);
	};

	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 소곤톡 글쓰기</title>
			</Helmet>
			<MobileHeader back={true} title={"소곤톡 글쓰기"} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="post">
					<section className="prodcut-content my-5">
						<div className="inner">
							<div className="postWrite-field title">
								<input
									type="text"
									placeholder="제목을 입력해주세요"
									className=""
									{...register("title", {
										required: "제목 입력은 필수입니다.",
									})}
								/>
								<p className="error_message">
									{errors.title?.message}{" "}
								</p>
							</div>

							<div className="postWrite-field content mt-3">
								<textarea
									type="text"
									placeholder="소중한 고민을 나누는 공간입니다.&#13;&#10;&#13;&#10;그러나 불쾌하거나 부적절한 내용은 사이트 규정에 의해 삭제될 수 있습니다.&#13;&#10;&#13;&#10;예를 들어, 혐오 발언, 욕설, 개인 정보 유출 등은 제재를 받을 수 있으며, 사용자 신고나 관리자 판단에 따라 글이 삭제될 수 있습니다.&#13;&#10;&#13;&#10;서로를 존중하고 건강한 커뮤니케이션에 기여해 주세요."
									className=""
									{...register("content", {
										required: "내용 입력은 필수입니다.",
									})}
								/>
								<p className="error_message">
									{errors.content?.message}{" "}
								</p>
							</div>

							<div className="SelectTag-wrap mt-4">
								<p className="select-tags">태그</p>
								<div className="post-Tags-wrap">
									{tags.map((tag, index) => (
										<div
											className="input-post-tag"
											key={index}
										>
											<span>{tag}</span>
											<span
												className="tag-close"
												onClick={() => removeTag(index)}
											>
												x
											</span>
										</div>
									))}
								</div>

								{tags.length < 3 && (
									<div className="TagInputBox">
										<input
											type="text"
											placeholder="태그를 입력하고 엔터를 누르세요 (최대 3개)"
											value={inputValue}
											onChange={handleInputChange}
											onKeyDown={handleInputKeyDown}
											ref={inputRef}
										/>
									</div>
								)}
							</div>

							{/* ai 태그 리스트 */}
							<div className="SelectTag-wrap">
								<OverlayTrigger
									placement="right"
									overlay={renderTooltip}
								>
									<p>추천태그</p>
								</OverlayTrigger>

								{aitagLoading ? <LoadingSpinner /> : null}

								<ul className="aiTags-list">
									{aitags?.map((tag, index) => (
										<li
											className={`aitag ${tags.includes(tag) ? "active" : ""}`}
											key={index}
											onClick={() => handleTagClick(tag)}
										>
											{tag}
										</li>
									))}
								</ul>
							</div>

							<div className="postWrite-field mt-3">
								{isMobileApp() ? (
									<MobileImageBlock
										images={images}
										handleFileChange={handleFileChange}
										handleDelete={handleDelete}
									/>
								) : (
									<PcImageBlock
										images={images}
										handleFileChange={handleFileChange}
										handleDelete={handleDelete}
									/>
								)}
							</div>

							<div className="postWrite-field mt-3">
								<input
									className="checkboxA"
									type="checkbox"
									id="hiddenCheck"
									{...register("is_anonymous")}
								/>

								<label
									className="form-check-label"
									for="hiddenCheck"
								>
									익명
								</label>
							</div>

							<button className="btn_2 mt-3 mb-5" type="submit">
								{isLoading ? <LoadingSpinner /> : "등록"}
							</button>
						</div>
					</section>

					<Gnb />
				</div>
			</form>
		</>
	);
}

function PcImageBlock({ images, handleFileChange, handleDelete }) {
	return (
		<div className="post-img-filebox">
			<label htmlFor="productImage_file">
				<FaCamera size={25} />
				<p>
					<span className="pcm">{images.length}</span>
					/5
				</p>
			</label>
			<input
				type="file"
				id="productImage_file"
				name="productImage"
				accept="image/*"
				onChange={handleFileChange}
				disabled={images.length >= 5}
				key={images.length}
			/>

			{images.map((file, index) => (
				<div key={index} className="post-image-container">
					<img
						key={index}
						src={URL.createObjectURL(file)}
						alt={`Selected ${index}`}
						onLoad={() => URL.revokeObjectURL(file)}
					/>
					<button type="button" onClick={() => handleDelete(index)}>
						X
					</button>
				</div>
			))}
		</div>
	);
}

function MobileImageBlock({ images, handleFileChange, handleDelete }) {
	const handleClick = () => {
		if (window.fileChooser) {
			window.fileChooser.postMessage("open");
		} else {
			alert("갤러리 접근이 불가능합니다. 갤러리 접근을 허용해주세요.");
		}
	};

	useEffect(() => {
		window.uploadAppImg = (base64) => {
			const blob = base64ToBlob(base64, "image/png");
			const file = new File([blob], "uploaded_image.png", {
				type: "image/png",
			});

			const event = { target: { files: [file] } };
			handleFileChange(event);
		};

		// 컴포넌트 언마운트 시 전역 함수 해제
		return () => {
			delete window.uploadAppImg;
		};
	}, [handleFileChange]);

	// base64 파일로 변환
	function base64ToBlob(base64, contentType) {
		const byteCharacters = atob(base64);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, { type: contentType });
	}

	return (
		<div className="post-img-filebox">
			<label htmlFor="productImage_file" onClick={handleClick}>
				<FaCamera size={25} />
				<p>
					<span className="pcm">{images.length}</span>
					/5
				</p>
			</label>
			<input
				type="file"
				id="productImage_file"
				name="productImage"
				accept="image/*"
				onChange={handleFileChange}
				disabled={images.length >= 5}
				key={images.length}
			/>

			{images.map((file, index) => (
				<div key={index} className="post-image-container">
					<img
						key={index}
						src={URL.createObjectURL(file)}
						alt={`Selected ${index}`}
						onLoad={() => URL.revokeObjectURL(file)}
					/>
					<button type="button" onClick={() => handleDelete(index)}>
						X
					</button>
				</div>
			))}
		</div>
	);
}
export default PostWrite;
