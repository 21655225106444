/***************************************************************************************
* FileName      : AdminSignup.js
* Description   : 중간관리자 콘텐츠 퍼블리셔 회원가입 페이지
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.04.08
* Modifide Date : 
*               : 
* Reference     : 미정
***************************************************************************************/

import React, { useEffect , useState } from 'react';
import { setCookie, setTokenToCookie } from '../../api/cookieUtils.js';
import { LoadingSpinner } from './Component/LoadingSpinner.js'; 
import { useForm } from 'react-hook-form';

import { useMutation, useQueries, useQueryClient } from 'react-query';

import { registerUser , getCheckedUsername } from '../../api/admin/memberApi.js';
import { Link } from 'react-router-dom';
import { verifyphoneNumber , verifyPhoneNumberCheck } from '../../api/services/memberApi.js';
import { formatTime } from '../service/Common/FormatTime.js';

function ContentPublisherSignup()
{
    const queryClient = useQueryClient();
    
    // 로딩 상태
    const [isLoading, setIsLoading] = useState(false);
 
    const { register, watch , handleSubmit, formState: { errors } , reset  } = useForm();

    // ID 중복확인 
    const [idCheckState, setIdCheckState] = useState(false);   

    const [phoneHash , setPhoneHash] = useState('');
   
    const [authRequestsCount, setAuthRequestsCount] = useState(0); // 인증 요청 횟수 상태
    const maxAuthRequests = 5; // 최대 인증 요청 횟수
    const [verificationCompleted, setVerificationCompleted] = useState(false); // 인증 완료 여부 상태

    const [phoneNumber, setPhoneNumber] = useState('');
 
    const [authCode, setAuthCode] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [timer, setTimer] = useState(180);
    const [interval, setIntervalId] = useState(null);


    const username = watch('username');

    const isPhoneNumberValid = phoneNumber.length === 11; 
    // 유효성 검사
    const validateForm = () => {
        let isValid = true;
    
        // id 중복확인
        if(!idCheckState){
            alert("ID 중복 확인은 필수입니다.");
            return;
        }      

        if(phoneHash === ''){
          alert("휴대폰 인증은 필수입니다.");
          return;
        }

        return isValid;
    }
          

    const mutation = useMutation(registerUser ,{
        onMutate: () => {
        },
        onSuccess: (data) => {
          setIsLoading(false);
          alert("회원가입이 완료되었습니다. 로그인 페이지로 이동합니다.");
          reset();
          window.location.href = '/admin/login';
        },
        onError : (error) => {
          const errors = error.response.data.error;
          const firstErrorMessage = Array.isArray(errors)
                ? Object.entries(errors[0]).map(([key, value]) => `${key}: ${value}`).join('\n')
                : errors;
              alert(firstErrorMessage);
        
          setIsLoading(false);
        }
    });

    const onSubmit = (data) => {
        data.role = "CP";
        data.phone_number = phoneNumber;
        data.phone_hash = phoneHash;

        if (validateForm()) {
          setIsLoading(true); 
          mutation.mutate(data)
        }
    };  

    

    useEffect(() => {
      setIdCheckState(false);
    }, [username]);



    // id 확인 
    const handleIdCheck = (event) => {
        event.preventDefault();

        getCheckedUsername( watch('username') )
        .then((data) => {
          alert(data.data);
          setIdCheckState(true);
        })
        .catch((error) => {
            alert(error.response.data.error);
        });
    
    };

    // 휴대폰 입력
    const handlePhoneNumberChange = (e) => {
      const onlyNumbers = e.target.value.replace(/[^0-9]/g, '');
      setPhoneNumber(onlyNumbers);
    };
    
    // 휴대폰 인증번호 확인
    const handlePhoneAuthCheck = (event) => {
        event.preventDefault();
        
        if (!phoneNumber) {
          alert('휴대폰 번호를 입력하여 인증번호를 요청해주세요.');
          return;
        }
        if (!authCode) {
          alert('인증번호를 입력해주세요.');
          return;
        }
    
        verifyPhoneNumberCheck(phoneNumber,authCode)
        .then(res =>{
          alert(res?.data?.data);
          setPhoneHash(res?.data?.phone_hash);
          setVerificationCompleted(true); // 인증 완료 상태로 설정
          clearInterval(interval); 
        })
        .catch(error => {
          alert(
            error?.response?.data?.error || "휴대폰 번호를 확인해주세요."
          );
        });
    };

    // 휴대폰 인증번호 요청
    const handlePhoneAuth = (event) => {
        event.preventDefault();

        verifyphoneNumber(phoneNumber)
        .then(res =>{
          alert(res?.data?.data);
          setShowMessage(true);
          setTimer(180); 
        })
        .catch(error => {
          alert(
            error?.response?.data?.error || "휴대폰 번호를 확인해주세요."
          );
        });
    };

    // 휴대폰 인증 시간 제한
    useEffect(() => {
      let id;
      if (showMessage && timer > 0) {
        id = setInterval(() => {
          setTimer(prev => prev - 1);
        }, 1000);
        setIntervalId(id); // interval id 저장
      }
      if (timer === 0) {
        setShowMessage(false); 
      }
      return () => clearInterval(id);
    }, [showMessage, timer]);
    
 
    return (
        <>
        <body id="Adminlogin">
          <section className='signup-content'>
              <div className="container Login">
                <div className='row'>
                  <div className='col-md-12 text-center mb-5'>
        
                    <div className='signtitbox'>
                      <h2>Sign Up</h2>
                      <p className='subtit'>소곤핏 관리자 회원가입</p>
                    </div>
                 
                    <div className='signIn'>
                        <ul className="side-menu" id="admin-signup-menu">
                            <Link to='/admin/signup'>
                                <li className='side-item'>
                                브랜드 매니저
                                </li>
                            </Link> 
                            <Link to='/admin/signupcp'>
                                <li className='side-item active'>
                                콘텐츠 퍼블리셔
                                </li>
                            </Link>    
                        </ul>

                        <form onSubmit={handleSubmit(onSubmit)}>     
                            <div className='signIn-form'>
                              <div className='input_box'>
                                <div className='icheck_box'>
                                   <div>
                                      <p>아이디*</p>
                                      <input 
                                        type='text' 
                                        name='username' 
                                        className={errors.username ? 'error-input' : ''}
                                        {...register('username', { required: '사용자 이름을 입력하세요' })} />
                                    </div> 
                                    <button className='pink_btn' onClick={handleIdCheck}>ID중복확인</button>
                                </div>

                                <p>비밀번호 *</p>
                                <input 
                                  type='password' 
                                  name='password' 
                                  className={errors.password ? 'error-input' : ''}
                                  {...register('password', { required: '비밀번호를 입력하세요' })} />

                                <p>비밀번호 확인 *</p>
                                <input 
                                  type='password' 
                                  name='password2' 
                                  className={errors.password2 ? 'error-input' : ''}
                                  {...register('password2', { required: '비밀번호를 입력하세요' })} />
                              
                                <p>이름 *</p>
                                <input 
                                  type='name' 
                                  name='name' 
                                  className={errors.name ? 'error-input' : ''}
                                  {...register('name', { required: '이름을 입력하세요' })} />
                                
                                <p>닉네임 *</p>
                                <input 
                                  type='text' 
                                  name='nickname' 
                                  className={errors.nickname ? 'error-input' : ''}
                                  {...register('nickname' , { required: '닉네임을 입력하세요' })} />

                                <p>이메일 *</p>
                                <input 
                                  type='email' 
                                  name='email' 
                                  className={errors.email ? 'error-input' : ''}
                                  {...register('email', { required: '이메일를 입력하세요' })} />

                                <div className='icheck_box'>
                                  <div>
                                    <p>휴대폰 번호 *</p>
                                    <input 
                                      type='text' 
                                      name='phone_number' 
                                      placeholder='-없이 휴대폰 번호를 입력해주세요.'
                                      onChange={handlePhoneNumberChange}
                                      disabled={verificationCompleted}/>
                                  </div> 
                                  <button 
                                    className='pink_btn' 
                                    onClick={handlePhoneAuth}        
                                    disabled={!isPhoneNumberValid}>
                                    인증요청
                                  </button>
                                </div>
                                {showMessage && (
                                <>
                                <div className='fm-wrap mb-4'>
                                  <p>인증번호를 전송하였습니다. (재전송 가능 남은시간: {formatTime(timer)})</p>
                                </div>

                                  <div className='icheck_box'>
                                    <div>
                                      <p>인증번호</p>
                                      <input 
                                        type="text"
                                        name="phoneNumber"  
                                        onChange={(e) => setAuthCode(e.target.value)}
                                        disabled={verificationCompleted}
                                        />       
                                    </div> 
                                    <button 
                                      className='pink_btn' 
                                      onClick={handlePhoneAuthCheck}        
                                      >
                                      인증하기
                                    </button>
                                  </div>
                          
                                </>
                              )}
                            </div>

        
                            <button 
                              className='pink_btn'
                              isLoding={mutation.isLoading}
                              type="submit">
                              {isLoading  ?  <LoadingSpinner /> : '회원가입'} 
                            </button>
                      </div>
                      </form>
                  </div>
                </div>
              </div>
            
            </div>
          </section> 
        </body>
        </>
    )
}

export default ContentPublisherSignup 