/***************************************************************************************
* FileName      : MyPosts.js
* Description   : 소곤핏 마이페이지 > 내가 작성한 글
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.09
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import MobileHeader from "./Component/MobileHeader";
import Gnb from '../../Gnb';

import { fetchMyPostData } from '../../api/services/post';
import { Link } from 'react-router-dom';

import { useInfiniteQuery } from 'react-query';

import Postitem from './Post/Postitem';

import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../admin/Component/LoadingSpinner';


function MyPosts()
{
    
    const [page, setPage] = useState(0);
    const [tag, setTag] = useState('all');

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
      } = useInfiniteQuery(  ['postdata', tag, page], ({ pageParam = 1 }) => fetchMyPostData(tag,pageParam), {
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.nextPage; 
        },
    });

    return (
        
        <>
        <MobileHeader back={true} title={"내가 작성한 글"}/>
        <div className="mypage">
            <section className='mypage-menu my-5'>
                <div className="inner pb-5"> 
          
                <div className="post-wrap">
                        {isLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <>
                            {data.pages.reduce((total, page) => total + page.data.length, 0) === 0 ? (
                                <div className='text-center mt-3'>내가 작성한 글이 없습니다</div>
                            ) : (
                                <InfiniteScroll 
                                    style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}
                                    dataLength={data.pages.reduce((total, page) => total + page.data.length, 0)}
                                    next={() => fetchNextPage()}
                                    hasMore={hasNextPage}
                                    loader={<div>Loading...</div>}
                                    endMessage={<div></div>}
                                >
                                    {data.pages.map((page, pageIndex) => (
                                        <React.Fragment key={pageIndex}>
                                            {page.data.map(item => (
                                                <Postitem item={item} key={item.id} />
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </InfiniteScroll>
                            )}
                        </>
                        )}
                    </div>
                </div>
            </section>

            <Gnb/>
        </div>
        </>
    )
}

export default MyPosts;