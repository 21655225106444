/***************************************************************************************
 * FileName      : Product.js
 * Description   : 소곤핏고객 상품 리스트 페이지 _ 무한스크롤
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.02
 * Modifide Date : 2024.05.30 : CHM : 무한스크롤 상품레이아웃 수정
 *               : 2024.07.23 : PKH : 상품 정렬 모달 추가
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import MobileHeader from "./Component/MobileHeader";
import Pagination from "./Component/Pagination";
import Gnb from "../../Gnb";
import { fetchData } from "../../api/services/product";
import { useGetBrands } from "../../api/services/product";
import ProductItem from "./Main/ProductItem";

import { useInfiniteQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";

import { instance } from "../../api/axiosConfig";
import { LoadingSpinner } from "../admin/Component/LoadingSpinner";
import { Helmet } from "react-helmet-async";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import BbtiFilter from "./Product/BbtiFilter";
import ProductOrdering from "./Product/ProductOrdering";

function Product() {
	const [page, setPage] = useState(0);

	const [brandId, setBrandId] = useState("all");

	// 드론 선택 드롭다운
	const [bbtiFilterModal, setbbtiFilterModal] = useState(false);

	// bbti 선택
	const [filteringBbti, setFilteringBbti] = useState();

	// 정렬 드롭다운
	const [orderingModal, setorderingModal] = useState(false);
	const [ordering, setOrdering] = useState('random');

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		isLoading,
	} = useInfiniteQuery(
		["productdata", brandId, filteringBbti, page, ordering],
		({ pageParam = 1 }) => fetchData(brandId, filteringBbti, pageParam , ordering),
		{
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.nextPage;
			},
		}
	);

	// 브랜드 조회
	const {
		data: brandsData,
		isLoading: isBrandsDataLoading,
		isError: isBrandsDataError,
	} = useGetBrands();

	const [activeIndex, setActiveIndex] = useState(null);

	const orderingDescriptions = {
		'random': '추천',
		'-view': '인기순',
		'-created_at': '최신 순',
		'sale_price': '낮은 가격 순',
		'-sale_price': '높은 가격 순',
		'discount_ratio': '할인율 높은 순',
	};
	
	useEffect(() => {
		setActiveIndex("-1");
	}, []);

	const handleBrandClick = (id, index) => {
		setBrandId(id);
		setActiveIndex(index);
	};

	const handleFilterModalShow = () => setbbtiFilterModal(true);
	const handleFilterModalClose = () => setbbtiFilterModal(false);

	// 정렬
	const handleOrderingModalShow = () => setorderingModal(true);
	const handleOrderingModalClose = () => setorderingModal(false);
	
	
	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - SHOP</title>
			</Helmet>
			<MobileHeader back={true} title={"SHOP"} />
			<div className="prodcut">
				<section className="prodcut-content my-5">
					<div className="inner">
						<nav className="brandNav">
							<span
								className={`brand-box pointer mb-2'  ${activeIndex === "-1" ? "active " : ""}`}
								onClick={() => {
									handleBrandClick("all", "-1");
								}}
							>
								전체상품
							</span>

							{brandsData?.data?.map((brand, index) => (
								<>
									<div
										className={`brand-box pointer mb-2 ${index === activeIndex ? "active" : ""}`}
										key={index}
										onClick={() => {
											handleBrandClick(brand.id, index);
										}}
									>
										<div className="brand-logo-imgbox">
											<img src={brand?.logo} />
										</div>
										{brand?.name}
									</div>
								</>
							))}
						</nav>

						<div className="filter-wrap">
							{/* <p style={{ fontSize: "14px", color: "#A2A2A2" }}>
								총 124개의 상품이 있습니다.
							</p> */}
							<div style={{ display: "flex", gap: "10px" }}>
								{/* 상품 필터 */}
								<div className="filter-box">
									<span
										className="filter-title"
										onClick={handleFilterModalShow}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											class="bi bi-filter"
											viewBox="0 0 16 16"
										>
											<path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
										</svg>
										필터
									</span>
								</div>

								{/* 정렬 */}
								<div className="filter-box">
									<span
										className="filter-title"
										onClick={handleOrderingModalShow}
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											color="#4F5558"
											fill="currentColor"
											class="bi bi-filter"
											viewBox="0 0 16 16"
										>
											<path d="M7.62265 5.32127L5.50133 2.19995L3.38 5.32127H4.75133V13.8213H6.25133V5.32127H7.62265ZM8.38 10.7L10.5013 13.8213L12.6226 10.7H11.2513V2.19995H9.75133V10.7H8.38Z" fill="currentColor"/>
										</svg>
										{ordering && orderingDescriptions[ordering]}
									</span>
								</div>

							</div>
						</div>

						<div style={{ paddingBottom: "64px" }}>
							{isLoading ? (
								<div className="spinner-container">
									<LoadingSpinner />
								</div>
							) : (
								<>
									{data.pages.reduce(
										(total, page) =>
											total + page.data.length,
										0
									) === 0 ? (
										<div className="no-data-message">
											해당 브랜드 상품이 없습니다.
										</div>
									) : (
										<InfiniteScroll
											className="product-wrap"
											dataLength={data.pages.reduce(
												(total, page) =>
													total + page.data.length,
												0
											)}
											next={() => fetchNextPage()}
											hasMore={hasNextPage}
											loader={<div>Loading...</div>}
											// endMessage={<div className="no-data-message">해당 브랜드 상품이 없습니다.</div>}
										>
											{data.pages.map(
												(page, pageIndex) => (
													<React.Fragment
														key={pageIndex}
													>
														{page?.data?.map(
															(item) => (
																<ProductItem
																	item={item}
																	key={
																		item?.id
																	}
																	clickArea={
																		"SHOP"
																	}
																/>
															)
														)}
													</React.Fragment>
												)
											)}
										</InfiniteScroll>
									)}
								</>
							)}
						</div>
					</div>
				</section>
				<Gnb />
			</div>

			{/* bbti 필터링 바텀시트 */}
			<BbtiFilter
				show={bbtiFilterModal}
				onHide={handleFilterModalClose}
				setFilteringBbti={setFilteringBbti}
			/>

			{/* 정렬 선택 바텀시트 */}
			<ProductOrdering
				show={orderingModal}
				onHide={handleOrderingModalClose}
				ordering={ordering}
				setOrdering={setOrdering}
			/>
		</>
	);
}

export default Product;
