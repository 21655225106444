/***************************************************************************************
* FileName      : MagazineLogRow.js
* Description   : 소곤핏 관리자 > 매거진 > log 조회 페이지 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.21
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { deleteMagazine , updateMagazineLog } from '../../../../api/admin/magazine';
import { extractYearMonthDayHourMinute } from '../../common/extractDateUtils';
import MLContentModal from './MLContentModal';

function MagazineLogRow({ magazine , role }){

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleEditClick = (id , magazine ) => {
        const editUrl = `/admin/magazinelog/edit/${id}/${magazine}`;
        window.location.href = editUrl;
    };

    const [isDeleting, setIsDeleting] = useState(false);
    const handleDeleteClick = (magazineId) => {
        const confirmDelete = window.confirm('정말로 삭제 하시겠습니까?');
        if (confirmDelete) {
          setIsDeleting(true);
    
          deleteMagazine(magazineId)
            .then(response => {
              alert("성공적으로 삭제되었습니다.")
            })
            .catch(error => {
              console.error('상품 삭제 중 에러 발생:', error);
            })
            .finally(() => {
              setIsDeleting(false);
            });
        }
    };

    const handleUpdateStatusClick = (magazineId , status) => {

        const confirm = status==="A" ? window.confirm('정말로 승인 하시겠습니까?') : window.confirm('정말로 거절 하시겠습니까?');
        if (confirm) {

            if(status === "A"){
                updateMagazineLog(magazineId, { status: status })
                .then(data => {
                    alert("성공적으로 승인 되었습니다.")
                })
                .catch(error => {
                    
                });
            }else if(status === "J"){
                updateMagazineLog(magazineId, { status: status , reject_reason : "관리자가 거절함"})
                .then(data => {
                    alert("성공적으로 거절 되었습니다.")
                })
                .catch(error => {
                    
                });
            }
     
        }
    };

    console.log(magazine);
    return (
        <React.Fragment key={magazine?.id}>
            <tr>
                <td>{magazine?.id}</td>
                <td>{magazine?.category_name}</td>
                <td><img src={magazine?.after?.main_image} /></td>
                <td>{magazine?.magazine_title}</td>
                <td>
                    {magazine?.after?.link && (
                        <a href={magazine.after.link} target="_blank" rel="noopener noreferrer">
                            {magazine.after.link}
                        </a>
                    )}
                </td>
                <td>     
                    <a className='pink_btn' onClick={handleShowModal}>확인</a>       
                </td>
                <td>{extractYearMonthDayHourMinute(magazine?.created_at)}</td>
                <td>{magazine?.user.username}</td>

                <td>{magazine?.hidden ? '비공개' : '공개' }</td>
                <td>
                    {magazine?.action === 'C' && '등록'}
                    {magazine?.action === 'U' && '수정'}
                </td>

                <td>
                    {magazine?.status === 'A' && '승인'}
                    {magazine?.status === 'R' && '대기'}
                    {magazine?.status === 'J' && '거절'}
                </td>
             
                <td>   
                    { role === "CP" && magazine?.status === 'J' &&
                        <a className='pink_btn' 
                            onClick={() => handleEditClick(magazine?.id , magazine?.magazine)}>재요청</a>
                    }  

                    { role === null &&  magazine?.status === 'R' &&
                    <>                    
                 
                    <a className='pink_btn mr-1' onClick={() => handleUpdateStatusClick(magazine?.id,"A")}>
                    승인
                    </a>
                  
    
                  
                    <a className='pink_btn' onClick={() => handleUpdateStatusClick(magazine?.id, "J")}>
                    거절
                    </a>
              
                    </>

                }


                </td>
               
            </tr>

            {/* 콘텐츠 확인 모달 */}
            <MLContentModal
                targetData={magazine?.after?.contents}
                show={showModal}
                onHide={handleCloseModal}
            />
        </React.Fragment>
    )
}

export default MagazineLogRow