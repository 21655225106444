/***************************************************************************************
* FileName      : AllProduct.js
* Description   : 소곤핏 관리자 > 브랜드 > 전체 상품 목록
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.03.19
* Modifide Date : 2024.06.11 : CHM : 사이즈 모달, 디자인 모달 추가
*               : 
* Reference     : 
***************************************************************************************/

import { Link } from 'react-router-dom';
import { useProductsQuery , handleProductSearch } from '../../../api/admin/brandApi.js';
import React, { useState } from 'react';
import Pagination from '../common/Pagination'
import ProductRow from './AllProduct/ProductRow.js'

import AddProductModal from './AllProduct/AddProductModal.js';
import EditProductModal from './AllProduct/EditProductModal.js';
import { SizeLabelModal } from './AllProduct/SizeLabelModal.js';
import { DesignLabelModal } from './AllProduct/DesignLabelModal.js';

function AllProduct()
{
    const { data, error, isFetching, page, setPage, toggle, setToggle } = useProductsQuery();
  
    // 검색
    const [searchAuth, setSearchAuth] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);
    
    const [searchUrl, setSearchUrl] = useState('');

    // 모달 관련 기능
    const [showModal, setShowModal] = useState(false); // 상품 등록 모달
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const [currentProduct, setCurrentProduct] = useState(null); // 모달open 대상 상품
    const [showEditModal, setShowEditModal] = useState(false); // 상품 수정 모달
    const [showSizeModal, setShowSizeModal] = useState(false); // 사이즈 등록 모달
    const [showSizeUpdateModal, setShowSizeUpdateModal] = useState(false); // 사이즈 수정 모달
    const [showDesignModal, setShowDesignModal] = useState(false); // 디자인 라벨 등록 모달
    const [showDesignUpdateModal, setShowDesignUpdateModal] = useState(false); // 디자인 라벨 수정 모달
    
    const handleShowEditModal = (currentId) => { // 상품 수정 모달
        setCurrentProduct(currentId);
        setShowEditModal(true);
    };
    const handleShowSizeModal = (currentId) => { // 사이즈 등록 모달
        setCurrentProduct(currentId);
        setShowSizeModal(true);
    };
    const handleShowSizeUpdateModal = (currentId) => { // 사이즈 수정 모달
        setCurrentProduct(currentId);
        setShowSizeUpdateModal(true);
    };
    const handleShowDesignModal = (currentId) => { // 디자인 라벨 등록 모달
        setCurrentProduct(currentId);
        setShowDesignModal(true);
    };
    const handleShowDesignUpdateModal = (currentId) => { // 디자인 라벨 등록 모달
        setCurrentProduct(currentId);
        setShowDesignUpdateModal(true);
    };


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };


    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleProductSearch({   
            searchAuth, 
            setSearchUrl,
            setSearchResults 
        });

        setSearchConfirm('true')
    }


    if (error) return <div>Error loading Brands</div>;
    if (!data) return <div>Loading Brands...</div>;

    return (
        <>
         <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <ul className="side-menu">
                
                            <Link to='/admin/brand'>
                                <li className='side-item'>
                                    전체브랜드
                                </li>
                            </Link> 
                            <Link to='/admin/brand/all'>
                                <li className='side-item active'>
                                    전체상품
                                </li>
                            </Link>    
                        </ul>
                    </div>

                    <div className='col-md-12'>
                        <div className="box search-wrap mb-4 " >
                            <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                            </select>
                         
                            <div className="search-field">
                                <i className='xi-search'></i>
                            
                                <input type="text" 
                                        value={searchAuth}
                                        onChange={(e) => setSearchAuth(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                onSearch();
                                            }
                                        }}
                                        placeholder="Search"
                                />
                            </div>
                        </div>
                        <div className="box">
                            <div className='tit'>
                                <span className='group-title'>전체({data?.totalCnt})</span>
                                <button onClick={handleShowModal}>상품 등록</button>
                            </div>

                            <table className="admin_table">
                                <colgroup>
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                </colgroup>

                                <thead>
                                    <th>ID</th>
                                    <th>브랜드</th>
                                    <th>상품명</th>
                                    <th>타입</th>
                                    <th>상품이미지</th>
                                    <th>상품링크</th>
                                    <th>상품가격</th>
                                    <th>판매가격</th>
                                    <th>bbti</th>
                                    <th>사이즈</th>
                                    <th>디자인</th>
                                    <th>조회수</th>
                                    <th>공개/비공개</th>
                                    <th>수정</th>
                                    <th>삭제</th>
                                </thead>

                                <tbody>
                                    {searchResults?.data?.length > 0 ? (
                                        searchResults.data.map((product) => (
                                            <ProductRow 
                                                key={product?.id}
                                                product={product}
                                                onEditClick={handleShowEditModal}
                                                onSizeClick={handleShowSizeModal}
                                                onEditSizeClick={handleShowSizeUpdateModal}
                                                onDesignClick={handleShowDesignModal}
                                                onEditDesignClick={handleShowDesignUpdateModal}
                                            />
                                        ))
                                    ) : data?.data?.length > 0 ? (
                                        data?.data?.map((product) => (
                                            <ProductRow 
                                                key={product?.id}
                                                product={product}
                                                onEditClick={handleShowEditModal}
                                                onSizeClick={handleShowSizeModal}
                                                onEditSizeClick={handleShowSizeUpdateModal}
                                                onDesignClick={handleShowDesignModal}
                                                onEditDesignClick={handleShowDesignUpdateModal}
                                            />
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="14">검색 결과가 없습니다.</td>
                                        </tr>
                                    )}
                                   
                                </tbody>
                            </table>

                            {/* 페이지내비게이션 */}
                            <div className='pagination-wrap'>   
                                <Pagination
                                    count={data?.totalCnt || 0}
                                    page={page}
                                    setPage={setPage}
                                    handlePrevPage={handlePrevPage}
                                    handleNextPage={handleNextPage}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* 새로운 상품 등록 모달 */}
        <AddProductModal
            show={showModal}
            onHide={handleCloseModal}
        />

        {/* 브랜드 수정 모달 */}
        {currentProduct && (
            <EditProductModal
                show={showEditModal}
                onHide={() => setShowEditModal(false)}
                id={currentProduct}
            />
        )}

        {/* 사이즈 등록 모달 */}
        {currentProduct && showSizeModal && (
            <SizeLabelModal
                show={showSizeModal}
                onHide={() => {
                    setShowSizeModal(false);
                    setCurrentProduct(null);
                }}
                id={currentProduct}
                type='create'
            />
        )}
        
        {/* 사이즈 수정 모달 */}
        {currentProduct && showSizeUpdateModal && (
            <SizeLabelModal
                show={showSizeUpdateModal}
                onHide={() => {
                    setShowSizeUpdateModal(false);
                    setCurrentProduct(null);
                }}
                id={currentProduct}
                type='update'
            />
        )}

        {/* 디자인 라벨 등록 모달 */}
        {currentProduct && showDesignModal && (
            <DesignLabelModal
                show={showDesignModal}
                onHide={() => {
                    setShowDesignModal(false);
                    setCurrentProduct(null);
                }}
                id={currentProduct}
                type='create'
            />
        )}
        
        {/* 디자인 라벨 수정 모달 */}
        {currentProduct && showDesignUpdateModal && (
            <DesignLabelModal
                show={showDesignUpdateModal}
                onHide={() => {
                    setShowDesignUpdateModal(false);
                    setCurrentProduct(null);
                }}
                id={currentProduct}
                type='update'
            />
        )}

        </>
    )
}

export default AllProduct 