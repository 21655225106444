/***************************************************************************************
* FileName      : Writing.js
* Description   : 소곤핏 관리자 > 매거진 > 매거진 작성 페이지
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 
* Modifide Date : 
*               : 
* Reference     : 미정
***************************************************************************************/

import React, { useEffect , useState } from 'react';
import Cookies from 'js-cookie';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGetCategory , registerMagazine , registercpMagazine } from "../../../api/admin/magazine";

import MagazineMenu from "./MagazineMenu";
import ProductImageUploader from './Writing/ProductImageUploader';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import CustomEditor from 'ckeditor5-custom-hw';

import { getUploadURL , uploadImage } from  '../../../api/common/uploadImageApi';
import { LoadingSpinner } from '../Component/LoadingSpinner';



// [hm] 에디터 이미지 업로드를 위한 설정 (사용자 토큰 조회) : 2023.10.13
import { BASE_URL } from '../../../api/apiConfig';
import { getAccessFromCookie } from '../../../api/cookieUtils';

import MagazineContentBox from './Writing/MagazineContentBox';
function Writing()
{
    // 쿠키 가져오기
    const role = Cookies.get('role');

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();

    const { categoryId } = useParams();

    // 매거진 카테고리 조회
    const { data: magazineCategoryData, isLoading: isCategoryDataLoading, isError: isCategoryDataError } = useGetCategory();
    
    // 선택한 매거진 타입 
    const [selectedMagazine, setSelectedMagazine] = useState();

    // 대표이미지 
    const [selectedFileName, setSelectedFileName] = useState('');

    // 콘텐츠 내용
    const [magazineContent, setMagazineContent] = useState();
    
       
    // 대표이미지 미리보기  
    const [previewUrl , setPreviewUrl] = useState();

       
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // 파일 미리보기를 위한 URL을 설정
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file); 
            setSelectedFileName(file.name);
        } else {
            setPreviewUrl(null);
            setSelectedFileName('');
        }

    };

    
    // 선택한 매거진 타입 저장
    const handleSelectChange = (event) => {
        const { value } = event.target;
        setSelectedMagazine(value);
    };
      
    useEffect(() => {
        if(magazineCategoryData && magazineCategoryData?.data?.length > 0){
            if(categoryId === "all"){
                setSelectedMagazine(magazineCategoryData.data[0].id);
            }else{
                const viewCategory = magazineCategoryData.data.find(category => category.id.toString() === categoryId.toString());
                if (viewCategory) {
                    setSelectedMagazine(viewCategory.id);
                }
            }
        }

    }, [magazineCategoryData]);


    // CP가 매거진 등록 요청
    const createCPMagazineMutaion = useMutation(registercpMagazine , {
        onSuccess : (data) => {
            reset();
            alert("매거진 등록 요청이 완료 되었습니다. 관리자 승인 후 게시됩니다.");
            window.location.href = '/admin/magazinelog/all';
        },
        onError: (data) => {
            data.response.data.error.map(errorMessage => {
                alert(errorMessage);
            });
        }
    });

    // 관리자가 매거진 등록
    const createAdminMagazineMutaion = useMutation(registerMagazine , {
        onSuccess : (data) => {
            reset();
            alert("매거진 등록 완료 되었습니다.");
            window.location.href = '/admin/magazine/all';
        },
        onError: (data) => {
            data.response.data.error.map(errorMessage => {
                alert(errorMessage);
            });
        }
    });

    const uploadImageMutation = useMutation(uploadImage,{
        onSuccess : (result) => {
            const Data = {
                title: watch("title"),
                link: watch("link"),
                main_image: result,
                contents: sendContents,
                category: selectedMagazine,
                hidden: watch("isPrivate") 
            };

            if(role === "CP"){
                const CPData = {
                    after : Data
                }
                createCPMagazineMutaion.mutate(CPData);

            }else{
                createAdminMagazineMutaion.mutate(Data);
            }

        }
    });

    const uploadURLMutaion = useMutation(getUploadURL , {
        onSuccess : (data) => {
            uploadImageMutation.mutate({
                uploadURL : data.uploadURL,
                imageURL : data.imageURL,
                file : watch("MImage"),
            })
        },
        onError : (data) => {
            console.log("error", data.response.error)
        }
    });

    

    // 유효성 검사
    const validateForm = () => {
        let isValid = true;
        if(!selectedMagazine){
            alert("카테고리 선택은 필수입니다.");
            return;
        }      

        return isValid;
    }
          

    const onSubmit = (data) => {
        if (validateForm()) {
            uploadURLMutaion.mutate();
        }
    };

    // 매거진 콘텐츠 형식에 따라 String 맨 앞에 형식 표시
    const CONTENT_TYPES = {
        content: 'C',
        product: 'P',
        new: 'N',
        best: 'B',
        random: 'R'
    };
    const [sendContents, setSendContents] = useState([]);

    // magazineContent의 변화에 따라 sendContents를 업데이트
    useEffect(() => {
        const contents = magazineContent?.filter(data => data?.data || data?.layout)
            .map(data => {
                // 기본적으로 data 또는 layout 중 존재하는 값을 사용
                const contentData = data?.data || data?.layout;
                const prefix = CONTENT_TYPES[data.type] || '';
                return prefix + contentData;
            }) || [];

        setSendContents(contents);
    }, [magazineContent]);

    function preview() {
        console.log("send: ", sendContents);
    }

    // 카테고리 선택 
    useEffect(() => {
        // magazineCategoryData.data가 존재하고 첫 번째 데이터가 있는지 확인
        if (magazineCategoryData?.data?.length > 0) {
            const firstData = magazineCategoryData.data[0].id;
            setSelectedMagazine(firstData);
        }
    }, [magazineCategoryData]);

    // 에디터 open상태 확인 (true면 등록버튼 비활성)
    const [onEditor, setOnEditor] = useState(true);
    
    return (
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            <div className='tit_area line_thick'>
                                <h2 className='tit_lv2'>
                                    {role === "CP" ? "매거진 글쓰기 요청" : "매거진 글쓰기"}
                                   
                                </h2>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>     
                                <div className='form_table no_line'>
                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>카테고리</p>
                                        </div>
                                        <div className='td'>
                                            {isCategoryDataLoading ? (
                                                <p>Loading...</p>
                                            ) : isCategoryDataError ? (
                                                <p>Error loading Category data</p>
                                            ) : (
                                                <select value={selectedMagazine} onChange={handleSelectChange}>
                                                    <option value="" disabled hidden>
                                                        카테고리를 선택하세요
                                                    </option>

                                                    {magazineCategoryData?.data?.map((category) => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                    </div>

                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>제목</p>
                                        </div>
                                        <div className='td'>
                                            <input 
                                                type='text' 
                                                name='title' 
                                                className={errors.title ? 'error-input' : ''}
                                                {...register('title', { required: '제목 입력은 필수입니다.' })} />
                                            
                                        </div>
                                    </div>

                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>링크</p>
                                        </div>
                                        <div className='td'>
                                            <input 
                                                type='text' 
                                                name='link' 
                                                className={errors.link ? 'error-input' : ''}
                                                {...register('link', { required: '링크 입력은 필수입니다.' })} />
                                            
                                        </div>
                                    </div>
                                    
                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>대표 이미지</p>
                                        </div>
                                        <div className='td'>
                                            <div className='imgPreview'>
                                                { previewUrl ? (
                                                <>
                                                    <div className='mpreimg'>
                                                        <img className='editImg' src={previewUrl}/>
                                                    </div>
                                        
                                                </>
                                                ) : (
                                                <></>
                                                )}
                                            </div>

                                            <div class="filebox">
                                                <input  className={errors.MImage ? 'upload-name error-input' : 'upload-name'} 
                                                        value={selectedFileName} 
                                                        placeholder="대표 이미지파일" />
                                                        
                                                <label htmlFor="productImage_file">파일찾기</label> 

                                                <input
                                                    type='file'
                                                    id='productImage_file'
                                                    name='productImage'
                                                    accept="image/jpg, image/png, image/jpeg"
                                            
                                                    {...register('MImage', { required: '대표 이미지는 필수입니다.' } )}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                     

                                            {/* <ProductImageUploader  /> */}
                                        </div>
                                    </div>
                                    <div className='tr'>
                                        <div className='th'>
                                            <p className='form_label required'>내용</p>
                                        </div>
                                        <div className='td'>
                                            <MagazineContentBox
                                                className='my-5 w-100'
                                                magazineContent={magazineContent}
                                                setMagazineContent={setMagazineContent}
                                                onEditor={onEditor}
                                                setOnEditor={setOnEditor}
                                            />

                                        </div>
                                    </div>
                                    <div className='tr'>
                                        <div className='th'>
                                            
                                        </div>
                                        <div className='td'>
                                        <div className="form-check">
                                            <input className="form-check-input custom-checkbox" 
                                                    type="checkbox"  
                                                    id="hiddenCheck" 
                                                    {...register('isPrivate')}
                                            />

                                            <label className="form-check-label" for="hiddenCheck">
                                            비공개
                                            </label>
                                        </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='btn-wrap'>
                                    <button className='btn_white_2'
                                        type="button"
                                        onClick={()=>alert('취소ㄱ?')}>
                                        취소
                                    </button>
                                    {
                                        onEditor ? (
                                            <button className='btn_disable_2'
                                                type="button"
                                                onClick={()=>alert('에디터가 열려있으면 매거진 등록이 불가능합니다.')}
                                            >등록</button>
                                        ):(
                                            <button className='btn_black_2' type="submit">
                                                {role === "CP" && 
                                                (createCPMagazineMutaion.isLoading || uploadImageMutation.isLoading || uploadURLMutaion.isLoading)
                                                ? <LoadingSpinner /> : role !== "CP" && (createAdminMagazineMutaion.isLoading || uploadImageMutation.isLoading || uploadURLMutaion.isLoading)
                                                ? <LoadingSpinner /> : '등록'}
                                            </button>
                                        )
                                    }
                                    
                                    {/* <p onClick={()=>console.log("매거진 콘텐츠 : ", preview())}>미리보기</p> */}
                                </div>
                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Writing 