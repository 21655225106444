/***************************************************************************************
* FileName      : ReplyReportList.js
* Description   : 소곤핏 관리자 >  > 댓글 신고 목록
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.08
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Link } from 'react-router-dom';
import { useBrandsQuery , handleBrandSearch } from '../../../api/admin/brandApi.js';
import { Modal , Button } from 'react-bootstrap';

import React, { useState, useEffect } from 'react';
// import BrandRow from './BrandList/BrandRow.js';

import Pagination from '../common/Pagination.js'
import { useReplyReportList } from '../../../api/admin/Report'

import ReplyReportRow from './ReplyReportList/ReplyReportRow.js';

function ReplyReportList()
{

    const { data, error, isFetching, page, setPage } = useReplyReportList();
    
 
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };


    return (
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <ul className="side-menu">
                            <Link to='/admin/report/post'>
                                <li className='side-item'>
                                게시글 신고
                                </li>
                            </Link> 
                            <Link to='/admin/report/report'>
                                <li className='side-item active'>
                                댓글 신고
                                </li>
                            </Link>   
                        </ul>
                    </div>

                    <div className='col-md-12'>
                      
                        <div className="box">
                            <div className='tit'>
                                <span className='group-title'>전체({data && data.totalCnt})</span>
                            </div>
                            <table className="admin_table">
                                <colgroup>
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '20%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '20%' }} />
                                </colgroup>
                                <thead>
                                    <th>ID</th>
                                    <th>신고대상자</th>
                                    <th>신고자</th>
                                    <th>글 링크</th>
                                    <th>댓글내용</th>
                                    <th>신고사유</th>
                                    <th>신고일시</th>
                                    <th>처리</th>
                                </thead>
                                <tbody>
                                    { data && data.data && data.data.length > 0 ? (
                                            data.data.map((report) => (
                                                <ReplyReportRow 
                                                    key={report?.id}
                                                    report={report}
                                                />
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="17">결과가 없습니다.</td>
                                            </tr>
                                    )}
                                  
                                </tbody>
                            </table>

                            {/* 페이지내비게이션 */}
                            <div className='pagination-wrap'>   
                                <Pagination
                                    count={data?.totalCnt || 0}
                                    page={page}
                                    setPage={setPage}
                                    handlePrevPage={handlePrevPage}
                                    handleNextPage={handleNextPage}
                                    />
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </section>


        </>
    )
}

export default ReplyReportList 