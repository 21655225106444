import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
	getUploadURL,
	uploadImage,
} from "../../../../api/common/uploadImageApi";
import { LoadingSpinner } from "../../Component/LoadingSpinner";

import { getCategory, updateCategory } from "../../../../api/admin/magazine";

export const EditCategoryModal = ({ id, show, onHide }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		watch,
	} = useForm();

	const { data, isLoading } = useQuery(["category", id], () =>
		getCategory(id)
	);

	useEffect(() => {
		if (!isLoading && data) {
			setValue("name", data?.name);
			setValue("description", data?.description);
		}
	}, [data]);

	const updateCategoryMutaion = useMutation(
		(newData) => updateCategory(id, newData),
		{
			onSuccess: (data) => {
				alert("수정되었습니다");
				reset();
				onHide();
			},
			onError: (data) => {
				alert(data?.request?.responseText);
			},
		}
	);

	const onSubmit = (data) => {
		const Data = {
			name: data.name,
			description: data.description,
			// hidden:isPrivate
		};
		updateCategoryMutaion.mutate(Data);
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>카테고리 수정</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="signIn-form">
						<div className="input_box">
							<p>카테고리 이름</p>
							<input
								type="text"
								name="name"
								className={errors.name ? "error-input" : ""}
								{...register("name", {
									required: "카테고리 명 입력은 필수입니다.",
								})}
							/>

							<p>상세설명</p>
							<input
								type="text"
								name="description"
								className={
									errors.description ? "error-input" : ""
								}
								{...register("description")}
							/>
						</div>
						<button className="btn_black_1" type="submit">
							카테고리 수정
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default EditCategoryModal;
