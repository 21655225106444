/***************************************************************************************
 * FileName      : MobileHeader.js
 * Description   : 소곤핏 고객 모바일 네비게이션 헤더 v2
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  :
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom/dist";

/**
 * @param back 백버튼이 있으면 true, 없으면 false
 * @param title 타이틀이 있으면 텍스트 입력, 없으면 null
 * @returns 모바일 헤더 출력
 */
export default function MobileHeader2({ back, username, more, setMore }) {
	// 뒤로가기 버튼 클릭 시 이전 페이지가 없으면 소곤핏 메인으로 이동
	const navigate = useNavigate();
	const handleBackClick = () => {
		const previousPath = document.referrer;
		const currentHost = window.location.hostname;

		if (
			previousPath === "" ||
			previousPath == currentHost ||
			previousPath.includes(currentHost)
		) {
			window.history.back();
		} else {
			navigate("/");
		}
	};

	return (
		<div className="mobile-header">
			<Row className="m-0 px-2 py-3">
				{/* 백버튼 */}
				<Col
					xs={2}
					className="text-start pointer"
					onClick={() => back && handleBackClick()}
				>
					{back && <FontAwesomeIcon icon={faArrowLeft} />}
				</Col>
				{/* 타이틀 */}
				<Col xs={8} className="text-center">
					<span className="mnh">
						{username == "Anonymous" ? "익명" : username}
					</span>
					님의 소곤소곤
				</Col>
				{/* 빈칸 */}
				<Col xs={2} className="text-end pointer">
					{/* <FontAwesomeIcon icon={faEllipsis} /> */}
				</Col>
			</Row>
		</div>
	);
}
