/***************************************************************************************
* FileName      : MagazineContentBox.js
* Description   : 소곤핏 관리자 > 매거진 > 매거진 작성 페이지 > 콘텐츠 작성란(에디터추가, 상품추가)
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.04.04
* Modifide Date : 2024.04.23 : CHM : 미리보기 사이즈 선택 버튼 추가
*               : 
* Reference     : 미정
***************************************************************************************/
import React, { useEffect, useState } from 'react';
import MagazineEditor from './MagazineEditor';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AppendModal, EditProductModal, ProductModal } from './MagazineProductModal';
import {ProductItemSample, CustomProductItemSample, CustomSelectProductItemSample} from './ProductItemSample';

const MagazineContentBox = ({magazineContent, setMagazineContent, prevMagazine, onEditor, setOnEditor}) => {
  const [editors, setEditors] = useState([]);
  const [nextEditorId, setNextEditorId] = useState(1);
  // 에디터 on/off (처음 페이지 접근 시 에디터 on 상태로 시작)
  // const [onEditor, setOnEditor] = useState(true);

  // 사용자 화면에 보여줄 콘텐츠 데이터
  const [contentData, setContentData] = useState([]);

  // (수정 시)prevMagazine 초기값이 있는경우
  useEffect(() => {
    if (prevMagazine) {
      const logList = []; // 정보를 담을 배열 선언
      prevMagazine.forEach(item => {
        switch (item[0]) {
          case 'C':
            logList.push({ type: 'content', data: item.slice(1) });
            break;
          case 'P':
            logList.push({ type: 'product', data: item.slice(1).split(',') });
            break;
          case 'N':
            logList.push({ type: 'new', layout: item.slice(1) });
            break;
          case 'B':
            logList.push({ type: 'best', layout: item.slice(1) });
            break;
          case 'R':
            logList.push({ type: 'random', layout: item.slice(1) });
            break;
          default:
            break;
        }
      });
      setContentData(logList); // 배열에 담긴 정보를 콘솔에 출력하거나 다른 작업 수행
    }
  }, [prevMagazine]);

  // 상품 추가 or 에디터 추가 모달
  const [showModal, setShowModal] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const [exDeviceSize, setExDeviceSize] = useState('600px');

  const handleConfirm = (data) => {
    setContentData(prevData => [...prevData, data]);
  };

  const addEditor = () => {
    setShowModal(true);
    setOnEditor(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAddEditor = () => {
    setEditors(prevEditors => [...prevEditors, nextEditorId]);
    setNextEditorId(prevId => prevId + 1);
    setShowModal(false);
    setOnEditor(true);
  };

  // 처음 컴포넌트가 마운트될 때에만 실행됨
  useEffect(() => {
    if (editors.length === 0) {
      setEditors(() => [0]);
    }
  }, []);

  // 상품콘텐츠 추가 모달 오픈
  const handleAddProduct = () => {
    setShowModal(false);
    setShowProductModal(true);
  };

  // 콘텐츠 수정 시 수정할 div 박스의 index를 받아와서 해당 콘텐츠 수정
  const [editContent, setEditContent] = useState(undefined);

  // 에디터 콘텐츠 수정 함수(index와 수정할 data)
  const updateContentData = (id, data) => {
    let newContentData = [...contentData];
    newContentData[id] = data;
    setContentData(newContentData);
    setEditContent(undefined);
  }

  // 상품영역 수정 시 수정할 div 박스의 index를 받아와서 해당 콘텐츠 수정
  const [editProduct, setEditProduct] = useState(undefined);

  // 상품 콘텐츠 수정 함수
  const updateProductContent = (index, data) => {
    let newContentData = [...contentData];
    newContentData[index] = data;
    setContentData(newContentData);
    setEditProduct(undefined);
  }

  useEffect(()=>{
    setMagazineContent(contentData);
    console.log('contentData: ', contentData);
  }, [contentData]);

  return (
    <>
    {/* 미리보기 화면 사이즈 선택 PC or Mobile */}
    <div className='btn-wrap m-1' style={{justifyContent: 'normal'}}>
      <p className={exDeviceSize === '600px' ? 'btn_black_2 p-2 pointer' : 'btn_white_2 p-2 pointer'} style={{height: 'auto', textAlign: 'center'}} onClick={()=>setExDeviceSize('600px')}>PC 화면 (600px)</p>
      <p className={exDeviceSize === '390px' ? 'btn_black_2 p-2 pointer' : 'btn_white_2 p-2 pointer'} style={{height: 'auto', textAlign: 'center'}} onClick={()=>setExDeviceSize('390px')}>모바일 화면 (390px)</p>
    </div>

    {/* 에디터 콘텐츠 영역 */}
    <Container className="p-1" style={{maxWidth: exDeviceSize, margin: '0'}}>
      <div className="mt-3 py-3 px-2 border rounded-2">
        {
          contentData.length >= 1 && (
            <div>
              {
                contentData.map((content, index) => (
                  console.log(content?.type, content?.data),
                  <div key={index}>
                    {editContent === index ? (
                      <MagazineEditor
                        id={index}
                        onConfirm={updateContentData}
                        setOnEditor={setOnEditor}
                        defaultContent={content?.data}
                      />
                    ) : (
                      // 에디터 콘텐츠인 경우
                      content?.type === 'content' ? (
                        <div className='editor-content'
                          dangerouslySetInnerHTML={{ __html: content?.data }}
                          onClick={()=>{
                            // 콘텐츠 수정 모드로 변경(상품콘텐츠는 수정 불가)
                            setEditContent(index);
                          }}
                        />
                      ):(
                      // 상품콘텐츠인 경우
                      content?.type === 'product' ? (
                        <div className='product-wrap' onClick={()=>{
                          setEditProduct(index);
                          setShowEditProductModal(true);
                        }}>
                          <CustomSelectProductItemSample 
                            key={index+content?.data}
                            productList={content?.data}
                          />
                          {/* {content?.data?.map((item, idx) => (
                            <ProductItemSample item={item} key={idx} />
                          ))} */}
                        </div>
                      ):(
                      // 신상품 or 인기상품 or 랜덤상품인 경우 
                      <div className='product-wrap'
                        onClick={()=>{
                          setEditProduct(index);
                          setShowEditProductModal(true);
                        }}
                      >
                        <CustomProductItemSample
                          key={index}
                          type={content?.type}
                          layout={content?.layout}
                        />
                      </div>
                      ))
                    )}
                  </div>
                ))
              }
            </div>
          )
        }
        <Row>
          {editors.map(editorId => (
            <Col xs={12} key={editorId}>
              <MagazineEditor
                onConfirm={handleConfirm}
                setOnEditor={setOnEditor}
              />
            </Col>
          ))}
        </Row>
        
        {!onEditor && (
          <Button 
            onClick={addEditor}
            className="mt-2 w-100"
            style={{
              border: '1px dashed grey',
              backgroundColor: 'transparent',
              color: 'grey'
            }}
          >+ 콘텐츠 추가</Button>
        )}

        {/* 추가할 콘텐츠 선택 모달 : 에디터 추가 or 상품 추가*/}
        <AppendModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          setModalType={setModalType}
          handleAddEditor={handleAddEditor}
          handleAddProduct={handleAddProduct}
        />

        {/* 상품 추가 모달 */}
        <ProductModal
          showProductModal={showProductModal}
          handleCloseProductModal={setShowProductModal}
          handleConfirm={handleConfirm}
        />

        {/* 상품 수정 모달 */}
        <EditProductModal
          showEditProductModal={showEditProductModal}
          handleCloseProductModal={setShowEditProductModal}
          handleConfirm={updateProductContent}
          index={editProduct}
        />

      </div>
    </Container>
    </>
  );
};

export default MagazineContentBox;
