/***************************************************************************************
 * FileName      : Mypage.js
 * Description   : 소곤핏 마이페이지 > 메인 페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.08.26
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import MypageMobileHeader from "./Component/MypageMobileHeader";
import Gnb from "../../Gnb";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { getMe, userLogout } from "../../api/common/memberApi";
import { getKakaoLogout } from "../../api/services/kakaoLogin";
import { Helmet } from "react-helmet-async";
import { AiOutlineCaretDown } from "react-icons/ai";


function Mypage() {
	const navigate = useNavigate();

	// 회원 정보
	const [user, setUser] = useState({});

	useEffect(() => {
		getMe()
			.then((data) => {
				console.log(data);
				const userData =
					data?.data === "guest"
						? { type: "guest", username: "" }
						: {
								type: data?.type,
								username: data?.username,
								nickname: data?.nickname,
								profile: data?.profile,
								loginMethod: data?.login_method,
							};

				setUser(userData);
			})
			.catch((error) => {
				console.log(error);
				logout();
				console.log("토큰만료");
			});
	}, []);

	useEffect(() => {
		if (user.type === "guest") window.location.href = "/login";
	}, [user.type]);

	const logout = () => {
		if (user.loginMethod === "카카오") {
			getKakaoLogout()
				.then((res) => {
					console.log(res?.data?.logout_url);
					window.location.href = res?.data?.logout_url;
					Cookies.remove("token");
					Cookies.remove("access");
					userLogout().then((res) => {
						window.location.href = "/";
					});
					//window.location.href = '/';
					alert("로그아웃되었습니다.");
					window.hackleClient.resetUser(); // 핵클 로그아웃
				})
				.catch((error) => {
					console.log(error?.response);
					alert("로그아웃 처리중 에러가 발생하였습니다.");
				});
		} else {
			userLogout().then((res) => {
				Cookies.remove("access");
				window.location.href = "/";
			});
			window.hackleClient.resetUser(); // 핵클 로그아웃
		}
	};

	useEffect(() => {
		if (!window?.Kakao?.isInitialized()) {
			window?.Kakao?.init("0907d499eb79097f57e355d7a8831723");
		}
	}, []);

	const handleChat = () => {
		if (window?.Kakao?.Channel) {
			window?.Kakao.Channel.chat({
				channelPublicId: "_HUxbxkG",
			});
		} else {
			AiOutlineCaretDown(
				"카카오톡에서 소곤핏 채널을 통해 문의 부탁드립니다."
			);
		}
	};

	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - Mypage</title>
			</Helmet>
			<MypageMobileHeader />
			<div className="mypagev2">
				<section className="mypage-menu my-5">
					<div className="user-info-wrap">
						<div className="user-info-box" onClick={() => navigate("/mypage/EditProfile")}>
							<h3>{user?.nickname}</h3>
							<MdKeyboardArrowRight size={30} style={{ color: 'white'}} />
						</div>
						<div className="curve-line">
							<img 
								src={process.env.PUBLIC_URL + '/img/mypageLine.svg'} 
								alt="" 
								style={{ transform: 'rotate(7deg)' }} 
								width="100%" />
					
						</div>
					
						<div className="curve-line2">
							<img 
								src={process.env.PUBLIC_URL + '/img/mypageLine2.svg'} 
								alt="" 
								width="100%" />
							
						</div>
						
					</div>

					<div className="mypage-wrap">
						<div className="mypage-box">
							<div className="mp-tit">
								<p>나의 활동</p>
							</div>
							<ul>
								<li>
									<Link to="/mypage/MyPosts">
									내가 작성한 글
									</Link>
								</li>
								<li>
									<Link to="/mypage/Comments">
									댓글/좋아요
									</Link>
								</li>
								<li>
									<Link to="/mypage/Likes">
										소곤톡 좋아요
									</Link>
								</li>
								<li>
									<Link to="/mypage/MLikes">
									매거진 좋아요
									</Link>
								</li>

							</ul>
						</div>

						<div className="mypage-box">
							<div className="mp-tit">
								<p>나의 분석 결과</p>
							</div>
							<ul>
								<li className="pointer" onClick={() => navigate("/bbtiResult")}>체형 분석 결과</li>
								{/*

									// 2024.11.19 : CHM : 취향분석, 사이즈분석 제거

									<li	className="pointer" onClick={() => alert("준비중입니다")}>취향 분석 결과</li>
									<li	className="pointer" onClick={() => alert("준비중입니다")}>사이즈 분석 결과</li>
								*/}
							</ul>
						</div>

						<div className="mypage-box">
							<div className="mp-tit">
								<p>고객센터</p>
							</div>
							<ul>
								<li className="pointer" onClick={() => alert("준비중입니다")}>공지사항</li>
								<li className="pointer" onClick={() => alert("준비중입니다")}>FAQ</li>
								<li	className="pointer" onClick={() => handleChat()}>1:1문의</li>
							</ul>
						</div>


					<button className="my-logout" onClick={() => {logout();}}>
						로그아웃
					</button>
					</div>

				</section>

				<Gnb />
			</div>
		</>
	);
}

export default Mypage;
