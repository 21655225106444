/***************************************************************************************
 * FileName      : ProductItem.js
 * Description   : 소곤핏 상품 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.04.01
 * Modifide Date : 2024.06.10 : CHM : safari 브라우저 호환성 문제로인한 팝업창 생성 방식 변경
 *               : 2024.06.12 : CHM : BBTI 라벨링 뱃지 임시제거
 * Reference     :
 ***************************************************************************************/
import { getProductViews } from "../../../api/services/mainApi";
import ReactGA from "react-ga4";

// 매거진에서 사용된 경우 magazineProduct : true
function ProductItem({ item, magazineProduct, clickArea }) {
	const originalPrice = item?.original_price;
	const salePrice = item?.sale_price;
	let discountPercentage = 0;

	if (originalPrice && salePrice && originalPrice > salePrice) {
		const discount = originalPrice - salePrice;
		discountPercentage = (discount / originalPrice) * 100;
	}

	// 모바일 앱인지 확인하는 함수
	const isMobileApp = () => {
		return /flutter-app/i.test(navigator.userAgent);
	};

	const handleClick = (e) => {
		e.preventDefault();
		// safari 브라우저 팝업 차단으로인해 빈 팝업 생성
		const popup = isMobileApp() || window.open("", "_blank");

		const clickAreaValue = clickArea ?? "";

		window.hackleClient.track({
			key: "clicked_product",
			properties: {
				클릭위치: clickAreaValue,
				상품정보: `${item?.id} - ${item?.name}`, // 상품ID-상품명
			},
		});

		if (magazineProduct) {
			ReactGA.event({
				action: "매거진 상품 클릭",
				category: "상품클릭",
				label: `${item?.id} : ${item?.name}`,
			});
		}
		ReactGA.event({
			action: "상품클릭",
			category: "상품클릭",
			label: `${item?.id} : ${item?.name}`,
		});
		getProductViews(item?.id)
			.then(() => {
				isMobileApp()
					? window.location.href = item?.link // 모바일 앱인 경우 바로 링크 이동
					: popup.location.href = item?.link; // safari 브라우저 팝업 차단으로인해 빈 팝업에 링크추가
			})
			.catch((error) => {
				console.error("API 호출에 실패했습니다:", error);
			});
	};

	return (
		<>
			<div className="product-item" onClick={handleClick}>
				<div className="thumbnail">
					<img src={item?.image} alt="" />
					{/* 2024.06.12 : CHM : BBTI 라벨링 뱃지 임시제거 */}
					{/*
                    <div className="goods-badge">
                        {item?.bbti?.map((bbtiItem, index) => (
                            <span key={index} className="badge bbti">
                                {bbtiItem?.type}
                            </span>
                        ))}
                    </div>
                    */}

					{/* <div className="goods-badge">
                        <span className="badge hot">HOT</span>
                        <span className="badge bbti">UIW</span>
                    </div> */}
				</div>
				<div className="product-info">
					<p className="vendor">{item?.brand?.name}</p>
					<p className="pd-name">{item?.name}</p>
					<div className="pd-discount">
						{/* <span>{item?.original_price?.toLocaleString()}원</span> */}
						<b>
							{discountPercentage?.toFixed(0)}
							<small>%</small>
						</b>
						<span>{item?.sale_price?.toLocaleString()}원</span>
					</div>

					{/* <span className="pd-salePrice">
						{item?.sale_price?.toLocaleString()}원
					</span> */}
				</div>
			</div>
		</>
	);
}

export default ProductItem;
