import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
  
    useEffect(() => {
      const initializeReactGA = () => {
        const gaKey = process.env.REACT_APP_ENV_NAME === 'main' ? process.env.REACT_APP_GA_KEY : process.env.REACT_APP_DEV_GA_KEY;
        if (gaKey && !window.location.href.includes("localhost")) {
          ReactGA.initialize(gaKey);
          setInitialized(true);
        }
      };
  
      initializeReactGA();
    }, []);
  
    useEffect(() => {
      if (initialized) {
        ReactGA.set({ page: location.pathname });
        ReactGA.send("pageview");
      }
    }, [initialized, location.pathname]);
  
    return null;
  };
  
  export default RouteChangeTracker;