import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import RouteChangeTracker from "./RouteChangeTracker";

import { BrowserRouter } from "react-router-dom";
import ScrollTop from "./ScrollTop";
import { HelmetProvider } from "react-helmet-async";

// 핵클SDK연동
import { createInstance, HackleProvider } from "@hackler/react-sdk";

// 클라이언트 인스턴스를 생성하는 함수
function createHackleClient(envName) {
	const isDev = envName !== "main";
	const key = isDev
		? process.env.REACT_APP_DEV_HACKCLE_KEY
		: process.env.REACT_APP_HACKCLE_KEY;
	const debug = isDev;
	return createInstance(key, { debug });
}
const hackleClient = createHackleClient(process.env.REACT_APP_ENV_NAME);
window.hackleClient = hackleClient;

const client = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<HackleProvider hackleClient={hackleClient}>
		<QueryClientProvider client={client}>
			<BrowserRouter>
				<RouteChangeTracker />
				<ScrollTop />
				<HelmetProvider>
					<App />
				</HelmetProvider>
			</BrowserRouter>
		</QueryClientProvider>
	</HackleProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
