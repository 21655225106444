/***************************************************************************************
 * FileName      : SimpleTestQuestion.js
 * Description   : 소곤핏 서비스 > 분석 페이지 > 체형분석(질문)
 * Company       : (주)엘리그
 * Author        : 최현민
 * Created Date  : 2024.05.14
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { useState, useEffect } from "react";
import { updateBBTI } from "../../../api/common/memberApi";
import { bbtiList } from "../../../api/services/bodytypeApi";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../../api/common/memberApi";
import MobileHeader from "../Component/MobileHeader";

import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

import { PropertyOperationsBuilder } from "@hackler/react-sdk";

function QuestionStepVersion(){
	const navigate = useNavigate();

	const [userId, setUserId] = useState("");
	const [bodyTypeList, setBodyTypeList] = useState({});
	const [step, setStep] = useState(1);

	const [roundSize, setRoundSize] = useState("");
	const [cupSize, setCupSize] = useState("");
	const [verticalVolume, setVerticalVolume] = useState("");
	const [horizontalVolume, setHorizontalVolume] = useState("");
	const [chestDistance, setChestDistance] = useState("");
	

	useEffect(() => {
		getMe().then((res) => {
			setUserId(res?.id);
		});
	}, []);

	useEffect(() => {
		// bbti리스트, id값 조회
		bbtiList().then((res) => {
			const dataMap = res?.data?.data?.reduce((acc, item) => {
				acc[item?.type] = item?.id;
				return acc;
			}, {});
			setBodyTypeList(dataMap);
		});
	}, []);

	const nextStep = () => {
		setStep(step+1);
	};
	const prevStep = () => {
		step === 1 ? alert("첫번째 질문입니다.") : setStep(step-1);
	}
	const onSubmit = () => {
		if( userId !== "" || verticalVolume === "" || horizontalVolume === "" || chestDistance === "" ){
			const bodyType = `${verticalVolume}${horizontalVolume}${chestDistance}`;
			const userSize = `${roundSize}${cupSize}`;

			ReactGA.event({
				action: "체형분석 테스트",
				category: "테스트 완료",
				label: bodyType,
			});

			updateBBTI({
				id: userId,
				newBbti: bodyTypeList[bodyType],
				size: userSize,
			})
				.then((res) => {
					// bbti 완료 이벤트
					window.hackleClient.track({
						key: "complete_research",
						properties: {
							완료체형: bodyType,
							완료사이즈: userSize,
						},
					});
					const operations =
						new PropertyOperationsBuilder()
							.set(
								"bbti",
								bodyType
							)
							.build();
					window.hackleClient.updateUserProperties(
						operations
					); // bbti 정보 업데이트
					// 결과페이지로 이동(뒤로가기 불가)
					navigate("/bbtiResult", {
						replace: true,
					});
				})
				.catch((err) => {
					console.log(err);
					alert(
						"처리중 에러가 발생했습니다."
					);
				});
		} else {
			alert("잘못된 요청입니다.");
		}
	}

	const questionData = {
		1:{
			progress: "10%",
			title: "둘레 사이즈를 선택해주세요.",
			selectType: "하나만 선택해 주세요.",
		},
		2:{
			progress: "30%",
			title: "컵 사이즈를 선택해주세요.",
			selectType: "하나만 선택해 주세요.",
		},
		3:{
			progress: "50%",
			title: "가슴 상하 볼륨의 형태를 선택해주세요.",
			selectType: "하나만 선택해 주세요.",
		},
		4:{
			progress: "70%",
			title: "가슴 좌우 볼륨의 비중을 선택해주세요.",
			selectType: "하나만 선택해 주세요.",
		},
		5: {
			progress: "90%",
			title: "유두의 방향을 선택해주세요.",
			selectType: "하나만 선택해 주세요.",
		}
	}
	
	return(
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 체형분석(질문)</title>
			</Helmet>
			<MobileHeader back={true} title={"체형분석"} unsetShadow={true}/>
			<div className="stq-wrap">
				<div className="progress-box">
					<div className="progress-bar" style={{"width": questionData[step].progress}}></div>
				</div>

				<div className="stq-question">
					<p className="title">{questionData[step].title}</p>
					<div className="answer-area">
						<p className="select-type">{questionData[step].selectType}</p>
						{step === 1 && <Step1 roundSize={roundSize} setRoundSize={setRoundSize}/>}
						{step === 2 && <Step2 cupSize={cupSize} setCupSize={setCupSize}/>}
						{step === 3 && <Step3 verticalVolume={verticalVolume} setVerticalVolume={setVerticalVolume}/>}
						{step === 4 && <Step4 horizontalVolume={horizontalVolume} setHorizontalVolume={setHorizontalVolume}/>}
						{step === 5 && <Step5 chestDistance={chestDistance} setChestDistance={setChestDistance}/>}
					</div>
				</div>
				
			</div>
			<div className="stq-bottom">
				<button className={step === 1 ? "btn disable" : "btn"} onClick={()=>prevStep()}>뒤로가기</button>
				{
					step === 1 ?
						roundSize === "" ? ( 
							<button className="btn disable" onClick={()=>alert("선택해주세요.")}>다음</button>
						) : (
							<button className="btn active" onClick={()=>nextStep()}>다음</button>
						) : null
				}
				{
					step === 2 ? 
						cupSize === "" ? ( 
							<button className="btn disable" onClick={()=>alert("선택해주세요.")}>다음</button>
						) : (
							<button className="btn active" onClick={()=>nextStep()}>다음</button>
						) : null
				}
				{
					step === 3 ?
						verticalVolume === "" ? ( 
							<button className="btn disable" onClick={()=>alert("선택해주세요.")}>다음</button>
						) : (
							<button className="btn active" onClick={()=>nextStep()}>다음</button>
						) : null
				}
				{
					step === 4 ?
						horizontalVolume === "" ? ( 
							<button className="btn disable" onClick={()=>alert("선택해주세요.")}>다음</button>
						) : (
							<button className="btn active" onClick={()=>nextStep()}>다음</button>
						) : null
				}
				{
					step === 5 ?
						chestDistance === "" ? ( 
							<button className="btn disable" onClick={()=>alert("선택해주세요.")}>완료</button>
						) : (
							<button className="btn active" onClick={()=>onSubmit()}>완료</button>
						) : null
				}
			</div>
		</>
	)
}

function Step1({roundSize, setRoundSize}){
	const sizeList = ["65", "70", "75", "80", "85", "90", "95"];
	return(
		<>
			{
				sizeList?.map((size, idx) => (
					<div 
						key={idx}
						className={roundSize === size ? "answer-btn active" : "answer-btn"}
						onClick={()=>setRoundSize(size)}
						>{size}</div>
				))
			}
		</>
	)
}

function Step2({cupSize, setCupSize}){
	const sizeList = ["A", "B", "C", "D", "E", "F", "G", "H"];
	return(
		<>
			{
				sizeList?.map((size, idx) => (
					<div 
						key={idx}
						className={cupSize === size ? "answer-btn active" : "answer-btn"}
						onClick={()=>setCupSize(size)}
						>{size}</div>
				))
			}
		</>
	)
}

function Step3({verticalVolume, setVerticalVolume}){
	const volumeList = [
		{ value: "T", label: "윗볼륨 발달형", imgSrc: "img/bbti/simple/T.png" },
		{ value: "M", label: "뾰족한 가슴형", imgSrc: "img/bbti/simple/M.png" },
		{ value: "U", label: "아래볼륨 발달형", imgSrc: "img/bbti/simple/U.png" }
	];
	return(
		<div className="block-type">
			{
				volumeList?.map((volume, idx) => (
					<div 
						key={idx}
						className={verticalVolume === volume?.value ? "answer-block active" : "answer-block"}
						onClick={()=>setVerticalVolume(volume?.value)}
					>
						<img src={volume?.imgSrc} alt={volume?.label}/>
						<p>{volume?.label}</p>
					</div>
				))
			}
		</div>
	)
}

function Step4({horizontalVolume, setHorizontalVolume}){
	const volumeList = [
		{ value: "O", label: "볼륨 발달형", imgSrc: "img/bbti/simple/O.png" },
		{ value: "R", label: "퍼지고 완만한형", imgSrc: "img/bbti/simple/R.png"},
		{ value: "I", label: "빈약형", imgSrc: "img/bbti/simple/I.png" }
	];
	return(
		<div className="block-type">
			{
				volumeList?.map((volume, idx) => (
					<div 
						key={idx}
						className={horizontalVolume === volume?.value ? "answer-block active" : "answer-block"}
						onClick={()=>setHorizontalVolume(volume?.value)}
					>
						<img src={volume?.imgSrc} alt={volume?.label}/>
						<p>{volume?.label}</p>
					</div>
				))
			}
		</div>
	)
}

function Step5({chestDistance, setChestDistance}){
	const chestList = [
		{ value: "W", label: "바깥방향", imgSrc: "img/bbti/simple/W.png" },
		{ value: "A", label: "정면방향", imgSrc: "img/bbti/simple/A.png" },
		{ value: "C", label: "아래방향", imgSrc: "img/bbti/simple/C.png" },
	];
	return(
		<div className="block-type">
			{
				chestList?.map((chest, idx) => (
					<div 
						key={idx}
						className={chestDistance === chest?.value ? "answer-block active" : "answer-block"}
						onClick={()=>setChestDistance(chest?.value)}
					>
						<img src={chest?.imgSrc} alt={chest?.label}/>
						<p>{chest?.label}</p>
					</div>
				))
			}
		</div>
	)
}

export default QuestionStepVersion;
