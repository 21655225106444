/***************************************************************************************
 * FileName      : MagazineContent.js
 * Description   : 매거진 내용 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  : 2024.07.05
 * Modifide Date : 2024.07.10 : CHM : 매거진 조회수 증가 api 연동
 *               :
 * Reference     : 미정
 ***************************************************************************************/
import { useEffect, useState } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import { getMe } from "../../../api/common/memberApi";
import { toggleMagazineLike } from "../../../api/services/magazine";
import {
	CustomProductItem,
	CustomSelectProductItem,
} from "../../admin/Magazine/Writing/ProductItemSample";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { getMagazine } from "../../../api/services/magazine";
import { GoShare } from "react-icons/go";
import { BsChatDots } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Cookies from "js-cookie";

function MagazineContent({ id, magazineTitle }) {
	const queryClient = useQueryClient();
	const { scrollYProgress } = useScroll();

	// 매거진 상세 데이터 조회
	const { data, isLoading } = useQuery(["magazine", id], () =>
		getMagazine({ id: id, viewCountKey: Cookies.get("_ga") })
	);

	// 회원 정보
	const [user, setUser] = useState({});

	// 해당 매거진 , 본인의 좋아요 상태
	const [isWished, setIsWished] = useState("");

	useEffect(() => {
		if (data && data.is_liked) {
			setIsWished(data?.is_liked);
		}
	}, [data]);

	// 매거진 좋아요
	const createLikeMutaion = useMutation(toggleMagazineLike, {
		onSuccess: (data) => {
			// console.log("좋아요성공",data)
			queryClient.invalidateQueries(["magazine", id]);
		},
		onError: (data) => {
			data?.response?.status === 401
				? alert("로그인이 필요한 기능입니다.")
				: alert(data?.request.responseText);
		},
	});

	useEffect(() => {
		getMe().then((data) => {
			const userData =
				data?.data === "guest"
					? { type: "guest", username: "" }
					: { type: data?.type, username: data?.username };

			setUser(userData);
		});
	}, []);

	// 매거진 좋아요 토글
	const toggleWish = () => {
		if (user.type === "guest") {
			const isConfirmed = window.confirm(
				"로그인이 필요한 서비스입니다. 로그인 페이지로 이동하시겠습니까?"
			);
			if (isConfirmed) {
				window.location.href = "/login";
			}
		} else {
			setIsWished(!isWished);

			const data = {
				magazine: id,
			};

			createLikeMutaion.mutate(data);
		}
	};

	// 공유하기
	const handleShare = async () => {
		if (navigator.share) {
			try {
				await navigator.share({
					title: `소곤핏 | ${magazineTitle}`,
					text: window.location.href,
					url: window.location.href,
				});
			} catch (error) {
				//   console.error('Error sharing:', error);
			}
		} else {
			try {
				await navigator.clipboard.writeText(window.location.href);
				alert("URL이 복사되었습니다.");
			} catch (error) {
				console.error("Failed to copy URL:", error);
			}
		}
	};

	// 1:1문의
	useEffect(() => {
		if (!window?.Kakao?.isInitialized()) {
			window?.Kakao?.init("0907d499eb79097f57e355d7a8831723");
		}
	}, []);
	const handleChat = () => {
		if (window?.Kakao?.Channel) {
			window?.Kakao.Channel.chat({
				channelPublicId: "_HUxbxkG",
			});
		} else {
			alert("카카오톡에서 소곤핏 채널을 통해 문의 부탁드립니다.");
		}
	};

	return (
		<>
			<motion.div
				className="progress-bar"
				style={{
					scaleX: scrollYProgress,
					opacity: scrollYProgress,
					zIndex: 1000,
					backgroundColor: "var(--main-color)",
					height: "4px",
					position: "fixed",
					top: 52,
					left: 0,
					right: 0,
					width: "100%",
					transformOrigin: "0 0",
					transition: "all 0.1s ease",
				}}
			/>
			<section
				className="magazine-content"
				style={{ margin: "64px auto 24px auto" }}
			>
				{data?.contents.map((item, index) => (
					<div key={index}>
						{item[0] === "C" ? (
							<div
								className="editor-content"
								dangerouslySetInnerHTML={{
									__html: item.slice(1),
								}}
							/>
						) : item[0] === "P" ? (
							<div className="product-wrap">
								<CustomSelectProductItem
									productList={item.substring(1)}
								/>
							</div>
						) : (
							<div className="product-wrap">
								<CustomProductItem
									type={
										item[0] === "N"
											? "new"
											: item[0] === "B"
												? "best"
												: item[0] === "R" && "random"
									}
									layout={item[1]}
								/>
							</div>
						)}
					</div>
				))}

				<div className="mlike-box">
					<OverlayTrigger
						overlay={
							<Tooltip id="tooltip-disabled">
								이 글이 마음에 드셨나요?
							</Tooltip>
						}
					>
						<button className="likeBtn" onClick={toggleWish}>
							{isWished ? (
								<FontAwesomeIcon
									icon={faSolidHeart}
									style={{ marginRight: "5px" }}
								/>
							) : (
								<FontAwesomeIcon
									icon={faHeart}
									style={{
										color: "#ddd",
										marginRight: "5px",
									}}
								/>
							)}
							{data?.like_count}
						</button>
					</OverlayTrigger>
				</div>

				<div className="magazine_d_footer">
					<OverlayTrigger
						overlay={<Tooltip id="tooltip-disabled">공유</Tooltip>}
					>
						<button className="pointer" onClick={handleShare}>
							<GoShare />
						</button>
					</OverlayTrigger>

					<OverlayTrigger
						overlay={
							<Tooltip id="tooltip-disabled">1:1 문의</Tooltip>
						}
					>
						<button className="pointer" onClick={handleChat}>
							<BsChatDots />
						</button>
					</OverlayTrigger>
				</div>
			</section>
		</>
	);
}

export default MagazineContent;
