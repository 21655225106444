import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

function Step7({answer, setAnswer}) {
    const answerList = [
        {num: 1, text: "바깥쪽", img: 'question5/2.png'},
        {num: 2, text: "정면", img: 'question5/3.png'},
        {num: 3, text: "아래쪽", img: 'question5/1.png'},
    ];

    return(
		<>
            <Row>
                {
                    answerList?.map((data) => (
                        <Col className='p-1'>
                            <div 
                                key={data?.num}
                                className={answer === data?.text ? "answer-btn active rounded-2 p-2" : "answer-btn rounded-2 p-2"}
                                onClick={()=>setAnswer(data?.text)}
                            >
                                <img src={`/img/bbti/question/${data?.img}`} className="w-100" alt="step7" />
                                <p style={{fontSize:"12px"}} className='pb-2'>{data?.text}</p>
                            </div>
                        </Col>
                    ))
                }
            </Row>
		</>
	)
}

export default Step7;