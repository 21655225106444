/***************************************************************************************
 * FileName      : MypageMobileHeader.js
 * Description   : 소곤핏 고객 > 마이페이지 > 모바일 네비게이션 헤더
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  :
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { Row, Col } from "react-bootstrap";
import { ReactComponent as SettingIcon } from "../../../assets/icon/setting.svg";
import { useNavigate } from "react-router-dom";

export default function MypageMobileHeader({ }) {
	const navigate = useNavigate();

	return (
		<div className="mobile-header">
			<div className="m-0 px-2 py-3 d-flex justify-content-between align-items-center">	
				<span className="mypage-mht">마이페이지</span>
				<SettingIcon
					onClick={() => navigate("/mypage/setting")}
					style={{
						fill: '#E85F43',
						width: '20px',
						height: '20px'
					}}
				/>
			</div>
		</div>
	);
	
		
	
}
