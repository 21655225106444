/***************************************************************************************
 * FileName      : bbti.js
 * Description   : 관리자페이지 테스트 통계 api
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  : 2023.12.15
 * Modifide Date : 2020.12.15 : CHM : 사이즈 테스트 결과 추가
 *               :
 * Reference     : 미정
 ***************************************************************************************/

// 관리자 > 테스트 통계
import { queryClient, useMutation, useQuery, prefetchQuery } from "react-query";
import { instance } from "../axiosConfig.js";

const PAGE_SIZE = 10;

// 관리자 테스트 > BBTI 통계 조회
export const useGetbbtiStatistic = () => {
	return useQuery(["bbti"], async () => {
		let url = "research/statistic";
		const response = await instance.get(url);
		return response.data;
	});
};

// 관리자 테스트 > 기본통계 > 참여 많은 순
export const useGetparticipant = () => {
	return useQuery(["participant"], async () => {
		let url = "research/participant/total/";
		const response = await instance.get(url);
		return response.data;
	});
};

// 관리자 테스트 bbti 전체 통계
export const useGetResearchStatistic = (target) => {
	return useQuery(["bbti"], async () => {
		let url = "/research/bbti/statistics";

		const response = await instance.get(url);
		return response?.data;
	});
};

// 관리자 테스트 사이즈테스트 결과 통합
export const useGetsizeStatistic = (target) => {
	// 2023.12.15 : CHM : useQuery bbti -> size 수정
	return useQuery(["size", target], async () => {
		let url = "/research/size/statistic/";

		if (target === "회원") {
			url = url + "?target=회원";
		}

		const response = await instance.get(url);
		return response.data;
	});
};
