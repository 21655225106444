/***************************************************************************************
* FileName      : BrandList.js
* Description   : 소곤핏 관리자 > 브랜드 > 전체 브랜드 목록
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Link } from 'react-router-dom';
import { useBrandsQuery , handleBrandSearch } from '../../../api/admin/brandApi.js';
import { Modal , Button } from 'react-bootstrap';

import React, { useState, useEffect } from 'react';
import BrandRow from './BrandList/BrandRow.js';
import AddBrandModal from './BrandList/AddBrandModal.js';

import Pagination from '../common/Pagination'


function BrandList()
{
    const { data, error, isFetching, page, setPage } = useBrandsQuery();
 
    // 검색
    const [searchAuth, setSearchAuth] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);
    
    const [searchUrl, setSearchUrl] = useState('');

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };
    
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

 
    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleBrandSearch({   
            searchAuth, 
            setSearchUrl,
            setSearchResults 
        });

        setSearchConfirm('true')
    }


    if (error) return <div>Error loading Brands</div>;
    if (!data) return <div>Loading Brands...</div>;

    return (
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <ul className="side-menu">
                            <Link to='/admin/brand'>
                                <li className='side-item active'>
                                    전체브랜드
                                </li>
                            </Link> 
                            <Link to='/admin/brand/all'>
                                <li className='side-item'>
                                    전체상품
                                </li>
                            </Link>    
                        </ul>
                    </div>

                    <div className='col-md-12'>
                        <div className="box search-wrap mb-4 " >
                            <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                                <option value="id">아이디</option>
                                <option value="name">이름</option>
                                <option value="email">이메일</option>
                                <option value="phone">휴대폰번호</option>
                                <option value="address">주소</option>
                            </select>
                         
                            <div className="search-field">
                                <i className='xi-search'></i>
                            
                                <input type="text" 
                                        value={searchAuth}
                                        onChange={(e) => setSearchAuth(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                onSearch();
                                            }
                                        }}
                                        placeholder="Search"
                                />
                            </div>
                        </div>
                        <div className="box">
                            <div className='tit'>
                                <span className='group-title'>전체({data && data.totalCnt})</span>
                                <button onClick={handleShowModal}>브랜드 등록</button>
                            </div>
                            <table className="admin_table">
                                <thead>
                                    <th>ID</th>
                                    <th>브랜드 명</th>
                                    <th>로고</th>
                                    <th>사이트링크</th>
                                    <th>대표자</th>
                                    <th>연락처</th>
                                    <th>등록상품 수</th>
                                    <th>조회 수</th>
                                    <th>공개/비공개</th>
                                    <th>수정</th>
                                    <th>삭제</th>
                                    <th>코드</th>
                                </thead>
                                <tbody>
                                    {searchResults?.data?.length > 0 ? (
                                        searchResults.data.map((brand) => (
                                            <BrandRow 
                                                key={brand?.id}
                                                brand={brand}
                                        />
                                        ))
                                        ) : data && data.data && data.data.length > 0 ? (
                                            data.data.map((brand) => (
                                                <BrandRow 
                                                    key={brand?.id}
                                                    brand={brand}
                                                />
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="17">결과가 없습니다.</td>
                                            </tr>
                                    )}
                                  
                                </tbody>
                            </table>

                            {/* 페이지내비게이션 */}
                            <div className='pagination-wrap'>   
                                <Pagination
                                    count={data?.totalCnt || 0}
                                    page={page}
                                    setPage={setPage}
                                    handlePrevPage={handlePrevPage}
                                    handleNextPage={handleNextPage}
                                    />
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </section>

        {/* 새로운 브랜드 등록 모달 */}
        <AddBrandModal
            show={showModal}
            onHide={handleCloseModal}
        />


        </>
    )
}

export default BrandList 