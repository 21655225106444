/***************************************************************************************
* FileName      : Footer.js
* Description   : 소곤핏 Footer
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Row,Col } from "react-bootstrap";
function Footer(){

    return (
        <>
        <div className="footer mt-2 mb-5 px-2 py-3" style={{backgroundColor: '#F5F5F5', fontSize: '12px'}}>
            <Row>
                <Col>
                    <p className="px-1 py-2"><strong>(주)비웨어랩</strong></p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <p className="p-1"><b>대표이사 </b> 김희혜</p>
                    <p className="p-1"><b>주소 </b> 전라남도 목포시 장미로 25 1층</p>
                    <p className="p-1"><b>문의전화 </b> 061-242-4426</p>
                </Col>
                <Col xs={12}>
                    <p className="p-1"><b>사업자등록번호 </b> 267-81-03180</p>
                    <p className="p-1"><b>통신판매업 신고번호 </b> 2023-전남목포-0511</p>
                    <p className="p-1"><b>호스팅사업자 </b> Amazon Web Services, Inc.</p>
                </Col>
            </Row>
            <hr className="my-3 p-0"/>
            <Row>
                <Col>
                    <p className="p-1"><span className="fw-bold pointer" onClick={()=>window.open('http://www.ftc.go.kr/bizCommPop.do?wrkr_no=2678103180')}>사업자 정보 조회</span> |  <span className="fw-bold pointer" onClick={()=>window.open('https://suave-cloudberry-17e.notion.site/36de9191557a416f93b016dcc84e3b37')}>이용약관</span>  |  <span className="fw-bold pointer" onClick={()=>window.open('https://suave-cloudberry-17e.notion.site/9fb9bbd74fc04e56b33981394226f4ce')}>개인정보처리방침</span></p>
                    <p className="p-1">(주)비웨어랩은 통신판매중개자로서 통신판매의 당사자가 아닙니다. 따라서, 등록된 상품, 거래정보 및 거래에 대하여 (주)비웨어랩은 어떠한 책임도 지지 않습니다.</p>
                    <p className="p-1">Copyright &copy; B wearlab inc. All rights reserved.</p>
                </Col>
            </Row>
        </div>
        </>
    )
}

export default Footer