import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHome,
	faUser,
	faTags,
	faT,
	faPen,
	faHashtag,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as HomeIcon } from "./assets/icon/home.svg";
import { ReactComponent as AnalysisIcon } from "./assets/icon/analysis.svg";
import { ReactComponent as LogIcon } from "./assets/icon/log.svg";
import { ReactComponent as MypageIcon } from "./assets/icon/mypage.svg";

function Gnb() {
	return (
		<>
			<div className="gnb">
				<Link
					to="/"
					onClick={() => {
						const event = {
							key: "Navbar_Click",
							properties: {
								page: "home",
							},
						};
						window.hackleClient.track(event);
					}}
				>
					<HomeIcon />
					Home
				</Link>
				<Link
					// 2024.11.19 : CHM : bbti탭 클릭 시 바로 bbtiIntro로 이동하도록 수정
					// to="/bbti"
					to="/bbtiIntro"
					onClick={() => {
						const event = {
							key: "Navbar_Click",
							properties: {
								page: "분석",
							},
						};
						window.hackleClient.track(event);
					}}
				>
					<AnalysisIcon />
					분석
				</Link>
				<Link
					to="/magazine"
					onClick={() => {
						const event = {
							key: "Navbar_Click",
							properties: {
								page: "소곤로그",
							},
						};
						window.hackleClient.track(event);
					}}
				>
					<LogIcon />
					소곤로그
				</Link>
				<Link
					to="/mypage"
					onClick={() => {
						const event = {
							key: "Navbar_Click",
							properties: {
								page: "Mypage",
							},
						};
						window.hackleClient.track(event);
					}}
				>
					<MypageIcon />
					Mypage
				</Link>
			</div>
		</>
	);
}

export default Gnb;
