// 고객 > 상품 관리
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState , useEffect } from 'react';
// import SkeletonComponent from '../components/service/SkeletonComponent';
import SkeletonComponent from '../../components/service/SkeletonComponent';
import { useQueryClient } from 'react-query';
import { instance } from '../axiosConfig.js';
const PAGE_SIZE = 10;

// 상품 등록 > 브랜드 조회 
export const useGetBrands = () => {
    return useQuery('brands', async () => {
      const response = await instance.get(`brand/customer?page_size=all`);
      return response.data;
    });
};

// 상품 조회 무한스크롤 (고객용)
export const fetchData = (brandId, filteringBbti, page , ordering) => {
  let url = brandId === "all"
    ? `/product/customer?page=${page}`
    : `/product/customer?brand=${brandId}&page=${page}`;

  if (filteringBbti !== undefined) {
    url += `&bbti=${filteringBbti}`;
  }

  if(ordering !== undefined) {
    url += `&ordering=${ordering}`;
  }

  return instance.get(url)
    .then(res => res.data);
};


// 상품 조회 (고객용)
const fetchProduct = (id, page, pageSize) => {
  let url;
  if (id === "all") {
      url = `/product/customer?page=${page}&page_size=${pageSize}`;
  } else {
      url = `/product/customer?brand=${id}&page=${page}&page_size=${pageSize}`;
  }

  return instance.get(url)
      .then(res => res.data);
};

export const useProductsQuery = (id) => {

  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [toggle, setToggle] = useState(false);

  useEffect(()=>{
      window.scrollTo(0, 0);
      const nextPage = page + 1;
      queryClient.prefetchQuery([`Cprodcut${id}`, id , nextPage],
        () => fetchProduct(id , nextPage)
      );
  },[page , queryClient])


  useEffect(() => {
    setPage(1);
  }, [id]);

  const { data, error, isFetching , isLoading } = useQuery(
    [`Cprodcut${id}`, id , page], () => fetchProduct(id , page), 
    {
      staleTime:5000,
      keepPreviousData:true
    },
    {
    onSuccess: (data) => {
      setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
   
      if (page < totalPages) {
        prefetchQuery(['Cprodcut', page + 1], () => fetchProduct(page + 1));
      }
    }
  });
  
  if (isLoading) {
    return <SkeletonComponent />; 
  }

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    totalPages,
    toggle,
    setToggle,
  };
};


// 고객 > 분석 결과 > bbti에 맞는 추천 상품 (고객용)
export const fetchRProductData = ({bbti, page, ordering, pageSize}) => {
  let url = `/product/customer?ordering=${ordering}&page_size=${pageSize}`
    
  if (page) url += `&page=${page}`;
  if (bbti) url += `&bbti=${bbti}`;

  return instance.get(url)
    .then(res => res.data);
};
