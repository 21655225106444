import React, { useEffect } from "react";
import { getKakaoCallback } from "../../api/services/kakaoLogin";
import { setCookie } from "../../api/cookieUtils";
import { LoadingSpinner } from "../admin/Component/LoadingSpinner";
import { PropertyOperationsBuilder } from "@hackler/react-sdk";

function kakaoCallback() {
	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get("code");

	getKakaoCallback(code)
		.then((res) => {
			console.log(res?.data?.access);
			if (res?.data?.user_username) {
				const operations = new PropertyOperationsBuilder()
					.set("role", res?.data?.user_role)
					.set("bbti", res?.data?.user_bbti)
					.set("type", res?.data?.user_type)
					.build();
				window.hackleClient.setUserId(res?.data?.user_username); // 핵클 로그인 ID 설정
				window.hackleClient.updateUserProperties(operations); // 로그인 정보 싱크

				window.hackleClient.track({
					key: "complete_signin",
					properties: {
						로그인수단: "kakao", // kakao 아이디로 로그인한 회원
					},
				});
			}
			setCookie("access", res?.data?.access); // Save Access Token
			setCookie("refresh", res?.data?.refresh); // Save Refresh Token

			if (/flutter-app/i.test(navigator.userAgent)) {
				//모바일 앱인 경우 로그인 함수 전달
				window.appLogin.postMessage("run");
			}

			window.location.href = "/";
		})
		.catch((error) => {
			// error?.response?.data?.data;
			alert("카카오 로그인 실패");
			window.location.href = "/";
		});

	return (
		<>
			{/* <p className='my-5'>카카오 로그인 요청중 ...{urlParams.get('code')}</p> */}
			<div className="spinner-container">
				<LoadingSpinner />
			</div>
		</>
	);
}

export default kakaoCallback;
