/***************************************************************************************
 * FileName      : BbtiLabelModal.js
 * Description   : 소곤핏 관리자 > 매거진 > 매거진관리 > BBTI 라벨 추가 모달 컴포넌트
 * Company       : (주)엘리그
 * Author        : 최현민
 * Created Date  : 2024.07.17
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { getMagazine } from "../../../../api/admin/magazine";
import { bbtiList } from "../../../../api/services/bodytypeApi";
import { updateMagazine } from "../../../../api/admin/magazine";

export const BbtiLabelModal = ({ id, show, onHide, type }) => {
	const isEdit = type === "update";
	const [bbtiData, setBbtiData] = useState([]);
	const [selectedBbti, setSelectedBbti] = useState([]);

	useEffect(() => {
		if (show && id !== undefined) {
			if (isEdit) {
				getMagazine(id).then((res) => {
					console.log("res: ", res?.bbti);
					setSelectedBbti(res?.bbti?.map((item) => item?.type));
				});
			}
			console.log("id: ", id);
			bbtiList().then((res) => {
				setBbtiData(res?.data?.data);
			});
		}
	}, [show, id]);

	const handleBbtiCheckboxChange = (bbti) => {
		const value = bbti;
		setSelectedBbti((prevSelectedValues) => {
			if (prevSelectedValues.includes(value)) {
				return prevSelectedValues.filter((item) => item !== value);
			} else {
				return [...prevSelectedValues, value];
			}
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(selectedBbti);
		updateMagazine(id, { bbti: selectedBbti })
			.then(() => {
				alert("BBTI 등록이 완료되었습니다.");
				onHide();
			})
			.catch((error) => {
				console.error("BBTI 등록 중 에러 발생:", error);
			});
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>
					{isEdit ? "BBTI 라벨 수정" : "BBTI 라벨 등록"}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Group controlId="cupSize" className="mb-3">
						<Form.Label>BBTI</Form.Label>
						<div className="custom-checkbox-group">
							{bbtiData.map((item) => (
								<Form.Check
									key={item?.id}
									type="checkbox"
									id={item?.id}
									label={item?.type}
									className="mb-2 custom-checkbox"
									custom
									onChange={() =>
										handleBbtiCheckboxChange(item?.type)
									}
									checked={selectedBbti?.includes(item?.type)}
								/>
							))}
						</div>
					</Form.Group>
					<Button variant="primary" type="submit">
						{isEdit ? "수정" : "등록"}
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default BbtiLabelModal;
