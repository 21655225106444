/***************************************************************************************
 * FileName      : MagazineMain.js
 * Description   : 소곤핏 메인페이지 대표글 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { extractYearMonthDay2 } from "../../admin/common/extractDateUtils";
import { useNavigate } from "react-router-dom";

function MagazineMain({ content, nowPage }) {
	const navigate = useNavigate();
	const handleClick = () => {
		// 소곤로그 클릭이벤트
		window.hackleClient.track({
			key: "clicked_sogonlog",
			properties: {
				클릭위치: nowPage ? nowPage : "",
				타이틀: content?.title,
				글작성자: content?.user?.username,
				카테고리: content?.category?.name,
				레이아웃: "대표글",
			},
		});
		navigate(`/magazine/${content?.id}`);
	};
	const title = content?.title;
	const maxLength = 30;
	const shortenedTitle =
		title && title.length > maxLength
			? `${title.substring(0, maxLength)}...`
			: title;

	return (
		<div className="magazine-main pointer" onClick={() => handleClick()}>
			<div className="thumbnail">
				{content?.main_image ? (
					<img src={content?.main_image} alt="" />
				) : (
					<img
						src={process.env.PUBLIC_URL + "img/sample.png"}
						alt=""
					/>
				)}
				{/* <img src={process.env.PUBLIC_URL + 'img/sample.png'} alt="" /> */}
				{/* <img src={content?.main_image} alt="" /> */}
				{/* 썸네일 이미지 위 그라데이션 효과 추가 */}
				<div class="thumbnail-overlay"></div>
			</div>
			<div className="magazine-main-info">
				<div className="infoBox">
					<span className="tagBox">{content?.category?.name}</span>
					<span>{extractYearMonthDay2(content?.created_at)}</span>
					<span>{content?.user?.username}</span>
				</div>
				<p className="mainCTitle">{shortenedTitle}</p>
			</div>
		</div>
	);
}

export default MagazineMain;
