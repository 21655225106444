/***************************************************************************************
 * FileName      : ProductList.js
 * Description   : 소곤핏고객 > 분석 결과 페이지 > bbti 추천 상품 리스트 (체형에 맞는 상품 리스트)
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.06.10
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { LoadingSpinner } from "../../../admin/Component/LoadingSpinner";
import ProductItem from "../../Main/ProductItem";
import { fetchRProductData } from "../../../../api/services/product";

function ProductList({ userBodyType }) {
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
		["productdata", userBodyType],
		({ pageParam = page }) =>
			fetchRProductData({
				bbti: userBodyType,
				ordering: "random",
				pageSize: "6",
				page: pageParam,
			}),
		{
			getNextPageParam: (lastPage) => {
				return lastPage.nextPage;
			},
			onSuccess: (fetchedData) => {
				setTotalPages(fetchedData.pages[0].pageCnt);
			},
		}
	);

	// 더보기 클릭 시
	const handleLoadMore = () => {
		setPage((prevPage) => prevPage + 1); // 페이지 증가
		fetchNextPage(); // 다음 페이지의 데이터 가져오기
	};

	return (
		<>
			<div className="product-wrap" style={{ paddingBottom: "64px" }}>
				{isLoading ? (
					<div className="spinner-container">
						<LoadingSpinner />
					</div>
				) : (
					<>
						{data.pages.reduce(
							(total, page) => total + page.data.length,
							0
						) === 0 ? (
							<div className="no-data-message">
								해당 상품이 없습니다.
							</div>
						) : (
							<>
								{data.pages.map((page, pageIndex) => (
									<React.Fragment key={pageIndex}>
										{page?.data?.map((item) => (
											<ProductItem
												item={item}
												key={item.id}
												clickArea={"분석"}
											/>
										))}
									</React.Fragment>
								))}
								{hasNextPage && (
									<button
										className="moreBtn"
										onClick={handleLoadMore}
									>
										더보기 ({page}/{totalPages})
									</button>
								)}
							</>
						)}
					</>
				)}
			</div>
		</>
	);
}

export default ProductList;
