/***************************************************************************************
* FileName      : layout.js
* Description   : 소곤핏 관리자 > 메인 > 매거진영역관리, 상품영역관리 페이지 API 
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.03.28
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { instance } from '../axiosConfig.js';

// 메인페이지 매거진 레이아웃 조회(관리자용)
export const getLayout = () => {
    return instance.get('/layout/main?page_size=all').then(response => response.data.data)
};

// 메인페이지 매거진 레이아웃 구분 조회(관리자용)
export const getLayoutTypes = () => {
    return instance.get('/layout/main/type')
};

// 메인페이지 매거진 레이아웃 생성(관리자용)
export const createLayout = (title, type, x, y, main, hidden) => {
    return instance.post('/layout/main', {
        title: title,
        type: type,
        x_layout: x,
        y_layout: y,
        main_content_exist: main,
        hidden: hidden
    })
};

// 메인페이지 매거진 레이아웃 수정(관리자용)
export const updateLayout = (id, title, type, x, y, main, hidden) => {
    return instance.patch(`/layout/main/${id}`, {
        title: title,
        type: type,
        x_layout: x,
        y_layout: y,
        main_content_exist: main,
        hidden: hidden
    })
};

// 메인페이지 매거진 레이아웃 순서 변경(관리자용)
export const newLayoutPriority = (priority) => {
    return instance.patch('/layout/main/priority',{
        priority: priority
    })
};

// 메인페이지 매거진 레이아웃 삭제(관리자용)
export const deleteLayout = (id) => {
    return instance.delete(`/layout/main/${id}`)
}

/***************************************************************************************
 * 여기부터 상품 레이아웃
* ***************************************************************************************/

// 메인페이지 상품 레이아웃 조회(관리자용)
export const getProductLayout = () => {
    return instance.get('/layout/product?page_size=all').then(response => response.data.data)
};

// 메인페이지 상품 레이아웃 구분 조회(관리자용)
export const getProductLayoutTypes = () => {
    return instance.get('/layout/product/type')
};

// 메인페이지 상품 레이아웃 생성(관리자용)
export const createProductLayout = (title, type, x, y,  hidden) => {
    return instance.post('/layout/product', {
        title: title,
        type: type,
        x_layout: x,
        y_layout: y,
        hidden: hidden
    })
};

// 메인페이지 상품 레이아웃 수정(관리자용)
export const updateProductLayout = (id, title, type, x, y, hidden) => {
    return instance.patch(`/layout/product/${id}`, {
        title: title,
        type: type,
        x_layout: x,
        y_layout: y,
        hidden: hidden
    })
};

// 메인페이지 상품 레이아웃 순서 변경(관리자용)
export const newProductLayoutPriority = (priority) => {
    return instance.patch('/layout/product/priority',{
        priority: priority
    })
};

// 메인페이지 매거진 레이아웃 삭제(관리자용)
export const deleteProductLayout = (id) => {
    return instance.delete(`/layout/product/${id}`)
}