/***************************************************************************************
* FileName      : MagazineLogBoard.js
* Description   : 소곤핏 관리자 > 매거진 > log 조회 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Link , useParams } from 'react-router-dom';

import Pagination from "../common/Pagination.js";
import ProductLogRow from "./ProductLogBoard/ProductLogRow.js";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';


import { getMe } from "../../../api/common/memberApi.js";

import { useGetBrands } from '../../../api/admin/brandApi.js';
import { useProductLog } from '../../../api/admin/product.js';

function ProductLogBoard()
{    
    // id 추출
    const { BrandId } = useParams();

    const { data, error, isFetching:brandisFetching} = useGetBrands();
  

    const { data:ProductData, error:ProductError, isFetching, page, setPage } = useProductLog(BrandId);
    
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const [role, setRole] = useState(null);


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    useEffect(()=>{
        getMe()
        .then(data => {
            setRole(data.role);
        })
        .catch(error => {
          console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
        });

    }, [])



    return (
        <>
         <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <ul className="side-menu">
                            <Link to='/admin/productlog/all'>
                                <li className={`side-item ${BrandId === "all" ? 'active' : ''}`}>
                                    전체
                                </li>
                            </Link> 
                            
                            {
                                data && data.data && data.data.length > 0 ? (
                                    <>
                                    {data.data.map((brand, index) => (
                                        <Link to={`/admin/productlog/${brand.id}`} key={index}>
                                            <li className={`side-item ${BrandId === brand.id.toString() ? 'active' : ''}`}>
                                                {brand.name}
                                            
                                            </li>
                                        </Link>
                                    ))}
                                    </>
                                ) : null
                            }
                        </ul>
                    </div>
                        
                       
                    <div className="col-md-12">
                        <div className="box">
                            <div className="tit">
                                <span className="group-title">
                                    전체({ProductData?.totalCnt ? ProductData?.totalCnt : 0 })
                                </span>
                            </div>
                            
                            <table className="admin_table">
                                <colgroup>
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '10%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '5%' }} />
                                    <col style={{ width: '10%' }} /> 
                                </colgroup>
                                <thead>
                                    <th>ID</th>
                                    <th>상품명</th>
                                    <th>상품이미지</th>
                                    <th>구매링크</th>
                                    <th>상품가격</th>
                                    <th>판매가격</th>
                                    <th>브랜드</th>
                                    <th>BBTI</th>
                                    <th>라벨링</th>
                                    <th>수정/등록</th>
                                    <th>승인상태</th>
                                    <th>
                                    {role === null ? "승인/거절" : "재요청"}
                                    </th>
                                </thead>
                                <tbody>
                                    {ProductData && ProductData.data && ProductData.data.length > 0 ? 
                                        ProductData.data.map((product) => (
                                            <ProductLogRow 
                                                key={product?.id}
                                                product={product}
                                                role={role}
                                            />
                                        )) :
                                        <tr>
                                            <td colSpan="10">데이터가 없습니다.</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            {/* 페이지내비게이션 */}
                            <div className='pagination-wrap'>   
                                <Pagination
                                    count={ProductData?.totalCnt || 0}
                                    page={page}
                                    setPage={setPage}
                                    handlePrevPage={handlePrevPage}
                                    handleNextPage={handleNextPage}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        </>
    )
}



export default ProductLogBoard 