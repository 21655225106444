import "./css/reset.css";
import "./css/styles.css";
import "./App.css";
import "./css/variables.css";
import "./css/responsive.css";
import "./css/editor.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
// 사용자 페이지
import Layout from "./Layout.js";
import Main from "./routes/service/Main.js";
import Login from "./routes/service/Login.js";
import Product from "./routes/service/Product2.js";
import Post from "./routes/service/Post.js";
import PostWrite from "./routes/service/PostWrite.js";
import PostDetails from "./routes/service/PostDetails.js";
import Magazine from "./routes/service/Magazine.js";
import Magazine2 from "./routes/service/Magazine2.js";
import MagazineDetail from "./routes/service/MagazineDetail.js";
import BbtiTest from "./routes/service/Research/BodyTypeTest.js";
import BodyTypeIntro from "./routes/service/Research/BodyTypeIntro.js";
import Mypage from "./routes/service/Mypage.js";
import MypageV2 from "./routes/service/MypageV2.js"

// import Mypage2 from './routes/service/Mypage2.js';
import EditProfile from "./routes/service/EditProfile.js";
import Setting from "./routes/service/Setting.js";
import MyPosts from "./routes/service/MyPosts.js";
import MyPostComments from "./routes/service/MyPostComments.js";
import MyLikes from "./routes/service/MyLikes.js";
import MyMagazineLikes from "./routes/service/MyMagazineLikes.js";
import Signup from "./routes/service/Signup.js";
import Signup2 from "./routes/service/Research/SimpleTestQuestion.js";
import SimpleTest from "./routes/service/Research/SimpleTest.js";
import SimpleTestIntro from "./routes/service/Research/SimpleTestIntro.js";
import SimpleTestResult from "./routes/service/Research/SimpleTestResult.js";
import BodyTypeResult from "./routes/service/Research/BodyTypeResult2.js";

import KakaoCallback from "./routes/service/kakaoCallback.js";
import KakaoLogout from "./routes/service/kakaoLogout.js";

// 관리자 페이지
import ManagerLayout from "./components/ManagerLayout/ManagerLayout";
import AdminDashboard from "./routes/admin/AdminDashboard";
import AdmingLoading from "./routes/admin/AdminLoding.js";
import AdminLogin from "./routes/admin/AdminLogin.js";
import BrandManagerSignup from "./routes/admin/BrandManagerSignup.js";
import ContentPublisherSignup from "./routes/admin/ContentPublisherSignup.js";

import AdminManagement from "./routes/admin/Users/AdminManagement.js";
import UserManagement from "./routes/admin/Users/UserManagement.js";

import BannerManagement from "./routes/admin/MainManagement/BannerManagement.js";
import MagazineManagement from "./routes/admin/MainManagement/MagazineManagement.js";
import ProductManagement from "./routes/admin/MainManagement/ProductManagement.js";

import MagazineBoard from "./routes/admin/Magazine/MagazineBoard.js";
import MagazineWriting from "./routes/admin/Magazine/Writing.js";
import MagazineEdit from "./routes/admin/Magazine/Edit.js";
import MagazineReEdit from "./routes/admin/Magazine/Edit2.js";
import MagazineCategoryOrdering from "./routes/admin/Magazine/CategoryOrdering.js";

import MagazineLogBoard from "./routes/admin/Magazine/MagazineLogBoard.js";

import BrandList from "./routes/admin/Brand/BrandList.js";
import AllProduct from "./routes/admin/Brand/AllProduct.js";
import BrandOrdering from "./routes/admin/Brand/BrandOrdering.js";

import BrandProductList from "./routes/admin/Prodcut/BrandProductList.js";

import ProductLogBoard from "./routes/admin/Prodcut/ProductLogBoard.js";

import PostList from "./routes/admin/Post/PostList.js";
import PostReportList from "./routes/admin/Report/PostReportList.js";
import ReplyReportList from "./routes/admin/Report/ReplyReportList.js";

import Test from "./routes/admin/Test/Test.js";
import { Routes, Route } from "react-router-dom";
import NotFound from "./routes/service/NotFound.js";
import AdminNotFound from "./routes/admin/NotFound.js";
import { Helmet } from "react-helmet-async";
import SimpleTestQuestion from "./routes/service/Research/SimpleTestQuestion.js";

import FindId from "./routes/service/FindId.js";
import FindPassword from "./routes/service/FindPassword.js";
import VerifyEmail from "./routes/service/Find/VerifyEmail.js";
import VerifyEmailPw from "./routes/service/Find/VerifyEmailPw.js";

function App() {
	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit</title>
				<meta name="description" content="속옷 전문 여성 매거진" />
				<meta
					name="keywords"
					content="여자속옷사이즈, 처진가슴브라, 가슴사이즈, 노와이어브라, 빅사이즈브라, 브라사이즈, 속옷브랜드, 큰컵브라, 와이어브라, 브라컵사이즈"
				/>
				<meta property="og:site_name" content="소곤핏" />
				<meta
					property="og:title"
					content="소곤핏 - 내 몸에 딱맞는 속옷 추천 서비스"
				/>
				<meta
					property="og:image"
					content="/img/sogonfit_thumbnail.jpeg"
				/>
			</Helmet>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route path="" element={<Main />} />
					<Route path="login" element={<Login />} />
					<Route
						path="login/kakao/callback"
						element={<KakaoCallback />}
					/>
					<Route
						path="logout/kakao/callback"
						element={<KakaoLogout />}
					/>

					<Route path="product" element={<Product />} />

					<Route path="magazine" element={<Magazine />} />
					{/* <Route path="magazine2" element={<Magazine2 />} /> */}

					<Route path="magazine/:id" element={<MagazineDetail />} />
					<Route path="*" element={<NotFound />} />
					<Route path="signup" element={<Signup />} />
					<Route path="signup2" element={<Signup2 />} />
					{/* 2024.11.21 : CHM : 기존 체형분석테스트 임시 활성화 */}
					<Route path="bbtiInt" element={<BodyTypeIntro />} />
					<Route path="bbtis" element={<BbtiTest/>}/>
					<Route path="BodyTypeResult/:bbti" element={<BodyTypeResult />} />
					<Route path="bbti" element={<SimpleTest />} />
					<Route path="bbtiResult" element={<SimpleTestResult />} />
					<Route path="bbtiIntro" element={<SimpleTestIntro />} />
					<Route
						path="bbtiQuestion"
						element={<SimpleTestQuestion />}
					/>

					<Route path="findId" element={<FindId />} />
					<Route path="findPassword" element={<FindPassword />} />

					<Route
						path="member/verify-email-username"
						element={<VerifyEmail />}
					/>
					<Route
						path="member/verify-email-password"
						element={<VerifyEmailPw />}
					/>
				</Route>

				<Route path="/post" element={<Layout />}>
					<Route path="" element={<Post />} />
					<Route path=":postId" element={<PostDetails />} />
					<Route path="write" element={<PostWrite />} />
				</Route>

				<Route path="mypage" element={<Layout />}>
					{/* <Route path="" element={<Mypage />} /> */}
					<Route path="" element={<MypageV2 />} />
					<Route path="setting" element={<Setting />} />
					<Route path="EditProfile" element={<EditProfile />} />
					<Route path="MyPosts" element={<MyPosts />} />
					<Route path="Comments" element={<MyPostComments />} />
					<Route path="Likes" element={<MyLikes />} />
					<Route path="MLikes" element={<MyMagazineLikes />} />

				</Route>

				<Route path="/admin" element={<ManagerLayout />}>
					<Route path="" element={<AdminDashboard />} />
					<Route path="loading" element={<AdmingLoading />} />
					<Route path="*" element={<AdminNotFound />} />
				</Route>

				<Route path="/admin/login" element={<AdminLogin />} />
				<Route path="/admin/signup" element={<BrandManagerSignup />} />
				<Route
					path="/admin/signupcp"
					element={<ContentPublisherSignup />}
				/>

				{/* 관리자 페이지 - 회원관리  */}
				<Route path="/admin/users" element={<ManagerLayout />}>
					<Route path="" element={<AdminManagement />} />
					<Route path="basic" element={<UserManagement />} />
				</Route>

				{/* 관리자 페이지 - 매거진 관리  */}
				<Route path="/admin/magazine" element={<ManagerLayout />}>
					<Route
						path=":magazineCategoryId"
						element={<MagazineBoard />}
					/>
					<Route
						path="writing/:categoryId"
						element={<MagazineWriting />}
					/>
					<Route path="edit/:magazineId" element={<MagazineEdit />} />
				</Route>

				{/* 관리자 페이지 - 매거진 log 관리  */}
				<Route path="/admin/magazinelog" element={<ManagerLayout />}>
					<Route
						path=":magazineCategoryId"
						element={<MagazineLogBoard />}
					/>
					<Route
						path="edit/:Id/:magazineId"
						element={<MagazineReEdit />}
					/>
				</Route>

				<Route path="/admin/category" element={<ManagerLayout />}>
					<Route
						path="ordering"
						element={<MagazineCategoryOrdering />}
					/>
				</Route>

				{/* 관리자 페이지 - 상품 log 관리  */}
				<Route path="/admin/productlog" element={<ManagerLayout />}>
					<Route path=":BrandId" element={<ProductLogBoard />} />
					{/* <Route path="edit/:Id/:magazineId/:role" element={<MagazineReEdit/> } />  */}
				</Route>

				{/* 관리자 페이지 - 메인페이지 관리  */}
				<Route path="/admin/mainManagement" element={<ManagerLayout />}>
					<Route path="banner" element={<BannerManagement />} />
					<Route path="magazine" element={<MagazineManagement />} />
					<Route path="product" element={<ProductManagement />} />
				</Route>

				{/* 관리자 페이지 - 테스트 통계 관리  */}
				<Route path="/admin/test" element={<ManagerLayout />}>
					<Route path="" element={<Test />} />
				</Route>

				{/* 관리자 페이지 - 신고관리  */}
				<Route path="/admin/report" element={<ManagerLayout />}>
					<Route path="" element={<PostReportList />} />
					<Route path="reply" element={<ReplyReportList />} />
					{/* <Route path="basic" element={<UserManagement/> } /> */}
				</Route>

				{/* 관리자 페이지 - 브랜드 관리  */}
				<Route path="/admin/brand" element={<ManagerLayout />}>
					<Route path="" element={<BrandList />} />
					<Route path="all" element={<AllProduct />} />
				</Route>
				<Route path="/admin/brandOrder" element={<ManagerLayout />}>
					<Route path="ordering" element={<BrandOrdering />} />
				</Route>

				{/* BM 페이지 - 상품 관리  */}
				<Route path="/admin/bmproduct" element={<ManagerLayout />}>
					<Route path=":BrandName" element={<BrandProductList />} />
					{/* <Route path="all" element={<AllProduct/> } /> */}
				</Route>

				{/* 관리자 페이지 - 소곤톡  */}
				<Route path="/admin/post" element={<ManagerLayout />}>
					<Route path="" element={<PostList />} />
				</Route>

				<Route path="/admin/report" element={<ManagerLayout />}>
					<Route path="post" element={<PostReportList />} />
					<Route path="report" element={<ReplyReportList />} />
				</Route>
			</Routes>
		</>
	);
}

export default App;
