/***************************************************************************************
* FileName      : AdminManagement.js
* Description   : 소곤핏 user 관리자 관리 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Link } from 'react-router-dom';
import { useAuthListQuery , handleSearch } from "../../../api/admin/memberApi";
import React, { useState , useEffect } from 'react';

import UserRow from "./UserRow";

import Pagination from '../common/Pagination'

// import SearchPagination from "../common/SearchPagination";

function AdminManagement()
{
    
    const { data, error, isFetching, page, setPage, toggle, setToggle } = useAuthListQuery("M");
  
    // 검색
    const [searchAuth, setSearchAuth] = useState('');
    const [searchOption, setSearchOption] = useState('all');
    const [searchResults, setSearchResults] = useState([]);
    const [searchConfirm, setSearchConfirm] = useState(false);
    
    const [searchUrl, setSearchUrl] = useState('');

    const updateSearchResults = (newResults) => {
        setSearchResults(newResults);
    };


    const handlePrevPage = () => {
        setPage(prevPage => prevPage - 1);
    };
    
    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    // 검색버튼 클릭 시 
    const onSearch = () => {
        handleSearch({   
            searchAuth, 
            setSearchUrl,
            setSearchResults 
        });

        setSearchConfirm('true')
    }

    if (error) return <div>Error loading Brands</div>;
    if (!data) return <div>Loading Brands...</div>;

    return (
        <>
         <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <ul className="side-menu">
                            <Link to='/admin/users'>
                                <li className='side-item active'>
                                    관리자
                                </li>
                            </Link> 
                            <Link to='/admin/users/basic'>
                                <li className='side-item'>
                                    일반유저
                                </li>
                            </Link>    

                        </ul>
           
                    </div>

                    <div className="col-md-12">
                        <div className="box search-wrap mb-4 " >
                            <select value={searchOption} onChange={(e) => setSearchOption(e.target.value)}>
                                <option value="all" selected="selected">전체</option>
                            </select>
                         
                            <div className="search-field">
                                <i className='xi-search'></i>
                            
                                <input type="text" 
                                        value={searchAuth}
                                        onChange={(e) => setSearchAuth(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                onSearch();
                                            }
                                        }}
                                        placeholder="Search"
                                />
                            </div>
                        </div>
                        <div className="box table-scroll">
                            <div className="table-wrap">
                                <table className="admin_table">
                                    <colgroup>
                                        <col style={{ width: '5%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '3%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '5%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '5%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '10%' }} />

                                    </colgroup>
                                    <thead>
                                        <th>번호</th>
                                        <th>ID</th>
                                        <th>관리자 구분</th>
                                        <th>관리 페이지</th>
                                        <th>이름<br/>(대표자)</th>
                                        <th>연락처</th>
                                        <th>상품<br/>(콘텐츠 수)</th>
                                        <th>승인상태</th>
                                        <th>가입일시</th>
                                        <th>마지막 로그인</th>
                                        <th>수정</th>
                                    </thead>
                                    <tbody>

                                        {searchResults?.data?.length > 0 ? (
                                            searchResults.data.map((user, index) => (
                                                <UserRow 
                                                    key={user?.id} 
                                                    user={user} 
                                                />
                                            ))
                                        ) : data && data.data && data.data.length > 0 ? (
                                            data.data.map((user, index) => (
                                                <UserRow 
                                                    key={user?.id} 
                                                    user={user} 
                                                />
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="17">검색 결과가 없습니다.</td>
                                            </tr>
                                        )}

        
                                    </tbody>
                                </table>
                            </div>
                            {/* 페이지내비게이션 */}
                            
                            <div className='pagination-wrap'>   
                                {/* {searchConfirm && (
                                    <SearchPagination
                                        count={searchResults.totalCnt}
                                        url={searchUrl}
                                        updateSearchResults={updateSearchResults}
                                    />
                                )} */}

                                {!searchConfirm && data && data.data && data.data.length > 0 && (
                                    <Pagination
                                        count={data?.totalCnt || 0}
                                        page={page}
                                        setPage={setPage}
                                        handlePrevPage={handlePrevPage}
                                        handleNextPage={handleNextPage}
                                    />
                                )}
                                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AdminManagement 