import React, { useState } from 'react';

function Step6({answers, setAnswers}) {
    
    const answerList = [
        "A. 컵의 위쪽",
        "B. 컵의 앞쪽",
        "C. 컵의 옆쪽",
        "D. 컵의 아래쪽",
        "E. 없음",
    ];

    const handleClick = (selectedAnswer) => {
        // "E. 없음"을 선택한 경우의 특별 처리
        if (selectedAnswer === "E. 없음") {
            if (!answers.includes("E. 없음")) {
                setAnswers(["E. 없음"]); // 다른 모든 선택 해제
            } else {
                setAnswers([]); // 해당사항 없음 선택 해제
            }
            return;
        }

        // "E. 없음"이 이미 선택되어 있는 경우, 제거
        if (answers.includes("E. 없음")) {
            setAnswers([selectedAnswer]);
            return;
        }

        // 일반적인 다중 선택 처리
        if (answers.includes(selectedAnswer)) {
            setAnswers(answers.filter(item => item !== selectedAnswer));
        } else {
            setAnswers([...answers, selectedAnswer]);
        }
    };

    return (
        <>
            <img src="/img/bbti/question/question9.jpg" className='w-100' alt="step6" />
            {answerList?.map((data, idx) => (
                <div 
                    key={idx}
                    className={answers.includes(data) ? "answer-btn active" : "answer-btn"}
                    onClick={() => handleClick(data)}
                >
                    {data}
                </div>
            ))}
        </>
    );
}

export default Step6;