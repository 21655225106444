/***************************************************************************************
* FileName      : ProductLogRow.js
* Description   : 중간 관리자 (BM) > product > 상품 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';

import { updateProductLog } from '../../../../api/admin/product';
import EditProductModal from './EditProductModal';

function ProductLogRow({ product , role }){
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);


    const [isDeleting, setIsDeleting] = useState(false);

    const handleUpdateStatusClick = (productId , status) => {

      const confirm = status==="A" ? window.confirm('정말로 승인 하시겠습니까?') : window.confirm('정말로 거절 하시겠습니까?');
      if (confirm) {
          if(status === "A"){
            updateProductLog(productId, { status: status })
            .then(data => {
                alert("성공적으로 승인 되었습니다.")
            })
            .catch(error => {
                
            });
          }else if(status === "J"){
            updateProductLog(productId, { status: status , reject_reason : "관리자가 거절함"})
            .then(data => {
                alert("성공적으로 거절 되었습니다.")
            })
            .catch(error => {
                
            });
          }
   
      }
  };

    console.log(product);
    return (
        <React.Fragment key={product?.id}>
            <tr className='fs-8'>
                <td>{product?.id}</td>

                <td>{product?.product_name}</td>
                <td><img src={product?.after?.image} /></td>
                
                <td>
                    {product?.after?.link && (
                        <a href={product.after.link} target="_blank" rel="noopener noreferrer">
                            {product.after.link}
                        </a>
                    )}
                </td>

                <td>
                  {typeof product?.after?.original_price === 'number' 
                  ? product.after.original_price.toLocaleString() 
                  : Number(product?.after?.original_price).toLocaleString()}
                </td>
                <td>
                  {typeof product?.after?.sale_price === 'number' 
                  ? product.after.sale_price.toLocaleString() 
                  : Number(product?.after?.sale_price).toLocaleString()}
                </td>
                <td>{product?.brand_name}</td>
                <td>{product?.after?.bbti?.map(item => item).join(', ')}</td>
         
                <td></td>
                <td>
                    {product?.status === 'A' && '승인'}
                    {product?.status === 'R' && '대기'}
                    {product?.status === 'J' && '거절'}
                </td>
                  <td>
                    {product?.action === "C" && "등록"}
                    {product?.action === "U" && "수정"}

                  </td>
                <td>
                  { role === "BM" && product?.status === 'J' &&
                    <a className='pink_btn mr-1' onClick={handleShowModal}>재요청</a>         
                  }

                  { role === null &&  product?.status === 'R' &&
                    <> 
                      <a className='pink_btn mr-1' onClick={() => handleUpdateStatusClick(product?.id,"A")}>
                      승인
                      </a>
                    
                      <a className='pink_btn' onClick={() => handleUpdateStatusClick(product?.id, "J")}>
                      거절
                      </a>
                    </>
                  } 

                </td>
             

            </tr>
        
            
            {/* 상품 수정 모달 */}
            <EditProductModal
                show={showModal}
                onHide={handleCloseModal}
                id={product?.id}
                productId={product?.product}
                status="J"
            />
        </React.Fragment>
    )
}

export default ProductLogRow