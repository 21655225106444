/***************************************************************************************
 * FileName      : ProductItemSample.js
 * Description   : 소곤핏 관리자페이지 매거진 작성화면 상품 컴포넌트 샘플
 * Company       : (주)엘리그
 * Author        : 최현민
 * Created Date  : 2024.04.22
 * Modifide Date : 2024.06.12 : CHM : BBTI 라벨링 뱃지 임시제거
 *               :
 * Reference     :
 ***************************************************************************************/

import { useState, useEffect } from "react";
import { getProductCustomer } from "../../../../api/admin/magazine";
import ProductItem from "../../../service/Main/ProductItem";

// 관리자페이지 매거진 상품영역 샘플
export function ProductItemSample({ item }) {
	const originalPrice = item?.original_price;
	const salePrice = item?.sale_price;
	let discountPercentage = 0;

	if (originalPrice && salePrice && originalPrice > salePrice) {
		const discount = originalPrice - salePrice;
		discountPercentage = (discount / originalPrice) * 100;
	}

	return (
		<>
			<div className="product-item">
				<div className="thumbnail">
					<img src={item?.image} alt="" />
					{/* 2024.06.12 : CHM : BBTI 라벨링 뱃지 임시제거 */}
					{/*
                    <div className="goods-badge">
                        {item?.bbti?.map((bbtiItem, index) => (
                            <span key={index} className="badge bbti">
                                {bbtiItem?.type}
                            </span>
                        ))}
                    </div>
                    */}
				</div>
				<div className="product-info">
					<p className="vendor">{item?.brand?.name}</p>
					<p className="pd-name">{item?.name}</p>
					<div className="pd-discount">
						<b>
							{discountPercentage?.toFixed(0)}
							<small>%</small>
						</b>
						<span>{item?.sale_price?.toLocaleString()}원</span>
					</div>
				</div>
			</div>
		</>
	);
}

export const CustomProductItemSample = ({ type, layout }) => {
	const [product, setProduct] = useState([]);
	let params = {};
	if (type === "new") params.ordering = "created_at";
	if (type === "best") params.ordering = "-veiw";
	if (type === "random") params.ordering = "random";
	if (layout === "1") params.pageSize = 2;
	if (layout === "2") params.pageSize = 4;
	if (layout === "3") params.pageSize = 6;
	useEffect(() => {
		getProductCustomer(params)
			.then((res) => {
				setProduct(res?.data?.data);
			})
			.catch((error) => {
				console.log(error?.response?.data?.data);
			})
			.finally(() => {
				console.log("product", product);
			});
	}, []);
	return (
		<>
			{/* 상품 샘플 */}
			<ProductItemSample item={product[0]} />
			<ProductItemSample item={product[1]} />
			{layout == "2" && (
				<>
					<ProductItemSample item={product[2]} />
					<ProductItemSample item={product[3]} />
				</>
			)}
			{layout == "3" && (
				<>
					<ProductItemSample item={product[2]} />
					<ProductItemSample item={product[3]} />
					<ProductItemSample item={product[4]} />
					<ProductItemSample item={product[5]} />
				</>
			)}
		</>
	);
};

// 직접선택 상품인 경우의 샘플이미지
export const CustomSelectProductItemSample = ({ productList }) => {
	const productListString = productList.join(",");
	const [product, setProduct] = useState([]);
	let params = {};
	params.id = productListString;
	useEffect(() => {
		getProductCustomer(params)
			.then((res) => {
				setProduct(res?.data?.data);
			})
			.catch((error) => {
				console.log(error?.response?.data?.data);
			})
			.finally(() => {
				console.log("product", product);
			});
	}, []);
	return (
		<>
			{product?.map((item, index) => {
				return <ProductItemSample item={item} key={index} />;
			})}
		</>
	);
};

// 직접선택 상품인 경우 상품
export const CustomSelectProductItem = ({ productList }) => {
	const [product, setProduct] = useState([]);
	let params = {};
	params.id = productList;
	useEffect(() => {
		getProductCustomer(params)
			.then((res) => {
				setProduct(res?.data?.data);
			})
			.catch((error) => {
				console.log(error?.response?.data?.data);
			})
			.finally(() => {
				console.log("product", product);
			});
	}, []);
	return (
		<>
			{product?.map((item, index) => {
				return (
					<ProductItem
						item={item}
						key={index}
						magazineProduct={true}
						clickArea={"소곤로그 상세페이지"}
					/>
				);
			})}
		</>
	);
};

export const CustomProductItem = ({ type, layout }) => {
	const [product, setProduct] = useState([]);
	let params = {};
	if (type === "new") params.ordering = "created_at";
	if (type === "best") params.ordering = "-veiw";
	if (type === "random") params.ordering = "random";
	if (layout === "1") params.pageSize = 2;
	if (layout === "2") params.pageSize = 4;
	if (layout === "3") params.pageSize = 6;
	useEffect(() => {
		getProductCustomer(params)
			.then((res) => {
				setProduct(res?.data?.data);
			})
			.catch((error) => {
				console.log(error?.response?.data?.data);
			})
			.finally(() => {
				console.log("product", product);
			});
	}, []);
	return (
		<>
			<ProductItem
				item={product[0]}
				magazineProduct={true}
				clickArea={"소곤로그 상세페이지"}
			/>
			<ProductItem
				item={product[1]}
				magazineProduct={true}
				clickArea={"소곤로그 상세페이지"}
			/>
			{layout == "2" && (
				<>
					<ProductItem
						item={product[2]}
						magazineProduct={true}
						clickArea={"소곤로그 상세페이지"}
					/>
					<ProductItem
						item={product[3]}
						magazineProduct={true}
						clickArea={"소곤로그 상세페이지"}
					/>
				</>
			)}
			{layout == "3" && (
				<>
					<ProductItem
						item={product[2]}
						magazineProduct={true}
						clickArea={"소곤로그 상세페이지"}
					/>
					<ProductItem
						item={product[3]}
						magazineProduct={true}
						clickArea={"소곤로그 상세페이지"}
					/>
					<ProductItem
						item={product[4]}
						magazineProduct={true}
						clickArea={"소곤로그 상세페이지"}
					/>
					<ProductItem
						item={product[5]}
						magazineProduct={true}
						clickArea={"소곤로그 상세페이지"}
					/>
				</>
			)}
		</>
	);
};
