import { instance } from '../axiosConfig.js';

export const bbtiList = () => {
    return instance.get('/bbti?page_size=all')
}

export const bbtiStart = () => {
    return instance.post(`research/start`, {title : 'BBTI'})
}

export const bbtiSave = (uuid, question, answer) => {
    return instance.patch(`research/${uuid}`, { question: question, answer: answer })
}

export const bbtiSubmit = (uuid, type, calc_data) => {
    return instance.patch(`research/submit/${uuid}`,{type: type, calc_data: calc_data})
    
}

export const bbitResult = () => {
    return instance.get(`research/result/my`)
}

export const researchSatisfaction = (uuid, satisfaction) =>{
    return instance.patch(`research/satisfaction/${uuid}`,{
        satisfaction: satisfaction
    })
}