/***************************************************************************************
 * FileName      : Main.js
 * Description   : 소곤핏 메인페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date : 2024.04.04 : CHM : 모바일 앱 사용 시 앱 다운로드 배너 제거
 *               : 2024.06.14 : CHM : 메인 콘텐츠 소곤톡 미리보기 추가
 * 				 : 2024.08.23 : PKH : 디자인 수정사항 반영  
 * Reference     :
 ***************************************************************************************/
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MagazineItem from "./Main/MagazineItem";
import LatestItem from "./Main/LatestItem";
import ProductItem from "./Main/ProductItem";
import MagazineMain from "./Main/MagazineMain";

import {
	getMainContents,
	getProductContents,
	getPostContents,
} from "../../api/services/mainApi";

import Gnb from "../../Gnb";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import ReactGA from "react-ga4";
import Footer from "../../Footer";

import { HackleExperiment, HackleVariation } from "@hackler/react-sdk";

import { ReactComponent as TalkIcon } from "../../assets/icon/talk.svg";
import { ReactComponent as LogIcon } from "../../assets/icon/log.svg";
import { ReactComponent as ShoppingIcon } from "../../assets/icon/shopping.svg";
import { ReactComponent as AnalysisIcon } from "../../assets/icon/analysis.svg";
import { ReactComponent as AboutusIcon } from "../../assets/icon/about_us.svg";
import Songontalk from "./Main/SogontalkItem";

import {Row, Col} from "react-bootstrap";

import { ReactComponent as HeartIcon } from "../../assets/icon/heart.svg";
import { ReactComponent as CommentIcon } from "../../assets/icon/comment.svg";

function Main() {
	const navigate = useNavigate();
	const [content, setContent] = useState([]);
	const [productContent, setProductContent] = useState([]);
	const [postNewContent, setPostNewContent] = useState([]);
	const [postHotContent, setPostHotContent] = useState([]);
	const [currentContentIndex, setCurrentContentIndex] = useState(0);

	const [mainLoading, setMainLoading] = useState(false);



	// 접근 디바이스 모바일 앱인지 확인
	const [isMobileApp, setIsMobileApp] = useState(false);
	useEffect(() => {
		setIsMobileApp(/appUser/i.test(navigator.userAgent));
	}, [isMobileApp]);
	console.log(navigator.userAgent);

	useEffect(() => {
		setMainLoading(true);

		getMainContents()
			.then((res) => {
				setContent(res.data.data);
				setMainLoading(false);
			})
			.catch((error) => {
				console.log(error?.response?.data?.data);
			});

		getProductContents()
			.then((res) => {
				setProductContent(res?.data?.data);
			})
			.catch((error) => {
				console.log(error?.response?.data?.data);
			});

		getPostContents("new")
			.then((res) => setPostNewContent(res?.data?.data))
			.catch((error) => {
				console.log(error?.response?.data?.data);
			});

		getPostContents("popular")
			.then((res) => setPostHotContent(res?.data?.data))
			.catch((error) => {
				console.log(error?.response?.data?.data);
			});
	}, []);


	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - Main</title>
			</Helmet>
			<div className="main">
				{!isMobileApp && ( // 모바일 앱 사용 시 앱 다운로드 배너 제거
					<button className="app-banner">
						<div>
							<img
								src={
									process.env.PUBLIC_URL + "favicon-96x96.png"
								}
								alt=""
							/>
							<span className="app-banner-title">
								소곤핏 베타테스트 기간입니다.
							</span>
						</div>
					</button>
				)}

				{/* 메인 배너 */}
				<section className="banner-content">
					<div className="nopadding">
						<video
							poster={
								process.env.PUBLIC_URL +
								"img/showcase_preview.png"
							}
							autoPlay
							loop
							muted
							playsInline
							className="w-100"
							onClick={() => {
								navigate("/bbti");
								window.hackleClient.track({
									key: "clicked_banner",
									properties: {
										배너타입: "영상배너", // 영상배너, 이미지배너
									},
								});
							}}
						>
							<source
								src={
									process.env.PUBLIC_URL +
									"../img/showcase_preview.mp4"
								}
								type="video/mp4"
							/>
						</video>
					</div>
				</section>

				<section className="main-category">
					<div className="inner">
						<Row>
							<Col className="text-center">
								<Link className="m-category-item"
									to="/post"
									onClick={() => {
										window.hackleClient.track({
											key: "clicked_category",
											properties: {
												카테고리명: "소곤톡",
											},
										});
									}}
								>
									<TalkIcon className="m-category-icon"/>
									<br/>
									<span style={{"color": "#ff9680"}}>소곤톡</span>
								</Link>
							</Col>
							<Col className="text-center">
								<Link className="m-category-item"
									to="/magazine"
									onClick={() => {
										window.hackleClient.track({
											key: "clicked_category",
											properties: {
												카테고리명: "소곤로그",
											},
										});
									}}
								>
									<LogIcon className="m-category-icon"/>
									<br/>
									<span style={{"color": "#ff9680"}}>소곤로그</span>
								</Link>
							</Col>
							<Col className="text-center">
								<Link className="m-category-item"
									to="/product"
									onClick={() => {
										window.hackleClient.track({
											key: "clicked_category",
											properties: {
												카테고리명: "SHOP",
											},
										});
									}}
								>
									<ShoppingIcon className="m-category-icon"/>
									<br/>
									<span style={{"color": "#ff9680"}}>SHOP</span>
								</Link>
							</Col>
							<Col className="text-center">
								<Link className="m-category-item"
									to="/bbti"
									onClick={() => {
										window.hackleClient.track({
											key: "clicked_category",
											properties: {
												카테고리명: "분석",
											},
										});
									}}
								>
									<AnalysisIcon className="m-category-icon"/>
									<br/>
									<span style={{"color": "#ff9680"}}>분석</span>
								</Link>
							</Col>
							<Col className="text-center">
								<Link className="m-category-item"
									onClick={() => {
										window.hackleClient.track({
											key: "clicked_category",
											properties: {
												카테고리명: "AboutUs",
											},
										});
										window.open(
											"https://daffy-rabbit-ea1.notion.site/3bfac3c4e34943a69b4df09b856af328#2a23e3eefd674108a940fc47c00a776e"
										);
									}}
								>
									<AboutusIcon className="m-category-icon"/>
									<br/>
									<span style={{"color": "#ff9680"}}>AboutUs</span>
								</Link>
							</Col>
							
						</Row>
					</div>
				</section>

				{/* 2024.06.14 : CHM : 소곤톡 콘텐츠 미리보기 영역 추가 */}
				{/* 소곤톡 콘텐츠 미리보기 */}
				{/* <section className="main-post">
					<div className="inner">
						<p className="main-title mt-2 mb-3">
							소곤톡
							<span className="post-type">
								{currentContentIndex === 0 ? "New" : "Hot"}
							</span>
						</p>
						{(currentContentIndex === 0
							? postNewContent
							: postHotContent
						)?.map((item, index) => (
							<div
								key={index}
								className={`main-post-block ${currentContentIndex === 0 ? "new" : "hot"}`} // 동적으로 클래스 추가
								style={{ animationDelay: `${index * 0.12}s` }}
								onClick={() => {
									// 매거진 클릭이벤트 추가
									window.hackleClient.track({
										key: "clicked_sogontalk",
										properties: {
											클릭위치: "메인페이지",
											타이틀: item?.title,
											작성자: item?.user?.username, // 익명, 작성자
										},
									});
									navigate(`/post/${item?.id}`);
								}}
							>
								<div className="main-post-tag mb-2">
									{item?.tags?.map((tag) => (
										<span
											key={tag?.id}
											className="post-tag"
										>
											# {tag?.name}
										</span>
									))}
								</div>
								<div className="main-post-content">
									<p className="post-title">{item?.title}</p>
									<p className="post-time">
										{item?.posted_at}
									</p>
								</div>
							</div>
						))}
					</div>
				</section> */}

				{/* 소곤톡 콘텐츠 미리보기 */}
				<section className="sogontalk">
					<div className="inner">
						<div className="title">
							<p>소곤톡</p>
							<div className="tab-btns">
							<span 
								className={`tab-btn ${currentContentIndex === 0 ? 'select' : ''}`} 
								onClick={() => setCurrentContentIndex(0)}
								>
							new
							</span>	
							<span 
								className={`tab-btn ${currentContentIndex === 1 ? 'select' : ''}`} 
								onClick={() => setCurrentContentIndex(1)}
								>
								hot
							</span>			
							</div>
						</div>	
						<div className="contents">
							{(currentContentIndex === 0
								? postNewContent
								: postHotContent
							)?.map((item, index) => (
								<Songontalk item={item} />
							))}
						
						</div>
					</div>
				</section>

				{/* PKH : 소곤로그 */}
				{/* <section className="main-log">
					<div className="inner">
						<div className="title-wrap">
							<p className="main-title">소곤로그</p>
							<span
								className="morebtn"	
								onClick={() => navigate("/magazine?type=blog")}
							>
								more &gt;
							</span>
						</div>
						<div className="log-wrap mb-5">
							<div className="log-item">
								<div className="log-img">
									<img
										src={process.env.PUBLIC_URL + "img/sample.png"}
										alt=""
									/>
								</div>
								<div className="log-content">
									<p className="log-title">
										같은 사이즈인데 왜 불편할까? (편한 ..
									</p>
									<p className="log-desc">
										소곤핏의 편한 속옷 감별사 에디터G입니다!..
									</p>
									<div className="log-btns">
										<span className="log-category">소곤 TIP</span>
										<div className="log-stats">
											<div className="">
												<HeartIcon className="main-meta-icon"/>
												<span>21</span>
											</div>
											<div className="">
												<CommentIcon className="main-meta-icon"/>
												<span>30</span>
											</div>
										</div>

									</div>
								</div>
							</div>
							
							<div className="log-item">
								<div className="log-img">
									<img
										src={process.env.PUBLIC_URL + "img/sample.png"}
										alt=""
									/>
								</div>
								<div className="log-content">
									<p className="log-title">
										같은 사이즈인데 왜 불편할까? (편한 ..
									</p>
									<p className="log-desc">
										소곤핏의 편한 속옷 감별사 에디터G입니다!..
									</p>
									<div className="log-btns">
										<span className="log-category">소곤 TIP</span>
										<div className="log-stats">
											<div className="">
												<HeartIcon className="main-meta-icon"/>
												<span>21</span>
											</div>
											<div className="">
												<CommentIcon className="main-meta-icon"/>
												<span>30</span>
											</div>
										</div>

									</div>
								</div>
							</div>
								
						</div>

						<div className="log-s-wrap">

							<div className="log-s-item">
								<div className="first-log">
									<div className="thumbnail">
										<img
											src={process.env.PUBLIC_URL + "img/sample.png"}
											alt=""
										/>
										<div className="log-tit">
											<p>세트가 25,000원!!!<br/>
											예스 속옷 체형별 추천</p>
											<span>소곤 PICK</span>
										</div>
								
									</div>
								
								</div>
								<div className="log-wrap mt-3 mb-5">
									<div className="log-item">
										<div className="log-img">
											<img
												src={process.env.PUBLIC_URL + "img/sample.png"}
												alt=""
											/>
										</div>
										<div className="log-content">
											<p className="log-title">
											푹푹 찌는 더위, 갑갑함에서 해방시켜중 속옷 추천
											</p>
											
											<span className="log-category2">소곤 TIP</span>
											

										</div>
									</div>
								</div>
							</div>
						</div>
			
						
						
					</div>
				</section> */}


				{/* 메인 콘텐츠 */}
				<section className="main-content">
					<div className="inner">
						{content?.map((item, index) => (
							<div key={index}>
								<div className="title-wrap">
									<p className="main-title">{item?.title}</p>
									<span
										className="morebtn"
										onClick={() =>
											navigate(
												`/magazine?type=${item?.type}`
											)
										}
									>
										more &gt;
									</span>
								</div>

								{item.main_content_exist && (
									<MagazineMain
										content={item?.main_content}
										npwPage={"메인페이지"}
									/>
								)}

								{item.x_layout === 1 && (
									<div className="latestContent-wrap">
										{item.contents.map((contentItem) => (
											<LatestItem
												key={contentItem.id}
												content={contentItem}
												isLoading={mainLoading}
												nowPage={"메인페이지"}
											/>
										))}
									</div>
								)}

								{item.x_layout === 2 && (
									<div className="magazine-wrap">
										{item.contents.map((contentItem) => (
											<MagazineItem
												key={contentItem.id}
												content={contentItem}
											/>
										))}
									</div>
								)}
							</div>
						))}
					</div>
				</section>

				{/* 상품 추천 */}
				<section className="recommended-content">
					<div className="inner">
						{/* <div className="title-wrap">
							<p className="main-title">이런 상품 어때요?</p>
							<span
								className="morebtn"
								onClick={() => navigate("/magazine")}
							>
								more &gt;
							</span>
						</div> */}

						{productContent?.map((item, index) => (
							<React.Fragment key={index}>
								<div className="title-wrap">
									<p className="main-title">{item.title}</p>
									<span
										className="morebtn"
										onClick={() => navigate("/product")}
									>
										more &gt;
									</span>
								</div>
								<div className="product-wrap">
									{item.contents.map(
										(content, contentIndex) => (
											<ProductItem
												item={content}
												key={contentIndex}
												clickArea={"메인페이지"}
												onClick={() => {
													window.hackleClient.track({
														key: "clicked_product",
														properties: {
															클릭위치:
																"메인페이지",
															상품정보: `${content?.id} - ${content?.name}`, // 상품ID-상품명
														},
													});
												}}
											/>
										)
									)}
								</div>
							</React.Fragment>
						))}
					</div>
				</section>
				<Footer />
				<Gnb />
			</div>
		</>
	);
}

export default Main;
