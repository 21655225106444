/***************************************************************************************
 * FileName      : MagazineItem.js
 * Description   : 소곤핏 메인페이지 콘텐츠 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date : 2024.05.05 : CHM : 매거진 상세페이지로 이동하는 기능 추가
 *               : 2024.05.22 : CHM : 메인페이지 매거진 클릭 이벤트 확인기능 추가
 * Reference     :
 ***************************************************************************************/
// x축이 2일때 사용하는 컴포넌트

import { extractYearMonthDay2 } from "../../admin/common/extractDateUtils";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

function MagazineItem({ content }) {
	const navigate = useNavigate();
	const handleClick = () => {
		// 소곤로그 클릭이벤트
		window.hackleClient.track({
			key: "clicked_sogonlog",
			properties: {
				클릭위치: "메인페이지", // 메인페이지, 소곤로그 상세페이지, 분석결과
				타이틀: content?.title,
				글작성자: content?.user?.username,
				카테고리: content?.category?.name,
				레이아웃: "블록형",
			},
		});
		navigate(`/magazine/${content?.id}`);
		ReactGA.event({
			category: "메인페이지에서 클릭한 매거진",
			action: "Magazine Clicked",
			value: content?.id,
		});
	};

	const title = content?.title;
	const maxLength = 50;
	const shortenedTitle =
		title && title.length > maxLength
			? `${title.substring(0, maxLength)}...`
			: title;

	return (
		<>
			<div
				className="magazine-item pointer"
				onClick={() => handleClick()}
			>
				<div className="magazineCard">
					<div className="thumbnail">
						{/* <img src={process.env.PUBLIC_URL + 'img/sample.png'} alt="" /> */}
						<img src={content?.main_image} alt="" />
					</div>
					<div className="cardText">
						<span className="tagBox">
							{content?.category?.name}
						</span>
						<div className="info">
							<p>{shortenedTitle}</p>
						</div>
					</div>
					<div className="cardFooter">
						<span>{extractYearMonthDay2(content?.created_at)}</span>
						<span>{content?.user?.username}</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default MagazineItem;
