
/***************************************************************************************
* FileName      : MenuItems.js
* Description   : 소곤핏 관리자 페이지 네비게이션 MenuItems 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.04.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React from 'react';
import { useState , useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown,} from '@fortawesome/free-solid-svg-icons';

import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function MenuItems({menuItems})
{
    const location = useLocation();
    const currentPathData = location.pathname.split('/').slice(0, 3).join('/').replace(/\/$/, '');

    const [openIndexes, setOpenIndexes] = useState([]);


    const handleClick = (index) => {
        if (openIndexes.includes(index)) {
            setOpenIndexes(openIndexes.filter((item) => item !== index));
        } else {
            setOpenIndexes([...openIndexes, index]);
        }
    };

    return (
        <>
          {menuItems.map((item, index) => (
              <div className={`category-wrap ${currentPathData === item.link2 ? 'active' : ''}`} key={index}>
                  {item.contents ? (
                      <>
                      <div onClick={() => handleClick(index)}>
                          <FontAwesomeIcon icon={item.icon} />
                          <h2>{item.title}</h2>

                          {openIndexes.includes(index) ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}

                      </div>

                      {openIndexes.includes(index) && (
                          <ul>
                              {item.contents.map((content, subIndex) => (
                                  <li
                                      className={currentPathData === content.link2 ? 'active' : ''}
                                      key={subIndex}>
                                      <Link to={content.link}>{content.title}</Link>
                                  </li>
                              ))}
                          </ul>
                      )}
                      </>
                  ) : (
                      <>
                          <Link to={item.link}>
                            <h2>{item.title}</h2>
                          </Link>
                      </>
                  )}
            </div>
          ))}
        </>
    )

}

export default MenuItems;