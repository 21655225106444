import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { postProductDesign } from "../../../../api/admin/brandApi";
import { radioButtonGroups, RadioButtonGroup } from "./DesignLabelModal";

// 디자인 라벨 등록용 모달
export const AddDesignLabelModal = ({ id, show, onHide }) => {
	const [selectedValues, setSelectedValues] = useState({
		padPouch: "",
		detachableStrap: "",
		wingLine: "",
		hookPosition: "",
		cupDesign: "",
		wingDesign: "",
		strapDesign: "",
		color: "",
	});

	useEffect(() => {
		if (!show) {
			setSelectedValues({
				padPouch: "",
				detachableStrap: "",
				wingLine: "",
				hookPosition: "",
				cupDesign: "",
				wingDesign: "",
				strapDesign: "",
				color: "",
			});
		}
	}, [show]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setSelectedValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	// 모든 값이 선택되었는지 확인
	const isFormValid = () => {
		return Object.values(selectedValues).every((value) => value !== "");
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{id} 라벨(디자인) 등록</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					onSubmit={(e) => {
						e.preventDefault();
						if (isFormValid()) {
							postProductDesign({ id: id, data: selectedValues })
								.then((res) => onHide())
								.catch((error) =>
									alert("라벨 등록 중 에러 발생")
								);
						}
					}}
				>
					{radioButtonGroups.map((group, index) => (
						<RadioButtonGroup
							key={index}
							label={group?.label}
							name={group?.name}
							options={group?.options}
							handleChange={handleInputChange}
						/>
					))}
					<Button
						className="w-100"
						variant={isFormValid() ? "primary" : "secondary"}
						type="submit"
						disabled={!isFormValid()}
					>
						저장
					</Button>
				</Form>
			</Modal.Body>
		</Modal>
	);
};
