/***************************************************************************************
* FileName      : Header.js
* Description   : 소곤핏 헤더
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

function Header()
{
    return (
        <header>
            <nav>
          
            </nav>
        </header>
    );
}

export default Header;  