/***************************************************************************************
* FileName      : MagazineMenu.js
* Description   : 소곤핏 관리자 > 매거진 >  매거진 카테고리 메뉴 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import { useEffect , useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import AddCategoryModal from './AddCategoryModal';

function MagazineMenu({categoryData , role})
{
    const currentUrl = window.location.href;
    const match = currentUrl.match(/\/admin\/([^\/]+)\/?/);
    const magazineUrl = match ? match[1] : null;
    
    console.log(magazineUrl); // "magazinelog" or null
    
    
    const { magazineCategoryId } = useParams();
  

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);


    const [menuItems, setMenuItems] = useState([]);

    const [categoryId , setCategoryId] = useState();

    const AlllinkTo = magazineUrl === 'magazinelog' ? '/admin/magazinelog/all' : '/admin/magazine/all';
    const linkTo = magazineUrl === 'magazinelog' ? `/admin/magazinelog/` : `/admin/magazine/`;


    useEffect(()=>{
        const processedData = categoryData?.data?.map(item => ({
            title: item.name,  
            id: item.id 
        }));

        setMenuItems(processedData);    
    },[categoryData])



    useEffect(()=>{
        if(magazineCategoryId === "all"){
            setCategoryId(magazineCategoryId);
        }else{
            const numericCategoryId = parseInt(magazineCategoryId, 10);
            setCategoryId(numericCategoryId);

        }
    },[magazineCategoryId])



    return(
        <>
         <ul className="side-menu">

            <Link to={AlllinkTo}>
                <li className={categoryId === 'all' ? 'side-item active' : 'side-item'}>
                    전체
                </li>
            </Link> 

            {menuItems?.map((item,index) => (
                <Link to={`${linkTo}${item.id}`} key={index}>
                    <li className={ categoryId === item.id ? 'side-item active' : 'side-item'}>
                        {item.title}
                    </li>
                 </Link>    
                
            ))}


            {role !="CP" && magazineUrl === 'magazine' &&
                <li className='side-item' onClick={handleShowModal}>
                +
                </li>
            }
                            

        </ul>

        {/* 새로운 카테고리 등록 모달 */}
        <AddCategoryModal
            show={showModal}
            onHide={handleCloseModal}
        />
        </>
    )
}
export default MagazineMenu