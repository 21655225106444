/***************************************************************************************
* FileName      : AdminDashboard.js
* Description   : 관리자 대시보드 페이지 
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.21
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import { useEffect, useState } from "react";
import React from 'react';
import { useGetbbtiStatistic } from '../../api/admin/bbti';

import { setCookie } from '../../api/cookieUtils.js';
import { getMe } from '../../api/common/memberApi';

function AdminDashboard()
{
    
    useEffect(()=>{

        getMe()
        .then(data => {
            setCookie('role', data?.role); 
        })
        .catch(error => {
          console.error('데이터를 가져오는 중 오류가 발생했습니다:', error);
        });

    }, [])
    // bbti target 조회
    const [bbtiTarget , setBbtiTarget] = useState('전체');


    // bbti 테스트 결과 통계 
    // const { data: statisticData } = useGetbbtiStatistic(bbtiTarget);

    
    // bbti 체형 target 변경 
    const [bbti_buttonText, setBbtiButtonText] = useState('전체');

    const handleItemClick = (text) => {
        setBbtiTarget(text);
        setBbtiButtonText(text);
    };

    return (
        <>
        <section className="admin-content">
            <div className="container-fluid">
                {/* BBTI 체형 테스트 */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            <div className="tit">
                                <div class="btn-group">
                                    <button class="selectBtn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {bbti_buttonText}
                                    </button>
                                    <ul class="selectDropdown dropdown-menu">
                                        <li>
                                            <a className="dropdown-item" onClick={() => handleItemClick('전체')}>전체</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item"  onClick={() => handleItemClick('회원')}>회원</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <p className='sub-tit'>결과값 비율 많은 순</p>

                            <table className="a-dash-table">
                                <thead>
                                    <th>#</th>
                                    <th>결과</th>
                                    <th width="30%">비율(%)</th>
                                    <th width="20%" className='tcenter' >비율(%)</th>
                                    <th  className='tcenter'>전체 이용자</th>
                                </thead>
{/* 
                                <tbody>
                                {statisticData && Object.keys(statisticData?.statistic).map((key,index) => (
                                    <tr key={key}>
                                        <td>{index}</td>
                                        <td>{key}</td>
                                        <td>
                                            <div className="bar_ratio" >
                                                <div style={{ width: `${statisticData.statistic[key].ratio}%` }}></div>
                                            </div>
                                        </td>
                                        <td className='tcenter'>{statisticData.statistic[key].ratio}</td> 
                                        <td className='tcenter'>{statisticData.statistic[key].count}</td>
                                    </tr>
                                ))}
                                </tbody> */}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default AdminDashboard 

