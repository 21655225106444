import React, { useState } from 'react';

function Step2({cupSize, setCupSize}){
	
	const sizeList = ["AA", "A", "B", "C", "D", "E", "F", "G", "H", "I"];
	return(
		<>
			{
				sizeList?.map((size, idx) => (
					<div 
						key={idx}
						className={cupSize === size ? "answer-btn active" : "answer-btn"}
						onClick={()=>setCupSize(size)}
						>{size}</div>
				))
			}
		</>
	)
}

export default Step2;