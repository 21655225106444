/***************************************************************************************
* FileName      : extractYearMonthDayHourMinute.js
* Description   : 날짜를 년,월,일,시,분으로 변환하는 함수
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

export function extractYearMonthDayHourMinute(dateString)
{
    const dateObject = new Date(dateString);
    const formattedDate = `${dateObject.getFullYear()}.${(dateObject.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${dateObject
        .getDate()
        .toString()
        .padStart(2, '0')} ${dateObject.getHours()}:${dateObject
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    
      return `${formattedDate}`; 
}

// 년,월,일 
export function extractYearMonthDay2(dateString) 
{
    const dateObject = new Date(dateString);
    const formattedDate = `${dateObject.getFullYear()}.${(dateObject.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${dateObject
        .getDate()
        .toString()
        .padStart(2, '0')}`;
    
    return formattedDate; 
}
