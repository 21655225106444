/***************************************************************************************
 * FileName      : Login.js
 * Description   : 소곤핏 고객 로그인 페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.11
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import React, { useEffect } from "react";
import { setCookie, setTokenToCookie } from "../../api/cookieUtils.js";
import MobileHeader from "./Component/MobileHeader.js";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom/dist/index.js";

import { useMutation, useQueries, useQueryClient } from "react-query";
import { usernameLogIn } from "../../api/common/memberApi.js";
import { Link } from "react-router-dom";
import Gnb from "../../Gnb.js";
import { BASE_URL } from "../../api/apiConfig.js";
import { Helmet } from "react-helmet-async";
import { PropertyOperationsBuilder } from "@hackler/react-sdk";
import { AiOutlineCaretDown } from "react-icons/ai";

function Login() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const mutation = useMutation(usernameLogIn, {
		onMutate: (variables) => {
			console.log("처리중 ...");
		},
		onSuccess: (data, variables) => {
			// console.log(JSON.stringify(data?.data, null, 2));
			console.dir("로그인 유저 데이터 : ", data?.data);
			const operations = new PropertyOperationsBuilder()
				.set("role", data?.data?.user_role)
				.set("bbti", data?.data?.user_bbti)
				.set("type", data?.data?.user_type)
				.build();

			window.hackleClient.setUserId(variables?.username); // 핵클 로그인 ID 설정
			window.hackleClient.updateUserProperties(operations); // 로그인 정보 싱크

			window.hackleClient.track({
				key: "complete_signin",
				properties: {
					로그인수단: "sogonfit", // sogonfit 아이디로 로그인한 회원
				},
			});

			setCookie("access", data?.data?.access);
			setCookie("refresh", data?.data?.refresh);

			if (/flutter-app/i.test(navigator.userAgent)) {
				//모바일 앱인 경우 로그인 함수 전달
				window.appLogin.postMessage("run");
			}

			navigate("/", { replace: true });
		},
		onError: () => {
			alert("로그인 실패\n 아이디/비밀번호를 확인해주세요.");
		},
	});

	const onSubmit = ({ username, password }) => {
		mutation.mutate({ username, password });
	};

	const kakaoSocialLogin = () => {
		window.location.href = `${BASE_URL}/auth/login/kakao`;
	};

	useEffect(() => {
		if (!window?.Kakao?.isInitialized()) {
			window?.Kakao?.init("0907d499eb79097f57e355d7a8831723");
		}
	}, []);
	
	const handleChat = () => {
		if (window?.Kakao?.Channel) {
			window?.Kakao.Channel.chat({
				channelPublicId: "_HUxbxkG",
			});
		} else {
			AiOutlineCaretDown(
				"카카오톡에서 소곤핏 채널을 통해 문의 부탁드립니다."
			);
		}
	};

	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - LOGIN</title>
			</Helmet>
			<MobileHeader back={true} title={"로그인"} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="login">
					<div className="inner">
						<div className="loginLogo-wrap">
							<img
								src={process.env.PUBLIC_URL + "img/slogo.png"}
								alt=""
							/>
						</div>
						<div className="signin-content">
							<div className="login-header">
								<p>
									로그인 후 이용하여 다양한 혜택을 만나보세요
								</p>
							</div>
							<div className="login-field">
								<input
									type="text"
									name="username"
									className={
										errors.username ? "error-input" : ""
									}
									placeholder="아이디"
									{...register("username", {
										required: "아이디를 입력해주세요",
									})}
								/>

								<input
									type="password"
									name="password"
									placeholder="비밀번호"
									className={
										errors.password ? "error-input" : ""
									}
									{...register("password", {
										required: "비밀번호를 입력해주세요",
									})}
								/>
							</div>

							<button className="btn-primary btn-login mt-4">
								로그인
							</button>

							<div className="easy-login mt-3">
								<button
									className="btn-primary btn-kakao-login"
									type="button"
									onClick={() => {
										kakaoSocialLogin();
									}}
								>
									<img
										src={
											process.env.PUBLIC_URL +
											"/img/kakao-icon.png"
										}
										alt=""
									/>
									카카오로 시작하기
								</button>
							</div>
							<div className="find-info mt-4">
								<Link to="/findId">아이디찾기</Link>
								<Link to="/findPassword" className="pbtn">
									비밀번호찾기
								</Link>
							</div>

							<div className="join-member mt-4">
								<span>아직 회원이 아니신가요 ?</span>
								<Link to="/signup"> 소곤핏 회원가입</Link>
							</div>
							<div className="join-member mt-1" onClick={()=>handleChat()}>
								<span style={{fontSize:"12px", color:"gray"}}>서비스 오류 보고</span>
							</div>
						</div>
					</div>
				</div>
			</form>
			<Gnb />
		</>
	);
}

export default Login;
