/***************************************************************************************
* FileName      : ToggleSwitch.js
* Description   : 토글 스위치 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.04.03
* Modifide Date : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import { updateBrandHidden , updateProductHidden ,  } from '../../../api/admin/brandApi';
import { updateMagazineHidden } from '../../../api/admin/magazine';

export default function ToggleSwitch({ pk, checked ,  toggleType }) {
    const [isChecked, setIsChecked] = useState(checked);
    const [disabledToggle, setDisabledToggle] = useState(!checked);

    const handleToggle = () => {
        setIsChecked(!isChecked);
    
        if (toggleType === 'brand') {
          updateBrandHidden(pk, !isChecked);
        } else if (toggleType === 'product') {
          updateProductHidden(pk, !isChecked);
        } else if (toggleType === 'magazine') {
          updateMagazineHidden(pk, !isChecked);
        }

        // window.location.reload();
      
    }
  
    return (
      <>
        <label className={`toggle ${isChecked ? 'checked' : ''}`}>
          <input type="checkbox" onChange={handleToggle} checked={isChecked} style={{ display: 'none' }}/>
          <span className={disabledToggle ? 'toggle-slider' : 'toggle-slider disabled'}></span>
        </label>
        
        <br/>
        <span className="text-secondary fs-8 fw-bold">
        {isChecked ? '비공개' : '공개' }
        </span>
      </>
    );
  }