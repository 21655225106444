// 고객 > 소곤톡 관리
import { queryClient, useMutation, useQuery, prefetchQuery } from "react-query";
// import SkeletonComponent from '../components/service/SkeletonComponent';
import SkeletonComponent from "../../components/service/SkeletonComponent.js";
import { useQueryClient } from "react-query";
import { instance } from "../axiosConfig.js";
import axios from "axios";
const PAGE_SIZE = 10;

// 소곤톡 > tag 리스트 조회
export const useGetTags = () => {
	return useQuery("tags", async () => {
		const response = await instance.get(`post/tags?page_size=all`);
		return response.data;
	});
};

// 소곤톡 조회 무한스크롤 (고객용)
export const fetchPostData = async (tag, page, order) => {
	// await new Promise((resolve) => setTimeout(resolve, 5000));
	const url =
		tag === "all"
			? `/post/?page=${page}&order=${order}`
			: `/post/?tag=${tag}&page=${page}&order=${order}`;

	return instance.get(url).then((res) => res.data);
};

// 마이페이지 > 내가 작성한 글 > 소곤톡 조회 무한스크롤 (고객용)
export const fetchMyPostData = (tag, page) => {
	const url =
		tag === "all"
			? `/post/my?page=${page}`
			: `/post/my?tag=${tag}&page=${page}`;

	return instance.get(url).then((res) => res.data);
};

// 고객 > post 등록
export const registerPost = (data) =>
	instance.post(`post/`, data).then((response) => response.data);

//선택한 post 정보 조회 (고객용)
export const getPostDetails = ({ id, viewCountKey }) => {
	return instance
		.get(`post/${id}`, {
			params: {
				"View-Count-Key": viewCountKey,
			},
		})
		.then((res) => res.data);
};

// 고객 > post 댓글 등록
export const registerReply = (data) =>
	instance.post(`reply/`, data).then((response) => response.data);

// 고객 > 해당 post 댓글 조회
export const fetchReplyList = (id) => {
	return instance
		.get(`reply/?post=${id}&page_size=all`)
		.then((res) => res.data);
};

// 고객 > 게시글 신고 등록
export const registerPostReport = (data) =>
	instance.post(`report/post`, data).then((response) => response.data);

// 고객 > 게시글 삭제
export const deletePost = (postId) => {
	return instance
		.delete(`post/${postId}`)
		.then((response) => response.data)
		.catch((error) => {
			console.error("Error deleting:", error);
			throw error;
		});
};

// 좋아요 (고객용)
export const togglePostLike = (data) =>
	instance.post(`like/post`, data).then((response) => response.data);

// 고객 > 해당 댓글 삭제
export const deleteReply = (Id) => {
	return instance
		.delete(`reply/${Id}`)
		.then((response) => response.data)
		.catch((error) => {
			console.error("Error deleting :", error);
			throw error;
		});
};

// 고객 > 댓글 신고 등록
export const registerReplyReport = (data) =>
	instance.post(`report/reply`, data).then((response) => response.data);

// 관리자 권한 > 게시글 비공개
export const adminHiddenPost = (postId) => {
	return instance
		.patch(`post/admin/hidden/${postId}`, {
			admin_hidden: true,
		})
		.then((response) => response.data)
		.catch((error) => {
			console.error("Error deleting:", error);
			throw error;
		});
};

// 관리자 권한 > 댓글 비공개
export const adminHiddenReply = (Id) => {
	return instance
		.patch(`reply/hidden/${Id}`, {
			admin_hidden: true,
		})
		.then((response) => response.data)
		.catch((error) => {
			console.error("Error deleting:", error);
			throw error;
		});
};

// post 등록 > 이미지
export async function getUploadURL(ImageFile) {
	try {
		const response = await instance.post(`aws/upload`);
		const data = response.data;
		// alert(`${ImageFile.name} urls ${data.imageURL}`);
		return {
			uploadURL: data.uploadURL,
			imageURL: data.imageURL,
			file: ImageFile,
		};
	} catch (error) {
		console.log("이미지 등록 오류가 발생했습니다.", error);
		return null;
	}
}

export const uploadImage = async ({ file, uploadURL, imageURL }) => {
	try {
		return axios.put(uploadURL, file).then(() => imageURL);
	} catch (error) {
		console.log("이미지 업로드 중 오류가 발생했습니다.", error);
		return null;
	}
};

export const recommendTags = async ({ value }) => {
	try {
		const response = await axios.post(
			"https://25wg4etdsmt3bvcashzz67fxgy0xspge.lambda-url.ap-northeast-2.on.aws/extract-keywords",
			{ text: value },
			{ headers: { "Content-Type": "application/json" } }
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching tag recommendations:", error);
		throw error;
	}
};
