/***************************************************************************************
 * FileName      : EditProductModal.js
 * Description   : 중간 관리자(bm) > 해당 브랜드 > 상품 수정 요청 모달 컴포넌트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date : 2024.05.30 : CHM : 브랜드 미선택 시 에러메세지 추가
 *               :
 * Reference     :
 ***************************************************************************************/
// 중간관리자 (bm)이 승인 된 상품 수정 요청 모달 컴포넌트
// 중간관리자 (bm)이 거절 된 상품 수정 요청 모달 컴포넌트

import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  getUploadURL,
  uploadImage,
} from "../../../../api/common/uploadImageApi";
import { LoadingSpinner } from "../../Component/LoadingSpinner";

import {
  getProduct,
  updateProduct,
  useGetBrands,
  useGetBbti,
} from "../../../../api/admin/brandApi";

import { getBMProduct, updateBMProduct } from "../../../../api/admin/product";
import { GiConsoleController } from "react-icons/gi";

const EditProductModal = ({ show, onHide, id, productId, status }) => {
  // status : J : 거절 => 재요청
  // status : A : 승인 => 수정요청
  const { data, isLoading } = useQuery(["product", id], () => {
    if (status === "J") {
      return getBMProduct(id);
    } else {
      return getProduct(id);
    }
  });

  //const { data ,isLoading } = useQuery(['product',id], () => getBMProduct(id));


  const [previousName, setPreviousName] = useState("");

  // 공개 비공개
  const [isPrivate, setIsPrivate] = useState(true);
  // bbti 선택
  const [selectedTypes, setSelectedTypes] = useState([]);

  // 대표이미지 미리보기
  const [previewUrl, setPreviewUrl] = useState();

  // 등록인지 수정인지
  const [action, setAction] = useState();

  useEffect(() => {
    if (!isLoading && data && status === "J") {
      setValue("name", data?.product_name);
      setPreviousName(data?.product_name); 

      setValue("brand", data?.after?.brand);
      setValue("original_price", data?.after?.original_price);
      setValue("sale_price", data?.after?.sale_price);
      setValue("link", data?.after?.link);
      setPreviewUrl(data?.after?.image);
      setIsPrivate(data?.after?.hidden);
      setSelectedTypes(data?.after?.bbti);
      setAction(data?.action);
    }
    if (!isLoading && data && status === "A") {
      const types = data?.bbti?.map((item) => item.type);

      setValue("name", data?.name);
      setPreviousName(data?.name); 
      setValue("brand", data?.brand?.id);
      setValue("original_price", data?.original_price);
      setValue("sale_price", data?.sale_price);
      setValue("link", data?.link);
      setPreviewUrl(data?.image);
      setIsPrivate(data?.hidden);
      setSelectedTypes(types);
      setAction(data?.action);
    }
    console.log(data);
  }, [data]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();

  const handleCheckboxChange = () => {
    setIsPrivate(!isPrivate);
  };

  const [selectedFileName, setSelectedFileName] = useState("");

  // 브랜드 조회
  const {
    data: brandsData,
    isLoading: isBrandsDataLoading,
    isError: isBrandsDataError,
  } = useGetBrands();

  // bbti 조회
  const {
    data: BbtiData,
    isLoading: isBbtiDataLoading,
    isError: isBbtiDataError,
  } = useGetBbti();

  const updateProductMutaion = useMutation(
    (newData) => updateBMProduct(newData),
    {
      onSuccess: (data) => {
        alert("재요청이 완료 되었습니다");
        reset();
        onHide();
      },
      onError: (data) => {
        alert(data?.request?.responseText);
      },
    }
    );


    const uploadImageMutation = useMutation(uploadImage, {
        onSuccess: (result) => {
          // brand 값을 선택하지 않았을 경우 undefined로 변환
          let brand = watch("brand");
          if(brand == ''){
              alert('브랜드를 선택해주세요');
              return;
          }

          const productData = {
              brand: brand,
              link: watch("link"),
              image: result,
              original_price: watch("original_price"),
              sale_price: watch("sale_price"),
              bbti: selectedTypes,
              hidden: isPrivate,
          };

          if (watch("name") !== previousName || productId === null) {
              // 이름이 수정되었거나 또는 등록요청 -> 거절 -> 재요청 시에는 name을 보내야됨
              productData.name = watch("name");
          }

          const BMData = {
              after: productData,
              product: productId,
          };
          
          updateProductMutaion.mutate(BMData);
        },
    });

    const uploadURLMutaion = useMutation(getUploadURL, {
      onSuccess: (data) => {
        uploadImageMutation.mutate({
          uploadURL: data.uploadURL,
          imageURL: data.imageURL,
          file: watch("productImage"),
        });
      },
      onError: (data) => {
        console.log("error", data);
      },
    });

  const onSubmit = (data) => {
    if (!(watch("productImage").length === 1)) {
      // brand 값을 선택하지 않았을 경우 undefined로 변환
      let brand = watch("brand");
      if(brand == ''){
          alert('브랜드를 선택해주세요');
          return;
      }
      // 이미지 수정 없을 경우
      const productData = {
          brand: brand,
          original_price: watch("original_price"),
          sale_price: watch("sale_price"),
          link: watch("link"),
          image: previewUrl,
          bbti: selectedTypes,
          hidden: isPrivate,
      };

      if (data.name !== previousName || productId === null) {
          // 이름이 수정되었거나    
          // 등록요청 -> 거절 -> 재요청 시에는 name을 보내야됨
          productData.name = watch("name");
      }

      const BMData = {
          after: productData,
          product: productId,
      };

      updateProductMutaion.mutate(BMData);
    } else {
      uploadURLMutaion.mutate();
    }
};


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setSelectedFileName(file.name);
    } else {
      setPreviewUrl(null);
      setSelectedFileName("");
    }
  };

  const handleBBTiCheckboxChange = (type) => {
    const isSelected =
      selectedTypes &&
      selectedTypes.some((selectedType) => selectedType === type);

    if (isSelected) {
      setSelectedTypes(
        selectedTypes.filter((selectedType) => selectedType !== type)
      );
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{status === "J" ? "상품 재요청" : "상품 수정 요청"} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-form">
            <div className="input_box">
              <p>브랜드</p>

              {isBrandsDataLoading ? (
                <p>Loading...</p>
              ) : isBrandsDataError ? (
                <p>Error loading brands data</p>
              ) : (
                <select {...register("brand")} defaultValue="">
                  <option value="" disabled>
                    브랜드를 선택하세요
                  </option>
                  {brandsData.data.map((brand) => (
                    <option key={brand?.id} value={brand?.id}>
                      {brand?.name}
                    </option>
                  ))}
                </select>
              )}

              <p>상품명</p>
              <input
                type="text"
                name="name"
                className={errors.name ? "error-input" : ""}
                {...register("name", { required: "상품명 입력은 필수입니다." })}
              />

              <p>사이트링크</p>
              <input
                type="text"
                name="link"
                className={errors.link ? "error-input" : ""}
                {...register("link", {
                  required: "사이트링크 입력은 필수입니다.",
                })}
              />

              <p>상품이미지</p>
              <div className="imgPreview">
                {previewUrl ? (
                  <>
                    <div className="mpreimg">
                      <img className="editImg" src={previewUrl} />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="filebox">
                <input
                  className={
                    errors.productImage
                      ? "upload-name error-input"
                      : "upload-name"
                  }
                  value={selectedFileName}
                  placeholder="대표 이미지파일"
                />

                <label htmlFor="productImage_file">파일찾기</label>

                <input
                  type="file"
                  id="productImage_file"
                  name="productImage"
                  accept="image/jpg, image/png, image/jpeg"
                  {...register("productImage")}
                  onChange={handleFileChange}
                />
              </div>

              <p>상품가격</p>
              <input
                type="text"
                name="original_price"
                className={errors.original_price ? "error-input" : ""}
                {...register("original_price", {
                  required: "상품가격 입력은 필수입니다.",
                })}
              />

              <p>판매가격</p>
              <input
                type="text"
                name="sale_price"
                className={errors.sale_price ? "error-input" : ""}
                {...register("sale_price", {
                  required: "판매가격 입력은 필수입니다.",
                })}
              />

              <p>BBTI</p>
              <div className="bbtiList">
                {isBbtiDataLoading ? (
                  <p>Loding...</p>
                ) : isBbtiDataError ? (
                  <p>Error bbti data</p>
                ) : (
                  BbtiData?.data.map((item) => (
                    <div key={item?.id}>
                      <input
                        type="checkbox"
                        className="bbti_checkbox"
                        checked={
                          selectedTypes && selectedTypes.includes(item?.type)
                        }
                        onChange={() => handleBBTiCheckboxChange(item?.type)}
                        id={`bbti_checkbox_${item?.id}`}
                      />

                      <label
                        className="bbti_label"
                        htmlFor={`bbti_checkbox_${item?.id}`}
                      >
                        {item?.type}
                      </label>
                    </div>
                  ))
                )}
              </div>

              <div className="form-check">
                <input
                  className="form-check-input custom-checkbox"
                  type="checkbox"
                  id="hiddenCheck"
                  checked={isPrivate}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" for="hiddenCheck">
                  비공개
                </label>
              </div>
            </div>
            <button className="btn_black_1" type="submit">
              {updateProductMutaion.isLoading ||
              uploadImageMutation.isLoading ||
              uploadURLMutaion.isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                {status === "J" ? "상품 재요청" : "상품 수정 요청"}
                </>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductModal;