/***************************************************************************************
* FileName      : SampleBlock.js
* Description   : 소곤핏 관리자 > 메인 > 상품영역관리 페이지 > 우측 샘플블록
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.04.11
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
function SampleProductBlock({ data}) {
    console.log("data", data);
    const imgSrc = {
        x2: "/img/template_x2_product.png",
        x3: "/img/template_x3_product.png"
    }
    return (
        <>
            <div className="mb-3 d-flex justify-content-between">
                <div />
                <h5 className="fw-bold text-center">Sample</h5>
                <div />
            </div>
            <div>
                {
                    data?.map((item) => (
                        item?.hidden == false && (
                            <>
                                <p className='fs-5 fw-bold mt-5'>{item?.title}</p>
                                {Array.from(Array(item?.y_layout).keys()).map((i) => <img src={imgSrc[item?.x_layout == 2 ? 'x2': 'x3']} alt="상품영역" className="img-fluid mt-2" />)}
                            </>
                        )
                    ))
                }
            </div>
        </>
    )
}
export default SampleProductBlock;