import React, { useState , useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { getUploadURL , uploadImage  } from '../../../api/common/uploadImageApi';

import { LoadingSpinner } from '../Component/LoadingSpinner';
import { updateBrand } from '../../../api/admin/brandApi';
import { getUser } from '../../../api/admin/memberApi';
import { useGetBrands } from '../../../api/admin/brandApi';
import { updateUser } from '../../../api/admin/memberApi';
import { useGetCategory } from '../../../api/admin/magazine';
const EditUserModal = ({ show, onHide ,id }) => {


    // 브랜드 조회
    const { data: brandsData, isLoading: isBrandsDataLoading, isError: isBrandsDataError } = useGetBrands();

    // 카테고리 조회 
    const { isLoading:categoryLoading , data:categoryData } = useGetCategory();


    const { data ,isLoading } = useQuery(['auth',id], () => getUser(id));

    const { register, handleSubmit, setValue ,formState: { errors } , reset , watch , getValues } = useForm();

    // 공개 비공개
    const [isPrivate, setIsPrivate] = useState(true);

    // 브랜드 리스트
    const [brandList, setBrandList] = useState([]);

    // 카테고리 리스트 
    const [categoryList , setCategoryList] = useState([]);

    useEffect(() => {
        if (!isLoading && data) {
            setValue('name', data.name);
            setValue('role', data.role);
            setValue('phone_number', data.phone_number);
            setBrandList(data.brand);
            setCategoryList(data.category);
        }
    }, [data]);



  
    const updateAuthMutaion = useMutation((newData) => updateUser(id, newData), {
        onSuccess : (data) => {
            alert("수정되었습니다")
            reset();
            window.location.href = '/admin/users';
    
        },
        onError : (data) => {
            console.log("error", data)
        }
    });



    const onSubmit = (data) => {
        let newData = {
            name: watch("name"),
            role: watch("role"),
            phone_number : watch("phone_number"),
        };
    
        if (watch("role") === "BM") {
            newData.brand = brandList;
            newData.category = [];
            setCategoryList([]);
        }
    
        if (watch("role") === "CP") {
            newData.category = categoryList;
            newData.brand = [];
            setBrandList([]);
        }
    
        updateAuthMutaion.mutate(newData);

    };

    const handleBrandCheckboxChange = (id) => {
        if (brandList.includes(id)) {
            setBrandList(brandList.filter(t => t !== id));
        } else {
            setBrandList([...brandList, id]);
        }
    };

    const handleCategoryCheckboxChange = (id) => {
        if (categoryList.includes(id)) {
            setCategoryList(categoryList.filter(t => t !== id));
        } else {
            setCategoryList([...categoryList, id]);
        }
    }

    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>회원 정보 수정</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>     
                <div className='signIn-form'>
                    <div className='input_box'>
                        <p>관리자 구분</p>
                        <select {...register('role')}>
                            <option value="">선택하세요</option>
                            <option value="BM">브랜드 매니저</option>
                            <option value="CP">콘텐츠 퍼블리셔</option>
                        </select>

                        <p>이름</p>
                        <input 
                            type='text' 
                            name='name' 
                            className={errors.name ? 'error-input' : ''}
                            {...register('name', { required: '이름 입력은 필수입니다.' })} />
                            
                        {/* {errors.name && <p className='error-message'>{errors.name.message}</p>} */}

                        <p>연락처</p>
                        <input 
                            type='text' 
                            name='phone_number' 
                            className={errors.phone_number ? 'error-input' : ''}
                            {...register('phone_number')} />

                        <p>관리 페이지</p>
                        {brandsData?.data && watch("role") === "BM" && (
                            <div className="brand-checkboxes">
                                {brandsData.data.map((brand) => (
                                    <>
                                       <input
                                           id={`brand-${brand.id}`}
                                           type="checkbox"
                                           value={brand.id}
                                           checked={brandList.includes(brand?.id)}
                                           onChange={() => handleBrandCheckboxChange(brand?.id)}
                                           className="brand-checkbox-input" 
                                       />
                                       <label htmlFor={`brand-${brand.id}`} className="brand-checkbox-label">
                                           {brand.name}
                                       </label>
                                    </>
                                ))}
                            </div>
                        )}

                        {categoryData?.data && watch("role") === "CP" && (
                            <div className="brand-checkboxes">
                                {categoryData.data.map((category) => (
                                     <>
                                     <input
                                         id={`category-${category.id}`}
                                         type="checkbox"
                                         value={category.id}
                                         checked={categoryList.includes(category?.id)}
                                         onChange={() => handleCategoryCheckboxChange(category?.id)}
                                         className="brand-checkbox-input" 
                                     />
                                     <label htmlFor={`category-${category.id}`} className="brand-checkbox-label">
                                         {category.name}
                                     </label>
                                  </>

                
                                ))}
                            </div>
                        )}


                      
                       
                    </div>
                    <button className='btn_black_1' type="submit">
                        {updateAuthMutaion.isLoading 
                        ?  <LoadingSpinner /> : '수정'} 
                    </button>

                </div>
            </form>
        </Modal.Body>

    </Modal>
  );
};

export default EditUserModal;
