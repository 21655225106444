/***************************************************************************************
* FileName      : SampleBlock.js
* Description   : 소곤핏 관리자 > 메인 > 매거진영역관리 페이지 > 우측 매거진 생성 블록
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.04.11
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { getLayoutTypes, createLayout } from '../../../../api/admin/layout';

// 매거진 생성 레이아웃
function MagazineGenerator({setManagementType}) {
    const queryClient = useQueryClient();
    const [layoutTypes, setLayoutTypes] = useState([]);
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [layout, setLayout] = useState('');
    const [mainContentExist, setMainContentExist] = useState(false);
    const [visible, setVisible] = useState(false); // 공개(visible) : true = 비공개(hidden) : false

    const layoutValues = {
        '2x1': { x: 2, y: 1 },
        '2x2': { x: 2, y: 2 },
        '2x3': { x: 2, y: 3 },
        '1x2': { x: 1, y: 2 },
        '1x4': { x: 1, y: 4 },
        '1x6': { x: 1, y: 6 }
    };

    useEffect(() => {
        getLayoutTypes().then((res) => {
            setLayoutTypes(res?.data.data);
        });
    },[])

    function onSubmit(){
        if(layout === '' || title === '' || type === ''){
            alert('필수 입력값을 입력해주세요');
            return;
        }else{
            createLayout(title, type, layoutValues[layout]?.x, layoutValues[layout]?.y, mainContentExist, !visible)
            .then((res) => {
                console.log(res.data);
                setManagementType('none');
                // 쿼리를 다시 불러와서 새로고침
                queryClient.invalidateQueries('layout');
            });
        }
    }

    const isButtonDisabled = !title || !type || !layout; // 버튼 비활성화 여부
   
    return (
        <>
            <div className="mb-3 d-flex justify-content-between">
                <div/>
                <h5 className="fw-bold text-center">매거진 영역 추가</h5>
                <p className="fw-bold pointer"
                    onClick={() => setManagementType('none')}
                >X</p>
            </div>
            <div className="mb-3">
                <label htmlFor="title" className="form-label">타이틀</label>
                <input type="text"
                    className="form-control"
                    id="title"
                    placeholder="타이틀을 입력하세요"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="category" className="form-label">구분</label>
                <select className="form-select"
                    id="category"
                    onChange={(e) => setType(e.target.value)}
                >
                    <option value="" selected disabled>구분을 선택하세요</option>
                    {layoutTypes != [] && (
                        layoutTypes?.map((type) => <option value={type}>{type}</option>)
                    )}
                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="layout" className="form-label">레이아웃</label>
                <select className="form-select"
                    id="layout"
                    onChange={(e) => setLayout(e.target.value)}
                >
                    <option value="" selected disabled>레이아웃을 선택하세요</option>
                    <option value="2x1">2x1</option>
                    <option value="2x2">2x2</option>
                    <option value="2x3">2x3</option>
                    <option value="1x2">1x2</option>
                    <option value="1x4">1x4</option>
                    <option value="1x6">1x6</option>
                </select>
            </div>
            {/* 대표글 사용 true/false 토글 */}
            <div className="mb-3 form-check ps-0">
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">대표글 사용</label>
                <div className="form-check form-switch">
                    <input className="form-check-input pointer"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={mainContentExist}
                        onChange={()=>setMainContentExist(!mainContentExist)}
                    />
                </div>
            </div>

            {/* 대표글 사용 true/false 토글 */}
            <div className="mb-3 form-check ps-0">
                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">공개</label>
                <div className="form-check form-switch">
                    <input className="form-check-input pointer"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={visible}
                        onChange={()=>setVisible(!visible)}
                    />
                </div>
            </div>
            <button type="submit"
                className="btn btn-primary w-100"
                onClick={()=>onSubmit()}
                disabled={isButtonDisabled} // 버튼 비활성화 여부를 상태에 따라 제어
            >저장</button>
        </>
    )
}
export default MagazineGenerator;