// 중간관리자 > 상품 관리
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState , useEffect} from 'react';
import { useQueryClient } from 'react-query';
import { instance } from '../axiosConfig.js';

const PAGE_SIZE = 10;


// 해당 브랜드 상품 조회 
const fetchProduct = (BrandName, page, pageSize) => {
    let url;
    if (BrandName === "all") {
        url = `product/?page=${page}&page_size=${pageSize}`;
    } else {
        url = `product/?brand__name=${BrandName}&page=${page}&page_size=${pageSize}`;
    }

    return instance.get(url)
        .then(res => res.data);
};

export const useBrandProduct = (BrandName) => {
    const queryClient = useQueryClient();

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [toggle, setToggle] = useState(false);
  
    useEffect(()=>{
      window.scrollTo(0, 0);
      const nextPage = page + 1;
      queryClient.prefetchQuery([`Product${BrandName}`, BrandName , nextPage],
        () => fetchProduct(BrandName , nextPage)
      );
    },[page , queryClient])

    useEffect(() => {
      setPage(1);
    }, [BrandName]);
  
    const { data, error, isFetching } = useQuery([`Product${BrandName}`, BrandName , page], () => fetchProduct(BrandName , page), {
      onSuccess: (data) => {
        setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
     
        if (page < totalPages) {
          prefetchQuery(['Product', page + 1], () => fetchProduct(page + 1));
        }
      }
    });
  
    return {
      data,
      error,
      isFetching,
      page,
      setPage,
      totalPages,
      toggle,
      setToggle,
    };
};

// 중간관리자 > 상품 등록 
export const registerBMProduct = (data) =>
  instance.post(`product/log`, data).then(response => response.data);



// 해당 브랜드 상품 Log 조회 
const fetchProductLog = (BrandName, page, pageSize) => {
  let url;
  if (BrandName === "all") {
      url = `product/log?page=${page}&page_size=${pageSize}`;
  } else {
      url = `product/log?brand=${BrandName}&page=${page}&page_size=${pageSize}`;
  }

  return instance.get(url)
      .then(res => res.data);
};

export const useProductLog = (BrandId) => {
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [toggle, setToggle] = useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0);
    const nextPage = page + 1;
    queryClient.prefetchQuery([`Product${BrandId}`, BrandId , nextPage],
      () => fetchProductLog(BrandId , nextPage)
    );
  },[page , queryClient])

  useEffect(() => {
    setPage(1);
  }, [BrandId]);

  const { data, error, isFetching } = useQuery([`ProductLog${BrandId}`, BrandId , page], () => fetchProductLog(BrandId , page), {
    onSuccess: (data) => {
      setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
   
      if (page < totalPages) {
        prefetchQuery([`ProductLog${BrandId}`, page + 1], () => fetchProductLog(page + 1));
      }
    }
  });

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    totalPages,
    toggle,
    setToggle,
  };
};

// 관리자가 BM 상품 승인 
export const updateProductLog = (id, newData) =>
  instance.patch(`product/log/${id}`, newData).then(response => response.data);


//선택한 상품로그 정보 조회
export const getBMProduct = async (id) => {
  try {
    const response = await instance.get(`product/log/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// bm(중간관리자) 선택한 상품 수정 요청
export const updateBMProduct = (newData) =>
  instance.post(`product/log`, newData).then(response => response.data);

// 정상가 변경
export const updateProductOriginalPrice = (pk, original_price) => {
  return instance.patch(`product/${pk}`,
  {
      original_price: original_price
  })
}
// 판매가 변경
export const updateProductPrice = (pk, price) => {
  return instance.patch(`product/${pk}`,
  {
      sale_price: price
  })
}