/***************************************************************************************
* FileName      : AddProductModal.js
* Description   : 소곤핏 관리자 > d브랜드 > 전체 상품 목록 > 상품 추가 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date : 2024.05.30 : CHM : 브랜드 미선택 시 에러메세지 추가
*               : 2024.07.23 : PKH : 상품 카테고리 추가
* Reference     : 
***************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { registerProduct , useGetBrands , useGetBbti } from '../../../../api/admin/brandApi';
import { getUploadURL , uploadImage } from '../../../../api/common/uploadImageApi';
import { LoadingSpinner } from '../../Component/LoadingSpinner';
import { Row, Col, DropdownButton, Dropdown, Badge } from 'react-bootstrap';

const AddProductModal = ({ show, onHide }) => {

    const { register, handleSubmit, formState: { errors } , reset , watch } = useForm();

    // 공개 비공개
    const [isPrivate, setIsPrivate] = useState(true);

    const handleCheckboxChange = () => {
        setIsPrivate(!isPrivate); 
    };

    const [selectedFileName, setSelectedFileName] = useState('');
    
    // 브랜드 조회
    const { data: brandsData, isLoading: isBrandsDataLoading, isError: isBrandsDataError } = useGetBrands();

    // bbti 조회 
    const { data: BbtiData, isLoading: isBbtiDataLoading, isError: isBbtiDataError } = useGetBbti();

    // bbti 선택
    const [selectedTypes, setSelectedTypes] = useState([]);

    // 카테고리 선택
    const [selectedCategory, setSelectedCategory] = useState('BRA');

    // 카테고리 목록
    const categories = [
        { id: 1, name: 'BRA', label: '브라' },
        { id: 2, name: 'PANTY', label: '팬티' },
        { id: 3, name: 'SET', label: '세트' },
        { id: 4, name: 'ETC', label: '기타' }
    ];
    
    // 카테고리 값 변경 핸들러
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };
    
    const createProductMutaion = useMutation(registerProduct , {
        onSuccess : (data) => {
            // console.log("성공" , data)
            reset();
            onHide();
            setSelectedTypes([]);
            setSelectedFileName('');
            alert("상품 등록 완료");
        },
        onError : (data) => {
            alert(data.request.responseText);
            // console.log("error", data)
        }
    });

    const uploadImageMutation = useMutation(uploadImage,{
        onSuccess : (result) => {
            // brand 값을 선택하지 않았을 경우 undefined로 변환
            let brand = watch("brand");
            if(brand == ''){
                alert('브랜드를 선택해주세요');
                return;
            }

            const productData = {
                type: selectedCategory,  
                name: watch("name"),
                brand: brand,
                // image: result.variants[0],
                image: result,
                original_price: watch("original_price"),
                sale_price : watch("sale_price"),
                link: watch("link"),
                bbti : selectedTypes,
                hidden : isPrivate 
            };
            // console.log("productData",productData)
            createProductMutaion.mutate(productData);
        }
    });

    const uploadURLMutaion = useMutation(getUploadURL , {
        onSuccess : (data) => {
            console.log(data);
            uploadImageMutation.mutate({
                uploadURL : data.uploadURL,
                imageURL : data.imageURL,
                file : watch("productImage"),
            })
        },
        onError : (data) => {
            console.log("error", data)
        }
    });



    const onSubmit = (data) => {
        //   console.log("data:", watch("name"))
        uploadURLMutaion.mutate();
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFileName(file.name);
        } else {
            setSelectedFileName('');
        }
    };
    

    const handleBBTiCheckboxChange = (type) => {
      if (selectedTypes.includes(type)) {
        setSelectedTypes(selectedTypes.filter(t => t !== type));
      } else {
        setSelectedTypes([...selectedTypes, type]);
      }
    };

    return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>상품 등록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(onSubmit)}>     
                <div className='input-form'>
                    <div className='input_box'>
                        <p>브랜드</p>
                        
                        {isBrandsDataLoading ? (
                                <p>Loading...</p>
                            ) : isBrandsDataError ? (
                                <p>Error loading brands data</p>
                            ) : (
                                <select {...register('brand')} defaultValue="">
                                    <option value="" disabled>
                                        브랜드를 선택하세요
                                    </option>
                                    {brandsData?.data.map((brand) => (
                                        <option key={brand?.id} value={brand?.id}>
                                            {brand?.name}
                                        </option>
                                    ))}
                                </select>
                            )}

                        <p>상품명</p>
                        <input 
                            type='text' 
                            name='name' 
                            className={errors.name ? 'error-input' : ''}
                            {...register('name', { required: '상품명 입력은 필수입니다.' })} />
                        
                        <p>상품 타입</p>
                        <div className='category-s-wrap'>
                            {categories.map(item => (
                                <div key={item.id}>
                                    <input
                                        type="radio"
                                        className='bbti_checkbox'
                                        checked={selectedCategory === item.name}
                                        onChange={() => handleCategoryChange(item.name)}
                                        id={item.name}  
                                        name="bbti_radio_group"
                                    />
                                    <label
                                        className="bbti_label"
                                        htmlFor={item.name}
                                    >
                                        {item.label}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <p>사이트링크</p>
                        <input 
                            type='text' 
                            name='link' 
                            className={errors.link ? 'error-input' : ''}
                            {...register('link', { required: '사이트링크 입력은 필수입니다.' })} />
                        
                        <p>상품이미지</p>
                        <div class="filebox">
                            <input  className={errors.productImage ? 'upload-name error-input' : 'upload-name'} 
                                    value={selectedFileName} 
                                    placeholder="상품 이미지파일" />
                                    
                            <label htmlFor="productImage_file">파일찾기</label> 

                            <input
                                type='file'
                                id='productImage_file'
                                name='productImage'
                                accept="image/jpg, image/png, image/jpeg"
                        
                                {...register('productImage', { required: '상품 이미지는 필수입니다.' } )}
                                onChange={handleFileChange}
                            />
                        </div>
                     
                        <p>상품가격</p>
                        <input 
                            type='text' 
                            name='original_price' 
                            className={errors.original_price ? 'error-input' : ''}
                            {...register('original_price', { required: '상품가격 입력은 필수입니다.' })} />

                        <p>판매가격</p>
                        <input 
                            type='text' 
                            name='sale_price' 
                            className={errors.sale_price ? 'error-input' : ''}
                            {...register('sale_price', { required: '판매가격 입력은 필수입니다.' })} />

                        <p>BBTI</p>
                        <div className='bbtiList'>
                            { isBbtiDataLoading ? (
                                <p>Loding...</p>
                            ) : isBbtiDataError ? (
                                <p>Error bbti data</p>
                            ) : (
                                BbtiData?.data.map(item => (
                                    <div key={item?.id}>

                                        <input 
                                            type="checkbox"
                                            className='bbti_checkbox'  
                                            checked={selectedTypes.includes(item?.type)}
                                            onChange={() => handleBBTiCheckboxChange(item?.type)}
                                            id={`bbti_checkbox_${item.id}`} />

                                        <label 
                                            className="bbti_label" 
                                            htmlFor={`bbti_checkbox_${item.id}`}>
                                            {item?.type}
                                        </label>

                                    </div>
                                ))
                            )}
                        </div>
                       
                        <div className="form-check">

                            <input className="form-check-input custom-checkbox" type="checkbox"  id="hiddenCheck" checked={isPrivate} onChange={handleCheckboxChange} />
                            <label className="form-check-label" for="hiddenCheck">
                            비공개
                            </label>
                        </div>
                       
                       
                           
                    </div>
                    <button className='btn_black_1' type="submit">
                        {createProductMutaion.isLoading || uploadImageMutation.isLoading || uploadURLMutaion.isLoading
                        ?  <LoadingSpinner /> : '상품 등록'} 
                    </button>

                </div>
            </form>
        </Modal.Body>
    </Modal>
  );
};

export default AddProductModal;
