/***************************************************************************************
* FileName      : MLContentModal.js
* Description   : 소곤핏 관리자 > 매거진 관리 , 매거진 로그 > 콘텐츠 확인 모달창
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.200
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/

import { Modal } from 'react-bootstrap';
import  { CustomProductItem, CustomSelectProductItem }  from '../../../admin/Magazine/Writing/ProductItemSample'

const MLContentModal = ({ targetData, show, onHide }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <MagazineContent targetData={targetData}/>
            </Modal.Body>
    </Modal>
  );
};

function MagazineContent({targetData}){
    return (
        <>
        <section className='magazine-content'>
            {targetData?.map((item, index) => (
                <div key={index}>
                    {item[0] === 'C' ? (
                        <div className='editor-content'
                            dangerouslySetInnerHTML={{ __html: item.slice(1) }}
                        />
                    ):(
                    item[0] === 'P' ? (
                        <div className='product-wrap'>
                            <CustomSelectProductItem productList={item.substring(1)}/>
                        </div>
                    ):(
                        <div className='product-wrap'>
                            <CustomProductItem
                                type={
                                    item[0] === 'N' ? 'new' :
                                    item[0] === 'B' ? 'best' :
                                    item[0] === 'R' && 'random'
                                }
                                layout={item[1]}
                            />
                        </div>
                    )
                    )}
                </div>)
            )}
        </section>
        </>
    )
}

export default MLContentModal;
