/***************************************************************************************
* FileName      : BannerManagement.js
* Description   : 소곤핏 관리자 > 메인 > 배너관리
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import SideMenuItem from './SideMenu';
function BannerManagement()
{
    return (       
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <SideMenuItem to="/admin/mainManagement/banner">배너관리</SideMenuItem>
                    </div>
                    <div className="col-md-12">
                        <div className="box">
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default BannerManagement;