/***************************************************************************************
 * FileName      : MagazineContentList.js
 * Description   : 소곤핏 매거진 콘텐츠 리스트
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.03.19
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { useState } from "react";
import { useMagazineContent } from "../../../api/services/magazine.js";
import LatestItem from "../Main/LatestItem";
import Pagination from "../../service/Component/Pagination.js";
import MagazineMain from "../Main/MagazineMain.js";

function MagazineContentList({ magazineCategoryId }) {
	const {
		data: MagazineData,
		error,
		isLoading,
		page,
		setPage,
		toggle,
		setToggle,
	} = useMagazineContent(magazineCategoryId);

	const handlePrevPage = () => {
		setPage((prevPage) => prevPage - 1);
	};

	const handleNextPage = () => {
		setPage((prevPage) => prevPage + 1);
	};

	return (
		<>
			{MagazineData?.data?.length > 0 ? (
				<>
					<MagazineMain
						content={MagazineData?.data[0]}
						nowPage={"소곤로그"}
					/>

					<div className="latestContent-wrap">
						{MagazineData?.data
							?.slice(1)
							.map(
								(contentItem, index) =>
									!contentItem.hidden && (
										<LatestItem
											key={contentItem.id}
											content={contentItem}
											isLoading={isLoading}
											nowPage={"소곤로그"}
										/>
									)
							)}
					</div>

					<div className="pagination-wrap">
						<Pagination
							count={MagazineData?.totalCnt || 0}
							page={page}
							setPage={setPage}
							handlePrevPage={handlePrevPage}
							handleNextPage={handleNextPage}
						/>
					</div>
				</>
			) : (
				<p>해당 카테고리에 등록된 매거진이 없습니다</p>
			)}
		</>
	);
}

export default MagazineContentList;
