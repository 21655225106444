import { instance } from '../axiosConfig.js';


export const usernameLogIn = ({ username , password }) => instance.post(`/auth/login`,{ username , password });

export const userLogout = () => instance.get('/auth/logout');

export const getMe = () => instance.get(`/auth/me`).then((response) => response.data);


// 아이디 비밀번호 찾기
export const findId = (email) => instance.post(`/auth/find-username`,{email: email});

export const findIdVerify = (email, key) => instance.post(`/auth/verify-email-username`,{email: email, verification_code: key});

export const findPw = (id, email) => instance.post(`/auth/find-password`,{username: id, email: email});

export const updatePw = (id, email, key, pw1, pw2) => instance.post(`/auth/verify-email-password`,{
    username: id, 
    email: email,
    verification_code: key,
    password: pw1,
    password2: pw2
});

// 유저 BBTI 테스트 결과 저장
export const updateBBTI = ({id, newBbti, size}) => {
    return instance.patch(`/auth/me`, {
        bbti: newBbti, 
        size: size
    })
};