// 관리자 > 신고 관리
import { queryClient, useMutation, useQuery, prefetchQuery } from "react-query";
import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { instance } from "../axiosConfig.js";

const PAGE_SIZE = 10;

// 관리자 > 소곤톡 > 게시글 신고 조회
const fetchPostReportList = (page, totalPages) => {
	if (page > totalPages) {
		return Promise.resolve({ data: [], totalCount: 0 });
	}
	return instance.get(`/report/post?page=${page}`).then((res) => res.data);
};

export const usePostReportList = () => {
	const queryClient = useQueryClient();

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		window.scrollTo(0, 0);
		const nextPage = page + 1;
		if (nextPage <= totalPages) {
			queryClient.prefetchQuery([`PostReportList`, nextPage], () =>
				fetchPostReportList(nextPage, totalPages)
			);
		}
	}, [page, totalPages, queryClient]);

	const { data, error, isFetching } = useQuery(
		[`PostReportList`, page],
		() => fetchPostReportList(page, totalPages),
		{
			onSuccess: (data) => {
				setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));

				if (page < totalPages) {
					prefetchQuery(["PostReportList", page + 1], () =>
						fetchPostReportList(page + 1, totalPages)
					);
				}
			},
		}
	);

	return {
		data,
		error,
		isFetching,
		page,
		setPage,
		totalPages,
	};
};

// 게시글 삭제
export const deletePost = (reportId) => {
	return instance
		.delete(`report/post/${reportId}`)
		.then((response) => response.data)
		.catch((error) => {
			console.error("Error deleting brand:", error);
			throw error;
		});
};

// 관리자 > 소곤톡 > 게시글 신고 상태 수정
export const updateReportPost = (id, newData) =>
	instance
		.patch(`report/post/${id}`, newData)
		.then((response) => response.data);

// 관리자 > 소곤톡 >  댓글 신고 조회
const fetchReplyReportList = (page, totalPages) => {
	if (page > totalPages) {
		return Promise.resolve({ data: [], totalCount: 0 });
	}
	return instance.get(`report/reply?page=${page}`).then((res) => res.data);
};

export const useReplyReportList = () => {
	const queryClient = useQueryClient();

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		window.scrollTo(0, 0);
		const nextPage = page + 1;
		if (nextPage <= totalPages) {
			queryClient.prefetchQuery([`ReplyReportList`, nextPage], () =>
				fetchReplyReportList(nextPage, totalPages)
			);
		}
	}, [page, totalPages, queryClient]);

	const { data, error, isFetching } = useQuery(
		[`ReplyReportList`, page],
		() => fetchReplyReportList(page, totalPages),
		{
			onSuccess: (data) => {
				setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));

				if (page < totalPages) {
					prefetchQuery(["ReplyReportList", page + 1], () =>
						fetchReplyReportList(page + 1, totalPages)
					);
				}
			},
		}
	);

	return {
		data,
		error,
		isFetching,
		page,
		setPage,
		totalPages,
	};
};

// 관리자 > 소곤톡 > 댓글 신고 상태 수정
export const updateReplyPost = (id, newData) =>
	instance
		.patch(`report/reply/${id}`, newData)
		.then((response) => response.data);
