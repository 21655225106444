/***************************************************************************************
* FileName      : MyMagazineLikes.js
* Description   : 소곤핏 마이페이지 > 매거진 좋아요 리스트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.07.08
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import React, { useState , useEffect } from 'react';
import MobileHeader from "./Component/MobileHeader";
import Gnb from '../../Gnb';

import { useInfiniteQuery } from 'react-query';

import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../admin/Component/LoadingSpinner';

import { fetchLikeMagazine } from '../../api/services/mypageApi';
import Likeitem from './Mypage/MyMagazineLikes/Likeitem';

function MyMagazineLikes()
{
    const [page, setPage] = useState(0);
    const [tag, setTag] = useState('all');

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
      } = useInfiniteQuery(  ['likeMagazine', page], ({ pageParam = 1 }) => fetchLikeMagazine(pageParam), {
        getNextPageParam: (lastPage, allPages) => {
          return lastPage.nextPage; 
        },
    });

    console.log(data);


    return (
        
        <>
        <MobileHeader back={true} title={"매거진 좋아요 목록"}/>
         <div className="mypage">
            <section className='mypage-menu my-5'>
                <div className="inner">
          
                <div className="post-wrap">
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                <>
                {data.pages.length === 0 || data.pages.reduce((total, page) => total + page.data.length, 0) === 0 ? (
                    <div className='text-center mt-3'>
                        좋아요를 눌러 보세요
                    </div>
                ) : (
                    <InfiniteScroll 
                        style={{ display: 'flex', flexWrap: 'wrap', gap : '10px' , flexDirection: 'column' }}
                        dataLength={data.pages.reduce((total, page) => total + page.data.length, 0)}
                        next={() => fetchNextPage()}
                        hasMore={hasNextPage}
                        loader={<div>Loading...</div>}
                        endMessage={<div></div>}
                    >
                    {data?.pages.map((page, pageIndex) => (
                        <React.Fragment key={pageIndex}>
                            {page.data.map(item => (
                                <Likeitem item={item} key={item.id} />
                            ))}
                        </React.Fragment>
                    ))}
                    </InfiniteScroll>
                )}
                </>
                )}
                
                </div>
                </div>
            </section>

            <Gnb/>
        </div>
        </>
    )
}

export default MyMagazineLikes;