/***************************************************************************************
* FileName      : ProductRow.js
* Description   : 소곤핏 관리자 > 브랜드 > 전체 상품 목록 > 상품 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.03.19
* Modifide Date : 2024.06.11 : CHM : 상품ROW 레이아웃 변경
*               : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';
import { deleteProduct } from  '../../../../api/admin/brandApi';
import ToggleSwitch from '../../Component/ToggleSwitch';

function ProductRow({ product, onEditClick, onSizeClick, onEditSizeClick, onDesignClick, onEditDesignClick}){
    const [isDeleting, setIsDeleting] = useState(false);

    const categoryLabels = {
      'BRA': '브라',
      'PANTY': '팬티',
      'SET': '세트',
      'ETC': '기타'
    }; 

    const handleDeleteClick = (productId) => {
        const confirmDelete = window.confirm('정말로 삭제 하시겠습니까?');
        if (confirmDelete) {
          setIsDeleting(true);
    
          deleteProduct(productId)
            .then(response => {
              alert("성공적으로 삭제되었습니다.")
            })
            .catch(error => {
              console.error('상품 삭제 중 에러 발생:', error);
            })
            .finally(() => {
              setIsDeleting(false);
            });
        }
    };

    const [bbtiList, setBbtiList] = useState([]);
    useEffect(()=>{
      setBbtiList(product?.bbti?.map(item => item?.type));
    }, [product?.bbti])

    return (
        <React.Fragment key={product?.id}>
            <tr style={{fontSize: '14px'}}>
                <td>{product?.id}</td>
                <td>{product?.brand?.name}</td>
                <td>{product?.name}</td>
                <td>{categoryLabels[product?.type]}</td>
                <td><img src={product?.image}/></td>
                <td><a className='pink_btn' onClick={()=>window.open(product?.link)}>열기</a></td>
                <td>{product?.original_price?.toLocaleString()}</td>
                <td>{product?.sale_price?.toLocaleString()}</td>
                {/*
                  <td>{product?.bbti?.map(item => item.type).join(', ')}</td>
                */}
                <td>
                  <div class="admin-tooltip-container">
                    {
                      bbtiList.length > 0 && (
                        <span class="admin-tooltip">
                          {bbtiList.map((item, index) => (
                            <span key={index} class="admin-tooltip-item">{item}</span>
                          ))}
                        </span>
                      )
                    }
                    <span class="text">{bbtiList.length === 0 ? '-' : `${bbtiList[0]} 외 ${product?.bbti?.length-1}`}</span>
                  </div>
                </td>
                <td>
                  <p className="mb-3">{product?.sizes?.length === 0 ? '-' : `총 ${product?.sizes?.length}개`}</p>
                  <a className='pink_btn' onClick={()=>{
                      product?.sizes?.length === 0 ?
                      onSizeClick(product?.id) :
                      onEditSizeClick(product?.id);
                    }}>
                    {product?.sizes?.length === 0 ? '등록' : '수정'}
                  </a>
                </td>
                <td>
                  {/* 디자인 라벨 */}
                  <p className="mb-3">{product?.design_label ? 'O' : 'X'}</p>
                  <a className='pink_btn' onClick={()=>{
                    product?.design_label ?
                    onEditDesignClick(product?.design_label):
                    onDesignClick(product?.id)
                  }}>
                    {product?.design_label ? '수정' : '등록'}
                  </a>
                </td>
                <td>{product?.view}</td>
                <td>
                  {/* {product?.hidden ? '비공개' : '공개' } */}
                  <ToggleSwitch pk={product?.id} checked={product?.hidden} toggleType="product" />
                </td>
                <td>
                  <a className='pink_btn' onClick={()=>onEditClick(product?.id)}>수정</a>         
                </td>
                <td>
                    <a 
                      className='pink_btn' 
                      onClick={() => handleDeleteClick(product?.id)} disabled={isDeleting}>
                        {isDeleting ? '삭제 중...' : '삭제'}
                    </a>
                </td>
            </tr>
        </React.Fragment>
    )
}

export default ProductRow