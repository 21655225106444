// 고객 > 매거진 관리
import { queryClient, useMutation, useQuery, prefetchQuery } from "react-query";
import { useState, useEffect } from "react";
// import SkeletonComponent from '../../routes/service/Magazine/SkeletonComponent.js';
import { useQueryClient } from "react-query";
import { instance } from "../axiosConfig.js";

const PAGE_SIZE = 10;

// 매거진 카테고리 조회 (고객용)
export const useGetCategory = () => {
	return useQuery("category", async () => {
		const response = await instance.get(`category/customer`);
		return response.data;
	});
};

// 매거진 조회 (고객용)
const fetchMagazineContent = (id, page, pageSize) => {
	let url;
	if (id === "all") {
		url = `/magazine/customer?page=${page}&page_size=${pageSize}`;
	} else {
		url = `/magazine/customer?category=${id}&page=${page}&page_size=${pageSize}`;
	}

	return instance.get(url).then((res) => res.data);
};

export const useMagazineContent = (id) => {
	const queryClient = useQueryClient();

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		const nextPage = page + 1;
		queryClient.prefetchQuery([`CmagazineContent${id}`, id, nextPage], () =>
			fetchMagazineContent(id, nextPage)
		);
	}, [page, queryClient]);

	useEffect(() => {
		setPage(1);
	}, [id]);

	const { data, error, isFetching, isLoading } = useQuery(
		[`CmagazineContent${id}`, id, page],
		() => fetchMagazineContent(id, page),
		{
			staleTime: 5000,
			keepPreviousData: true,
		},
		{
			onSuccess: (data) => {
				setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));

				if (page < totalPages) {
					prefetchQuery(["magazineContent", page + 1], () =>
						fetchMagazineContent(page + 1)
					);
				}
			},
		}
	);

	// if (isLoading) {
	//   return <SkeletonComponent />;
	// }

	return {
		data,
		error,
		isFetching,
		page,
		setPage,
		totalPages,
		toggle,
		setToggle,
	};
};

//선택한 매거진 정보 조회 (고객용)
export const getMagazine = ({ id, viewCountKey }) => {
	return instance
		.get(`magazine/customer/${id}`, {
			params: {
				"View-Count-Key": viewCountKey,
			},
		})
		.then((res) => res.data);
};

export const getMainMagazine = (name) =>
	instance.get(`magazine/customer/?category=${name}&page_size=2`);

// 북마크 (고객용)
export const toggleBookmark = (data) =>
	instance.post(`bookmark/`, data).then((response) => response.data);

// 매거진 좋아요 (고객용)
export const toggleMagazineLike = (data) =>
	instance.post(`like/magazine`, data).then((response) => response.data);

// BBTI기준 매거진 조회 (고객용)
export const getBbtiMagazie = (bbti) =>
	instance.get(`magazine/customer?bbti=${bbti}`);

export const getRecommendMagazine = (id) =>
	instance.get(`magazine/recommend/${id}?page_size=3`);
