/***************************************************************************************
* FileName      : MagazineManagement.js
* Description   : 소곤핏 관리자 > 메인 > 매거진영역관리 페이지 
* Company       : (주)엘리그
* Author        : 최현민
* Created Date  : 2024.03.28
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import SideMenuItem from './SideMenu';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getLayout, newLayoutPriority } from '../../../api/admin/layout';
import SampleMagazineBlock from './MagazineBlock/SampleBlock';
import MagazineGenerator from './MagazineBlock/GeneratorBlock';
import UpdateMagazineBlock from './MagazineBlock/UpdateBlock';
import { useLocation } from 'react-router-dom';

function MagazineManagement(){
    const queryClient = useQueryClient();
    const location = useLocation();

    const { data, isLoading, isError } = useQuery('layout', getLayout);

    const [magazines, setMagazines] = useState([]);
    const [managementType, setManagementType] = useState('none');
    const [updateData, setUpdateData] = useState();

    useEffect(() => {
        if (data) {
            console.log(data);
            setMagazines(data);
        }
    }, [data, location.pathname]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(magazines);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        console.log("순서 변경 : ",(items?.map(x => x?.id)).join(","));

        newLayoutPriority((items?.map(x => x?.id)).join(","))
            .then((res) => {
                // 쿼리를 다시 불러와서 새로고침
                queryClient.invalidateQueries('layout');
            })
            .catch((error) => {
                alert("순서변경에 실패했습니다.");
            });
    };

    isLoading && <div>Loading...</div>;

    function magazineSetting(magazineBlockData){
        setUpdateData(magazineBlockData);
        setManagementType('setting');
    }

    return (       
        <>
        <section className="admin-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 menu-2">
                        <SideMenuItem to="/admin/mainManagement/magazine">매거진영역관리</SideMenuItem>
                    </div>

                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="magazine-items">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="col-md-4">
                                    {magazines?.map((item, index) => (
                                        <Draggable key={item?.id} draggableId={String(item?.id)} index={index}>
                                            {(provided) => (
                                                <div
                                                    key={item?.id}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <MagazineDndBlock
                                                        key={item?.id}
                                                        id={item?.id}
                                                        title={item?.title}
                                                        type={item?.type}
                                                        x={item?.x_layout}
                                                        y={item?.y_layout}
                                                        main={item?.main_content_exist}
                                                        hidden={item?.hidden}
                                                        setManagementType={setManagementType}
                                                        magazineSetting={magazineSetting}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    <div className="box text-center pointer"
                                        onClick={() => setManagementType('generate')}
                                    >+ 매거진 영역 추가</div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    

                    
                    {managementType === 'generate' ? (
                        <div className="col-md-5">
                            <div className="box">
                                <MagazineGenerator setManagementType={setManagementType}/>
                            </div>
                        </div>
                    ) : (
                    managementType === 'setting' ? (
                        <div className="col-md-5">
                            <div className="box">
                                <UpdateMagazineBlock 
                                    data={updateData}
                                    setManagementType={setManagementType}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-3">
                            <div className="box">
                                <SampleMagazineBlock data={data}/>
                            </div>
                        </div>
                    )
                    )}
                    
                </div>
            </div>
        </section>
        </>
    )
}

// 드래그 앤 드롭 블록
function MagazineDndBlock({id, title, type, x, y, main, hidden, setManagementType, magazineSetting}){
    return(
        <div className={`box mb-2 ${hidden && "bg-light"}`}>
            <div className="d-flex justify-content-between">
                <p className={`${hidden && "text-secondary"}`}>{title}</p>
                <p className="pointer"
                    onClick={() => magazineSetting({id, title, type, x, y, main, hidden})}
                >설정</p>
            </div>
        </div>
    )
}


export default MagazineManagement;