import React from 'react';

function SkeletonComponent({ width, height }) {
  const style = {
    width: width,
    height: height
  };

  return <div className="skeleton" style={style}></div>;
}

export default SkeletonComponent;
