import { useState , useEffect} from 'react';
import { queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { instance } from '../axiosConfig.js';

const PAGE_SIZE = 10;

export const registerUser = (data) =>{
  return instance.post(`auth/signup`, data)
  .then(response => response.data)

}

// 회원가입 > ID 중복 및 아이디 형식 확인
export function getCheckedUsername(username) {  
  return instance.post('/auth/check-username', {
      username: username,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}



// 회원정보 조회
const fetchAuthList = (page , type , pageSize) => {
  return instance.get(`/auth/list?type=${type}&page=${page}&page_size=${pageSize}`)
    .then(res => res.data);
};

export const useAuthListQuery = (type) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [toggle, setToggle] = useState(false);

  const { data, error, isFetching } = useQuery([`authList${type}`, page], () => fetchAuthList(page,type), 
  {
    staleTime:5000,
    keepPreviousData:true
  },
  {
    onSuccess: (data) => {
      setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
   
      if (page < totalPages) {
        prefetchQuery(['authList', page + 1], () => fetchAuthList(page + 1));
      }
    }
  });

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    totalPages,
    toggle,
    setToggle,
  };
};

// 고객관리 검색 
export const handleSearch = async ({
  searchAuth, 
  setSearchUrl,
  setSearchResults,
  }) => {
  try {
    let url = `/auth/list`;

    if(searchAuth) url += `?search=${searchAuth}` 

    console.log(url)
    setSearchUrl(url)
    const response = await instance.get(url);
    console.log("검색결과",response.data)
    setSearchResults(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
}
};

export const UpdateAcceptedAuth = (authId) =>
  instance.patch(`auth/${authId}`, { is_accepted: true }).then(response => response.data);

export const DeleteAuth = (pk) =>{
  return instance.delete(`auth/${pk}`);;
}

//선택한 유저 정보 조회
export const getUser = async (id) => {
  try {
    const response = await instance.get(`auth/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// 선택한 유저 수정.
export const updateUser = (id, newData) =>
  instance.patch(`auth/${id}`, newData).then(response => response.data);
