/***************************************************************************************
* FileName      : ProductRow.js
* Description   : 중간 관리자 (BM) > product > 상품 Row 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2024.03.19
* Modifide Date : 2024.05.30 : CHM : 상품 수정모달 key값 추가
*               : 
* Reference     : 
***************************************************************************************/

import React, { useState , useEffect } from 'react';
import { deleteProduct } from  '../../../../api/admin/brandApi';
import EditProductModal from '../ProductLogBoard/EditProductModal';
import ToggleSwitch from '../../Component/ToggleSwitch';
import { AiOutlineEdit } from 'react-icons/ai';
import { updateProductOriginalPrice , updateProductPrice } from '../../../../api/admin/product';

function ProductRow({ product }){
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);


    const [isDeleting, setIsDeleting] = useState(false);

    const handleDeleteClick = (productId) => {
        const confirmDelete = window.confirm('정말로 삭제 하시겠습니까?');
        if (confirmDelete) {
          setIsDeleting(true);
    
          deleteProduct(productId)
            .then(response => {
              alert("성공적으로 삭제되었습니다.")
            })
            .catch(error => {
              console.error('상품 삭제 중 에러 발생:', error);
            })
            .finally(() => {
              setIsDeleting(false);
            });
        }
    };


    return (
        <React.Fragment key={product?.id}>
            <tr className='fs-8'>
                <td>{product?.id}</td>
                <td>{product?.name}</td>
                <td><img src={product?.image}/></td>
                <td style={{maxWidth: '360px'}}>
                    {product?.link && (
                        <a href={product.link} target="_blank" rel="noopener noreferrer">
                            {product.link}
                        </a>
                    )}
                </td>
                <td>
                  {product?.original_price?.toLocaleString()}
                  <AiOutlineEdit 
                    className="text-grey pointer" 
                    onClick={()=>{
                      let newOriginalPrice = prompt("상품가격 수정(숫자만 입력)\n"+product?.name, product?.original_price);
                      if(Number(newOriginalPrice)){
                        updateProductOriginalPrice(product?.id, newOriginalPrice);
                      }else{
                        alert('변경 실패 : 숫자만 입력해주세요.');
                      }
                    }}
                  />
                </td>
                <td>
                  {product?.sale_price?.toLocaleString()}
                  <AiOutlineEdit 
                      className="text-grey pointer" 
                      onClick={()=>{
                        let newOriginalPrice = prompt("판매가격 수정(숫자만 입력)\n"+product?.name, product?.sale_price);
                        if(Number(newOriginalPrice)){
                          updateProductPrice(product?.id, newOriginalPrice);
                        }else{
                          alert('변경 실패 : 숫자만 입력해주세요.');
                        }
                      }}
                  />
                </td>
                <td>{product?.brand?.name}</td>
                <td>{product?.view}</td>
                <td>{product?.bbti?.map(item => item.type).join(', ')}</td>
          
                <td></td>
                <td>
                  {/* {product?.hidden ? '비공개' : '공개' } */}
                  <ToggleSwitch pk={product?.id} checked={product?.hidden} toggleType="product" />
                </td>
                <td>
                  <a className='pink_btn mr-1' onClick={handleShowModal}>수정</a>         
                
                    <a 
                      className='pink_btn' 
                      onClick={() => handleDeleteClick(product?.id)} disabled={isDeleting}>
                        {isDeleting ? '삭제 중...' : '삭제'}
                    </a>
                </td>
            </tr>

          {/* 상품 수정 모달 */}
          <EditProductModal
            key={product?.id}
            show={showModal}
            onHide={handleCloseModal}
            id={product?.id}
            productId={product?.id}
            status="A"
          />
        </React.Fragment>
    )
}

export default ProductRow