/***************************************************************************************
* FileName      : NotFound.js
* Description   : 소곤핏 404 페이지
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import Gnb from "../../Gnb";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";

function NotFound(){
    return (
        <>
        <Helmet>
            <title>페이지를 찾을 수 없습니다.</title>
        </Helmet>
        <div className="main">
            <section className="errorWrap">
                {/* <FontAwesomeIcon icon={faExclamation} /> */}
                <p className="h404">페이지를 찾을 수 없습니다</p>
                <p>요청하신 페이지는 사라졌거나</p>
                <p>다른 페이지로 변경 되었습니다</p>
                <p>주소를 다시 확인해 주세요</p>
                
                <Link to="/" className="e-home-btn mt-3">홈으로 가기</Link>

            </section>
        </div>
        
        <Gnb/>
        </>
    )
}

export default NotFound;