/***************************************************************************************
* FileName      : Likeitem.js
* Description   : 마이페이지 > 관심 목록 > Likeitem 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.05.13
* Modifide Date :
*               : 
* Reference     : 
***************************************************************************************/
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart , faCommentDots , faPen } from '@fortawesome/free-solid-svg-icons';

import { extractYearMonthDay2 } from '../../../admin/common/extractDateUtils';

function Likeitem({ item })
{
    // const handleClick = () => {
    //     window.location.href = `/post/${item.post}`;
    // };

    function truncateText(text, maxLength) {
        if (text.length > maxLength) {
          return text.slice(0, maxLength) + '...';
        }
        return text;
    }
    
    console.log(item);
    return(
        <>
    <Link className='post-content' to={`/post/${item.id}`}>
            <div className='post-tags'>    
                {item?.tags.map(tag => (
                    <span key={tag.id} className='post-tag'>#{tag.name}</span>
                ))}
                {item?.is_hot && <span className='post-tag hot'>HOT</span>}
                {item?.is_new && <span className='post-tag new'>NEW</span>}
            </div>
            <p className='post-tit'>{item?.title}</p>
            <span className='post-desc'>{truncateText(item?.content, 50)}</span>
            <div className='post-info'>
                <div>   
                    <span className='date'>
                        {item?.is_edited ? `${item?.posted_at} (수정됨)` : item?.posted_at}
                    </span>

                    <span>조회수 {item?.view_count}</span>
                </div>
                <div>   
                    <span className='heart'>
                        <FontAwesomeIcon icon={faHeart} style={{ marginRight: '3px' }}/>
                        {item?.like_count}
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faCommentDots} style={{ marginRight: '3px' }}/>
                        {item?.reply_count}
                    </span>
                </div>
            </div>
        </Link>
        </>
    )
}

export default Likeitem