// 고객 > 마이페이지
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState , useEffect } from 'react';
// import SkeletonComponent from '../components/service/SkeletonComponent';
import SkeletonComponent from '../../components/service/SkeletonComponent.js';
import { useQueryClient } from 'react-query';
import { instance } from '../axiosConfig.js';
const PAGE_SIZE = 10;


// 본인의 활동기록 리스트 조회 조회 무한스크롤 (고객용)
export const fetchRecordData = (tag, page) => {
    const url = tag === "all"
    ? `/record/?page=${page}`
    : `/record/?tag=${tag}&page=${page}`;
  
    return instance.get(url)
        .then(res => res.data);
};


// 마이페이지 > 관심목록 리스트 조회 무한스크롤 (고객용)
export const fetchLikePost = (page) => {
    const url = `/like/post?page=${page}`;
  
    return instance.get(url)
        .then(res => res.data);
};
  
export const getRecordCheck = (id) => instance.get(`/record/check/${id}`);


// 마이페이지 > 매거진 좋아요 리스트 조회 무한스크롤 (고객용)
export const fetchLikeMagazine = (page) => {
    const url = `/like/magazine?page=${page}`;
  
    return instance.get(url)
        .then(res => res.data);
};
  