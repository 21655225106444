/***************************************************************************************
 * FileName      : MobileHeader.js
 * Description   : 소곤핏 고객 모바일 네비게이션 헤더
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  :
 * Modifide Date :
 *               :
 * Reference     :
 ***************************************************************************************/
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom/dist";
import { useEffect } from "react";

/**
 * @param back 백버튼이 있으면 true, 없으면 false
 * @param title 타이틀이 있으면 텍스트 입력, 없으면 null
 * @param shadow 그림자 지울때 false 사용
 * @returns 모바일 헤더 출력
 */
export default function MobileHeader({ back, title, unsetShadow }) {
	// 뒤로가기 버튼 클릭 시 이전 페이지가 없으면 소곤핏 메인으로 이동
	const navigate = useNavigate();
	const handleBackClick = () => {
		const previousPath = document.referrer;
		const currentHost = window.location.hostname;

		if (
			previousPath === "" ||
			previousPath == currentHost ||
			previousPath.includes(currentHost)
		) {
			window.history.back();
		} else {
			navigate("/");
		}
	};

	if (title !== null) {
		return (
			<div className="mobile-header" style={unsetShadow && {"boxShadow":"unset"}}>
				<Row className="m-0 px-2 py-3">
					{/* 백버튼 */}
					<Col
						xs={2}
						className="text-start pointer"
						onClick={() => back && handleBackClick()}
					>
						{back && <FontAwesomeIcon icon={faArrowLeft} />}
					</Col>
					{/* 타이틀 */}
					<Col xs={8} className="text-center">
						<span className="mnh">{title}</span>
					</Col>
					{/* 빈칸 */}
					<Col xs={2}></Col>
				</Row>
			</div>
		);
	} else {
		return (
			<div className="mobile-header">
				<Row className="m-0 px-2 py-3">
					{/* 타이틀 */}
					<Col
						xs={2}
						className="text-start fs-5 fw-bold pointer"
						onClick={() => {
							window.location.href = "/";
						}}
					>
						SOGONFIT
					</Col>
					<Col xs={8}></Col>
					<Col
						xs={2}
						className="text-end fs-6 text-grey pointer"
						onClick={() => {
							window.location.href = "/brands";
						}}
					>
						<FontAwesomeIcon
							icon={faHashtag}
							onClick={() => {
								window.location.href = "/";
							}}
						/>{" "}
					</Col>
				</Row>
			</div>
		);
	}
}
