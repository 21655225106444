import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { getProductCustomer, getProductDetail } from '../../../../api/admin/magazine';

import { useInfiniteQuery } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../../Component/LoadingSpinner';

// 매거진 관리 페이지 모달
// 모달1(AppendModal) : 상품영역 추가 or 에디터 콘텐츠 추가 선택용 모달
// 모달2(ProductModal) : 상품영역 추가용 모달
// 모달3(EditProductModal) : 상품영역 수정용 모달


// [모달1] 상품영역 추가 or 에디터 콘텐츠 추가 선택용 모달
export function AppendModal({ showModal, handleCloseModal, setModalType, handleAddEditor, handleAddProduct}){
    return(
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>추가할 항목을 선택하세요</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button onClick={() => { setModalType('editor'); handleAddEditor(); }} className="w-100 mb-2">에디터 추가</Button>
          <Button onClick={() => { setModalType('product'); handleAddProduct(); }} className="w-100">상품 추가</Button>
        </Modal.Body>
      </Modal>
    )
}


// [모달2] 상품영역 추가 모달
export function ProductModal({ showProductModal, handleCloseProductModal, handleConfirm}){
    const [step, setStep] = useState(1);
    const [layout, setLayout] = useState(''); //'1 : 2개', '2 : 4개', '3 : 6개'
    const [type, setType] = useState(''); // product, new, best, random

    function onSubmit(){
        handleConfirm({
            type: type,
            layout: layout
        });
        handleCloseProductModal(false);
        setStep(1);
        setType('');
        setLayout('');
    }

    function modalReset(){
        setStep(1);
        setType('');
        setLayout('');
    }
    
    return(
        <Modal show={showProductModal} onHide={()=>{
            // 모달 닫기 시 입력 데이터 초기화
            handleCloseProductModal(false);
            setStep(1);
            setType('');
            setLayout('');
        }}>
            {step === 1 && <ProductModalStep1
                layout={layout}
                setLayout={setLayout}
                setStep={setStep}
            />}
            {step === 2 && <ProductModalStep2
                type={type}
                setType={setType}
                setStep={setStep}
                onSubmit={onSubmit}
            />}
            {step === 3 && <ProductSelectModal
                type={'create'}
                show={showProductModal}
                close={handleCloseProductModal}
                handleConfirm={handleConfirm}
                modalReset={modalReset}
            />}
        </Modal>
    )
}

// [모달3] 상품영역 수정,삭제 모달
export function EditProductModal({ showEditProductModal, handleCloseProductModal, handleConfirm, index}){
    const [step, setStep] = useState(1);
    const [layout, setLayout] = useState(''); // '1', '2', '3'
    const [type, setType] = useState(''); // product, new, best, random

    function onSubmit(){
        handleConfirm(index,{
            type: type,
            layout: layout
        });
        handleCloseProductModal(false);
        setStep(1);
        setType('');
        setLayout('');
    }

    function modalReset(){
        setStep(1);
        setType('');
        setLayout('');
    }

    // 상품 삭제
    function onDelete(){
        handleConfirm(index, {
            type: 'content',
            data: ''
        });
        handleCloseProductModal(false);
        modalReset();
    }
    
    return(
        <Modal show={showEditProductModal} onHide={()=>{
            handleCloseProductModal(false);
            setStep(1);
            setType('');
            setLayout('');
        }}>
            {step === 1 && <ProductModalStep1
                edit={true}
                layout={layout}
                setLayout={setLayout}
                setStep={setStep}
                onDelete={onDelete}
            />}
            {step === 2 && <ProductModalStep2
                type={type}
                setType={setType}
                setStep={setStep}
                onSubmit={onSubmit}
            />}
            {step === 3 && <ProductSelectModal
                type={'edit'}
                show={showEditProductModal}
                close={handleCloseProductModal}
                handleConfirm={handleConfirm}
                index={index}
                modalReset={modalReset}
                onDelete={onDelete}
            />}
        </Modal>
    )
}

function ProductModalStep1({layout, setLayout, setStep, edit, onDelete}){
    const on = 'text-center text-primary fw-bold pointer'; // 선택된 스타일
    const off = 'text-center text-dark fw-bold pointer'; // 선택되지 않은 스타일
    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>Step1 - 레이아웃</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className='p-2'>
                    <Col className='p-1' onClick={()=>{setLayout('1')}}>
                        <div className='border rounded-2 p-1'>
                            <p className={layout === '1' ? on : off}>2개씩 보기</p>
                            <img className='w-100 pointer' src={'/img/template_x2_product.png'} alt="" />
                        </div>
                    </Col>
                    <Col className='p-1' onClick={()=>{setLayout('2')}}>
                        <div className='border rounded-2 p-1'>
                            <p className={layout === '2' ? on : off}>4개씩 보기</p>
                            <img className='w-100 pointer' src={'/img/template_x2_product.png'} alt="" />
                            <img className='w-100 pointer' src={'/img/template_x2_product.png'} alt="" />
                        </div>
                    </Col>
                    <Col  className='p-1' onClick={()=>{setLayout('3')}}>
                        <div className='border rounded-2 p-1'>
                            <p className={layout === '3' ? on : off}>6개씩 보기</p>
                            <img className='w-100 pointer' src={'/img/template_x2_product.png'} alt="" />
                            <img className='w-100 pointer' src={'/img/template_x2_product.png'} alt="" />
                            <img className='w-100 pointer' src={'/img/template_x2_product.png'} alt="" />
                        </div>
                    </Col>
                </Row>
                
            </Modal.Body>
            <Modal.Footer style={{flexWrap: 'nowrap'}}>
                {edit ? (
                    <>
                        <Button
                            variant="danger"
                            onClick={() => onDelete()}
                            className='w-50'
                        >삭제</Button>
                        <Button
                            variant="primary"
                            onClick={() => setStep(2)}
                            className='w-50'
                        >다음</Button>
                    </>
                ):(
                    <Button
                        variant="primary"
                        onClick={() => setStep(2)}
                        className='w-100'
                    >다음</Button>
                )}
            </Modal.Footer>
        </>
    )
}

function ProductModalStep2({type, setType, setStep, onSubmit}){
    const on = 'text-primary fw-bold pointer';
    const off = 'text-dark fw-bold pointer';
    return(
        <>
            <Modal.Header closeButton>
                <Modal.Title>Step2 - 상품타입</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={type=='product'? on : off } 
                    onClick={()=>{setType('product')}}>
                    - 직접선택 : 원하는 상품을 직접 선택합니다.(고정상품)
                </p>
                <p className={type=='new'? on : off } 
                    onClick={()=>{setType('new')}}>
                    - 최신상품 : 최근에 등록된 상품이 등록됩니다.(콘텐츠 조회 시 변경)
                </p>
                <p className={type=='best'? on : off } 
                    onClick={()=>{setType('best')}}>
                    - 인기상품 : 조회수가 높은 상품이 등록됩니다.(콘텐츠 조회 시 변경)
                </p>
                <p className={type=='random'? on : off } 
                    onClick={()=>{setType('random')}}>
                    - 랜덤상품 : 조회시 매번 새롭게 상품이 등록됩니다.(콘텐츠 조회 시 변경)
                </p>
            </Modal.Body>
            <Modal.Footer style={{flexWrap: 'nowrap'}}>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setStep(1);
                        setType('');
                    }}
                    className='w-50'
                >이전</Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        type === 'product' ? setStep(3) : onSubmit();
                    }}
                    className='w-50'
                >다음</Button>
            </Modal.Footer>
        </>
    )
}

// 상품 직접선택용 모달
function ProductSelectModal({type, show, close, handleConfirm, index, modalReset}){
    return(
        <Modal show={show} onHide={()=>close(false)}>
            <Modal.Header closeButton>
                <Modal.Title>추가할 상품을 선택해주세요</Modal.Title>
            </Modal.Header>
            <ProductModalManagement
                type={type}
                show={show}
                close={close}
                handleConfirm={handleConfirm}
                index={index}
                modalReset={modalReset}
            />
        </Modal>
    )
}


// 모달2, 모달3 공통 기능. 상품생성, 수정, 삭제
function ProductModalManagement({type, show, close, handleConfirm, index, modalReset, onDelete}){
    // type : 생성(create) or 수정(edit)
    // show : 모달 T/F 여부
    // close : 모달 닫기 함수
    // handleConfirm : 부모 컴포넌트로 데이터 전달 함수
    // index : 콘텐츠의 현재 순서(type === edit 경우만 사용)
    // modalReset : 모달 정보 초기화 힘수

    const [productList, setProductList] = useState([]); // 상품 리스트
    const [selectedProducts, setSelectedProducts] = useState([]); // 선택한 상품 리스트

    // useEffect(()=>{
    //     show ? ( // 모달이 열릴 때 상품리스트 조회
    //         getProductCustomer({})
    //             .then(res => setProductList(res?.data?.data))
    //             .catch(err => console.log(err))
    //     ) : ( // 모달이 닫히면 선택한 상품 리스트 초기화
    //         setSelectedProducts([])
    //     )
    // }, [show])

    useEffect(() => {
        if (!show) {
            setSelectedProducts([]);
        }
    }, [show]);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isLoading,
    } = useInfiniteQuery(
        'productdata',
        ({ pageParam = 1 }) => getProductCustomer({page: pageParam}), {
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage ?? false;
        },
    });


    // 상품선택 토글
    const toggleProductSelection = (productId) => {
        setSelectedProducts((prevSelectedProducts) => {
            if (prevSelectedProducts.includes(productId)) {
                return prevSelectedProducts.filter((id) => id !== productId);
            } else {
                return [...prevSelectedProducts, productId];
            }
        });
    };

    const handleProductSelection = (selectedProducts) => {
        // 상품 선택 완료 테스트(ID값만 전달)
        let confirmDataId = {
            type: 'product',
            data: selectedProducts
        }
        type === 'create' ? handleConfirm(confirmDataId) : handleConfirm(index, confirmDataId);
        close(false);
        modalReset();
    };

    // 상품 선택 완료
    function onSuccess(){
        handleProductSelection(selectedProducts);
    }

    return(
        <>
            <Modal.Body id="scrollable-modal-body" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <Row>
                {/*
                {productList?.map((product) => (
                    <Col xs={6} sm={4} key={product?.id}>
                        <div
                            className={`border text-center p-2 mb-2 rounded-2 ${selectedProducts.includes(product.id) ? 'border-primary bg-light' : ''}`}
                            onClick={() => toggleProductSelection(product?.id)}
                        >
                            <div style={{ width: '100%', height: '150px', overflow: 'hidden' }}>
                            <img 
                                src={product?.image}
                                alt="상품이미지"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                            </div>
                            <p>{product?.name}</p>
                        </div>
                    </Col>
                ))}
                */}
                {isLoading ? (
                    <LoadingSpinner />
                ) : (
                    <InfiniteScroll
                        dataLength={data?.pages?.reduce((total, page) => total + page?.data?.data?.length, 0) || 0}
                        scrollableTarget="scrollable-modal-body"
                        style={{ display: 'flex', flexWrap: 'wrap', gap: '10px'}}
                        next={()=>fetchNextPage()}
                        hasMore={hasNextPage}
                        loader={<div>로딩중...</div>}
                        endMessage={<div></div>}
                    >
                        {data?.pages?.map((page, pageIndex) => (
                            <React.Fragment key={pageIndex}>
                                {page?.data?.data?.map(item => (
                                    <div key={item?.id} style={{width: '31%'}}>
                                        <div
                                            className={`border text-center p-2 mb-2 rounded-2 ${selectedProducts.includes(item?.id) ? 'border-primary bg-light' : ''}`}
                                            onClick={() => toggleProductSelection(item?.id)}
                                        >
                                            <div style={{ width: '100%', height: '150px', overflow: 'hidden' }}>
                                                <img 
                                                    src={item?.image}
                                                    alt="상품이미지"
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                />
                                            </div>
                                            <p>{item?.name}</p>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        ))}
                    </InfiniteScroll>
                )}
                </Row>
            </Modal.Body>
            <Modal.Footer style={{flexWrap: 'nowrap'}}>
                <>
                {
                    type === 'create' ? (
                        // 생성 타입일 경우 선택완료 버튼만 표시
                        <Button
                            variant="primary"
                            onClick={() => onSuccess()}
                            className='w-100'
                        >선택 완료</Button>
                    ) : (
                        // 수정 타입일 경우 삭제, 수정 버튼 표시
                        <>
                            <Button
                                variant="danger"
                                onClick={() => onDelete()}
                                className='w-50'
                            >삭제</Button>
                            <Button
                                variant="primary"
                                onClick={() => onSuccess()}
                                className='w-50'
                            >수정</Button>
                        </>
                    )
                }
                </>
            </Modal.Footer>
        </>
    )
}
