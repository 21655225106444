/***************************************************************************************
* FileName      : adminLogin.js
* Description   : 관리자 로그인 페이지
* Company       : (주)엘리그
* Author        : 박권희, 최현민
* Created Date  : 2023.12.14
* Modifide Date : 2020.12.14 : CHM : import 정리 및 로그인 실패 알림 추가
*               : 
* Reference     : 미정
***************************************************************************************/

import React, { useEffect } from 'react';
import { setCookie, setTokenToCookie } from '../../api/cookieUtils.js';

import { useForm } from 'react-hook-form';

import { useMutation, useQueries, useQueryClient } from 'react-query';
import { usernameLogIn } from '../../api/common/memberApi.js';
import { Link } from 'react-router-dom';

function AdminLogin()
{
    const { register, handleSubmit, formState: { errors } } = useForm();
    const queryClient = useQueryClient();

    const mutation = useMutation(usernameLogIn ,{
      onMutate: () => {
        console.log("mutaion starting")
      },
      onSuccess: (data) => {
        
        // if(process.env.REACT_APP_ENV_NAME === 'dev'){
          console.log('개발자 모드입니다.');
          setCookie('access', data?.data?.access); // Save Access Token
          setCookie('refresh', data?.data?.refresh); // Save Refresh Token
        // };

        window.location.href = '/admin/loading';
      },
      onError : (error) => {
        alert(error?.response?.data?.error);
      }
    });

    const onSubmit = ({ username,password }) => {
      mutation.mutate({ username,password })
    };

    return (
        <>
        <body id="Adminlogin">
          <section className="Adminlogin">
              <div className='adminLogo-wrap'>
                <img src={process.env.PUBLIC_URL + '/img/slogo.png'} alt="" width="100%" />

                <p className='gray_txt'>소곤핏 관리자 계정 로그인</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>     
                <div className='signIn-form'>
                  <div className='input_box'>
                      <span className='loginspan'>아이디</span>
                      <input 
                          type='text' 
                          name='username' 
                          className={errors.username ? 'error-input' : ''}
                          placeholder='아이디를 입력해주세요'
                          {...register('username', { required: '사용자 이름을 입력하세요' })} />
                      
                      <span className='loginspan'>비밀번호</span>
                      <input 
                          type='password' 
                          name='password' 
                          placeholder='비밀번호를 입력해주세요 '
                          className={errors.password ? 'error-input' : ''}
                          {...register('password', { required: '비밀번호를 입력하세요' })} />
                        
                  </div>
                  
                  <button 
                      className='btn_2'
                      isLoding={mutation.isLoading} 
                      type="submit">
                      로그인
                  </button>
                        
                <Link to='/admin/signup' className='signUpBtn'>회원가입</Link>
                </div>
              </form>
              
            </section>
        </body>
        </>
    )
}

export default AdminLogin 