/***************************************************************************************
* FileName      : BbtiFilter.js
* Description   : 고객 > 상품 리스트 > bbti 필터링 모달 컴포넌트
* Company       : (주)엘리그
* Author        : 박권희
* Created Date  : 2024.06.10
* Modifide Date : 
*               : 
* Reference     : 
***************************************************************************************/
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useGetBbti } from '../../../api/admin/brandApi';

function BbtiFilter({ show, onHide ,setFilteringBbti }) {

    // bbti 조회 
    const { data: BbtiData, isLoading: isBbtiDataLoading, isError: isBbtiDataError } = useGetBbti();
 
    // bbti 선택
    const [selectedTypes, setSelectedTypes] = useState([]);

    const handleBBTiCheckboxChange = (type) => {
      if (selectedTypes.includes(type)) {
        setSelectedTypes(selectedTypes.filter(t => t !== type));
      } else {
        setSelectedTypes([...selectedTypes, type]);
      }
    };

    // 초기화
    const resetFilters = () => {
      setSelectedTypes([]);
      setFilteringBbti('');
    };

    // 필터링 적용하기
    const applySettings = () => {
      const formattedSelectedTypes = selectedTypes.join(',');
      setFilteringBbti(formattedSelectedTypes);
      onHide();
    };

    return (
    <>
      <Offcanvas show={show} onHide={onHide}  placement="bottom" >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>BBTI 필터</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='bbtiList_service'>
              { isBbtiDataLoading ? (
                  <p>Loding...</p>
              ) : isBbtiDataError ? (
                  <p>Error bbti data</p>
              ) : (
                  BbtiData?.data.map(item => (
                      <div key={item?.id}>

                          <input 
                              type="checkbox"
                              className='bbti_checkbox'  
                              checked={selectedTypes.includes(item?.type)}
                              onChange={() => handleBBTiCheckboxChange(item?.type)}
                              id={`bbti_checkbox_${item.id}`} />

                          <label 
                              className="bbti_label_s" 
                              htmlFor={`bbti_checkbox_${item.id}`}>
                              {item?.type}
                          </label>

                      </div>
                  ))
              )}
            </div>
            <div className='bbtiFilterBtn-wrap'>
                <button className="btn-primary reset-btn" onClick={resetFilters}>초기화</button>
                <button className="btn-primary apply-btn" onClick={applySettings}>적용하기</button>
            </div>
         
          </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}



export default BbtiFilter;