// 관리자 > 매거진 관리
import {  queryClient , useMutation , useQuery , prefetchQuery } from 'react-query';
import { useState , useEffect} from 'react';
import { useQueryClient } from 'react-query';
import { instance } from '../axiosConfig.js';

const PAGE_SIZE = 10;

// 매거진 등록 
export const registerMagazine = (data) =>
  instance.post(`/magazine/`, data).then(response => response.data);

// 매거진 카테고리 조회 
export const useGetCategory = () => {
  return useQuery('category', async () => {
    const response = await instance.get(`category/?page_size=all`);
    return response.data;
  });
};

// 매거진 카테고리 순서 변경
export const updateMagazineOrdering = (data) => {
  return instance.post(`category/priority`, {
    priority: data
  })
}

//선택한 매거진 카테고리 조회
export const getCategory = async (id) => {
  try {
    if(id !== "all"){
      const response = await instance.get(`category/${id}`);
      return response.data;
    }
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// 선택한 매거진 카테고리 수정.
export const updateCategory = (id, newData) =>
  instance.patch(`category/${id}`, newData, {
    headers: {
      'Content-Type': 'application/json',
    }
  }).then(response => response.data);

// 선택한 매거진 카테고리 삭제 
export const deleteMagazineCategory = (magazineId) => {
  return instance.delete(`category/${magazineId}`, {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.data)
  .catch(error => {
    // console.error('Error deleting :', error);
    alert(error.response.data.data)
    throw error; 
  });
};

// 대시보드 매거진 조회수 많은순
export const useGetOrderMagazineContent = () => {
  return useQuery('orderMagazine', async () => {
    const response = await instance.get(`magazine/?ordering=view`);
    return response.data;
  });
};

// 매거진 조회 
const fetchMagazineContent = (id, page, pageSize) => {
  let url;
  if (id === "all") {
    url = `magazine/?page=${page}&page_size=${pageSize}`;
  } else {
    url = `magazine/?category=${id}&page=${page}&page_size=${pageSize}`;
  }

  return instance.get(url)
      .then(res => res.data);
};

export const useMagazineContent = (id) => {
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [toggle, setToggle] = useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0);
    const nextPage = page + 1;
    queryClient.prefetchQuery([`magazineContent${id}`, id , nextPage],
      () => fetchMagazineContent(id , nextPage)
    );
  },[page , queryClient])

  // console.log("id:",id)
  useEffect(() => {
    setPage(1);
  }, [id]);

  const { data, error, isFetching } = useQuery([`magazineContent${id}`, id , page], () => fetchMagazineContent(id , page), {
    onSuccess: (data) => {
      setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));
    
      if (page < totalPages) {
        prefetchQuery(['magazineContent', page + 1], () => fetchMagazineContent(page + 1));
      }
    }
  });

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    totalPages,
    toggle,
    setToggle,
  };
};


// 매거진 카테고리 등록
export const registerMagazineCategory = (data) =>
  instance.post(`/category/`, data).then(response => response.data);

//선택한 매거진 정보 조회
export const getMagazine = async (id) => {
  try {
    const response = await instance.get(`magazine/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

// 선택한 매거진 수정
export const updateMagazine = (id, newData) =>
  instance.patch(`magazine/${id}`, newData).then(response => response.data);

// 관리자가 매거진 공개,비공개 변경 
export const updateMagazineHidden = (pk, hidden) => {
  return instance.patch(`magazine/${pk}`,
  {
    hidden: hidden
  })
}

// 선택한 매거진 삭제 
export const deleteMagazine = (magazineId) => {
  return instance.delete(`magazine/${magazineId}`)
  .then(response => response.data)
  .catch(error => {
    console.error('Error deleting :', error);
    throw error; 
  });
};

// 매거진 상품 조회
export const getProductCustomer = ({bbti, brand, brandName, id, ordering, page, search, pageSize}) => {
  const params = {};
  if (bbti) params.bbti = bbti;
  if (brand) params.brand = brand;
  if (brandName) params.brand__name = brandName;
  if (id) params.id__in = id;
  if (ordering) params.ordering = ordering;
  if (page) params.page = page;
  if (search) params.search = search;
  if (pageSize) params.page_size = pageSize;
  return  instance.get(`product/customer`, {params})
}

// 매거진 에디터로 작성된 상품 상세정보 조회
export const getProductDetail = (id) => {
  return instance.get(`product/${id}`)
}

// export const deleteMagazine = (magazineId) =>{
// 	return instance.delete(`magazine/${magazineId}`);
// }

// CP용 매거진 등록 
export const registercpMagazine = (data) =>
  instance.post(`/magazine/log`, data).then(response => response.data);

// 매거진 조회 
const fetchMagazineLog = (id, page, pageSize) => {
  let url;
  if (id === "all") {
      url = `magazine/log?page=${page}&page_size=${pageSize}`;
  } else {
      url = `magazine/log?category=${id}&page=${page}&page_size=${pageSize}`;
  }

  return instance.get(url)
      .then(res => res.data);
};

export const useMagazineLog = (id) => {
  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [toggle, setToggle] = useState(false);

  useEffect(()=>{
    window.scrollTo(0, 0);
    const nextPage = page + 1;
    queryClient.prefetchQuery([`magazineLog${id}`, id , nextPage],
      () => fetchMagazineLog(id , nextPage)
    );
  },[page , queryClient])

  useEffect(() => {
    setPage(1);
  }, [id]);

  const { data, error, isFetching } = useQuery([`magazineLog${id}`, id , page], () => fetchMagazineLog(id , page), {
    onSuccess: (data) => {
      setTotalPages(Math.ceil(data.totalCount / PAGE_SIZE));

      if (page < totalPages) {
        prefetchQuery(['magazineLog', page + 1], () => fetchMagazineLog(page + 1));
      }
    }
  });

  return {
    data,
    error,
    isFetching,
    page,
    setPage,
    totalPages,
    toggle,
    setToggle,
  };
};

// 관리자가 CP 매거진 승인 
export const updateMagazineLog = (id, newData) =>
  instance.patch(`magazine/log/${id}`, newData).then(response => response.data);

// cp 선택한 매거진 수정 요청
export const updateCPMagazine = (id, newData) =>
  instance.post(`magazine/log`, newData).then(response => response.data);

//cp 매거진 정보 조회
export const getCPMagazine = async (id) => {
  try {
    const response = await instance.get(`magazine/log/${id}`);
    return response.data;
  } catch (error) {
    throw new Error('데이터를 가져오는 중 오류가 발생했습니다.');
  }
};

